import axios from 'axios';

export const watchEvents = (path, handler, eventNames = ['message']) => {
  let cancelled = false;

  let eventSource;

  let heartbeatH;
  const onHeartbeat = () => {
    if (heartbeatH) clearTimeout(heartbeatH);
    if (cancelled) return undefined;
    heartbeatH = setTimeout(() => {
      reconnect();
    }, 10000);
  };

  const reconnect = () => {
    if (cancelled) return undefined;
    if (eventSource) {
      eventSource.close();
    }

    eventSource = new EventSource(`/api/${path}`);

    const onEvent = eventName => {
      let firstEventReceived = false;
      return event => {
        onHeartbeat();
        handler(JSON.parse(event.data), {
          eventName,
          initial: firstEventReceived ? false : (firstEventReceived = true),
        });
      };
    };

    eventNames.forEach(eventName => eventSource.addEventListener(eventName, onEvent(eventName)));

    eventSource.addEventListener('Heartbeat', () => {
      onHeartbeat();
    });
    onHeartbeat();
  };

  reconnect();

  return () => {
    if (cancelled) return undefined;
    cancelled = true;
    if (heartbeatH) clearTimeout(heartbeatH);
    if (eventSource) eventSource.close();
  };
};

export default axios.create({
  baseURL: '/api',
});
