import React, { useEffect, useRef } from 'react';
import { Alert, Panel } from 'react-bootstrap';
import { TASK_FINISH_STATUS_FAILED } from '../../constants/task';
import { useUncontrolled } from '../../utils/useUncontrolled';
import { TaskEventsList } from './EventsList';
import { TaskStepsProgress } from './StepsProgress';

export const TaskProgressPanel = props => {
  const {
    taskMeta = {},
    stepsDescs,
    taskEvents,
    onComplete,
    expanded,
    onToggle,
    defaultExpanded,
    markTaskAsRead,
  } = props;

  const { taskId, taskTitle, finishStatus, hasBeenRead } = taskMeta;

  const defaultExpandedCtrl =
    defaultExpanded !== undefined ? defaultExpanded : finishStatus !== 'completed';

  const [expandedUnctrl, onToggleUnctrl] = useUncontrolled(expanded, onToggle, defaultExpandedCtrl);

  // Only report if task wasn't completed from start
  const reportCompletion = useRef(finishStatus ? undefined : onComplete);

  useEffect(() => {
    if (reportCompletion.current && finishStatus === 'completed') {
      reportCompletion.current();
      // Resetting it so it won't be reported twice
      reportCompletion.current = undefined;
    }
  }, [finishStatus]);

  useEffect(() => {
    if (!taskId || !finishStatus || hasBeenRead) return undefined;

    const handler = setTimeout(() => {
      markTaskAsRead(taskId);
    }, 1000);

    return () => {
      clearTimeout(handler);
    };
  }, [finishStatus, hasBeenRead, taskId]);

  const failedAlert = finishStatus === TASK_FINISH_STATUS_FAILED && (
    <Alert bsStyle="danger">This task failed.</Alert>
  );

  return (
    <Panel
      expanded={expandedUnctrl}
      onToggle={onToggleUnctrl}
      data-test-id="TaskProgressPanel"
      data-events-count={(taskEvents || []).length}
      data-finish-status={finishStatus}
    >
      <Panel.Heading>
        <Panel.Title toggle>{taskTitle}</Panel.Title>
      </Panel.Heading>
      <Panel.Collapse>
        <Panel.Body>
          <TaskStepsProgress taskEvents={taskEvents} stepsDescs={stepsDescs} />
          {failedAlert}
          <TaskEventsList taskEvents={taskEvents} />
        </Panel.Body>
      </Panel.Collapse>
    </Panel>
  );
};
