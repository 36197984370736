import React, { useMemo } from 'react';
import { ProgressBar } from 'react-bootstrap';

export const TaskStepsProgress = props => {
  const { stepsDescs = [], taskEvents } = props;

  const stepResults = useMemo(
    () =>
      taskEvents
        ? taskEvents.filter(({ eventType }) => eventType === 'TaskEventStepResult')
        : undefined,
    [taskEvents],
  );

  const completedResults = useMemo(
    () =>
      stepResults
        ? stepResults
            .filter(({ completed }) => completed)
            .reduce(
              (res, event) => ({
                ...res,
                [event.name]: event,
              }),
              {},
            )
        : undefined,
    [stepResults],
  );

  const failureResults = useMemo(
    () =>
      stepResults
        ? stepResults
            .filter(({ error }) => !!error)
            .reduce(
              (res, event) => ({
                ...res,
                [event.name]: event,
              }),
              {},
            )
        : undefined,
    [stepResults],
  );

  let hasStepsResults = false;
  const stepsDescsReversed = useMemo(() => [...stepsDescs].reverse(), [stepsDescs]);

  if (!taskEvents)
    return (
      <ProgressBar now={100} active={true} bsStyle="info" striped={true} style={{ opacity: 0.4 }} />
    );

  const stepsEls = stepsDescsReversed
    .map(({ name, title, weight }) => {
      let bsStyle;
      if (failureResults[name]) bsStyle = 'danger';
      if (completedResults[name]) bsStyle = 'success';

      if (bsStyle) hasStepsResults = true;

      return (
        <ProgressBar
          key={name}
          bsStyle={bsStyle}
          style={{
            opacity: hasStepsResults ? 1 : 0.4,
          }}
          now={weight}
          label={title}
        />
      );
    })
    .reverse();

  return <ProgressBar>{stepsEls}</ProgressBar>;
};
