import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { reduxForm, Field, propTypes, change } from 'redux-form';
import _ from 'lodash';
import { Modal, Button, Row, Col } from 'react-bootstrap';
import { DEALER_SETTINGS_MODAL_FORM } from '../../redux/modules/form';
import { getDealerSettings, updateDealerSettings } from '../../redux/modules/dealerSettings';
import ValidationCheckbox from '../../components/common/ValidationCheckbox';

export function DealerSettingsModal(props) {
  const [dealerSettingsLoaded, setDealerSettingsLoaded] = useState(false);

  useEffect(() => {
    const { dealerId, getDealerSettings } = props;
    getDealerSettings(dealerId);
  }, []);

  const { dealerSettingsId, features } = props;

  useEffect(() => {
    const { dispatch } = props;
    if (dealerSettingsId && !dealerSettingsLoaded) {
      // NOTE - Not in love with pattern but need a better pre-mount service pattern
      dispatch(
        change(DEALER_SETTINGS_MODAL_FORM, 'features.usedNew', _.get(features, 'usedNew', false)),
      );
      setDealerSettingsLoaded(true);
    }
  }, [dealerSettingsId, features, dealerSettingsLoaded]);

  const { dealerId, handleSubmit, onCancel } = props;

  return (
    <Modal show={!!dealerId}>
      <Modal.Header>
        <Modal.Title>Dealer Settings ({dealerId})</Modal.Title>
      </Modal.Header>
      {!!dealerSettingsId && (
        <form className="form-horizontal" onSubmit={handleSubmit} noValidate={true}>
          <Modal.Body>
            <Field
              name="features.usedNew"
              type="checkbox"
              component={ValidationCheckbox}
              label="Used/New GAP"
            />
          </Modal.Body>
          <Modal.Footer>
            <Row>
              <Col xs={8}>
                <Button bsStyle="primary" type="submit" block>
                  Save
                </Button>
              </Col>
              <Col xs={4}>
                <Button onClick={onCancel} bsStyle="default" block>
                  Cancel
                </Button>
              </Col>
            </Row>
          </Modal.Footer>
        </form>
      )}
    </Modal>
  );
}

DealerSettingsModal.propTypes = {
  ...propTypes,
  dealerId: PropTypes.string.isRequired,
  dealerSettingsId: PropTypes.string,
  getDealerSettings: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

DealerSettingsModal.defaultProps = {
  dealerSettingsId: null,
};

export default compose(
  connect(
    ({ dealerSettings }) => ({
      dealerSettingsId: _.get(dealerSettings, 'data._id'),
      features: _.get(dealerSettings, 'data.features', {}),
    }),
    {
      getDealerSettings,
      updateDealerSettings,
    },
    (stateProps, dispatchProps, ownProps) => ({
      ...stateProps,
      ...dispatchProps,
      ...ownProps,
      onSubmit: async data => {
        await dispatchProps.updateDealerSettings(stateProps.dealerSettingsId, {
          ...data,
          features: _.mapValues(stateProps.features, (v, k) => !!_.get(data.features, k)),
        });
        ownProps.onComplete();
      },
      onCancel: ownProps.onComplete,
    }),
  ),
  reduxForm({
    form: DEALER_SETTINGS_MODAL_FORM,
  }),
)(DealerSettingsModal);
