import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { LinkContainer } from 'react-router-bootstrap';
import { connect } from 'react-redux';

export function OperationComplete(props) {
  const message = _.get(props.router, 'location.query.message', 'Your payment was successful!');
  const buttonText = _.get(props.router, 'location.query.buttonText', 'Submit another payment');

  return (
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-12">
          <h2 className="text-center">{message}</h2>
        </div>
      </div>
      <div className="row justify-content-xs-center">
        <div className="col-xs-4 col-xs-offset-4">
          <LinkContainer to={props.user ? '/payment' : '/guestPayment'}>
            <a className="btn btn-primary btn-block">{buttonText}</a>
          </LinkContainer>
        </div>
      </div>
    </div>
  );
}

OperationComplete.propTypes = {
  user: PropTypes.object,
  router: PropTypes.object.isRequired,
};

OperationComplete.defaultProps = {
  user: null,
};

export default connect(state => ({ user: state.auth.user }))(OperationComplete);
