import _ from 'lodash';
import { combineReducers } from 'redux';
import { routerReducer as routing } from 'react-router-redux';
import { reducer as form } from 'redux-form';
import auth from './auth';
import dealer from './dealer';
import dealerSettings from './dealerSettings';
import deployment from './deployment';
import eSign from './eSign';
import info from './info';
import issuedContract from './issuedContract';
import lender from './lender';
import messaging from './messaging';
import emailVerification from './emailVerification';
import notifs from './notifs';
import page from './page';
import queryParams from './queryParams';
import user from './user';
import newQuote from './newQuote';
import savedQuote from './savedQuote';
import eSignAdmin from './eSignAdmin';
import template from './template';
import apiKeys from './apiKeys';
import { task } from './task';
import verificationOverride from './verificationOverride';
import uploadCSVFile from './uploadCSVFile';

const CLEAR_ALL_MESSAGES = 'redux/root/CLEAR_ALL_MESSAGES';
const CLEAR_ALL_ERRORS = 'redux/root/CLEAR_ALL_ERRORS';

const appReducer = combineReducers({
  auth,
  dealer,
  dealerSettings,
  deployment,
  eSign,
  form,
  info,
  issuedContract,
  lender,
  messaging,
  emailVerification,
  notifs,
  page,
  queryParams,
  routing,
  user,
  newQuote,
  savedQuote,
  eSignAdmin,
  template,
  apiKeys,
  task,
  verificationOverride,
  uploadCSVFile,
});

export function clearAllMessages() {
  return dispatch => dispatch({ type: CLEAR_ALL_MESSAGES });
}

export function clearAllErrors() {
  return dispatch => dispatch({ type: CLEAR_ALL_ERRORS });
}

function rootReducer(state, action) {
  switch (action.type) {
    case CLEAR_ALL_MESSAGES:
      return appReducer(
        _.mapValues({ ...state }, subState => ({
          ...subState,
          message: null,
        })),
        action,
      );
    case CLEAR_ALL_ERRORS:
      return appReducer(
        _.mapValues({ ...state }, subState => ({
          ...subState,
          error: null,
        })),
        action,
      );
    default:
      return appReducer(state, action);
  }
}

export default rootReducer;
