import _ from 'lodash';
import { reset } from 'redux-form';
import store from '../store';

export const PROFILE_FORM = 'PROFILE_FORM';
export const PROFILE_CHANGE_PASSWORD_FORM = 'PROFILE_CHANGE_PASSWORD_FORM';
export const ADMIN_OVERRIDE_FORM = 'adminOverride';
export const DEALER_SETTINGS_MODAL_FORM = 'dealerSettingsModal';
export const DEALER_SETTINGS_FORM = 'dealerSettingsForm';
export const EMAIL_VERIFICATION_FORM = 'emailVerficationForm';
export const NEW_ACCOUNT_FORM = 'newAccount';
export const E_SIGN_MODAL_FORM = 'eSignModal';
export const PAYMENT_FORM = 'payment';
export const NEW_QUOTE_META_FORM = 'newQuote';
export const VSC_QUOTE_FORM = 'vscQuote';
export const GAP_QUOTE_FORM = 'gapQuote';
export const VEHICLE_BASIC_INFORMATION_FORM = 'vehicleBasicInformation';
export const VSC_RATE_FORM = 'vscRate';
export const GAP_RATE_FORM = 'gapRate';
export const TWP_RATE_FORM = 'twpRate';
export const CUSTOMER_INFORMATION_RATE_FORM = 'customerInformationRate';
export const LENDER_INFORMATION_RATE_FORM = 'lenderInformationRate';
export const QUOTE_SUMMARY_FORM = 'quoteSummary';
export const SAVE_ACCOUNT_FORM = 'saveAccount';
export const NEW_USER_FORM = 'newUser';
export const TEMPLATE_META_DATA_FORM = 'templateMetaData';
export const EDIT_FIELDS_FORM = 'editFields';
export const UPDATE_MESSAGING_MODAL_FORM = 'updateMessagingModal';
export const API_KEYS_FORM = 'apiKeys';
export const UPDATE_ISSUED_CONTRACT_MODAL = 'updateIssuedContractModal';
export const VERIFICATION_OVERRIDE_FORM = 'verificationOverRideForm';
export const UPLOAD_CSV_FILE_FORM = 'uploadCsvFile';

const ALL_FORMS = [
  PROFILE_FORM,
  PROFILE_CHANGE_PASSWORD_FORM,
  ADMIN_OVERRIDE_FORM,
  EMAIL_VERIFICATION_FORM,
  NEW_ACCOUNT_FORM,
  DEALER_SETTINGS_MODAL_FORM,
  DEALER_SETTINGS_FORM,
  E_SIGN_MODAL_FORM,
  PAYMENT_FORM,
  NEW_QUOTE_META_FORM,
  VSC_QUOTE_FORM,
  GAP_QUOTE_FORM,
  VEHICLE_BASIC_INFORMATION_FORM,
  VSC_RATE_FORM,
  GAP_RATE_FORM,
  SAVE_ACCOUNT_FORM,
  CUSTOMER_INFORMATION_RATE_FORM,
  LENDER_INFORMATION_RATE_FORM,
  QUOTE_SUMMARY_FORM,
  SAVE_ACCOUNT_FORM,
  NEW_USER_FORM,
  EDIT_FIELDS_FORM,
  API_KEYS_FORM,
  UPDATE_ISSUED_CONTRACT_MODAL,
  VERIFICATION_OVERRIDE_FORM,
  UPLOAD_CSV_FILE_FORM,

  // TEMPLATE_META_DATA_FORM
];

export function clearForms() {
  return dispatch => _.each(ALL_FORMS, form => dispatch(reset(form)));
}

export function clearForm(form) {
  return dispatch => dispatch(reset(form));
}

export function selectRegisteredFields(form) {
  return _.get(store.getState(), `form.${form}.registeredFields`);
}
