const STORE_CUSTOMER_E_SIGN_PREFERENCE = 'redux/eSign/STORE_CUSTOMER_E_SIGN_PREFERENCE';
const CLEAR_CUSTOMER_E_SIGN_PREFERENCE = 'redux/eSign/CLEAR_CUSTOMER_E_SIGN_PREFERENCE';
const NAVIGATE_TO_SIGN_CONTRACTS_FAIL = 'redux/eSign/NAVIGATE_TO_SIGN_CONTRACTS_FAIL';

const initialState = {};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case STORE_CUSTOMER_E_SIGN_PREFERENCE:
      return {
        ...state,
        customer: action.customer,
      };
    case CLEAR_CUSTOMER_E_SIGN_PREFERENCE:
      return {
        ...state,
        customer: null,
      };
    case NAVIGATE_TO_SIGN_CONTRACTS_FAIL:
      return {
        ...state,
        error: action.error,
      };
    default:
      return state;
  }
}

export function storeCustomerESignPreference(customer) {
  return dispatch =>
    dispatch({
      type: STORE_CUSTOMER_E_SIGN_PREFERENCE,
      customer,
    });
}

export function clearCustomerESignPreference() {
  return dispatch =>
    dispatch({
      type: CLEAR_CUSTOMER_E_SIGN_PREFERENCE,
    });
}

export function navigateToSignContracts(signingUrl) {
  return window.open(signingUrl, '_blank');
}
