import React, { Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';
import { compose } from 'recompose';
import withState from 'recompose/withState';
import { reduxForm, Field, propTypes } from 'redux-form';
import { Modal, Button, Row, Col, Alert } from 'react-bootstrap';
import { UPDATE_ISSUED_CONTRACT_MODAL } from '../../redux/modules/form';
import { verifyEmail } from '../../redux/modules/email';
import { required, email as emailValidation, notMatch, match } from '../../utils/validation';
import ValidationCheckbox from '../../components/common/ValidationCheckbox';
import ValidationInput from '../../components/common/ValidationInput';
import { getIssuedContract, updateIssuedContract } from '../../redux/modules/issuedContract';
import { ROLES } from '../../constants/issuedContract';

function hasSignedDocuments(documents = [], roleId) {
  return (
    documents
      .reduce((acc, { approvals }) => [...acc, ...approvals], [])
      .filter(({ role, signed }) => role === roleId && !_.isNull(signed)).length !== 0
  );
}

async function UpdateSignersModalAsyncValidation({
  buyerEmail,
  resendOrUpdateBuyer,
  coBuyerEmail,
  resendOrUpdateCoBuyer,
}) {
  // Note - Make sure the email matches the basic regex of an email address before reaching out to server
  if (emailValidation(buyerEmail) === undefined && resendOrUpdateBuyer) {
    try {
      await verifyEmail(buyerEmail);
    } catch (error) {
      return {
        buyerEmail: _.get(error, 'message'),
      };
    }
  }
  if (emailValidation(coBuyerEmail) === undefined && resendOrUpdateCoBuyer) {
    try {
      await verifyEmail(coBuyerEmail);
    } catch (error) {
      return {
        coBuyerEmail: _.get(error, 'message'),
      };
    }
  }
}

const UpdateSignersModal = ({
  handleSubmit,
  valid,
  onCancel,
  error,
  resendOrUpdateBuyer,
  setResendOrUpdateBuyer,
  resendOrUpdateCoBuyer,
  setResendOrUpdateCoBuyer,
  hasCoBuyer,
  hasUpdates,
  loading,
  getIssuedContract,
  httpError,
  buyerHasSignedDocuments,
  coBuyerHasSignedDocuments,
  requestSubmitted,
  asyncValidating,
}) => {
  const errorToDisplay = httpError || error;
  const requestSucceeded = requestSubmitted && !loading && _.isNull(httpError);

  useEffect(() => {
    getIssuedContract();
  }, []);

  useEffect(() => {
    if (requestSucceeded) {
      setTimeout(onCancel, 2000); // Cancel the modal
    }
  }, [requestSucceeded]);

  const hasDestructiveUpdates =
    (resendOrUpdateBuyer && buyerHasSignedDocuments) ||
    (resendOrUpdateCoBuyer && coBuyerHasSignedDocuments);

  return (
    <Modal show>
      <Modal.Header>
        <Modal.Title>Update Signers</Modal.Title>
      </Modal.Header>
      <form className="form-horizontal" onSubmit={handleSubmit} noValidate={false}>
        <Modal.Body>
          <Row>
            <Col xs={12}>
              {hasCoBuyer ? (
                <Field
                  name="resendOrUpdateBuyer"
                  component={ValidationCheckbox}
                  label="Update buyer"
                  onChange={() => setResendOrUpdateBuyer(!resendOrUpdateBuyer)}
                  disabled={loading}
                />
              ) : (
                ''
              )}
              {resendOrUpdateBuyer ? (
                <Fragment>
                  {buyerHasSignedDocuments ? (
                    <Alert bsStyle="warning">Customer has already signed the document.</Alert>
                  ) : (
                    ''
                  )}
                  <Field
                    name="buyerEmail"
                    type="text"
                    component={ValidationInput}
                    label="Email"
                    placeholder="eg. george.jones@superiorautos.com"
                    validate={[required, emailValidation]}
                    disabled={loading}
                  />
                  <Field
                    name="buyerConfirmEmail"
                    type="text"
                    component={ValidationInput}
                    label="Confirm"
                    placeholder="eg. george.jones@superiorautos.com"
                    validate={[required, match('buyerEmail')]}
                    disabled={loading}
                  />
                </Fragment>
              ) : (
                ''
              )}
              {hasCoBuyer ? (
                <Fragment>
                  <Field
                    name="resendOrUpdateCoBuyer"
                    component={ValidationCheckbox}
                    label="Update co-buyer"
                    onChange={() => setResendOrUpdateCoBuyer(!resendOrUpdateCoBuyer)}
                    disabled={loading}
                  />
                  {resendOrUpdateCoBuyer ? (
                    <Fragment>
                      {coBuyerHasSignedDocuments ? (
                        <Alert bsStyle="warning">Co-buyer has already signed the document.</Alert>
                      ) : (
                        ''
                      )}
                      <Field
                        name="coBuyerEmail"
                        type="text"
                        component={ValidationInput}
                        label="Email"
                        placeholder="eg. waylon.jennings@worthycarriages.net"
                        validate={[
                          required,
                          emailValidation,
                          notMatch(
                            'buyerEmail',
                            'The co-buyer must have a separate email from the buyer',
                          ),
                        ]}
                        disabled={loading}
                      />
                      <Field
                        name="coBuyerConfirmEmail"
                        type="text"
                        component={ValidationInput}
                        label="Confirm"
                        placeholder="eg. waylon.jennings@worthycarriages.net"
                        validate={[required, match('coBuyerEmail')]}
                        disabled={loading}
                      />
                    </Fragment>
                  ) : (
                    ''
                  )}
                </Fragment>
              ) : (
                ''
              )}
              {errorToDisplay && (
                <p className="text-danger">
                  <strong>{errorToDisplay}</strong>
                </p>
              )}
              {requestSucceeded && (
                <p className="text-success">
                  <strong>Request succeeded</strong>
                </p>
              )}
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button
            bsStyle={hasDestructiveUpdates ? 'danger' : 'primary'}
            type="submit"
            block
            disabled={
              requestSucceeded ||
              errorToDisplay ||
              loading ||
              !!asyncValidating ||
              !valid ||
              (!resendOrUpdateBuyer && !resendOrUpdateCoBuyer)
            }
          >
            Resend{hasUpdates ? ' & Update' : ''}
          </Button>
          <Button bsStyle="default" type="submit" block onClick={onCancel}>
            Cancel
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

UpdateSignersModal.propTypes = {
  ...propTypes,
  resendOrUpdateBuyer: PropTypes.bool.isRequired,
  setResendOrUpdateBuyer: PropTypes.func.isRequired,
  resendOrUpdateCoBuyer: PropTypes.bool.isRequired,
  setResendOrUpdateCoBuyer: PropTypes.func.isRequired,
  hasCoBuyer: PropTypes.bool.isRequired,
  hasUpdates: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  getIssuedContract: PropTypes.func.isRequired,
  buyerHasSignedDocuments: PropTypes.bool.isRequired,
  coBuyerHasSignedDocuments: PropTypes.bool.isRequired,
  requestSubmitted: PropTypes.bool.isRequired,
};

export default compose(
  withState('resendOrUpdateBuyer', 'setResendOrUpdateBuyer', true),
  withState('resendOrUpdateCoBuyer', 'setResendOrUpdateCoBuyer', false),
  withState('hasUpdates', 'setHasUpdates', false),
  withState('requestSubmitted', 'setRequestSubmitted', false),
  connect(
    ({ issuedContract: issuedContractState }) => ({ issuedContractState }),
    {
      getIssuedContract,
      updateIssuedContract,
    },
    (
      { issuedContractState },
      { getIssuedContract, updateIssuedContract },
      {
        onProceed,
        setHasUpdates,
        workingIssuedContract,
        requestSubmitted,
        setRequestSubmitted,
        ...ownProps
      },
    ) => {
      const { error: httpError, result: issuedContract } = issuedContractState;
      const customer = _.get(workingIssuedContract, 'originalRequest.customer');
      const hasCoBuyer = _.get(customer, 'hasCoBuyer');
      const documents = _.get(issuedContract, 'eSign.documents', []);
      return {
        ...ownProps,
        initialValues: {
          resendOrUpdateBuyer: true,
          buyerEmail: _.get(customer, 'email'),
          buyerConfirmEmail: _.get(customer, 'email'),
          coBuyerEmail: _.get(customer, 'coBuyerEmail'),
          coBuyerConfirmEmail: _.get(customer, 'coBuyerEmail'),
        },
        hasCoBuyer,
        loading: _.isUndefined(issuedContract) && _.isUndefined(httpError),
        issuedContract,
        getIssuedContract: _.partial(getIssuedContract, _.get(workingIssuedContract, 'id')),
        httpError,
        buyerHasSignedDocuments: hasSignedDocuments(documents, ROLES.CUSTOMER),
        coBuyerHasSignedDocuments: hasSignedDocuments(documents, ROLES.CO_BUYER),
        requestSubmitted,
        onSubmit: data => {
          updateIssuedContract(workingIssuedContract.id, data);
          setRequestSubmitted(true); // Set this flag so the modal closes on request success
        },
        onChange: data => {
          const { buyerEmail, coBuyerEmail, resendOrUpdateBuyer, resendOrUpdateCoBuyer } = data;
          const hasUpdates =
            (resendOrUpdateBuyer ? buyerEmail !== _.get(customer, 'email') : false) ||
            (hasCoBuyer && resendOrUpdateCoBuyer
              ? coBuyerEmail !== _.get(customer, 'coBuyerEmail')
              : false);
          setHasUpdates(hasUpdates);
        },
      };
    },
  ),
  reduxForm({
    form: UPDATE_ISSUED_CONTRACT_MODAL,
    asyncValidate: UpdateSignersModalAsyncValidation,
    asyncBlurFields: ['buyerEmail', 'coBuyerEmail'],
  }),
)(UpdateSignersModal);
