import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Helmet from 'react-helmet';
import moment from 'moment-timezone';
import { connect } from 'react-redux';
import { Grid, Row, Col, Table, Button } from 'react-bootstrap';
import { compose, withState, withProps } from 'recompose';
import cn from 'classnames';
import { getAllUsers, createUser, updateUser, deleteUser } from '../redux/modules/user';
import { clearForm, NEW_USER_FORM } from '../redux/modules/form';
import NewUserForm from '../components/NewUserForm';
import { escapeForRegExp } from '../utils/string';
import { preventDefault, stopPropagation } from '../utils/click';
import DealerSettingsModal from './user-admin/DealerSettingsModal';
import NotificationModal from '../components/common/NotificationModal';

// NOTE - Client side filtering is fine for now
function filterByColumn(collection, filters) {
  if (!_.isEmpty(filters)) {
    const regExFilters = _.chain(filters)
      .omitBy(_.isEmpty)
      .mapValues(v => new RegExp(escapeForRegExp(v), 'i'))
      .value();
    return _.filter(collection, item => _.every(regExFilters, (v, k) => v.test(_.get(item, k))));
  } else {
    return collection;
  }
}

export function UserAdmin(props) {
  useEffect(() => {
    const { getAllUsers } = props;
    getAllUsers();
  }, []);

  const handleColumnFilter = (property, e) => {
    const { columnFilters, updateColumnFilters } = props;
    const value = _.get(e, 'target.value');
    updateColumnFilters({
      ...columnFilters,
      [property]: value,
    });
  };

  const handleCreateOrUpdateUser = data => {
    const { selectedUser, createUser, updateUser, getAllUsers, clearForm } = props;
    if (_.get(selectedUser, 'userId')) {
      updateUser(selectedUser.userId, data)
        .then(getAllUsers)
        .then(_.partial(clearForm, NEW_USER_FORM));
    } else {
      createUser(data).then(getAllUsers).then(_.partial(clearForm, NEW_USER_FORM));
    }
  };

  const isUserSelected = user => _.get(user, 'userId') === _.get(props.selectedUser, 'userId');
  const {
    dealerSettingsId,
    selectedUser,
    users,
    currentUser,
    selectUser,
    deleteUser,
    setDealerSettingsId,
  } = props;
  const isPrcoAdmin = _.get(currentUser, 'dealerRel.prcoAdmin');

  const [userToDelete, setUserToDelete] = useState();
  const [confirmingDeleteUser, setConfirmingDeleteUser] = useState();

  return (
    <div>
      <Helmet title="User Administration" />

      <Grid>
        <Row>
          <Col xs={12}>
            <NewUserForm
              user={selectedUser}
              currentUser={currentUser}
              onSubmit={handleCreateOrUpdateUser}
            />
          </Col>
        </Row>
        <Row className="form-list-gap">
          <Col xs={12}>
            <legend>All Users</legend>
            <Table responsive hover>
              <tr>
                {isPrcoAdmin && <th>Dealer ID</th>}
                {isPrcoAdmin && <th>PRco Admin</th>}
                <th>Dealer Admin</th>
                <th>Email</th>
                <th>Deployment</th>
                <th>Created</th>
                <th>&nbsp;</th>
              </tr>
              <tbody>
                {(() => {
                  if (isPrcoAdmin) {
                    return (
                      <tr>
                        <td>
                          <input
                            className="form-control"
                            placeholder="eg. 5879"
                            style={{ width: '100px' }}
                            onChange={_.partial(handleColumnFilter, 'dealerRel.dealerId')}
                          />
                        </td>
                        <td colSpan={2}>&nbsp;</td>
                        <td>
                          <input
                            className="form-control"
                            placeholder="eg. bwright@directautosales.com"
                            onChange={_.partial(handleColumnFilter, 'email')}
                          />
                        </td>
                        <td>
                          <input
                            className="form-control"
                            placeholder="eg. A"
                            onChange={_.partial(handleColumnFilter, 'deployment')}
                          />
                        </td>
                        <td colSpan={2}>&nbsp;</td>
                      </tr>
                    );
                  } else {
                    return (
                      <tr>
                        <td colSpan={1}>&nbsp;</td>
                        <td>
                          <input
                            className="form-control"
                            placeholder="eg. bwright@directautosales.com"
                            onChange={_.partial(handleColumnFilter, 'email')}
                          />
                        </td>
                        <td colSpan={2}>&nbsp;</td>
                      </tr>
                    );
                  }
                })()}
                {_.map(users, user => {
                  const created = moment(user.created).format('MMM Do YYYY, h:mm:ss a');
                  const userIsDealerAdmin = _.get(user, 'dealerRel.dealerAdmin');
                  const userIsPrcoAdmin = _.get(user, 'dealerRel.prcoAdmin');
                  return (
                    <tr
                      key={user.userId}
                      className={cn({ selected: isUserSelected(user) })}
                      onClick={() => (!isUserSelected(user) ? selectUser(user) : selectUser(null))}
                    >
                      {isPrcoAdmin && (
                        <td>
                          <a onClick={() => setDealerSettingsId(_.get(user, 'dealerRel.dealerId'))}>
                            {_.get(user, 'dealerRel.dealerId')}
                          </a>
                        </td>
                      )}
                      {isPrcoAdmin && <td>{userIsPrcoAdmin ? 'Yes' : '--'}</td>}
                      <td>{userIsDealerAdmin || userIsPrcoAdmin ? 'Yes' : '--'}</td>
                      <td>{user.email}</td>
                      <td>{user.deployment?.toUpperCase() || '--'}</td>
                      <td>{created}</td>
                      <td>
                        {(isPrcoAdmin || !userIsDealerAdmin) && (
                          <Button
                            bsStyle="danger"
                            onClick={_.flow(preventDefault, stopPropagation, () => {
                              setConfirmingDeleteUser(true);
                              setUserToDelete(user);
                            })}
                          >
                            Delete
                          </Button>
                        )}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </Col>
        </Row>
      </Grid>
      {dealerSettingsId && (
        <DealerSettingsModal
          dealerId={dealerSettingsId}
          onComplete={() => setDealerSettingsId(null)}
        />
      )}
      <NotificationModal
        show={confirmingDeleteUser}
        title="Delete User"
        message="Are you sure you would like to permanently delete this user?"
        confirmText="Continue"
        cancelText="Cancel"
        onConfirm={() => {
          deleteUser(userToDelete.userId);
          setConfirmingDeleteUser(false);
        }}
        onCancel={() => setConfirmingDeleteUser(false)}
        data-test-name="UserAdmin-deleteUser"
      />
    </div>
  );
}

UserAdmin.propTypes = {
  selectedUser: PropTypes.object,
  users: PropTypes.array,
  currentUser: PropTypes.object.isRequired,
  columnFilters: PropTypes.object.isRequired,
  dealerSettingsId: PropTypes.string.isRequired,
  getAllUsers: PropTypes.func.isRequired,
  createUser: PropTypes.func.isRequired,
  updateUser: PropTypes.func.isRequired,
  deleteUser: PropTypes.func.isRequired,
  clearForm: PropTypes.func.isRequired,
  selectUser: PropTypes.func.isRequired,
  updateColumnFilters: PropTypes.func.isRequired,
  setDealerSettingsId: PropTypes.func.isRequired,
};

UserAdmin.defaultProps = {
  selectedUser: null,
  users: [],
};

export default compose(
  withState('selectedUser', 'selectUser'),
  withState('columnFilters', 'updateColumnFilters', {}),
  withState('dealerSettingsId', 'setDealerSettingsId'),
  connect(
    ({ auth, user }) => ({
      currentUser: auth.user,
      users: user.list,
    }),
    {
      getAllUsers,
      createUser,
      updateUser,
      deleteUser,
      clearForm,
    },
  ),
  withProps(({ users, columnFilters }) => ({
    users: filterByColumn(users, columnFilters),
  })),
)(UserAdmin);
