import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Alert from 'react-bootstrap/lib/Alert';
import { infoDismiss } from '../redux/modules/info';

export function Info(props) {
  const handleClose = () => {
    const { onDismissInitiate, onDismiss } = props;
    onDismissInitiate();
    onDismiss();
  };
  const { message, className } = props;

  return (
    <div className={`info-container ${className}`}>
      <Alert className="alert-success">
        <div className="row">
          <div className="col-xs-11">{message}</div>
          <div className="col-xs-1">
            <span className="pointer" onClick={handleClose}>
              x
            </span>
          </div>
        </div>
      </Alert>
    </div>
  );
}

Info.propTypes = {
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  className: PropTypes.string.isRequired,
  onDismissInitiate: PropTypes.func.isRequired,
  onDismiss: PropTypes.func.isRequired,
};

Info.defaultProps = {
  message: null,
};

export default connect(({ info }) => ({ ...info }), { onDismissInitiate: infoDismiss })(Info);
