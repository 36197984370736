import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';
import { Grid, Row, Alert, ProgressBar, Button } from 'react-bootstrap';
import TemplateFieldSelectionOptions from './TemplateFieldSelectionOptions';
import FieldTable from '../../shared/FieldTable';
import { TEMPLATE_META_DATA_FORM } from '../../../../../redux/modules/form';
import {
  disableUpload,
  fetchFields,
  postTemplateForFieldValidation,
  getTemplateExampleFile,
  toggleFieldRequired,
  latestFields,
  selectedFields,
} from '../../../../../redux/modules/eSignAdmin';

const templateSelector = formValueSelector(TEMPLATE_META_DATA_FORM);

const toState = state => ({
  fields: latestFields(state),
  selectedFields: selectedFields(state),
  modifiedFields: state.eSignAdmin.modifiedFields,
  templateType: templateSelector(state, 'templateType'),
  disableUpload: disableUpload(state),
  error: state.eSignAdmin.error,
  templateExampleFileName: state.eSignAdmin.templateValidationReturn,
});

export function FieldListSelection(props) {
  useEffect(() => {
    const { fetchFields, templateType } = props;
    if (templateType) {
      fetchFields(templateType);
    }
  }, []);
  const {
    goBack,
    templateType,
    disableUpload,
    postTemplateForFieldValidation,
    error,
    fields,
    selectedFields,
    toggleFieldRequired,
  } = props;

  return (
    <Grid fluid>
      {error && <Alert className="alert-danger">{error}</Alert>}
      {templateType ? (
        <React.Fragment>
          <Row>
            {fields.length === 0 ? (
              <ProgressBar striped active now={100} />
            ) : (
              <TemplateFieldSelectionOptions />
            )}
          </Row>
          <Row style={{ overflowY: 'auto', height: '45vmin' }}>
            {
              <div>
                <h4>{templateType} Fields</h4>
                <FieldTable
                  fields={fields}
                  toggleRequired={toggleFieldRequired}
                  checkboxHeaderTitle="Selected"
                />
              </div>
            }
          </Row>
          <Row>
            <Button
              disabled={disableUpload}
              style={{ float: 'right', marginTop: 8 }}
              bsStyle="primary"
              onClick={() => postTemplateForFieldValidation(selectedFields)}
            >
              Upload & QC
            </Button>
          </Row>
        </React.Fragment>
      ) : (
        <Button bsStyle="link" onClick={goBack}>
          {' '}
          Please select a template type{' '}
        </Button>
      )}
    </Grid>
  );
}

export default connect(toState, {
  fetchFields,
  postTemplateForFieldValidation,
  getTemplateExampleFile,
  toggleFieldRequired,
})(FieldListSelection);
