import memoize from 'lru-memoize';
import _ from 'lodash';
import numeral from 'numeral';
import { createValidator, required, money, integer } from '../../utils/validation';

const metaValidation = {
  commercialType: required,
  quoteType: required,
};

const sharedValidation = {
  vin: required,
  vehicleMileage: [required, integer],
  vehiclePurchasePrice: [required, money],
};

const vsc = createValidator(sharedValidation);
const meta = createValidator(metaValidation);

export function minFinancedAmountLessCostOfGap({ vehicleCondition, msrp, nadaAverageRetailValue }) {
  return value => {
    const min = Math.floor(
      (() => {
        switch (vehicleCondition) {
          case 'NEW':
            return 0.8 * parseFloat(msrp);
          case 'USED':
            return 0.8 * parseFloat(nadaAverageRetailValue);
          default:
            return 0;
        }
      })(),
    );
    if (parseFloat(value) < min) {
      return (
        'The sale of the GAP Waiver Agreement is not permitted if the amount financed, ' +
        'less the cost of the GAP Waiver Agreement, less the cost of credit insurance, ' +
        'and less the cost of warranties or service agreements is less than 80% of MSRP ' +
        'for a new vehicle or 80% of the NADA average retail value for a used vehicle.'
      );
    }
  };
}

export const DEFAULT_STATE_LOAN_MAX = 60000;
const STATE_LOAN_MAXES = {
  OR: ({ contractYear }) =>
    ({
      '2015A': 1000000,
      '2014A': 100000,
    }[contractYear] || DEFAULT_STATE_LOAN_MAX),
  NV: ({ contractYear }) =>
    ({
      '2015A': 1000000,
      '2014A': 100000,
    }[contractYear] || DEFAULT_STATE_LOAN_MAX),
};

export function maxStateLoanAmount({ dealerState, contractYear }) {
  return _.isFunction(STATE_LOAN_MAXES[dealerState])
    ? STATE_LOAN_MAXES[dealerState]({ contractYear })
    : DEFAULT_STATE_LOAN_MAX;
}

export function maxLoanAmount({ dealerState, contractYear }) {
  return value => {
    const max = maxStateLoanAmount({
      dealerState,
      contractYear,
    });
    if (parseFloat(value) > max) {
      return `The maximum loan amount is ${numeral(max).format('$0,0.00')} in ${dealerState}.`;
    }
  };
}

export const vehicleBasicInformationValidation = memoize(10)(vsc);
export const newQuoteMetaValidation = memoize(10)(meta);
export const vscFields = [...Object.keys(metaValidation), ...Object.keys(sharedValidation)];
export const gapFields = [...vscFields, ...Object.keys(sharedValidation), 'loanAmount'];
