import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Breadcrumb, Glyphicon } from 'react-bootstrap';

export default function NewQuoteBreadcrumb(props) {
  const { moveToPage, pageNo, pages, isPageValid, isPageDisabled } = props;
  return (
    <Fragment>
      <Breadcrumb>
        {pages.map(({ name }, idx) => {
          const invalidMark = pageNo !== idx && !isPageValid(idx) && (
            <span>
              &nbsp;
              <Glyphicon glyph="exclamation-sign" className="text-danger" />
            </span>
          );

          if (pageNo === idx)
            return (
              <li>
                <strong>{name}</strong>
                {invalidMark}
              </li>
            );

          if (isPageDisabled(idx))
            return (
              <li>
                <span>{name}</span>
                {invalidMark}
              </li>
            );

          return (
            <Breadcrumb.Item key={name} onClick={() => moveToPage(idx)}>
              {name}
              {invalidMark}
            </Breadcrumb.Item>
          );
        })}
      </Breadcrumb>
    </Fragment>
  );
}

NewQuoteBreadcrumb.propTypes = {
  moveToPage: PropTypes.func.isRequired,
  pages: PropTypes.array.isRequired,
  pageNo: PropTypes.number.isRequired,
  isPageValid: PropTypes.func.isRequired,
  isPageDisabled: PropTypes.func.isRequired,
};
