import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { connect } from 'react-redux';
import { compose, withState } from 'recompose';
import { reduxForm, Field, propTypes } from 'redux-form';
import { Modal, Button, Row, Col } from 'react-bootstrap';
import { E_SIGN_MODAL_FORM } from '../../redux/modules/form';
import { selectRegisteredFields } from '../../redux/modules/form';
import {
  storeCustomerESignPreference,
  clearCustomerESignPreference,
} from '../../redux/modules/eSign';
import { verifyEmail } from '../../redux/modules/email';
import { required, email as emailValidation } from '../../utils/validation';
import ValidationInput from '../../components/common/ValidationInput';

function isESignRequired() {
  // Use state from redux-form to make sure email input field is still registered
  if (_.get(selectRegisteredFields(E_SIGN_MODAL_FORM), 'customer.email') !== undefined) return true;
  return false;
}

async function eSignModalFormAsyncValidation({ customer }) {
  const email = _.get(customer, 'email');
  // Note - Skip Validation if eSign is not required
  // Note - Make sure the email matches the basic regex of an email address before reaching out to server
  if (isESignRequired() && emailValidation(email) === undefined) {
    try {
      await verifyEmail(email);
    } catch (error) {
      return {
        customer: {
          email: _.get(error, 'message'),
        },
      };
    }
  }
}

const ESignModal = ({
  handleSubmit,
  error,
  show,
  customerHasMobileDevice,
  selectCustomerHasMobileDevice,
  eSignRequired,
  ...props
}) => {
  const showESign = customerHasMobileDevice || eSignRequired;
  useEffect(() => {
    if (!props.submitting && props.invalid) {
      const errorEl = document.querySelector(`[name="customer.email"]`);
      if (errorEl && errorEl.focus) {
        errorEl.focus();
      }
    }
  }, [props]);
  return (
    <Modal show={show}>
      <Modal.Header>
        <Modal.Title>E-Signature</Modal.Title>
      </Modal.Header>
      <form className="form-horizontal" onSubmit={handleSubmit} noValidate={false}>
        <Modal.Body data-test-id="ESignModal">
          {eSignRequired ? (
            <Row>
              <Col xs={12}>
                <p>E-signature is required, please provide the below email.</p>
              </Col>
            </Row>
          ) : (
            <React.Fragment>
              <Row>
                <Col xs={12}>
                  <p>
                    Does the customer have a smart device with them today (i.e. iPhone, android, or
                    tablet)?
                  </p>
                </Col>
              </Row>
              <Row>
                <Col xs={6}>
                  <Button
                    bsStyle={customerHasMobileDevice ? 'primary' : 'default'}
                    block
                    onClick={() => selectCustomerHasMobileDevice(true)}
                    data-test-id="ESignModal-option-yes"
                  >
                    Yes
                  </Button>
                </Col>
                <Col xs={6}>
                  <Button
                    bsStyle={!customerHasMobileDevice ? 'primary' : 'default'}
                    block
                    onClick={() => selectCustomerHasMobileDevice(false)}
                    data-test-id="ESignModal-option-no"
                  >
                    No
                  </Button>
                </Col>
              </Row>
            </React.Fragment>
          )}
          <Row className={eSignRequired ? 'margin-top-16px' : 'margin-top-40px'}>
            <Col xs={12}>
              {showESign && (
                <Field
                  name="customer.email"
                  type="text"
                  component={ValidationInput}
                  label="Customer Email"
                  placeholder="eg. george.jones@superiorautos.com"
                  validate={[required, emailValidation]}
                  data-test-id="ESignModal-email"
                />
              )}
              {error && (
                <p className="text-danger">
                  <strong>{error}</strong>
                </p>
              )}
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button bsStyle="primary" type="submit" block data-test-id="ESignModal-proceed">
            {showESign ? 'Proceed With E-Signature Contract' : 'Proceed With Standard Contract'}
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

ESignModal.propTypes = {
  ...propTypes,
  show: PropTypes.bool,
  eSignRequired: PropTypes.bool,
  customerHasMobileDevice: PropTypes.bool,
  selectCustomerHasMobileDevice: PropTypes.func.isRequired,
};

ESignModal.defaultProps = {
  show: false,
  eSignRequired: false,
  customerHasMobileDevice: false,
};

export default compose(
  withState('customerHasMobileDevice', 'selectCustomerHasMobileDevice', true),
  connect(
    () => ({
      initialValues: {
        customer: {},
      },
    }),
    {
      storeCustomerESignPreference,
      clearCustomerESignPreference,
    },
    (stateProps, dispatchProps, ownProps) => ({
      ...ownProps,
      onSubmit: data => {
        if (
          !_.isEmpty(_.get(data, 'customer.email')) &&
          _.get(ownProps, 'customerHasMobileDevice')
        ) {
          dispatchProps.storeCustomerESignPreference(data.customer);
        } else {
          dispatchProps.clearCustomerESignPreference();
        }
        ownProps.onProceed();
      },
    }),
  ),
  reduxForm({
    form: E_SIGN_MODAL_FORM,
    asyncValidate: eSignModalFormAsyncValidation,
    asyncBlurFields: ['customer.email'],
    onSubmitFail: () => {
      const errorEl = document.querySelector(`[name="customer.email"]`);
      if (errorEl && errorEl.focus) {
        errorEl.focus();
      }
    },
  }),
)(ESignModal);
