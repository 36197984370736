import _ from 'lodash';
import { loadingCycle } from './page';
import api from '../../utils/service';

const GET_EMAIL_VERIFICATION_SUCCESS = 'redux/emailVerification/GET_EMAIL_VERIFICATION_SUCCESS';
const GET_EMAIL_VERIFICATION_FAIL = 'redux/emailVerification/GET_EMAIL_VERIFICATION_FAIL';
const DELETE_EMAIL_VERIFICATION_SUCCESS =
  'redux/emailVerification/DELETE_EMAIL_VERIFICATION_SUCCESS';
const DELETE_EMAIL_VERIFICATION_FAIL = 'redux/emailVerification/DELETE_EMAIL_VERIFICATION_FAIL';
const CREATE_EMAIL_VERIFICATION_SUCCESS =
  'redux/emailVerification/CREATE_EMAIL_VERIFICATION_SUCCESS';
const CREATE_EMAIL_VERIFICATION_FAIL = 'redux/emailVerification/CREATE_EMAIL_VERIFICATION_FAIL';

const initialState = {};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case GET_EMAIL_VERIFICATION_SUCCESS:
      return {
        ...state,
        data: action.data,
      };
    case CREATE_EMAIL_VERIFICATION_SUCCESS:
      return {
        ...state,
        error: false,
      };
    case GET_EMAIL_VERIFICATION_FAIL:
    case CREATE_EMAIL_VERIFICATION_FAIL:
    case DELETE_EMAIL_VERIFICATION_FAIL:
      return {
        ...state,
        error: action.error,
      };
    case DELETE_EMAIL_VERIFICATION_SUCCESS:
      return {
        ...state,
      };
    default:
      return state;
  }
}

export function getAllVerifiedEmails() {
  return dispatch =>
    loadingCycle(api.get('/white-listed-emails'))
      .then(({ data }) => dispatch(getAllVerifiedEmailsSuccess(data)))
      .catch(error => dispatch(getAllVerifiedEmailsFail(error)));
}

export function deleteVerifiedEmail(id) {
  return dispatch =>
    loadingCycle(api.delete(`/white-listed-emails/${id}`))
      .then(({ data }) => dispatch(deleteEmailVerificationSuccess(data)))
      .then(() => dispatch(getAllVerifiedEmails()))
      .catch(error => dispatch(deleteEmailVerificationFail(error)));
}

export function deleteEmailVerificationSuccess(data) {
  return {
    type: DELETE_EMAIL_VERIFICATION_SUCCESS,
    data,
  };
}

export function deleteEmailVerificationFail(error) {
  return {
    type: DELETE_EMAIL_VERIFICATION_FAIL,
    error: _.get(error, 'response.data.message', _.get(error, 'message')),
  };
}

export function getAllVerifiedEmailsSuccess(data) {
  return {
    type: GET_EMAIL_VERIFICATION_SUCCESS,
    data,
  };
}

export function getAllVerifiedEmailsFail(error) {
  return {
    type: GET_EMAIL_VERIFICATION_FAIL,
    error: _.get(error, 'response.data.message', _.get(error, 'message')),
  };
}

export function createEmailVerification(data) {
  return dispatch => {
    return loadingCycle(api.post('/white-listed-emails', data))
      .then(({ data }) => dispatch(createEmailVerificationSuccess(data)))
      .then(() => dispatch(getAllVerifiedEmails()))
      .catch(error => dispatch(createEmailVerificationFail(error)));
  };
}

export function createEmailVerificationSuccess(data) {
  return {
    type: CREATE_EMAIL_VERIFICATION_SUCCESS,
    data,
  };
}

export function createEmailVerificationFail(error) {
  return {
    type: CREATE_EMAIL_VERIFICATION_FAIL,
    error: _.get(error, 'response.data.message', _.get(error, 'message')),
  };
}
