import React from 'react';
import { connect } from 'react-redux';
import Dropzone from 'react-dropzone';
import { startCase } from 'lodash';
import { ListGroup, ListGroupItem, Badge, Glyphicon } from 'react-bootstrap';
import {
  storeTemplateUploads,
  storeSelectedTemplateFileData,
} from '../../../../../redux/modules/eSignAdmin';

const toState = ({ eSignAdmin }) => ({
  ...eSignAdmin.files,
  templateFileData: eSignAdmin.templateFileData,
});

const ACCEPTED_TEMPLATE_MIME_TYPES = ['application/pdf'];

const TemplateUpload = ({
  onDrop,
  accepted = [],
  goForward,
  templateFileData = {},
  storeSelectedTemplateFileData,
}) => (
  <React.Fragment>
    <div>
      <Dropzone onDrop={onDrop} accept={ACCEPTED_TEMPLATE_MIME_TYPES}>
        {({ getRootProps, getInputProps, isDragActive }) => (
          <div
            style={{
              height: 300,
              borderRadius: 7,
              border: isDragActive ? '1px dashed green' : '1px solid black',
            }}
            {...getRootProps()}
          >
            <input {...getInputProps()} />
            <div
              style={{
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              {isDragActive ? (
                <p>Drop PDF Template here</p>
              ) : (
                <p>Drag & Drop PDF Template here Or click to Upload</p>
              )}
            </div>
          </div>
        )}
      </Dropzone>
    </div>

    {accepted.length > 0 && (
      <div style={{ marginTop: 10 }}>
        <ListGroup style={{ overflow: 'auto', maxHeight: '45vmin' }}>
          {accepted.map(file => {
            const { name, lastModified } = file || {};
            const active = name === templateFileData.name;
            return (
              <ListGroupItem
                key={name}
                active={active}
                onClick={() => {
                  if (accepted.length > 1) {
                    storeSelectedTemplateFileData(file);
                    return goForward();
                  } else if (active) {
                    return goForward();
                  }
                  storeSelectedTemplateFileData(file);
                }}
              >
                <p style={{ fontWeight: 'bold', display: 'inline' }}>
                  {startCase(name.split('.')[0])}
                </p>{' '}
                - {name}
                {active && <Glyphicon glyph="chevron-right" style={{ float: 'right' }} />}
                <Badge pullRight>{new Date(lastModified).toLocaleString()}</Badge>
              </ListGroupItem>
            );
          })}
        </ListGroup>
      </div>
    )}
  </React.Fragment>
);

export default connect(toState, { onDrop: storeTemplateUploads, storeSelectedTemplateFileData })(
  TemplateUpload,
);
