import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import numeral from 'numeral';
import moment from 'moment';
import { map, startCase, capitalize, replace, size, noop, pickBy, chain } from 'lodash';
import { Checkbox, Label, ListGroup, ListGroupItem, Panel, Table } from 'react-bootstrap';
import {
  CONTRACT_TYPE_GAP,
  CONTRACT_TYPE_VSC,
  CONTRACT_TYPE_TWP,
} from '../../../constants/contractType';

export function RequestTable(props) {
  const renderKey = k => {
    switch (k) {
      case 'vin':
        return 'VIN #';
      case 'quoteRequestId':
        return 'Quote Request ID';
      case 'ratingVehicleClass':
        return 'Vehicle Class';
      default:
        return startCase(k);
    }
  };

  const renderValues = (v = '', k) => {
    if (isNaN(v)) {
      switch (k) {
        case 'commercialType':
        case 'vehicleCondition':
          return capitalize(replace(v, /_/g, ' '));
        case 'vehicleSaleDate':
          return moment(v, 'MM-DD-YY').format('MM/DD/YYYY');
        default:
          return v;
      }
    } else {
      switch (k) {
        case 'vehicleDescription':
          return v || '- No Description -';
        case 'vehicleYear':
          return v || '- No Year -';
        case 'ratingVehicleClass':
          return v || '- No Class -';
        case 'quoteRequestId':
          return v;
        case 'vehicleMileage':
          return numeral(v).format('0,0');
        default:
          return numeral(v).format('$0,0.00');
      }
    }
  };

  const { request } = props;
  return (
    <Table striped bordered condensed responsive>
      <thead>
        <tr>
          <th>Item</th>
          <th>Value</th>
        </tr>
      </thead>
      <tbody>
        {map(request, (v, k) => (
          <tr key={k}>
            <td>{renderKey(k)}</td>
            <td>{renderValues(v, k)}</td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
}

RequestTable.propTypes = {
  request: PropTypes.object.isRequired,
};

export function Coverages(props) {
  const [active, setActive] = useState(false);
  useEffect(() => {
    const { coverages, selectedCoverageId } = props;

    // Auto open if the plan contains selected coverages.
    if (coverages.some(({ quoteId }) => quoteId === selectedCoverageId)) setActive(true);
  }, []);

  const handleSelection = quoteId => {
    const { selectedCoverageId } = props;
    const coverage = selectedCoverageId === quoteId ? null : quoteId;
    props.onCoverageClick(coverage ? quoteId : null);
  };

  /**
   * When Control key is down, only open panel.
   */
  const handleToggle = (_newActive, event) => setActive(event.ctrlKey ? true : !active);

  const calculateContractQuotePrice = (price, type) => {
    const { dealerSettings } = props;
    if (dealerSettings.dollarMarkupVSC && type === CONTRACT_TYPE_VSC) {
      return +price + +dealerSettings.dollarMarkupVSC;
    } else if (dealerSettings.percentageMarkupVSC && type === 'VSC') {
      return +price + +price * (+dealerSettings.percentageMarkupVSC / 100);
    }
    if (dealerSettings.dollarMarkupTWP && type === 'TWP') {
      return +price + +dealerSettings.dollarMarkupTWP;
    } else if (dealerSettings.percentageMarkupTWP && type === CONTRACT_TYPE_TWP) {
      return +price + +price * (+dealerSettings.percentageMarkupTWP / 100);
    }
    if (dealerSettings.dollarMarkupGAP && type === 'GAP') {
      return +price + +dealerSettings.dollarMarkupGAP;
    } else if (dealerSettings.percentageMarkupGAP && type === CONTRACT_TYPE_GAP) {
      return +price + +price * (+dealerSettings.percentageMarkupGAP / 100);
    }
    return +price;
  };

  const { planName, title, coverages, selectedCoverageId, onOtherCoverageClick, contractType } =
    props;
  return (
    <Panel
      expanded={active}
      onToggle={handleToggle}
      data-test-type="Coverages-Plan"
      data-test-id={`Coverages-Plan:${planName}`}
    >
      <Panel.Heading>
        <Panel.Title toggle data-test-type="Coverages-Plan-selectHandle">
          {title} ({size(coverages)})
        </Panel.Title>
      </Panel.Heading>
      <Panel.Body collapsible>
        <ListGroup>
          {map(
            coverages,
            ({
              quoteId,
              quoteType,
              termMonthsQuoted,
              termMilesQuoted,
              contractQuotePrice,
              coverages: otherCoverages,
              ...quote
            }) => {
              const quotePriceWithMarkup = calculateContractQuotePrice(
                contractQuotePrice,
                quoteType,
              ).toFixed(2);

              return (
                <ListGroupItem
                  className="hover-item"
                  style={{ margin: 5 }}
                  bsStyle={selectedCoverageId === quoteId ? 'info' : null}
                  key={quoteId}
                  data-test-type="Coverages-Item"
                  data-test-id={`Coverages-Item-${contractType}-${quoteId}`}
                >
                  <legend // eslint-disable-line jsx-a11y/no-noninteractive-element-interactions, jsx-a11y/click-events-have-key-events
                    style={{ cursor: 'pointer' }}
                    onClick={() =>
                      handleSelection(quoteId, {
                        title,
                        quoteId,
                        termMonthsQuoted,
                        termMilesQuoted,
                        coverages: otherCoverages,
                        ...quote,
                        contractQuotePrice: quotePriceWithMarkup,
                      })
                    }
                    data-test-type="Coverages-Item-selectHandle"
                  >
                    <Label style={{ float: 'left', marginRight: 8 }} bsStyle="primary">
                      ${quotePriceWithMarkup}
                    </Label>
                    {'  '}
                    {chain([
                      quoteType === CONTRACT_TYPE_GAP && 'Up to',
                      `${termMonthsQuoted} Months`,
                      quoteType === CONTRACT_TYPE_VSC && `/ ${termMilesQuoted} Miles`,
                    ])
                      .filter()
                      .join(' ')
                      .value()}
                  </legend>
                  {selectedCoverageId === quoteId && (
                    <div>
                      {map(otherCoverages, ({ label, issueKey, selectable, ...cov }) => {
                        const disabled = !selectable;

                        // TODO `checked` prop initialization doesn't work (https://app.clickup.com/t/nz7p40)
                        const props = pickBy({ checked: disabled });
                        return (
                          <Checkbox
                            key={issueKey}
                            disabled={disabled}
                            onClick={
                              selectable
                                ? () => onOtherCoverageClick({ label, issueKey, ...cov })
                                : noop
                            }
                            {...props}
                            data-test-id={`Coverages-Item-${contractType}-${quoteId}-options-${issueKey}`}
                          >
                            {label}
                          </Checkbox>
                        );
                      })}
                    </div>
                  )}
                </ListGroupItem>
              );
            },
          )}
        </ListGroup>
      </Panel.Body>
    </Panel>
  );
}

Coverages.propTypes = {
  planName: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  coverages: PropTypes.array.isRequired,
  selectedCoverageId: PropTypes.string,
  onCoverageClick: PropTypes.func.isRequired,
  onOtherCoverageClick: PropTypes.func.isRequired,
  contractType: PropTypes.string.isRequired,
};

Coverages.defaultProps = {
  selectedCoverageId: null,
};

export default {
  RequestTable,
  Coverages,
};
