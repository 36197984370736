import { isEmpty } from 'deep-cuts';
import qs from 'qs';
import { useEffect, useState } from 'react';
import _ from 'lodash';

export function useStoreFormInQuery(router, location) {
  const [initialValuesFromQuery, setInitialValuesFromQuery] = useState();

  if (location && router) {
    const valueFromQuery = qs.parse(location.search.replace('?', ''));

    useEffect(() => {
      setInitialValuesFromQuery(valueFromQuery);
    }, []);

    const setValuesInQuery = values => {
      if (!isEmpty(values)) {
        const newLocation = `${location.pathname}?${qs.stringify(_.omitBy(values, isEmpty))}`;
        if (location.pathname + location.search !== newLocation) router.push(newLocation);
      } else {
        return router.push(`${location.pathname}`);
      }
    };

    return [initialValuesFromQuery, setValuesInQuery];
  } else {
    throw new Error(
      'You must pass a React Router V3 router and location in order to use the useStoreFormInQuery() hook.',
    );
  }
}
