import _ from 'lodash';
import { loadingCycle } from './page';
import api from '../../utils/service';

const GET_ALL_USERS_SUCCESS = 'redux/user/GET_ALL_USERS_SUCCESS';
const GET_ALL_USERS_FAIL = 'redux/user/GET_ALL_USERS_FAIL';
const GET_USER_SUCCESS = 'redux/user/GET_USER_SUCCESS';
const GET_USER_FAIL = 'redux/user/GET_USER_FAIL';
const CREATE_USER_SUCCESS = 'redux/user/CREATE_USER_SUCCESS';
const CREATE_USER_FAIL = 'redux/user/CREATE_USER_FAIL';
const UPDATE_USER_SUCCESS = 'redux/user/UPDATE_USER_SUCCESS';
const UPDATE_USER_FAIL = 'redux/user/UPDATE_USER_FAIL';
const DELETE_USER_SUCCESS = 'redux/user/DELETE_USER_SUCCESS';
const DELETE_USER_FAIL = 'redux/user/DELETE_USER_FAIL';

const initialState = {};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case GET_ALL_USERS_SUCCESS:
      return {
        ...state,
        list: action.data,
        error: null,
      };
    case GET_USER_SUCCESS:
      return {
        ...state,
        detail: action.data,
        error: null,
      };
    case CREATE_USER_SUCCESS:
    case DELETE_USER_SUCCESS:
      return {
        ...state,
        error: null,
      };
    case GET_ALL_USERS_FAIL:
    case GET_USER_FAIL:
    case CREATE_USER_FAIL:
    case DELETE_USER_FAIL:
      return {
        ...state,
        error: action.error,
      };
    default:
      return state;
  }
}

export function getAllUsers() {
  return dispatch =>
    api
      .get('/users')
      .then(({ data }) => dispatch(getAllUsersSuccess(data)))
      .catch(error => dispatch(getAllUsersFail(error)));
}

export function getAllUsersSuccess(data) {
  return {
    type: GET_ALL_USERS_SUCCESS,
    data,
  };
}

export function getAllUsersFail(error) {
  return {
    type: GET_ALL_USERS_FAIL,
    error: _.get(error, 'response.data.message', _.get(error, 'message')),
  };
}

export function getUser(id) {
  return dispatch =>
    api
      .get(`/users/${id}`)
      .then(({ data }) => dispatch(getUserSuccess(data)))
      .catch(error => dispatch(getUserFail(error)));
}

export function getUserSuccess(data) {
  return {
    type: GET_USER_SUCCESS,
    data,
  };
}

export function getUserFail(error) {
  return {
    type: GET_USER_FAIL,
    error: _.get(error, 'response.data.message', _.get(error, 'message')),
  };
}

export function createUser(userData) {
  return dispatch =>
    loadingCycle(api.post('/users', userData))
      .then(({ data }) => dispatch(createUserSuccess(data)))
      .catch(error => dispatch(createUserFail(error)));
}

export function createUserSuccess(data) {
  return {
    type: CREATE_USER_SUCCESS,
    data,
  };
}

export function createUserFail(error) {
  return {
    type: CREATE_USER_FAIL,
    error: _.get(error, 'response.data.message', _.get(error, 'message')),
  };
}

export function updateUser(id, data) {
  return dispatch =>
    loadingCycle(api.put(`/users/${id}`, data))
      .then(({ data }) => dispatch(updateUserSuccess(data)))
      .catch(error => dispatch(updateUserFail(error)));
}

export function updateUserSuccess(data) {
  return {
    type: UPDATE_USER_SUCCESS,
    data,
  };
}

export function updateUserFail(error) {
  return {
    type: UPDATE_USER_FAIL,
    error: _.get(error, 'response.data.message', _.get(error, 'message')),
  };
}

export function deleteUser(id) {
  return dispatch =>
    loadingCycle(api.delete(`/users/${id}`))
      .then(({ data }) => dispatch(deleteUserSuccess(data)))
      .then(() => dispatch(getAllUsers()))
      .catch(error => dispatch(deleteUserFail(error)));
}

export function deleteUserSuccess(data) {
  return {
    type: DELETE_USER_SUCCESS,
    data,
  };
}

export function deleteUserFail(error) {
  return {
    type: DELETE_USER_FAIL,
    error: _.get(error, 'response.data.message', _.get(error, 'message')),
  };
}
