import { find, omit, chain, flow, get } from 'lodash';
import { loadingCycle } from './page';
import customSelector from '../../utils/selector';
import rest from '../../utils/service';

const TOGGLE_EULA_AGREEMENT = 'redux/newQuote/TOGGLE_EULA_AGREEMENT';
const STORE_NEW_QUOTE_FORM_VALUES = 'redux/newQuote/STORE_NEW_QUOTE_FORM_VALUES';
const STORE_NEW_QUOTE_ERROR = 'redux/newQuote/STORE_NEW_QUOTE_ERROR';
const STORE_NEW_QUOTE_RESPONSE = 'redux/newQuote/STORE_NEW_QUOTE_RESPONSE';
const STORE_VSC_QUOTE_PLAN_SELECTION = 'redux/newQuote/STORE_VSC_QUOTE_PLAN_SELECTION';
const STORE_VSC_EXTRA_COVERAGE_SELECTION = 'redux/newQuote/STORE_VSC_EXTRA_COVERAGE_SELECTION';
const STORE_EXTRA_COVERAGE_SELECTION = 'redux/newQuote/STORE_EXTRA_COVERAGE_SELECTION';
const CLEAR_VSC_EXTRA_COVERAGE_SELECTION = 'redux/newQuote/CLEAR_EXTRA_COVERAGE_SELECTION';
const STORE_GAP_QUOTE_PLAN_SELECTION = 'redux/newQuote/STORE_GAP_QUOTE_PLAN_SELECTION';
const STORE_TWP_QUOTE_PLAN_SELECTION = 'redux/newQuote/STORE_TWP_QUOTE_PLAN_SELECTION';
const SET_SIGNATURE_FLOW = 'redux/newQuote/SET_SIGNATURE_FLOW';
const CLEAR_STATE = 'redux/newQuote/CLEAR_STATE';

const initialState = {
  eula: false,
  form: {},
  extraCoverages: {},
};
export default (state = initialState, { type, payload }) => {
  switch (type) {
    case TOGGLE_EULA_AGREEMENT:
      return {
        ...state,
        eula: payload,
      };
    case STORE_NEW_QUOTE_FORM_VALUES:
      return {
        ...state,
        form: { ...state.form, ...payload },
      };
    case STORE_NEW_QUOTE_ERROR:
      return {
        ...state,
        error: payload,
      };
    case STORE_NEW_QUOTE_RESPONSE:
      return {
        ...state,
        ...payload,
        error: null,
      };
    case STORE_VSC_QUOTE_PLAN_SELECTION:
      return {
        ...state,
        selectedVscQuoteId: payload,
      };
    case STORE_GAP_QUOTE_PLAN_SELECTION:
      return {
        ...state,
        selectedGapQuoteId: payload,
      };
    case STORE_TWP_QUOTE_PLAN_SELECTION:
      return {
        ...state,
        selectedTwpQuoteId: payload,
      };
    case STORE_VSC_EXTRA_COVERAGE_SELECTION:
      return {
        ...state,
        extraCoverages: payload,
      };
    case CLEAR_VSC_EXTRA_COVERAGE_SELECTION:
      return {
        ...state,
        extraCoverages: {},
      };
    case SET_SIGNATURE_FLOW:
      return {
        ...state,
        signatureFlow: payload,
      };
    case CLEAR_STATE:
      return {
        eula: false,
        form: {},
        extraCoverages: {},
      };
    default:
      return state;
  }
};

export const storeNewQuoteFormValues = payload => ({
  type: STORE_NEW_QUOTE_FORM_VALUES,
  payload,
});

export const storeNewQuoteError = error => ({
  type: STORE_NEW_QUOTE_ERROR,
  payload: get(error, 'response.data.message', get(error, 'message')),
});

export const submitNewQuoteRequest = request => async (dispatch, getState) => {
  try {
    const { dealer, newQuote } = getState();
    const req = request || newQuote.form;
    const { data } = await loadingCycle(
      rest.get('quotes', {
        params: {
          ...req,
          dealerLot: get(dealer, 'details.dealerLot', 0),
        },
      }),
    );
    dispatch({
      type: STORE_NEW_QUOTE_RESPONSE,
      payload: omit(data, 'raw'),
    });
  } catch (err) {
    dispatch(storeNewQuoteError(err));
  }
};

export const storeVscQuotePlanSelection = payload => ({
  type: STORE_VSC_QUOTE_PLAN_SELECTION,

  payload,
});

/** @deprecated old form action, can be deleted? */
export const storeExtraCoverageSelection = payload => ({
  type: STORE_EXTRA_COVERAGE_SELECTION,
  payload,
});

/** @deprecated old form action, can be deleted? */
export const clearExtraCoverageSelection = () => ({
  type: CLEAR_VSC_EXTRA_COVERAGE_SELECTION,
});

export const storeVSCExtraCoverageSelection = payload => ({
  type: STORE_VSC_EXTRA_COVERAGE_SELECTION,
  payload,
});

export const clearVSCExtraCoverageSelection = () => ({
  type: CLEAR_VSC_EXTRA_COVERAGE_SELECTION,
});

export const storeGapQuotePlanSelection = payload => ({
  type: STORE_GAP_QUOTE_PLAN_SELECTION,
  payload,
});

export const storeTwpQuotePlanSelection = payload => ({
  type: STORE_TWP_QUOTE_PLAN_SELECTION,
  payload,
});

export const setSignatureFlow = payload => ({
  type: SET_SIGNATURE_FLOW,
  payload,
});

export const clearState = () => ({
  type: CLEAR_STATE,
});

/* redux selectors */
const groupedQuotes = ({ newQuote }) => newQuote.groupedRatingQuotes;
const flattenGroupedQuotes = groupedRatingQuotes =>
  chain(groupedRatingQuotes).map('data').flatten().value();
const vscQuoteId = ({ newQuote }) => newQuote.selectedVscQuoteId;
const gapQuoteId = ({ newQuote }) => newQuote.selectedGapQuoteId;
const twpQuoteId = ({ newQuote }) => newQuote.selectedTwpQuoteId;

export const selectedGapQuote = customSelector(
  gapQuoteId,
  flow(groupedQuotes, flattenGroupedQuotes),
  (quoteId, q) => find(q, { quoteId }),
);

export const selectedTwpQuote = customSelector(
  twpQuoteId,
  flow(groupedQuotes, flattenGroupedQuotes),
  (quoteId, q) => find(q, { quoteId }),
);

export const selectedVscQuote = customSelector(
  vscQuoteId,
  flow(groupedQuotes, flattenGroupedQuotes),
  (quoteId, q) => find(q, { quoteId }),
);

export const vehicleYear = ({ newQuote }) => get(newQuote, ['ratingQuotes', 0, 'vehicleYear']);
