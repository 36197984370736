import React from 'react';
import PropTypes from 'prop-types';

const ValidationInput = ({
  input,
  label,
  type,
  placeholder,
  disabled,
  'data-test-id': dataTestId,
  meta: { touched, error },
}) => (
  <div className={`form-group ${error && touched ? 'has-error' : ''}`}>
    <label htmlFor={input.name} className="col-sm-3">
      {label}
    </label>
    <div className="col-sm-9">
      <input
        {...input}
        type={type}
        className="form-control"
        placeholder={placeholder}
        disabled={disabled}
        data-test-id={dataTestId}
      />
      {error && touched && <span className="glyphicon glyphicon-remove form-control-feedback" />}
      {error && touched && (
        <div className="text-danger">
          <strong>{error}</strong>
        </div>
      )}
    </div>
  </div>
);

ValidationInput.propTypes = {
  input: PropTypes.object,
  label: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  meta: PropTypes.object.isRequired,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  'data-test-id': PropTypes.string,
};

ValidationInput.defaultProps = {
  input: {},
  placeholder: '',
  disabled: false,
  'data-test-id': undefined,
};

export default ValidationInput;
