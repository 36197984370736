import React from 'react';
import PropTypes from 'prop-types';

const ValidationTextarea = ({ input, label, placeholder, meta: { touched, error } }) => (
  <div className={`form-group ${error && touched ? 'has-error' : ''}`}>
    <label htmlFor={input.name} className="col-sm-3">
      {label}
    </label>
    <div className="col-sm-9">
      <textarea {...input} className="form-control" placeholder={placeholder} />
      {error && touched && <span className="glyphicon glyphicon-remove form-control-feedback" />}
      {error && touched && (
        <div className="text-danger">
          <strong>{error}</strong>
        </div>
      )}
    </div>
  </div>
);

ValidationTextarea.propTypes = {
  input: PropTypes.object,
  label: PropTypes.string.isRequired,
  meta: PropTypes.object.isRequired,
  placeholder: PropTypes.string,
};

ValidationTextarea.defaultProps = {
  input: {},
  placeholder: '',
};

export default ValidationTextarea;
