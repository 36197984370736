import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm, propTypes } from 'redux-form';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { Col, Grid } from 'react-bootstrap';
import { browserHistory as history } from 'react-router';
import { UPLOAD_CSV_FILE_FORM } from '../redux/modules/form';
import ValidationFileInput from '../components/common/ValidationFileInput';
import { uploadCSVFile } from '../redux/modules/uploadCSVFile';
import ConfirmationModal from '../components/ConfirmationModal';
export class UploadFile extends PureComponent {
  static propTypes = {
    ...propTypes,
    csvResponse: PropTypes.bool,
    uploadCsvFileError: PropTypes.string,
  };

  static defaultProps = {
    file: {},
    csvResponse: false,
    uploadCsvFileError: '',
  };

  constructor() {
    super();
    this.state = {
      uploadDisable: true,
      confirmationModalDisable: false,
    };
  }

  componentDidMount() {
    this.setState({ confirmationModalDisable: false });
  }

  componentWillReceiveProps(nextProps) {
    if (!this.state.uploadDisable === nextProps.csvResponse) {
      this.setState({ confirmationModalDisable: true });
    }
  }

  handleOnConfirm = () => {
    this.setState({ confirmationModalDisable: false });
    history.push('/admin');
  };

  render() {
    const handleDisable = () => {
      this.setState({ uploadDisable: false });
    };
    const { handleSubmit, error, csvResponse, uploadCsvFileError } = this.props;
    const { uploadDisable, confirmationModalDisable } = this.state;

    return (
      <div>
        {csvResponse && confirmationModalDisable && (
          <ConfirmationModal
            onConfirm={this.handleOnConfirm}
            visible={confirmationModalDisable}
            message={uploadCsvFileError}
          />
        )}
        <Grid>
          <Col xs={12}>
            <form
              className="form-horizontal margin-bottom-20px"
              onSubmit={handleSubmit}
              noValidate={true}
              data-test-id="uploadCSV"
            >
              <legend>Upload CSV File</legend>
              <Field
                name="file"
                type="file"
                data-test-id="csvFile"
                onChange={handleDisable}
                component={ValidationFileInput}
                label="CSV File"
                accept=".csv"
              />
              {error && (
                <p className="text-danger" data-test-id="csvFile">
                  <strong>{error}</strong>
                </p>
              )}
              <div className="row">
                <div className="col-md-2 col-xs-2">
                  <button
                    className="btn btn-primary btn-block"
                    type="submit"
                    data-test-id="csvSubmit"
                    disabled={uploadDisable}
                  >
                    Upload
                  </button>
                </div>
              </div>
            </form>
          </Col>
        </Grid>
      </div>
    );
  }
}

export default compose(
  connect(
    ({ uploadCSVFile }) => ({
      uploadCsvFileError: uploadCSVFile.error,
      csvResponse: uploadCSVFile.isSuccessful,
    }),
    {
      uploadCSVFile,
    },
    (stateProps, dispatchProps, ownProps) => ({
      ...stateProps,
      ...dispatchProps,
      ...ownProps,
      onSubmit: async data => {
        await dispatchProps.uploadCSVFile({
          ...data,
        });
      },
    }),
  ),
  reduxForm({
    form: UPLOAD_CSV_FILE_FORM,
  }),
)(UploadFile);
