import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Button, Row, Col } from 'react-bootstrap';

function SavedQuoteControls({ updateSavedQuote, deleteSavedQuote }) {
  return (
    <Fragment>
      <Row>
        <Col xs={6}>
          <Button bsStyle="primary" block onClick={updateSavedQuote}>
            Update
          </Button>
        </Col>
        <Col xs={6}>
          <Button bsStyle="danger" block onClick={deleteSavedQuote}>
            Delete
          </Button>
        </Col>
      </Row>
    </Fragment>
  );
}

SavedQuoteControls.propTypes = {
  updateSavedQuote: PropTypes.func.isRequired,
  deleteSavedQuote: PropTypes.func.isRequired,
};

export default SavedQuoteControls;
