import PropTypes from 'prop-types';

export const CONTRACT_TYPE_GAP = 'GAP';
export const CONTRACT_TYPE_VSC = 'VSC';
export const CONTRACT_TYPE_TWP = 'TWP';

// TODO - Remove this once the functionality that uses this variable name is upgraded.
export const contractTypesAll = [CONTRACT_TYPE_GAP, CONTRACT_TYPE_VSC, CONTRACT_TYPE_TWP].sort();

export const ALL_CONTRACT_TYPES = [CONTRACT_TYPE_GAP, CONTRACT_TYPE_VSC, CONTRACT_TYPE_TWP].sort();

const contractTypeTitles = {
  VSC: 'VSC',
  GAP: 'GAP',
  TWP: 'T&W',
};
export const contractTypeTitle = quoteTypeItem =>
  contractTypeTitles[quoteTypeItem] || quoteTypeItem;

const contractTypeDetailTitles = {
  VSC: 'Service Contract',
  GAP: 'GAP',
  TWP: 'Tires And Wheels',
};
export const contractTypeDetailTitle = quoteTypeItem =>
  contractTypeDetailTitles[quoteTypeItem] || quoteTypeItem;

export const ContractTypePropType = PropTypes.oneOf(contractTypesAll);
