import _ from 'lodash';
import { loadingCycle } from './page';
import api from '../../utils/service';
const POST_VERIFICATION_OVERRIDE_SUCCESS =
  'redux/verificationOverride/POST_VERIFICATION_OVERRIDE_SUCCESS';
const POST_VERIFICATION_OVERRIDE_FAIL =
  'redux/verificationOverride/POST_VERIFICATION_OVERRIDE_FAIL';
const GET_VERIFICATION_OVERRIDE_SUCCESS =
  'redux/verificationOverride/GET_VERIFICATION_OVERRIDE_SUCCESS';
const GET_VERIFICATION_OVERRIDE_FAIL = 'redux/verificationOverride/GET_VERIFICATION_OVERRIDE_FAIL';
const DELETE_VERIFICATION_OVERRIDE_SUCCESS =
  'redux/verificationOverride/DELETE_VERIFICATION_OVERRIDE_SUCCESS';
const DELETE_VERIFICATION_OVERRIDE_FAIL =
  'redux/verificationOverride/DELETE_VERIFICATION_OVERRIDE_FAIL';
const initialState = {};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case POST_VERIFICATION_OVERRIDE_SUCCESS:
    case GET_VERIFICATION_OVERRIDE_SUCCESS:
    case DELETE_VERIFICATION_OVERRIDE_SUCCESS:
      return {
        ...state,
        data: action.data,
      };
    case GET_VERIFICATION_OVERRIDE_FAIL:
    case POST_VERIFICATION_OVERRIDE_FAIL:
    case DELETE_VERIFICATION_OVERRIDE_FAIL:
      return {
        ...state,
        error: action.error,
      };
    default:
      return state;
  }
}
export function postVerificationOverride(data) {
  return dispatch =>
    loadingCycle(api.post(`/verification-overrides`, data))
      .then(({ data }) => {
        dispatch(postVerificationOverrideSuccess(data.data));
        dispatch(getVerificationOverride());
      })
      .catch(error => dispatch(postVerificationOverrideFail(error)));
}
export function postVerificationOverrideSuccess(data) {
  return {
    type: POST_VERIFICATION_OVERRIDE_SUCCESS,
    data,
  };
}
export function postVerificationOverrideFail(error) {
  return {
    type: POST_VERIFICATION_OVERRIDE_FAIL,
    error: _.get(error, 'response.data.message', _.get(error, 'message')),
  };
}
export function getVerificationOverride() {
  return dispatch =>
    loadingCycle(api.get(`/verification-overrides`))
      .then(({ data }) => {
        dispatch(getVerificationOverrideSuccess(data.verifications));
      })
      .catch(error => dispatch(getVerificationOverrideFail(error)));
}
export function getVerificationOverrideSuccess(data) {
  return {
    type: GET_VERIFICATION_OVERRIDE_SUCCESS,
    data,
  };
}
export function getVerificationOverrideFail(error) {
  return {
    type: GET_VERIFICATION_OVERRIDE_FAIL,
    error: _.get(error, 'response.data.message', _.get(error, 'message')),
  };
}
export function deleteVerificationOverride(id) {
  return dispatch => {
    loadingCycle(api.delete(`/verification-overrides/${id}`))
      .then(({ data }) => {
        dispatch(getVerificationOverride());
        dispatch(deleteVerificationOverrideSuccess(data.verification));
      })
      .catch(error => dispatch(deleteVerificationOverrideFail(error)));
  };
}
export function deleteVerificationOverrideSuccess(data) {
  return {
    type: DELETE_VERIFICATION_OVERRIDE_SUCCESS,
    data,
  };
}
export function deleteVerificationOverrideFail(error) {
  return {
    type: DELETE_VERIFICATION_OVERRIDE_FAIL,
    error: _.get(error, 'response.data.message', _.get(error, 'message')),
  };
}
