import memoize from 'lru-memoize';
import { createValidator, required, oneOf } from '../../utils/validation';
import { contractTypesAll } from '../../constants/contractType';

const validation = createValidator({
  templateType: [required, oneOf(contractTypesAll)],
  state: [required],
  language: [required, oneOf(['english', 'spanish'])],
});

export default memoize(10)(validation);
