import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Helmet from 'react-helmet';
import { connect } from 'react-redux';
import Grid from 'react-bootstrap/lib/Grid';
import Row from 'react-bootstrap/lib/Row';
import Col from 'react-bootstrap/lib/Col';
import Button from 'react-bootstrap/lib/Button';
import { LinkContainer } from 'react-router-bootstrap';
import { stringToBoolean } from 'deep-cuts';
import { loadQueryParams } from '../redux/modules/queryParams';

class AdminContent extends PureComponent {
  static propTypes = {
    user: PropTypes.object.isRequired,
  };

  render() {
    const { user } = this.props;

    const dealerAdmin = !!_.get(user, 'dealerRel.dealerAdmin');
    const prcoAdmin = !!_.get(user, 'dealerRel.prcoAdmin');

    return (
      <div data-test-id="Home">
        <Helmet title="Home" />
        <Grid>
          {(prcoAdmin || dealerAdmin) && (
            <React.Fragment>
              {prcoAdmin && (
                <Row>
                  <Col md={8} xs={12} mdOffset={2}>
                    <LinkContainer to="/admin/override-verification">
                      <Button
                        bsStyle="primary"
                        bsSize="large"
                        block
                        className="home-container-link-container"
                      >
                        Verification Override
                      </Button>
                    </LinkContainer>
                  </Col>
                </Row>
              )}
              {prcoAdmin && (
                <Row>
                  <Col md={8} xs={12} mdOffset={2}>
                    <LinkContainer to="/admin/e-sign-administration">
                      <Button
                        bsStyle="primary"
                        bsSize="large"
                        block
                        className="home-container-link-container"
                      >
                        E-Sign Administration
                      </Button>
                    </LinkContainer>
                  </Col>
                </Row>
              )}
              {prcoAdmin && (
                <Row>
                  <Col md={8} xs={12} mdOffset={2}>
                    <LinkContainer to="/admin/messaging-administration">
                      <Button
                        bsStyle="primary"
                        bsSize="large"
                        block
                        className="home-container-link-container"
                      >
                        Messaging Administration
                      </Button>
                    </LinkContainer>
                  </Col>
                </Row>
              )}
              {prcoAdmin && (
                <Row>
                  <Col md={8} xs={12} mdOffset={2}>
                    <LinkContainer to="/admin/email-verification-administration">
                      <Button
                        bsStyle="primary"
                        bsSize="large"
                        block
                        className="home-container-link-container"
                      >
                        Email Verification Administration
                      </Button>
                    </LinkContainer>
                  </Col>
                </Row>
              )}
              {prcoAdmin && (
                <Row>
                  <Col md={8} xs={12} mdOffset={2}>
                    <LinkContainer to="/admin/cache">
                      <Button
                        bsStyle="primary"
                        bsSize="large"
                        block
                        className="home-container-link-container"
                      >
                        Cache Administration
                      </Button>
                    </LinkContainer>
                  </Col>
                </Row>
              )}
              {prcoAdmin && stringToBoolean(process.env.REACT_APP_CSV_UPLOADING_FEATURE_FLAG) && (
                <Row>
                  <Col md={8} xs={12} mdOffset={2}>
                    <LinkContainer to="/admin/upload-file">
                      <Button
                        bsStyle="primary"
                        bsSize="large"
                        block
                        className="home-container-link-container"
                      >
                        Upload Addresses File
                      </Button>
                    </LinkContainer>
                  </Col>
                </Row>
              )}
            </React.Fragment>
          )}
        </Grid>
      </div>
    );
  }
}

export default connect(
  ({ auth }) => ({
    user: auth.user,
  }),
  {
    loadQueryParams,
  },
)(AdminContent);
