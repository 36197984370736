import React from 'react';
import Helmet from 'react-helmet';

export default function Statements() {
  return (
    <div className="container">
      <Helmet title="Statements" />
      <iframe
        title="prco-statements"
        style={{
          height: '800px',
          width: '100%',
        }}
        src={`//prcostatements.com`}
        width="1200"
        height="800"
        frameBorder="0"
        scrolling="yes"
      />
    </div>
  );
}
