import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { Col, Grid, Row } from 'react-bootstrap';
import ValidationInput from '../components/common/ValidationInput';
import ValidationSelect from '../components/common/ValidationSelect';
import { required } from '../utils/validation';
import { API_KEYS_FORM } from '../redux/modules/form';
import apiKeysValidation from '../components/validation/apiKeysValidation';
import GenericTable from '../components/common/GenericTable';
import { getApiKeys, createOrUpdateApiKey, deleteApiKey } from '../redux/modules/apiKeys';

const toState = ({ apiKeys }) => ({
  // form: form.apiKeys,
  ...apiKeys,
});

const PERMISSION_OPTIONS = [
  {
    value: null,
    label: 'No Permissions',
  },
  {
    value: 'DEALER:CREATE',
    label: 'Create Dealer',
  },
  {
    value: 'TEMPLATES:SYNC',
    label: 'Synchronize Templates',
  },
];

const ApiKeyForm = reduxForm({ form: API_KEYS_FORM, validate: apiKeysValidation })(
  ({ isUpdate, handleSubmit }) => (
    <form className="form-horizontal" onSubmit={handleSubmit}>
      <legend>{isUpdate ? 'Update Key' : 'Create Key'}</legend>
      <Field
        name="application"
        type="text"
        component={ValidationInput}
        label="Application Name"
        placeholder="prcosales.com"
        validation={[required]}
      />
      <Field
        name="permissions"
        type="text"
        component={ValidationSelect}
        label="Permissions"
        options={PERMISSION_OPTIONS}
        default={PERMISSION_OPTIONS[0]}
        validation={[required]}
      />
      <div className="row">
        <div className="col-md-3 col-xs-12">
          <button className="btn btn-primary btn-block" type="submit">
            {isUpdate ? 'Update Key' : 'Create Key'}
          </button>
        </div>
      </div>
    </form>
  ),
);

const ApiKeysManager = ({ keys, getApiKeys, createOrUpdateApiKey, deleteApiKey }) => {
  useEffect(() => {
    getApiKeys();
  }, []);
  const onSubmit = createOrUpdateApiKey;
  const onDelete = ({ _id }) => {
    deleteApiKey(_id);
  };

  return (
    <Grid>
      <Row>
        <Col xs={12}>
          <ApiKeyForm onSubmit={onSubmit} />
        </Col>
      </Row>
      <Row style={{ paddingTop: 8 }}>
        <Col xs={12}>
          <legend>API Keys</legend>
          {keys.length > 0 ? (
            <GenericTable
              headers={['application', 'permissions', 'key', 'created']}
              data={keys}
              onDelete={onDelete}
            />
          ) : (
            <div>No API Keys have been created</div>
          )}
        </Col>
      </Row>
    </Grid>
  );
};

export default connect(toState, { getApiKeys, createOrUpdateApiKey, deleteApiKey })(ApiKeysManager);
