import React from 'react';
import { ControlLabel, FormGroup } from 'react-bootstrap';
import { useField } from 'formik';

export default function Select({ label = '', description = '', options, disabled, ...props }) {
  const [field, meta, helpers] = useField(props); // eslint-disable-line no-unused-vars
  const { value: currentValue } = meta;
  const { setValue } = helpers;

  const handleChange = e => setValue(e.target.value);

  return (
    <FormGroup>
      {label && <ControlLabel>{label}</ControlLabel>}
      {description && <label>{description}</label>}
      <select
        className="form-control"
        value={currentValue}
        onChange={handleChange}
        disabled={disabled}
      >
        {(options || []).map(option => {
          const label = typeof option === 'string' ? option : option.label;
          const value = typeof option === 'string' ? option : option.value;
          return (
            <option value={value} key={value}>
              {label}
            </option>
          );
        })}
      </select>
      {meta.error && meta.touched && <span className="text-danger">{meta.error}</span>}
    </FormGroup>
  );
}
