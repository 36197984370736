import React from 'react';
import PropTypes from 'prop-types';
import DateTime from 'react-datetime';
import _ from 'lodash';
import moment from 'moment';

const DATE_FORMAT = 'MM/DD/YYYY';

const ValidationDatePicker = ({
  input,
  label,
  placeholder,
  disabled,
  meta: { touched, error },
  'data-test-id': dataTestId,
}) => (
  <div className={`form-group ${error && touched ? 'has-error' : ''}`}>
    <label htmlFor={input.name} className="col-sm-3">
      {label}
    </label>
    <div className="col-sm-9">
      <DateTime
        timeFormat={false}
        dateFormat={DATE_FORMAT}
        value={_.get(input, 'value', moment().format(DATE_FORMAT))}
        onChange={momentDate => {
          if (!_.isString(momentDate) && _.isFunction(_.get(momentDate, 'format'))) {
            _.get(input, 'onChange', _.noop)(momentDate.format(DATE_FORMAT));
          }
        }}
        inputProps={{
          ..._.omit(input, 'value', 'onChange'),
          disabled,
          placeholder,
          'data-test-id': dataTestId,
        }}
      />
      {error && touched && <span className="glyphicon glyphicon-remove form-control-feedback" />}
      {error && touched && (
        <div className="text-danger">
          <strong>{error}</strong>
        </div>
      )}
    </div>
  </div>
);

ValidationDatePicker.propTypes = {
  input: PropTypes.object,
  label: PropTypes.string.isRequired,
  meta: PropTypes.object.isRequired,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  'data-test-id': PropTypes.string,
};

ValidationDatePicker.defaultProps = {
  input: {},
  placeholder: '',
  disabled: false,
  'data-test-id': undefined,
};

export default ValidationDatePicker;
