import _ from 'lodash';
import api, { watchEvents } from '../../utils/service';
import { loadingCycle } from './page';

const UPDATE_METAS = 'redux/task/UPDATE_METAS';
const SET_HAS_BEEN_READ = 'redux/task/SET_HAS_BEEN_READ';

const initialState = {};

const setHasBeenRead = (tasksMetas, taskId, hasBeenRead) => {
  const taskMeta = tasksMetas && tasksMetas[taskId];
  if (!taskMeta) return tasksMetas;

  return {
    ...tasksMetas,
    [taskId]: {
      ...taskMeta,
      hasBeenRead,
    },
  };
};

export const task = (state = initialState, action = {}) => {
  switch (action.type) {
    case UPDATE_METAS:
      return {
        ...state,
        tasksMetas: action.initial
          ? action.tasksMetas
          : _.omitBy(
              {
                ...state.tasksMetas,
                ...action.tasksMetas,
              },
              _.isNil,
            ),
      };
    case SET_HAS_BEEN_READ:
      return {
        ...state,
        tasksMetas: setHasBeenRead(state.tasksMetas, action.taskId, action.hasBeenRead),
      };
    default:
      return state;
  }
};

export const watchTasks = dispatch =>
  watchEvents(
    'tasks',
    (tasksMetas, { initial }) =>
      dispatch({
        type: UPDATE_METAS,
        initial,
        tasksMetas,
      }),
    ['TasksMetas'],
  );

export const markTaskAsRead = taskId => async dispatch => {
  await dispatch({
    type: SET_HAS_BEEN_READ,
    taskId,
    hasBeenRead: true,
  });
  return api.get(`/tasks/${taskId}/mark-as-read`);
};

export const markTaskAsUnread = taskId => async dispatch => {
  await dispatch({
    type: SET_HAS_BEEN_READ,
    taskId,
    hasBeenRead: false,
  });
  return api.get(`/tasks/${taskId}/mark-as-unread`);
};

export const deleteTask = taskId => loadingCycle(api.delete(`/tasks/${taskId}`));
