import React, { Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { connect } from 'react-redux';
import { PanelGroup, Alert } from 'react-bootstrap';
import { pick, isEmpty, get, chain } from 'lodash';
import { reduxForm, propTypes } from 'redux-form';
import { compose } from 'recompose';
import { QUOTE_SUMMARY_FORM } from '../../../redux/modules/form';
import { submitNewQuoteRequest, vehicleYear } from '../../../redux/modules/newQuote';
import Summary from './Summary';
import Buttons from '../../../components/wizard/Buttons';
import {
  storeVscQuotePlanSelection,
  storeExtraCoverageSelection,
  clearExtraCoverageSelection,
  storeGapQuotePlanSelection,
  storeTwpQuotePlanSelection,
  selectedGapQuote,
  selectedTwpQuote,
  selectedVscQuote,
} from '../../../redux/modules/newQuote';
import {
  quoteTypeIncludesGAP,
  quoteTypeIncludesTWP,
  quoteTypeIncludesVSC,
  quoteTypeTitle,
} from '../../../constants/quoteType';
import {
  CONTRACT_TYPE_VSC,
  CONTRACT_TYPE_GAP,
  CONTRACT_TYPE_TWP,
} from '../../../constants/contractType';

const determineInvalidOptions = newQuote => {
  const {
    form: { quoteType },
  } = newQuote;

  if (quoteTypeIncludesVSC(quoteType) && !newQuote.selectedVscQuoteId) return true;

  if (quoteTypeIncludesGAP(quoteType) && !newQuote.selectedGapQuoteId) return true;

  if (quoteTypeIncludesTWP(quoteType) && !newQuote.selectedTwpQuoteId) return true;

  return false;
};

const ButtonsHOC = props => <Buttons {...props} data-test-name="QuoteSummary-Buttons" />;
const ButtonWrapper = connect(({ newQuote }) => ({ invalid: determineInvalidOptions(newQuote) }))(
  ButtonsHOC,
);

/**
 * Assigns the groupedQuotes to global window var, to use in automated tests.
 */
const QuotesExpose = ({ groupedQuotes }) => {
  if (!groupedQuotes) return null;
  useEffect(() => {
    // @ts-ignore-next-line
    window.QuotesExpose_groupedQuotes = groupedQuotes;
    return () => {
      // @ts-ignore-next-line
      delete window.QuotesExpose_groupedQuotes;
    };
  }, [groupedQuotes]);

  return <div data-test-id="QuotesExpose" />;
};

export function QuoteSummary(props) {
  console.log(props);
  useEffect(() => {
    const { submitNewQuoteRequest, hasInvalidPagesBefore } = props;

    if (!hasInvalidPagesBefore) submitNewQuoteRequest();
  }, []);

  const handleStoreQuotePlanSelection = (...args) => {
    const { storeQuotePlanSelection, clearExtraCoverageSelection } = props;
    storeQuotePlanSelection(...args);
    clearExtraCoverageSelection();
  };

  const handleStoreGapCoverage = (...args) => {
    const { storeGapCoverage } = props;
    storeGapCoverage(...args);
    // NOTE - We do not clear extra coverage in the case of GAP
  };

  const handleStoreTwpCoverage = (...args) => {
    const { storeTwpCoverage } = props;
    storeTwpCoverage(...args);
    // NOTE - We do not clear extra coverage in the case of TWP
  };

  const handleStoreCoverage =
    contractType =>
    (...args) => {
      if (contractType === CONTRACT_TYPE_VSC) return handleStoreQuotePlanSelection(...args);
      if (contractType === CONTRACT_TYPE_GAP) return handleStoreGapCoverage(...args);
      if (contractType === CONTRACT_TYPE_TWP) return handleStoreTwpCoverage(...args);
    };

  const {
    dealer,
    request = {},
    groupedQuotes,
    selectedVscQuote,
    selectedGapQuote,
    selectedTwpQuote,
    storeExtraCoverageSelection,
    loading,
    serviceError,
    goBack,
    handleSubmit,
    hasInvalidPagesBefore,
    dealerSettings,
  } = props;

  if (hasInvalidPagesBefore)
    return (
      <Alert className="alert-success">
        You need to fix problems on previous pages to get your options
      </Alert>
    );

  const selectedVSCQuoteId = get(selectedVscQuote, 'quoteId');
  const selectedGapQuoteId = get(selectedGapQuote, 'quoteId');
  const selectedTWPQuoteId = get(selectedTwpQuote, 'quoteId');
  const getSelectedCoverageId = contractType => {
    if (contractType === CONTRACT_TYPE_VSC) return selectedVSCQuoteId;
    if (contractType === CONTRACT_TYPE_GAP) return selectedGapQuoteId;
    if (contractType === CONTRACT_TYPE_TWP) return selectedTWPQuoteId;

    return undefined;
  };

  return (
    <form onSubmit={handleSubmit} noValidate={true} data-test-id="QuoteSummary">
      {!loading && (
        <Fragment>
          {!serviceError && (
            <Fragment>
              <legend>This Quote Qualifies</legend>
              <h6>
                For the following PRco Products and Coverage as of{' '}
                {request.vehicleSaleDate
                  ? moment(request.vehicleSaleDate, 'MM-DD-YY').format('MM/DD/YYYY')
                  : ''}
              </h6>
              <Summary.RequestTable dealer={dealer} request={request} />
              <h6>The rates below include any applicable surcharges for 4x4, Turbo or V10</h6>
              <legend>
                <span>Plans</span>{' '}
                <span className="title-help">
                  (Click coverage boxes below to expand and display a list of available options for
                  the vehicle)
                </span>
              </legend>
              {isEmpty(groupedQuotes) && !loading && (
                <Alert bsStyle="warning">There were no quotes returned.</Alert>
              )}
              <PanelGroup id="accordion-other-coverages">
                {chain(groupedQuotes)
                  .toPairs()
                  .sortBy(([k, v]) => v.index) // eslint-disable-line no-unused-vars
                  .map(([name, { title, contractType, data }]) => (
                    <Summary.Coverages
                      title={
                        title === contractType ? (
                          quoteTypeTitle(contractType)
                        ) : (
                          <span>
                            {title}{' '}
                            <span className="text-muted">{quoteTypeTitle(contractType)}</span>
                          </span>
                        )
                      }
                      coverages={data}
                      selectedCoverageId={getSelectedCoverageId(contractType)}
                      key={name}
                      planName={name}
                      onCoverageClick={handleStoreCoverage(contractType)}
                      onOtherCoverageClick={storeExtraCoverageSelection}
                      contractType={contractType}
                      dealerSettings={dealerSettings}
                    />
                  ))
                  .value()}
              </PanelGroup>
            </Fragment>
          )}
          <ButtonWrapper goBack={goBack} />
          <QuotesExpose groupedQuotes={groupedQuotes} />
        </Fragment>
      )}
    </form>
  );
}

QuoteSummary.propTypes = {
  ...propTypes,
  dealer: PropTypes.object.isRequired,
  quoteSubmissionValues: PropTypes.object, // eslint-disable-line react/no-unused-prop-types
  request: PropTypes.object,
  groupedQuotes: PropTypes.object,
  loading: PropTypes.bool.isRequired,
  serviceError: PropTypes.string,
  selectedVscQuote: PropTypes.object,
  selectedGapQuote: PropTypes.object,
  storeQuotePlanSelection: PropTypes.func.isRequired,
  storeExtraCoverageSelection: PropTypes.func.isRequired,
  clearExtraCoverageSelection: PropTypes.func.isRequired,
  storeGapCoverage: PropTypes.func.isRequired,
  goBack: PropTypes.func.isRequired,
  submitNewQuoteRequest: PropTypes.func.isRequired,
  hasInvalidPagesBefore: PropTypes.bool,
};

QuoteSummary.defaultProps = {
  quoteSubmissionValues: {},
  request: {},
  serviceError: null,
  groupedQuotes: null,
  selectedVscQuote: null,
  selectedGapQuote: null,
  selectedTwpQuote: null,
  hasInvalidPagesBefore: false,
};

export default compose(
  connect(
    ({ newQuote, dealer, page, dealerSettings }) => ({
      dealerSettings: dealerSettings.data,
      dealer: pick(dealer.details, ['name']),
      quoteSubmissionValues: newQuote.form,
      request: chain(newQuote.ratingQuoteRequest)
        .cloneDeep()
        .merge(pick(newQuote.form, ['commercialType', 'vehicleCondition']), {
          vehicleYear: vehicleYear({ newQuote }),
        })
        .pick([
          'quoteRequestId',
          'dealerName',
          'commercialType',
          'vehicleCondition',
          'vin',
          'vehicleDescription',
          'vehicleYear',
          'vehicleMileage',
          'ratingVehicleClass',
          'vehiclePurchasePrice',
          'vehicleSaleDate',
          'loanAmount',
        ])
        .value(),
      groupedQuotes: newQuote.groupedRatingQuotes,
      loading: page.loading,
      serviceError: newQuote.error,
      selectedVscQuote: selectedVscQuote({ newQuote }),
      selectedGapQuote: selectedGapQuote({ newQuote }),
      selectedTwpQuote: selectedTwpQuote({ newQuote }),
    }),
    {
      storeQuotePlanSelection: storeVscQuotePlanSelection,
      storeExtraCoverageSelection,
      clearExtraCoverageSelection,
      storeGapCoverage: storeGapQuotePlanSelection,
      storeTwpCoverage: storeTwpQuotePlanSelection,
      submitNewQuoteRequest,
    },
  ),
  reduxForm({
    form: QUOTE_SUMMARY_FORM,
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
  }),
)(QuoteSummary);
