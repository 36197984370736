import React, { useContext, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { connect } from 'react-redux';
import { Grid, Row, Col, Button, ProgressBar } from 'react-bootstrap';
import { FormGroup, FormControl, ControlLabel, Panel } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { loadQueryParams } from '../redux/modules/queryParams';
import HomeContent from '../components/HomeContent';
import { AuthContext } from '../components/Auth';
import api from '../utils/service';

export function PreHome(props) {
  const { user, isLoading } = props;
  const { login } = useContext(AuthContext);
  const [email, setEmail] = useState('');
  const [inProgress, setInProgress] = useState(false);

  const handleEmailChange = useCallback(({ currentTarget: { value } }) => {
    setEmail(value);
  }, []);

  const handleMigrate = async () => {
    setInProgress(true);
    try {
      await api.post('/auth/login-with-migration', { email });
      setInProgress(false);
      login();
    } catch (error) {
      setInProgress(false);
      if (error.response && error.response.status) {
        props.router.push('/MigrateFrom219ApiToAuth0');
      }
    }
  };

  if (isLoading) {
    return <ProgressBar now={100} active={true} />;
  }

  if (!user) {
    return (
      <div>
        <Helmet title="Home" />

        <Grid>
          <Row>
            <Col md={6} xs={12} mdOffset={3}>
              <div>
                <Panel bsStyle="danger">
                  <Panel.Heading>
                    <Panel.Title componentClass="h3">Instructions</Panel.Title>
                  </Panel.Heading>
                  <Panel.Body style={{ fontSize: 17 }}>
                    <p>To improve online security, we are updating your password.</p>
                    <p>
                      1. Log in using your current username and password. If your current password
                      is secure, no changes need to be made.
                    </p>
                    <p>
                      2. If your current password is not secure, it will prompt you to change to a
                      secure password. Follow the instructions.
                    </p>
                  </Panel.Body>
                </Panel>
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={6} xs={12} mdOffset={3}>
              <form style={{ maxWidth: '40em', margin: '2em auto' }}>
                <FormGroup>
                  <ControlLabel>Email</ControlLabel>
                  <FormControl
                    value={email}
                    onChange={handleEmailChange}
                    name="email"
                    readOnly={inProgress}
                  />
                </FormGroup>

                <Button
                  style={{ margin: '1em auto' }}
                  bsSize="large"
                  bsStyle="primary"
                  block
                  onClick={handleMigrate}
                  disabled={!handleMigrate || inProgress}
                >
                  Next
                </Button>
              </form>
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }

  return <HomeContent />;
}

PreHome.propTypes = {
  user: PropTypes.object,
};

PreHome.defaultProps = {
  user: null,
  queryParamsLoaded: false,
  loadQueryParams: _.noop,
};

export default connect(
  ({ auth }) => ({
    user: auth.user,
    isLoading: auth.isLoading,
  }),
  {
    loadQueryParams,
  },
)(PreHome);
