import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Modal, Button, Alert } from 'react-bootstrap';
import { partial, get } from 'lodash';
import TemplateUpload from './components/TemplateUpload';
import TemplateMetaDataForm from './components/TemplateMetaDataForm';
import FieldListSelection from './components/FieldListSelection';
import TemplateWizardBreadcrumbs from './components/TemplateWizardBreadcrumbs';
import {
  acceptedFiles,
  disableSave,
  toggleUploadTemplateModal,
  saveTemplateAndMetaData,
  getTemplateExampleFile,
} from '../../../../redux/modules/eSignAdmin';

const mapStateToProps = ({ form, eSignAdmin }) => ({
  showUploadTemplateModal: eSignAdmin.showUploadTemplateModal,
  acceptedFiles: acceptedFiles({ eSignAdmin }),
  disableSave: disableSave({ form, eSignAdmin }),
  templateExampleFileName: eSignAdmin.templateValidationReturn,
  savedTemplate: eSignAdmin.savedTemplate,
});

const FIRST_PAGE = 'upload';
const TEMPLATE_WIZARD_PAGES = [
  { page: FIRST_PAGE, component: TemplateUpload },
  { page: 'template-data', component: TemplateMetaDataForm },
  { page: 'field-selection', component: FieldListSelection },
];

export function TemplateWizard(props) {
  const [page, setPage] = useState(FIRST_PAGE);

  const handlePageClick = page => {
    setPage(page);
  };

  const activePage = page;
  const {
    showUploadTemplateModal,
    toggleUploadTemplateModal,
    saveTemplateAndMetaData,
    disableSave,
    templateExampleFileName,
    getTemplateExampleFile,
    savedTemplate,
  } = props;
  return (
    <Modal
      bsSize="large"
      show={showUploadTemplateModal}
      onHide={() => toggleUploadTemplateModal(false)}
    >
      <Modal.Header closeButton>
        <Modal.Title>E-Sign Admin Template Wizard</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <TemplateWizardBreadcrumbs
          items={TEMPLATE_WIZARD_PAGES}
          activePage={activePage}
          onClick={handlePageClick}
        />
        {TEMPLATE_WIZARD_PAGES.map(({ page, component: Component }, idx) => {
          const prevPage = get(TEMPLATE_WIZARD_PAGES, `${idx - 1 > -1 ? idx - 1 : 0}.page`);
          const nextPage = get(
            TEMPLATE_WIZARD_PAGES,
            `${
              idx + 1 <= TEMPLATE_WIZARD_PAGES.length ? idx + 1 : TEMPLATE_WIZARD_PAGES.length
            }.page`,
          );
          const goForward = partial(handlePageClick, nextPage);
          const formProps = page === 'template-data' ? { onSubmit: goForward } : {};
          return (
            page === activePage && (
              <Component
                key={page}
                goBack={partial(handlePageClick, prevPage)}
                goForward={partial(handlePageClick, nextPage)}
                {...formProps}
              />
            )
          );
        })}
      </Modal.Body>
      <Modal.Footer style={{ textAlign: 'left' }}>
        {templateExampleFileName ? (
          <Alert className="alert-success">
            <p>Your template is valid, click "Save Template" to finish the process.</p>
            <p>
              {savedTemplate &&
                savedTemplate.id &&
                `Template (${savedTemplate.fileName}) was saved.`}
            </p>
            <p>
              <Button onClick={() => toggleUploadTemplateModal(false)}>Close</Button>
              <Button
                bsStyle="primary"
                title={`Download example template with name ${templateExampleFileName}`}
                onClick={() => getTemplateExampleFile(templateExampleFileName)}
              >
                Download Example
              </Button>
              <Button
                disabled={disableSave}
                bsStyle="primary"
                title="You must Upload and QC (see field-selection to submit) your template before saving"
                onClick={async () => {
                  await saveTemplateAndMetaData();
                  handlePageClick(FIRST_PAGE);
                }}
              >
                Save Template
              </Button>
            </p>
          </Alert>
        ) : (
          <Button onClick={() => toggleUploadTemplateModal(false)}>Close</Button>
        )}
      </Modal.Footer>
    </Modal>
  );
}

export default connect(mapStateToProps, {
  toggleUploadTemplateModal,
  saveTemplateAndMetaData,
  getTemplateExampleFile,
})(TemplateWizard);
