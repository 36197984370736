import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Helmet from 'react-helmet';
import moment from 'moment-timezone';
import { connect } from 'react-redux';
import { Grid, Row, Col, Table } from 'react-bootstrap';
import { compose, withState, withProps } from 'recompose';
import url from 'url';
import { isEmpty } from 'deep-cuts';
import IssuedContractControls from './all-quotes/IssuedContractControls';
import SavedQuoteControls from './all-quotes/SavedQuoteControls';
import { getAllSavedQuotes, deleteSavedQuote } from '../redux/modules/savedQuote';
import {
  getAllIssuedContracts,
  deleteIssuedContract,
  downloadIssuedContract,
} from '../redux/modules/issuedContract';
import { navigateToSignContracts } from '../redux/modules/eSign';
import { COMMERCIAL_TYPES } from '../constants/newQuote';
import { escapeForRegExp } from '../utils/string';
import { filenameForDownload } from './all-quotes/helpers/download';
import UpdateSignersModal from './all-quotes/UpdateSignersModal';
import { quoteTypeTitle, QUOTE_TYPE_ONLY_VSC } from '../constants/quoteType';
const savedQuoteNameFromQuoteType = quoteType => `${quoteTypeTitle(quoteType)} Quote`;

// NOTE - Client side filtering is fine for the limited amount our users have
function filterByColumn(collection, filters) {
  if (!_.isEmpty(filters)) {
    const regExFilters = _.chain(filters)
      .omitBy(_.isEmpty)
      .mapValues(v => new RegExp(escapeForRegExp(v), 'i'))
      .value();
    return _.filter(collection, item =>
      _.every(
        regExFilters,
        (v, k) => v.test(_.get(item, k)) || v.test(_.get(item, ['fourDResponse', k])),
      ),
    );
  } else {
    return collection;
  }
}

export function AllQuotes(props) {
  useEffect(() => {
    const { getAllSavedQuotes, getAllIssuedContracts } = props;
    getAllSavedQuotes();
    getAllIssuedContracts();
  }, []);

  const handleColumnFilter = (property, e) => {
    const { columnFilters, updateColumnFilters } = props;
    const value = _.get(e, 'target.value');
    updateColumnFilters({
      ...columnFilters,
      [property]: value,
    });
  };

  const handleCopySavedQuote = savedQuote => {
    const { router } = props;
    const query = url.format({
      query: _.omitBy(savedQuote, isEmpty),
    });
    router.push(`/newQuote${query}`);
  };

  const handleCopyIssuedContract = issuedContract => contractTypes => {
    const { router } = props;
    const { originalRequest, fourDResponse } = issuedContract;
    const quoteType = contractTypes.sort().join(',');
    const query = url.format({
      query: _.omitBy(
        {
          /* Quote */
          commercialType:
            _.get(fourDResponse, 'commercialUse') && quoteType === QUOTE_TYPE_ONLY_VSC
              ? COMMERCIAL_TYPES.COMMERCIAL
              : COMMERCIAL_TYPES.NON_COMMERCIAL,
          quoteType,
          vin: _.get(fourDResponse, 'vin'),
          retailPriceOfTwp:
            _.get(originalRequest, 'twpRate.retailPriceOfTwp') ||
            _.get(fourDResponse, 'retailPriceOfTwp'),
          /* VSC Rate */
          vehicleCondition: _.get(fourDResponse, 'vehicleCondition'),
          msrp: _.get(fourDResponse, 'msrp'),
          nadaAverageRetailValue: _.get(fourDResponse, 'nadaAverageRetailValue'),
          financedAmountLessCostOfGap: _.get(fourDResponse, 'financedAmountLessCostOfGap'),
          vehiclePurchasePrice: _.get(fourDResponse, 'vehiclePurchasePrice'),
          retailPriceOfVsc: _.get(fourDResponse, 'retailPriceOfVsc'),
          loanAmount: _.get(fourDResponse, 'loanAmount'),
          vehicleMileage: _.get(fourDResponse, 'vehicleMileage'),
          stockNumber: _.get(fourDResponse, 'stockNumber'),
          /* GAP Rate */
          retailPriceOfGap: _.get(fourDResponse, 'retailPriceOfGap'),
          loanTerm: _.get(fourDResponse, 'loanTerm'),
          bhph: _.get(fourDResponse, 'bhph'),
          kelleyBlueBook: _.get(fourDResponse, 'kelleyBlueBook'),
          /* Customer */
          customerFirstName: _.get(fourDResponse, 'customerFirstName'),
          customerLastName: _.get(fourDResponse, 'customerLastName'),
          customerEmail: _.get(fourDResponse, 'customerEmail'),
          customerAddress: _.get(fourDResponse, 'customerAddress'),
          customerCity: _.get(fourDResponse, 'customerCity'),
          customerState: _.get(fourDResponse, 'customerState'),
          customerZip: _.get(fourDResponse, 'customerZip'),
          customerPhone: _.get(fourDResponse, 'customerPhone'),
          /* Co-Buyer */
          coBuyerFirstName: _.get(originalRequest, 'customer.coBuyerFirstName'),
          coBuyerLastName: _.get(originalRequest, 'customer.coBuyerLastName'),
          coBuyerEmail: _.get(originalRequest, 'customer.coBuyerEmail'),
          coBuyerConfirmEmail: _.get(originalRequest, 'customer.coBuyerConfirmEmail'),
          /* Lender */
          financierName: _.get(fourDResponse, 'financierName'),
          financierAddress: _.get(fourDResponse, 'financierAddress'),
          financierCity: _.get(fourDResponse, 'financierCity'),
          financierState: _.get(fourDResponse, 'financierState'),
          financierZip: _.get(fourDResponse, 'financierZip'),
        },
        isEmpty,
      ),
    });
    router.push(`/newQuote${query}`);
  };

  const openUpdateSignersModal = issuedContract => {
    const { setShowUpdateSignersModal, setWorkingIssuedContract } = props;
    setShowUpdateSignersModal(true);
    setWorkingIssuedContract(issuedContract);
  };

  const closeUpdateSignersModal = () => {
    const { setShowUpdateSignersModal } = props;
    setShowUpdateSignersModal(false);
  };

  const handleDownloadContract = issuedContract => contractType => {
    const { downloadIssuedContract } = props;
    downloadIssuedContract(
      issuedContract._id,
      _.toLower(contractType),
      filenameForDownload(issuedContract, contractType),
    );
  };

  const {
    issuedContracts,
    savedQuotes,
    user,
    deleteSavedQuote,
    deleteIssuedContract,
    navigateToSignContracts,
    workingIssuedContract,
    showUpdateSignersModal,
  } = props;
  const isPrcoAdmin = _.get(user, 'dealerRel.prcoAdmin');

  return (
    <div>
      <Helmet title="All Quotes" />
      <Grid>
        <Row>
          <Col xs={12}>
            <p className="text-center">
              <b>WARNING:</b> Quotes and contracts sent to PRco will be available for editing and
              reprinting for 180 days.
            </p>
          </Col>
        </Row>
        <Row className="form-list-gap">
          <Col xs={12}>
            <Table responsive hover>
              <thead>
                <tr>
                  <th>Customer</th>
                  {isPrcoAdmin && <th>Dealer</th>}
                  <th>VIN</th>
                  <th>Status</th>
                  <th>Date</th>
                  <th>&nbsp;</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>&nbsp;</td>
                  {isPrcoAdmin && (
                    <td>
                      <input
                        className="form-control"
                        placeholder="eg. 5879"
                        style={{ width: '100px' }}
                        onChange={_.partial(handleColumnFilter, 'dealerId')}
                      />
                    </td>
                  )}
                  <td>
                    <input
                      className="form-control"
                      placeholder="eg. 1GNSKJKC6GR123038"
                      onChange={_.partial(handleColumnFilter, 'vin')}
                    />
                  </td>
                  <td colSpan="3">&nbsp;</td>
                </tr>
                {_.map(savedQuotes, savedQuote => {
                  const { _id, quoteType, dealerId, vin, created } = savedQuote;
                  return (
                    <tr key={`${_id}-saved-quote`}>
                      <td>{savedQuoteNameFromQuoteType(quoteType)}</td>
                      {isPrcoAdmin && <td>{dealerId}</td>}
                      <td>{vin}</td>
                      <td>QUOTED</td>
                      <td>{moment(created).format('MM/DD/YYYY')}</td>
                      <td>
                        <SavedQuoteControls
                          updateSavedQuote={_.partial(handleCopySavedQuote, savedQuote)}
                          deleteSavedQuote={_.partial(deleteSavedQuote, _id)}
                        />
                      </td>
                    </tr>
                  );
                })}
                {_.map(issuedContracts, issuedContract => {
                  const {
                    _id,
                    status,
                    eSign,
                    fourDResponse,
                    vscContract,
                    gapContract,
                    twpContract,
                    created,
                  } = issuedContract;
                  const signingUrl = _.get(eSign, 'url');
                  return (
                    <tr key={`${_id}-issued-contract`}>
                      <td>{`${_.get(fourDResponse, 'customerFirstName')} ${_.get(
                        fourDResponse,
                        'customerLastName',
                      )}`}</td>
                      {isPrcoAdmin && <td>{_.get(fourDResponse, 'dealerId')}</td>}
                      <td>{_.get(fourDResponse, 'vin')}</td>
                      <td>{status}</td>
                      <td>{moment(created).format('MM/DD/YYYY')}</td>
                      <td>
                        <IssuedContractControls
                          showDelete={isPrcoAdmin}
                          status={status}
                          vscContract={vscContract}
                          gapContract={gapContract}
                          twpContract={twpContract}
                          commercialUse={_.get(fourDResponse, 'commercialUse')}
                          downloadContractType={handleDownloadContract(issuedContract)}
                          deleteIssuedContract={() => deleteIssuedContract(_id)}
                          copyQuote={handleCopyIssuedContract(issuedContract)}
                          signContracts={_.partial(navigateToSignContracts, signingUrl)}
                          openUpdateSignersModal={_.partial(openUpdateSignersModal, issuedContract)}
                        />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </Col>
        </Row>
        {showUpdateSignersModal ? (
          <UpdateSignersModal
            workingIssuedContract={workingIssuedContract}
            onProceed={closeUpdateSignersModal}
            onCancel={closeUpdateSignersModal}
          />
        ) : (
          ''
        )}
      </Grid>
    </div>
  );
}

AllQuotes.propTypes = {
  savedQuotes: PropTypes.array,
  issuedContracts: PropTypes.array,
  user: PropTypes.object.isRequired,
  columnFilters: PropTypes.object.isRequired,
  router: PropTypes.object.isRequired,
  updateColumnFilters: PropTypes.func.isRequired,
  getAllSavedQuotes: PropTypes.func.isRequired,
  deleteSavedQuote: PropTypes.func.isRequired,
  getAllIssuedContracts: PropTypes.func.isRequired,
  deleteIssuedContract: PropTypes.func.isRequired,
  downloadIssuedContract: PropTypes.func.isRequired,
  navigateToSignContracts: PropTypes.func.isRequired,
  showUpdateSignersModal: PropTypes.bool.isRequired,
  setShowUpdateSignersModal: PropTypes.func.isRequired,
  workingIssuedContract: PropTypes.object,
  setWorkingIssuedContract: PropTypes.func.isRequired,
};

AllQuotes.defaultProps = {
  savedQuotes: [],
  issuedContracts: [],
  showUpdateSignerModal: false,
  workingIssuedContract: null,
};

export default compose(
  withState('columnFilters', 'updateColumnFilters', {}),
  withState('showUpdateSignersModal', 'setShowUpdateSignersModal', false),
  withState('workingIssuedContract', 'setWorkingIssuedContract', {}),
  connect(
    ({ auth, issuedContract, savedQuote }) => ({
      user: auth.user,
      savedQuotes: savedQuote.list,
      issuedContracts: issuedContract.list,
    }),
    {
      getAllSavedQuotes,
      deleteSavedQuote,
      getAllIssuedContracts,
      deleteIssuedContract,
      downloadIssuedContract,
      navigateToSignContracts,
    },
  ),
  withProps(({ savedQuotes, issuedContracts, columnFilters }) => ({
    savedQuotes: filterByColumn(savedQuotes, columnFilters),
    issuedContracts: filterByColumn(issuedContracts, columnFilters),
  })),
)(AllQuotes);
