import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';
import { Row, Col, Button } from 'react-bootstrap';
import AdminOverrides from './AdminOverrides';

export function Eula(props) {
  const handleClick = () => {
    const { onSubmit } = props;
    onSubmit();
  };
  const { user, dealerValid } = props;

  if (dealerValid) {
    return (
      <Row data-test-id="Eula">
        <Col xs={12}>
          <h3>USER'S AGREEMENT</h3>
          <p>
            I understand PRcoRATE will provide a quote for a Vehicle Service Contract on a specific
            vehicle from the VIN I entered. PRcoRATE will only determine if the vehicle is a
            four-wheel drive, super/turbo charged or a V10 in addition to determining if the vehicle
            is an included or excluded make, model or type. I agree it is my responsibility to
            confirm the vehicle complies with all other vehicle eligibility requirements.
          </p>
          <p>
            I agree, when using PRcoRATE, I am responsible for choosing the Open Road Plan Coverage,
            Optional or Other Coverage that the vehicle qualifies for under PRco’s program; and, I
            understand that the coverage as stated in the Vehicle Service Contract, signed by the
            purchaser of the Vehicle Service Contract and me, is controlling for the purposes of
            application and interpretation.
          </p>
          <p>
            I understand that PRco will not provide coverage for an ineligible vehicle in the event
            a quote is given.
          </p>
          <Button
            bsStyle="primary"
            onClick={handleClick}
            style={{ cursor: 'pointer' }}
            data-test-id="Eula-accept"
          >
            I Have Read This Page and Agree to These Terms of Use
          </Button>
        </Col>
        {_.get(user, 'dealerRel.prcoAdmin') && (
          <Col xs={12} style={{ marginTop: '40px' }}>
            <AdminOverrides />
          </Col>
        )}
      </Row>
    );
  } else {
    return null;
  }
}

Eula.propTypes = {
  user: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  dealerValid: PropTypes.bool,
};

Eula.defaultProps = {
  dealerValid: false,
};

export default connect(({ auth, dealer }) => ({
  user: auth.user,
  dealerValid: !!_.get(dealer, 'details'),
}))(Eula);
