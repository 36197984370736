import {
  concat,
  get,
  has,
  includes,
  isEmpty,
  first,
  find,
  some,
  every,
  partial,
  without,
} from 'lodash';
import { reset } from 'redux-form';
import customSelector from '../../utils/selector';
import api from '../../utils/service';
import { openDownload } from '../../utils/download';
import { isActuallyEmpty } from '../../utils/object';
import { startLoading, stopLoading } from './page';
import { TEMPLATE_META_DATA_FORM } from './form';

const initialState = {
  showUploadTemplateModal: false,
  showEditFieldsModal: false,
  allTemplateFieldsAreExpected: true,
  allTemplateFieldsAreRequired: false,
  templateFileData: {},
  templateList: [],
  fieldsType: null,
  fieldsLoading: false,
  fieldsError: null,
  fields: [],
  modifiedFields: [],
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_TEMPLATES:
      return {
        ...state,
        templateList: payload,
      };
    case TOGGLE_UPLOAD_TEMPLATE_MODAL:
      return {
        ...state,
        showUploadTemplateModal: payload,
      };
    case TOGGLE_EDIT_FIELDS_MODAL:
      return {
        ...state,
        showEditFieldsModal: payload,
      };
    case STORE_SELECTED_TEMPLATE_FILE_DATA:
      return {
        ...state,
        templateFileData: payload,
      };
    case STORE_TEMPLATE_UPLOADS:
      return {
        ...state,
        files: payload,
      };
    case RESET_FIELDS:
      return {
        ...state,
        fields: [],
        modifiedFields: [],
        fieldsType: null,
      };
    case FETCH_FIELDS_LOADING:
      return {
        ...state,
        fieldsType: payload,
        fieldsError: null,
        fieldsLoading: true,
      };
    case FETCH_FIELDS_ERROR:
      return {
        ...state,
        fieldsError: payload,
        fieldsLoading: false,
      };
    case FETCH_FIELDS_SUCCESS:
      return {
        ...state,
        fields: payload,
        fieldsLoading: false,
      };
    case TOGGLE_FIELD_REQUIRED: {
      const modified = { ...payload, required: !payload.required };
      return {
        ...state,
        modifiedFields: includes(state.modifiedFields, payload)
          ? without(state.modifiedFields, payload)
          : concat(state.modifiedFields, modified),
      };
    }
    case SAVE_FIELDS:
      return {
        ...state,
        fieldsLoading: true,
      };
    case SAVE_FIELDS_ERROR:
      return {
        ...state,
        fieldsLoading: false,
        error: payload,
      };
    case SAVE_FIELDS_SUCCESS:
      return {
        ...state,
        modifiedFields: [],
      };
    case TOGGLE_ALL_FIELDS_ARE_EXPECTED:
      return {
        ...state,
        allTemplateFieldsAreExpected: payload,
      };
    case TOGGLE_ALL_FIELDS_ARE_REQUIRED:
      return {
        ...state,
        allTemplateFieldsAreRequired: payload,
      };
    case POST_TEMPLATE_FOR_FIELD_VALIDATION:
      return {
        ...state,
        templateValidationReturn: payload,
        error: false,
      };
    case SAVE_TEMPLATE_AND_META_DATA:
      return {
        ...state,
        savedTemplate: payload,
        templateFileData: {},
      };
    case DISPLAY_TEMPLATE_ERROR:
      return {
        ...state,
        error: payload,
      };
    case STORE_TEMPLATE_SEARCH_TEXT:
      return {
        ...state,
        templateSearchText: payload,
      };
    default:
      return state;
  }
};

export const DISPLAY_TEMPLATE_ERROR = 'DISPLAY_TEMPLATE_ERROR';
export const displayTemplateError = payload => ({
  type: DISPLAY_TEMPLATE_ERROR,
  payload,
});

export const TOGGLE_UPLOAD_TEMPLATE_MODAL = 'TOGGLE_UPLOAD_TEMPLATE_MODAL';
export const toggleUploadTemplateModal = payload => ({
  type: TOGGLE_UPLOAD_TEMPLATE_MODAL,
  payload,
});

export const RESET_FIELDS = 'RESET_FIELDS';
export const resetFields = () => ({
  type: RESET_FIELDS,
});

export const TOGGLE_EDIT_FIELDS_MODAL = 'TOGGLE_EDIT_FIELDS_MODAL';
export const toggleEditFieldsModal = payload => dispatch => {
  dispatch({ type: TOGGLE_EDIT_FIELDS_MODAL, payload });
  dispatch(resetFields());
};

export const STORE_SELECTED_TEMPLATE_FILE_DATA = 'STORE_SELECTED_TEMPLATE_FILE_DATA';
export const storeSelectedTemplateFileData = payload => ({
  type: STORE_SELECTED_TEMPLATE_FILE_DATA,
  payload,
});

export const STORE_TEMPLATE_UPLOADS = 'STORE_TEMPLATE_UPLOADS';
export const storeTemplateUploads = (accepted, rejected) => dispatch => {
  if (accepted.length === 1) {
    dispatch(storeSelectedTemplateFileData(first(accepted)));
  }
  dispatch({ type: STORE_TEMPLATE_UPLOADS, payload: { accepted, rejected } });
};

const FETCH_FIELDS_LOADING = 'FETCH_FIELDS_LOADING';
const FETCH_FIELDS_ERROR = 'FETCH_FIELDS_ERROR';
const FETCH_FIELDS_SUCCESS = 'FETCH_FIELDS_SUCCESS';
export const fetchFields = type => async dispatch => {
  dispatch(resetFields());
  dispatch({ type: FETCH_FIELDS_LOADING, payload: type });
  type = String(type).toLowerCase();
  try {
    const fields = await api.get(`/template-fields/${type}`);
    dispatch({ type: FETCH_FIELDS_SUCCESS, payload: fields.data });
  } catch (err) {
    dispatch({ type: FETCH_FIELDS_ERROR, payload: err.response.data.message });
  }
};

const TOGGLE_FIELD_REQUIRED = 'TOGGLE_FIELD_REQUIRED';
export const toggleFieldRequired = field => {
  return { type: TOGGLE_FIELD_REQUIRED, payload: field };
};

const SAVE_FIELDS = 'SAVE_FIELDS';
const SAVE_FIELDS_ERROR = 'SAVE_FIELDS_ERROR';
const SAVE_FIELDS_SUCCESS = 'SAVE_FIELDS_SUCCESS';
export const saveModifiedFields = () => async (dispatch, getState) => {
  const {
    eSignAdmin: { modifiedFields, fieldsType },
  } = getState();
  dispatch({ type: SAVE_FIELDS });
  try {
    await api.put('/template-fields', modifiedFields);
    dispatch({ type: SAVE_FIELDS_SUCCESS });
    dispatch(fetchFields(fieldsType));
  } catch (err) {
    dispatch({ type: SAVE_FIELDS_ERROR, payload: err.response.data.message });
  }
};

export const TOGGLE_ALL_FIELDS_ARE_EXPECTED = 'TOGGLE_ALL_FIELDS_ARE_EXPECTED';
export const toggleAllFieldsAreExpected = payload => ({
  type: TOGGLE_ALL_FIELDS_ARE_EXPECTED,
  payload,
});

export const TOGGLE_ALL_FIELDS_ARE_REQUIRED = 'TOGGLE_ALL_FIELDS_ARE_REQUIRED';
export const toggleAllFieldsAreRequired = payload => ({
  type: TOGGLE_ALL_FIELDS_ARE_REQUIRED,
  payload,
});

export const LOADING_TEMPLATES = 'LOADING_TEMPLATES';
export const GET_TEMPLATES = 'GET_TEMPLATES';
export const getTemplates = params => async dispatch => {
  try {
    /* { text, sort, order, fields } */
    dispatch(startLoading());
    const { data } = await api.get('/actions/templates/search/', { params });
    dispatch({ type: GET_TEMPLATES, payload: data.records });
    dispatch(stopLoading());
  } catch (err) {
    dispatch(displayTemplateError(err.toString()));
    dispatch(stopLoading());
  }
};

export const deleteTemplate = id => async (dispatch, getState) => {
  try {
    dispatch(startLoading());
    const {
      eSignAdmin: { templateSearchText },
    } = getState();
    await api.delete(`/templates/${id}`);
    const { data } = await api.get(
      templateSearchText
        ? `/actions/templates/search?text=${templateSearchText}`
        : '/actions/templates/search',
    );
    dispatch({ type: GET_TEMPLATES, payload: data.records });
    dispatch(stopLoading());
  } catch (err) {
    dispatch(displayTemplateError(err.toString()));
    dispatch(stopLoading());
  }
};

export const SAVE_TEMPLATE_AND_META_DATA = 'SAVE_TEMPLATE_AND_META_DATA';
export const saveTemplateAndMetaData = () => async (dispatch, getState) => {
  try {
    const {
      eSignAdmin,
      form: { templateMetaData = {} },
    } = getState() || {};
    const { values } = templateMetaData;
    const { templateFileData } = eSignAdmin || {};
    const fileForm = new FormData();
    fileForm.append('templateFile', templateFileData);
    fileForm.append('metaData', JSON.stringify(values));
    const { data } = await api.put('/actions/templates/save', fileForm);
    dispatch({ type: SAVE_TEMPLATE_AND_META_DATA, payload: data });
    dispatch(getTemplates());
    dispatch(reset(TEMPLATE_META_DATA_FORM));
  } catch (err) {
    console.error(err);
    dispatch(displayTemplateError(err.toString()));
  }
};

export const POST_TEMPLATE_FOR_FIELD_VALIDATION = 'POST_TEMPLATE_FOR_FIELD_VALIDATION';
export const postTemplateForFieldValidation = payload => async (dispatch, getState) => {
  try {
    const { eSignAdmin } = getState() || {};
    const selectedFields = payload;
    const { templateFileData, allTemplateFieldsAreExpected, allTemplateFieldsAreRequired } =
      eSignAdmin || {};
    const fileForm = new FormData();
    fileForm.append('templateFile', templateFileData);
    fileForm.append(
      'metaData',
      JSON.stringify({
        fields: selectedFields,
        allFieldsExpected: allTemplateFieldsAreExpected,
        allFieldsRequired: allTemplateFieldsAreRequired,
      }),
    );
    const { data } = await api.post('/actions/templates/validate', fileForm);
    const { message, valid, fileName } = data || {};
    if (valid) {
      return dispatch({ type: POST_TEMPLATE_FOR_FIELD_VALIDATION, payload: fileName });
    }
    dispatch(displayTemplateError(`Template is not valid: ${message}`));
  } catch (err) {
    console.error(err);
    const { response } = err || {};
    if (response && response.data) {
      return dispatch(
        displayTemplateError(
          response.data.message || `Error during template validation: ${err.toString()}`,
        ),
      );
    }
    dispatch(displayTemplateError(err.toString()));
  }
};

export const getTemplateExampleFile = templateExampleFileName => async (/* dispatch */) => {
  const response = await api.get(`/actions/templates/validated/${templateExampleFileName}`, {
    responseType: 'blob',
  });
  openDownload(response.data, templateExampleFileName);
};

// export const VIEW_TEMPLATE = 'VIEW_TEMPLATE';
export const viewTemplate = (id, name) => async dispatch => {
  try {
    const response = await api.get(`/actions/templates/view/${id}`, { responseType: 'blob' });
    openDownload(response.data, name);
  } catch (err) {
    dispatch(displayTemplateError(err.toString()));
  }
};

export const STORE_TEMPLATE_SEARCH_TEXT = 'STORE_TEMPLATE_SEARCH_TEXT';
export const storeTemplateSearchText = payload => ({
  type: STORE_TEMPLATE_SEARCH_TEXT,
  payload,
});

const files = ({ eSignAdmin }) => eSignAdmin.files;
const validatedFileName = ({ eSignAdmin }) => eSignAdmin.templateValidationReturn;
const fields = ({ eSignAdmin }) => eSignAdmin.fields;
const modifiedFields = ({ eSignAdmin }) => eSignAdmin.modifiedFields;
const metaDataValues = ({ form }) => get(form, 'templateMetaData.values', {});

export const acceptedFiles = customSelector(files, (f = {}) => f.accepted);

export const latestFields = customSelector(fields, modifiedFields, (fs, ms) => {
  return fs.map(f => find(ms, m => m._id === f._id) || f);
});

export const selectedFields = customSelector(latestFields, fs => {
  return fs.filter(f => f.required);
});

export const disableUpload = customSelector(files, metaDataValues, (f = {}, md) => {
  const noFiles = isEmpty(f.accepted);
  const missingFields = !every(['templateType', 'language', 'state'], partial(has, md));
  const missingValues = some(md, isActuallyEmpty);
  return noFiles || missingFields || missingValues;
});

export const disableSave = customSelector(
  files,
  metaDataValues,
  validatedFileName,
  (f = {}, md, vfn) => {
    const noFiles = isEmpty(f.accepted);
    const missingFields = !every(['templateType', 'language', 'state'], partial(has, md));
    const missingValues = some(md, isActuallyEmpty);
    return !vfn || noFiles || missingFields || missingValues;
  },
);
