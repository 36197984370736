import React, { useEffect, useState, useMemo } from 'react';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import moment from 'moment';
import { Col, Row } from 'react-bootstrap';
import Input from '../../../components/common/form/Input';
import Select from '../../../components/common/form/Select';
import SubmitButton from '../../../components/common/form/Submit';
import {
  textTrimRequired,
  money,
  minFinancedAmountLessCostOfGap,
  notBeforeDays,
  notInFuture,
  maxLoanAmount,
  requiredIfTrue,
} from '../../../components/common/form/schema/common';
import { quoteMetaTestDataIfRequested } from '../../../utils/testData';
import { submitNewQuoteRequest } from '../../../redux/modules/newQuote';
import {
  COMMERCIAL_TYPES,
  COMMERCIAL_OPTIONS,
  VEHICLE_CONDITION_TYPES,
  VEHICLE_CONDITION_OPTIONS,
  RELIABLE_CREDIT_ASSOCIATION_OPTIONS,
} from '../constants';
import ContractTypeCheckboxes from './form/ContractTypeCheckboxes';
import { useStoreFormInQuery } from '../../../components/common/query/hooks';
import CommercialMessage from './CommercialMessage';
import { quoteTypeIncludesVSCxTWP } from '../../../constants/quoteType';
import {
  QUOTE_TYPE_ONLY_VSC,
  quoteTypeIncludesGAP,
  quoteTypeIncludesTWP,
  quoteTypeIncludesVSC,
} from '../../../constants/quoteType';
import { getVerificationOverride } from '../../../redux/modules/verificationOverride';
import { getDealer } from '../../../redux/modules/dealer';
import { getDealerSettings } from '../../../redux/modules/dealerSettings';
import QuoteSummary from './QuoteSummary';
import FinalQuote from './FinalQuote';

export const isLoanAmountRequired = ({ quoteType }) => quoteTypeIncludesGAP(quoteType);

export const isVehicleConditionRequired = ({ quoteType, dealerState }) =>
  quoteTypeIncludesGAP(quoteType) && dealerState === 'IN';

export const isMsrpRequired = ({ quoteType, dealerState, vehicleCondition }) =>
  quoteTypeIncludesGAP(quoteType) &&
  dealerState === 'IN' &&
  vehicleCondition === VEHICLE_CONDITION_TYPES.NEW;

export const isNadaAverageRetailValueRequired = ({ quoteType, dealerState, vehicleCondition }) =>
  quoteTypeIncludesGAP(quoteType) &&
  dealerState === 'IN' &&
  vehicleCondition === VEHICLE_CONDITION_TYPES.USED;

export const isFinancedAmountLessCostOfGapRequired = ({ quoteType, dealerState }) =>
  quoteTypeIncludesGAP(quoteType) && dealerState === 'IN';

export const isReliableCreditAssociatedRequired = ({ quoteType, dealerState }) =>
  quoteTypeIncludesGAP(quoteType) && dealerState === 'AZ';

const isAllTypesSelected = newQuote => {
  const {
    form: { quoteType },
  } = newQuote;

  if (quoteTypeIncludesVSC(quoteType) && !newQuote.selectedVscQuoteId) return false;

  if (quoteTypeIncludesGAP(quoteType) && !newQuote.selectedGapQuoteId) return false;

  if (quoteTypeIncludesTWP(quoteType) && !newQuote.selectedTwpQuoteId) return false;

  return true;
};

function Quote({
  router,
  location,
  dealerHasUsedNewFeature,
  dealerState,
  dealerInfo,
  getVerificationOverride,
  verificationOverride,
  contractYear,
  getDealer,
  getDealerSettings,
  submitNewQuoteRequest,
  allTypesSelected,
}) {
  useEffect(() => {
    if (!dealerState) {
      getDealer();
      getDealerSettings();
    }
  }, [dealerState]);
  const [showFinalQuote, setShowFinalQuote] = useState(false);
  const [valuesFromQuery, setValuesFromQuery] = useStoreFormInQuery(router, location);
  const [loanAmountCleared, setLoanAmountCleared] = useState(false);
  const [loanAmountOverride, setLoanAmountOverride] = useState(false);
  const [quoteSubmissionValues, setQuoteSubmissionValues] = useState();

  const showSummary = showFinalQuote ? false : location && location.pathname.includes('/summary');
  const formDisabled = showSummary || showFinalQuote;

  const schema = useMemo(
    () =>
      Yup.object().shape({
        vin: textTrimRequired,
        msrp: Yup.number().test(money).test(requiredIfTrue(isMsrpRequired, { dealerState })),
        nadaAverageRetailValue: Yup.number()
          .test(money)
          .test(requiredIfTrue(isNadaAverageRetailValueRequired, { dealerState })),
        financedAmountLessCostOfGap: Yup.number()
          .test(money)
          .test(minFinancedAmountLessCostOfGap)
          .test(requiredIfTrue(isFinancedAmountLessCostOfGapRequired, { dealerState })),
        reliableCreditAssociation: Yup.string().test(
          requiredIfTrue(isReliableCreditAssociatedRequired, { dealerState }),
        ),
        loanAmount: loanAmountOverride
          ? Yup.number().test(money).test(requiredIfTrue(isLoanAmountRequired))
          : Yup.number()
              .test(money)
              .test(maxLoanAmount({ dealerState, contractYear }))
              .test(requiredIfTrue(isLoanAmountRequired)),
        vehicleSaleDate: Yup.date('MM/DD/YYYY')
          .test(notInFuture)
          .test(notBeforeDays(20))
          .required('Required'),
      }),
    [dealerState, contractYear, loanAmountOverride],
  );

  function parseValues(values) {
    const { quoteType: quoteTypeArray } = values || {};

    const quoteType = quoteTypeArray && quoteTypeArray.join(',');

    return { ...values, quoteType };
  }

  const handleSubmit = values => {
    // TODO Currently there is not check if user have selected at least one of contract types.
    setQuoteSubmissionValues(parseValues(values));
    router.push(`${location.pathname}/summary${location.search}`);
  };

  useEffect(() => {
    setQuoteSubmissionValues(valuesFromQuery);
  }, [valuesFromQuery]);

  useEffect(() => {
    getVerificationOverride();
  }, []);

  useEffect(() => {
    if (quoteSubmissionValues && showSummary) {
      submitNewQuoteRequest(quoteSubmissionValues);
    }
  }, [quoteSubmissionValues, showSummary]);

  const testData = quoteMetaTestDataIfRequested() || {};

  return (
    <div className="container">
      {!showFinalQuote && (
        <Formik
          validateOnBlur
          validateOnMount
          enableReinitialize
          initialValues={{
            commercialType: COMMERCIAL_TYPES.NON_COMMERCIAL,
            vehicleCondition: VEHICLE_CONDITION_TYPES.USED,
            ...testData,
            ...valuesFromQuery,
            ...quoteSubmissionValues,
            quoteType:
              (quoteSubmissionValues &&
                quoteSubmissionValues.quoteType &&
                quoteSubmissionValues.quoteType.split(',')) ||
              (valuesFromQuery &&
                valuesFromQuery.quoteType &&
                valuesFromQuery.quoteType.split(',')) ||
              (testData && testData.quoteType && testData.quoteType.split(',')),
          }}
          validationSchema={schema}
          onSubmit={handleSubmit}
        >
          {({ handleReset, handleSubmit, values, isValid, isSubmitting, setFieldValue }) => {
            const {
              quoteType: quoteTypeArray,
              commercialType,
              vehicleCondition,
              vin,
            } = values || {};

            const quoteType = quoteTypeArray && quoteTypeArray.join(',');

            useEffect(() => {
              setValuesFromQuery({
                ...values,
                quoteType,
              });
            }, [values]);

            useEffect(() => {
              if (commercialType === COMMERCIAL_TYPES.COMMERCIAL) {
                if (dealerHasUsedNewFeature) {
                  setFieldValue('vehicleCondition', VEHICLE_CONDITION_TYPES.USED);
                }
                setFieldValue('quoteType', [QUOTE_TYPE_ONLY_VSC]);
              }
            }, [commercialType, dealerHasUsedNewFeature]);

            useEffect(() => {
              if (quoteTypeIncludesVSCxTWP(quoteType) && dealerHasUsedNewFeature) {
                setFieldValue('vehicleCondition', VEHICLE_CONDITION_TYPES.USED);
              }
            }, [quoteType, dealerHasUsedNewFeature]);

            useEffect(() => {
              if (!quoteTypeIncludesGAP(quoteType) && !loanAmountCleared) {
                console.warn(
                  'This is a deprecated workflow, we should consider another route for ',
                );
                setFieldValue('loanAmount', 0);
                // @TODO - We need to replace in the long term. This will not work well.
                setLoanAmountCleared(true);
              }
            }, [loanAmountCleared, quoteType]);

            useEffect(() => {
              if (_.get(verificationOverride, 'length')) {
                const overrides = verificationOverride.filter(({ vin: vin2 }) => vin === vin2);
                setLoanAmountOverride(overrides.length > 0);
              }
            }, [vin, verificationOverride]);

            return (
              <form
                onReset={handleReset}
                noValidate={true}
                onSubmit={handleSubmit}
                data-test-id="QuoteMetaForm"
              >
                <Row>
                  <Col xs={12} md={8}>
                    <Select
                      name="commercialType"
                      label="Designation"
                      placeholder="Choose one"
                      options={COMMERCIAL_OPTIONS}
                      data-test-id="QuoteMetaForm-commercialType"
                      disabled={formDisabled}
                    />
                    {dealerHasUsedNewFeature && (
                      <Select
                        name="vehicleCondition"
                        label="Vehicle Condition"
                        options={VEHICLE_CONDITION_OPTIONS}
                        disabled={quoteTypeIncludesVSCxTWP(quoteType) || formDisabled}
                        data-test-id="QuoteMetaForm-vehicleCondition"
                      />
                    )}
                  </Col>
                  <Col xs={12} md={4}>
                    <ContractTypeCheckboxes
                      name="quoteType"
                      disabled={commercialType === COMMERCIAL_TYPES.COMMERCIAL || formDisabled}
                      dealerInfo={dealerInfo}
                    />
                  </Col>
                  <Col xs={12}>
                    <CommercialMessage
                      show={
                        (commercialType && commercialType === COMMERCIAL_TYPES.COMMERCIAL) ||
                        formDisabled
                      }
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <hr />
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} md={6}>
                    <Input
                      type="text"
                      name="vin"
                      label="VIN"
                      placeholder="Full VIN - eg. 1GNSKJKC6GR123038"
                      data-test-id="VehicleBasicInformationForm-vin"
                      disabled={formDisabled}
                    />
                  </Col>
                  <Col xs={12} md={6}>
                    <Input
                      type="text"
                      name="vehicleMileage"
                      label="Current Mileage"
                      placeholder="eg. 4681"
                      data-test-id="VehicleBasicInformationForm-vehicleMileage"
                      disabled={formDisabled}
                    />
                  </Col>
                </Row>
                <Row>
                  {isVehicleConditionRequired({ quoteType, dealerState }) && (
                    <Col xs={12} md={6}>
                      <Select
                        name="vehicleCondition"
                        label="Vehicle Condition"
                        component={ValidationSelect}
                        options={VEHICLE_CONDITION_OPTIONS}
                        data-test-id="VehicleBasicInformationForm-vehicleCondition"
                        disabled={formDisabled}
                      />
                    </Col>
                  )}
                  {isMsrpRequired({ quoteType, dealerState, vehicleCondition }) && (
                    <Col xs={12} md={6}>
                      <Input
                        name="msrp"
                        type="number"
                        label="MSRP"
                        placeholder="eg. 22000"
                        data-test-id="VehicleBasicInformationForm-msrp"
                        disabled={formDisabled}
                      />
                    </Col>
                  )}
                </Row>
                <Row>
                  {isNadaAverageRetailValueRequired({
                    quoteType,
                    dealerState,
                    vehicleCondition,
                  }) && (
                    <Col xs={12} md={6}>
                      <Input
                        name="nadaAverageRetailValue"
                        type="number"
                        label="NADA Average Retail Value"
                        placeholder="eg. 16500"
                        data-test-id="VehicleBasicInformationForm-nadaAverageRetailValue"
                        disabled={formDisabled}
                      />
                    </Col>
                  )}
                  {isFinancedAmountLessCostOfGapRequired({
                    quoteType,
                    dealerState,
                  }) && (
                    <Col xs={12} md={6}>
                      <Input
                        name="financedAmountLessCostOfGap"
                        type="number"
                        label="Financed Amount less cost of GAP, credit insurance, and vehicle service contract"
                        placeholder="eg. 7600"
                        data-test-id="VehicleBasicInformationForm-financedAmountLessCostOfGap"
                        disabled={formDisabled}
                      />
                    </Col>
                  )}
                </Row>
                <Row>
                  <Col xs={12} md={6}>
                    <Input
                      name="vehiclePurchasePrice"
                      type="number"
                      label="Vehicle Price"
                      placeholder="eg. 20000"
                      data-test-id="VehicleBasicInformationForm-vehiclePurchasePrice"
                      disabled={formDisabled}
                    />
                  </Col>
                  <Col xs={12} md={6}>
                    <Input
                      name="vehicleSaleDate"
                      type="date"
                      label="Vehicle Sale Date"
                      placeholder={`eg. ${moment().format('MM/DD/YYYY')}`}
                      data-test-id="VehicleBasicInformationForm-vehicleSaleDate"
                      disabled={formDisabled}
                    />
                  </Col>
                </Row>
                <Row>
                  {isLoanAmountRequired({ quoteType }) && (
                    <Col xs={12} md={6}>
                      <Input
                        name="loanAmount"
                        type="number"
                        label="Loan Amount"
                        placeholder="eg. 4681"
                        step="0.01"
                        data-test-id="VehicleBasicInformationForm-loanAmount"
                        disabled={formDisabled}
                      />
                    </Col>
                  )}
                  {isReliableCreditAssociatedRequired({ quoteType, dealerState }) && (
                    <Col xs={12} md={6}>
                      <Input
                        name="reliableCreditAssociation"
                        label="Is the lender Reliable Credit Association or not?"
                        placeholder="Choose one"
                        options={RELIABLE_CREDIT_ASSOCIATION_OPTIONS}
                        data-test-id="VehicleBasicInformationForm-reliableCreditAssociation"
                        disabled={formDisabled}
                      />
                    </Col>
                  )}
                </Row>
                {!showSummary && (
                  <Row className="margin-top-20px">
                    {' '}
                    <Col xs={12}>
                      <SubmitButton
                        loadingText="Retrieving Quote..."
                        isValid={isValid}
                        isSubmitting={isSubmitting}
                        data-test-name="VehicleBasicInformationForm-Buttons"
                      >
                        Generate Quote
                      </SubmitButton>
                    </Col>
                  </Row>
                )}
              </form>
            );
          }}
        </Formik>
      )}
      {showSummary && (
        <QuoteSummary
          quoteSubmissionValues={quoteSubmissionValues || {}}
          handleSubmit={() => setShowFinalQuote(true)}
        />
      )}
      {showSummary && allTypesSelected && (
        <FinalQuote
          quoteSubmissionValues={quoteSubmissionValues || {}}
          router={router}
          location={location}
        />
      )}
      {showSummary && !allTypesSelected && (
        <div>
          <i>Select plan for each contract type to continue.</i>
        </div>
      )}
    </div>
  );
}

const mapFromConnect = state => ({
  dealerHasUsedNewFeature: _.get(state, 'dealerSettings.data.features.usedNew', false),
  dealerInfo: _.get(state, 'dealer.details'),
  contractYear: _.get(state, 'dealer.details.rating.contractYear'),
  dealerState: _.get(state, 'dealer.details.address.state'),
  verificationOverride: _.get(state, 'verificationOverride.data'),
  loading: _.get(state, 'page.loading'),
  allTypesSelected: isAllTypesSelected(state.newQuote),
});

export default compose(
  connect(mapFromConnect, {
    getVerificationOverride,
    getDealer,
    getDealerSettings,
    submitNewQuoteRequest,
  }),
)(Quote);
