import _ from 'lodash';
import api from '../../utils/service';
import { loadingCycle } from './page';

const GET_ALL_SAVED_QUOTES_SUCCESS = 'redux/savedQuote/GET_ALL_SAVED_QUOTES_SUCCESS';
const GET_ALL_SAVED_QUOTES_FAIL = 'redux/savedQuote/GET_ALL_SAVED_QUOTES_FAIL';
const GET_SAVED_QUOTE_SUCCESS = 'redux/savedQuote/GET_SAVED_QUOTE_SUCCESS';
const GET_SAVED_QUOTE_FAIL = 'redux/savedQuote/GET_SAVED_QUOTE_FAIL';
const CREATE_SAVED_QUOTE_SUCCESS = 'redux/savedQuote/CREATE_SAVED_QUOTE_SUCCESS';
const CREATE_SAVED_QUOTE_FAIL = 'redux/savedQuote/CREATE_SAVED_QUOTE_FAIL';
const DELETE_SAVED_QUOTE_SUCCESS = 'redux/savedQuote/DELETE_SAVED_QUOTE_SUCCESS';
const DELETE_SAVED_QUOTE_FAIL = 'redux/savedQuote/DELETE_SAVED_QUOTE_FAIL';

const initialState = {};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case GET_ALL_SAVED_QUOTES_SUCCESS:
      return {
        ...state,
        list: action.data,
        error: null,
      };
    case GET_SAVED_QUOTE_SUCCESS:
    case DELETE_SAVED_QUOTE_SUCCESS:
      return {
        ...state,
        result: action.data,
        error: null,
      };
    case CREATE_SAVED_QUOTE_SUCCESS:
      return {
        ...state,
        result: action.data,
        error: null,
      };
    case GET_ALL_SAVED_QUOTES_FAIL:
    case GET_SAVED_QUOTE_FAIL:
    case CREATE_SAVED_QUOTE_FAIL:
    case DELETE_SAVED_QUOTE_FAIL:
      return {
        ...state,
        error: action.error,
      };
    default:
      return state;
  }
}

export function getAllSavedQuotes() {
  return dispatch =>
    loadingCycle(api.get('/saved-quotes'))
      .then(({ data }) => dispatch(getAllSavedQuotesSuccess(data)))
      .catch(error => dispatch(getAllSavedQuotesFail(error)));
}

export function getAllSavedQuotesSuccess(data) {
  return {
    type: GET_ALL_SAVED_QUOTES_SUCCESS,
    data,
  };
}

export function getAllSavedQuotesFail(error) {
  return {
    type: GET_ALL_SAVED_QUOTES_FAIL,
    error: _.get(error, 'response.data.message', _.get(error, 'message')),
  };
}

export function getSavedQuote(id) {
  return dispatch =>
    loadingCycle(api.get(`/saved-quotes/${id}`))
      .then(({ data }) => dispatch(getSavedQuoteSuccess(data)))
      .catch(error => dispatch(getSavedQuoteFail(error)));
}

export function getSavedQuoteSuccess(data) {
  return {
    type: GET_SAVED_QUOTE_SUCCESS,
    data,
  };
}

export function getSavedQuoteFail(error) {
  return {
    type: GET_SAVED_QUOTE_FAIL,
    error: _.get(error, 'response.data.message', _.get(error, 'message')),
  };
}

export function createSavedQuote(data) {
  return dispatch =>
    loadingCycle(api.post('/saved-quotes', data))
      .then(({ data }) => dispatch(createSavedQuoteSuccess(data)))
      .catch(error => dispatch(createSavedQuoteFail(error)));
}

export function createSavedQuoteSuccess(data) {
  return {
    type: CREATE_SAVED_QUOTE_SUCCESS,
    data,
  };
}

export function createSavedQuoteFail(error) {
  return {
    type: CREATE_SAVED_QUOTE_FAIL,
    error: _.get(error, 'response.data.message', _.get(error, 'message')),
  };
}

export function deleteSavedQuote(id) {
  return dispatch =>
    loadingCycle(api.delete(`/saved-quotes/${id}`))
      .then(({ data }) => dispatch(createSavedQuoteSuccess(data)))
      .then(() => dispatch(getAllSavedQuotes()))
      .catch(error => dispatch(createSavedQuoteFail(error)));
}

export function deleteSavedQuoteSuccess(data) {
  return {
    type: DELETE_SAVED_QUOTE_SUCCESS,
    data,
  };
}

export function deleteSavedQuoteFail(error) {
  return {
    type: DELETE_SAVED_QUOTE_FAIL,
    error: _.get(error, 'response.data.message', _.get(error, 'message')),
  };
}
