import React from 'react';
import { ControlLabel, FormGroup, Row, Col } from 'react-bootstrap';
import { Field } from 'formik';
import { contractTypeDetailTitle as getContractTypeTitle } from '../../../../constants/contractType';

import { quoteTypeItems } from '../../../../constants/quoteType';

export default function ContractTypeCheckboxes({ quoteType, ...props }) {
  return (
    <FormGroup>
      <ControlLabel>Contract Type</ControlLabel>
      <Row>
        {quoteTypeItems(quoteType).map(ct => (
          <Col xs={4} key={ct}>
            <label>
              <Field type="checkbox" {...props} className="mr-2" key={ct} value={ct} />
              {getContractTypeTitle(ct)}
            </label>
          </Col>
        ))}
      </Row>
    </FormGroup>
  );
}
