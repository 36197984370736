import PropTypes from 'prop-types';

export const stateBasedConstants = {
  AZ: {
    RELIABLE_CREDIT_ASSOCIATION: 'AZRCA',
    OTHER: 'OTHER',
  },
};

export const COMMERCIAL_TYPES = {
  NON_COMMERCIAL: 'NON_COMMERCIAL',
  COMMERCIAL: 'COMMERCIAL',
};

export const VEHICLE_CONDITION_TYPES = {
  NEW: 'NEW',
  USED: 'USED',
};

export const CommercialTypePropType = PropTypes.oneOf([
  COMMERCIAL_TYPES.NON_COMMERCIAL,
  COMMERCIAL_TYPES.COMMERCIAL,
]);
export const QuoteTypePropType = PropTypes.string;
