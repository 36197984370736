import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import numeral from 'numeral';
import _ from 'lodash';
import {
  ContractTypePropType,
  contractTypeTitle,
  CONTRACT_TYPE_VSC,
  CONTRACT_TYPE_GAP,
  CONTRACT_TYPE_TWP,
} from '../../../constants/contractType';

function planTableKeyValue(k) {
  switch (k) {
    case 'quoteId':
      return 'Selected Quote ID';
    case 'vin':
      return 'VIN #';
    case 'groupTitle':
      return 'Plan Selected';
    default:
      return _.startCase(k);
  }
}

function planTableFormattedValue(v, k, quote, contractType) {
  switch (k) {
    case 'vehicleMileage':
      return numeral(v).format('0,0');
    case 'vehiclePurchasePrice':
    case 'planDeductible':
      return numeral(v).format('$0,0.00');
    case 'commercialType':
    case 'vehicleCondition':
      return _.capitalize(_.replace(v, /_/g, ' '));
    case 'groupTitle':
      if (contractType === CONTRACT_TYPE_VSC) {
        return `${quote.termMonthsQuoted} Months / ${numeral(quote.termMilesQuoted).format(
          '0,0',
        )} - ${quote.groupTitle}`;
      } else {
        return `${quote.termMonthsQuoted} Months - ${numeral(quote.contractQuotePrice).format(
          '$0,0.00',
        )}`;
      }
    default:
      return v;
  }
}

const calculateContractQuotePrice = (price, type, dealerSettings) => {
  if (dealerSettings.dollarMarkupVSC && type === CONTRACT_TYPE_VSC) {
    return +price + +dealerSettings.dollarMarkupVSC;
  } else if (dealerSettings.percentageMarkupVSC && type === CONTRACT_TYPE_VSC) {
    return +price + +price * (+dealerSettings.percentageMarkupVSC / 100);
  }
  if (dealerSettings.dollarMarkupTWP && type === CONTRACT_TYPE_TWP) {
    return +price + +dealerSettings.dollarMarkupTWP;
  } else if (dealerSettings.percentageMarkupTWP && type === CONTRACT_TYPE_TWP) {
    return +price + +price * (+dealerSettings.percentageMarkupTWP / 100);
  }
  if (dealerSettings.dollarMarkupGAP && type === CONTRACT_TYPE_GAP) {
    return +price + +dealerSettings.dollarMarkupGAP;
  } else if (dealerSettings.percentageMarkupGAP && type === CONTRACT_TYPE_GAP) {
    return +price + +price * (+dealerSettings.percentageMarkupGAP / 100);
  }
  return +price;
};

function calculateTotalPrice(quote, otherCoverages, dealerSettings) {
  const contractQuotePrice = calculateContractQuotePrice(
    quote.contractQuotePrice,
    quote.quoteType,
    dealerSettings,
  );
  const otherCoveragesPrices =
    _.chain(otherCoverages)
      .filter('value')
      .sumBy(({ value }) => parseFloat(value))
      .value() || 0;
  return contractQuotePrice + otherCoveragesPrices;
}

// NOTE - This looks very similar to the Summary.RequestTable, but there are a couple differences that I would like to keep separate
function PlanTable({ request, contractType, quote, otherCoverages, dealerSettings }) {
  return (
    <div style={{ marginTop: 40 }}>
      <h2>{contractTypeTitle(contractType)} Plan</h2>

      {_.chain(quote)
        .cloneDeep()
        .merge(_.pick(request, ['quoteRequestId', 'commercialType', 'vehicleCondition']))
        .pick([
          'quoteRequestId',
          'dealerName',
          'commercialType',
          'vehicleCondition',
          'vin',
          'vehicleMileage',
          'vehicleDescription',
          'vehicleYear',
          'ratingVehicleClass',
          'vehiclePurchasePrice',
        ])
        .map((v, k) => (
          <div key={k}>
            <b>{planTableKeyValue(k)}:</b> {planTableFormattedValue(v, k, quote, contractType)}
          </div>
        ))
        .value()}

      <div>
        <h3>Plan Details</h3>
        {_.chain(quote)
          .pick(
            _.filter([
              'quoteId',
              'groupTitle',
              contractType === CONTRACT_TYPE_VSC && 'planDeductible',
            ]),
          )
          .map((v, k) => (
            <div key={k}>
              <b>{planTableKeyValue(k)}:</b> {planTableFormattedValue(v, k, quote, contractType)}
            </div>
          ))
          .value()}
      </div>

      {!_.isEmpty(otherCoverages) && (
        <Fragment>
          <b>Other Coverages</b>
          <table>
            <tbody>
              {_.map(otherCoverages, ({ label }) => (
                <tr key={label}>
                  <td>{label}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </Fragment>
      )}
      <div style={{ margin: '20px 0' }}>
        <b>{`${contractTypeTitle(contractType)} Total: ${numeral(
          calculateTotalPrice(quote, otherCoverages, dealerSettings),
        ).format('$0,0.00')}`}</b>
      </div>
    </div>
  );
}

PlanTable.propTypes = {
  request: PropTypes.object.isRequired,
  contractType: ContractTypePropType.isRequired,
  quote: PropTypes.object.isRequired,
  otherCoverages: PropTypes.array,
  dealerSettings: PropTypes.object,
};

PlanTable.defaultProps = {
  otherCoverages: null,
  dealerSettings: {},
};

export default PlanTable;
