import _ from 'lodash';
import { parse } from 'query-string';
import moment from 'moment';
import { STATES, STATES_ABBREVIATIONS } from '../constants/general';
import { COMMERCIAL_TYPES } from '../constants/newQuote';
import { quoteTypeNormalize, quoteTypeUnion } from '../constants/quoteType';
import { contractTypesAll } from '../constants/contractType';

const ACCOUNT_NUMBER_BANK = [
  28033062, 89733529, 65130762, 16260679, 66970730, 88519093, 88985532, 95471647, 34873310,
  43476537, 57571556,
];
const ROUTING_NUMBER_BANK = [
  252984520, 223947429, 519976804, 857168141, 373482575, 399123218, 210940551, 507612563, 309620007,
  536097649, 262928167,
];
const DEALER_ID_BANK = [1820, 7739, 1113, 5018];
const BANK_NAME_BANK = ['Bank of America', 'Chase', 'Wells Fargo', 'Capital One', 'Citibank'];
const NAME_BANK = [
  'Syreeta Spraggins',
  'Alden Tomlin',
  'Leora Matheney',
  'Keenan Paolucci',
  'Ivette Pigeon',
  'Thanh Cyphers',
  'Elisa Bowens',
  'Daniela Beers',
  'Adelia Hazen',
  'Flor Archer',
];
const FIRST_NAME_BANK = _.map(NAME_BANK, name => _.split(name, ' ')[0]);
const LAST_NAME_BANK = _.map(NAME_BANK, name => _.split(name, ' ')[1]);
const EMAIL_BANK = [
  'Nunc.sed@Proinvel.edu',
  'nec.urna@facilisisloremtristique.edu',
  'sem.ut.cursus@eunulla.ca',
  'mi@quamquisdiam.co.uk',
  'ac.orci.Ut@Nunclectuspede.ca',
  'Proin@dui.edu',
  'iaculis.aliquet@Crasvulputatevelit.com',
  'facilisis.magna.tellus@liberonec.net',
  'Integer@magnaLorem.ca',
  'risus@tincidunt.org',
  'erat@Donecnibh.co.uk',
  'luctus.Curabitur@consectetueripsum.com',
  'adipiscing.fringilla.porttitor@quis.org',
  'senectus.et.netus@Nulla.co.uk',
  'cubilia.Curae.Donec@molestie.net',
  'euismod.enim.Etiam@semmollisdui.co.uk',
  'dignissim.lacus.Aliquam@magnaLorem.com',
  'augue.scelerisque.mollis@Nuncullamcorpervelit.co.uk',
  'ultricies.ornare.elit@elit.org',
  'nisl.sem@inceptoshymenaeosMauris.edu',
  'a.auctor.non@dolor.com',
  'euismod@Suspendissenonleo.net',
  'vitae@temporerat.co.uk',
  'Mauris.vestibulum.neque@ornarefacilisis.com',
  'turpis.vitae.purus@Etiambibendum.ca',
  'et@Nuncsollicitudin.co.uk',
  'massa@pulvinararcu.edu',
  'feugiat.tellus@semperpretium.org',
  'aliquet.metus.urna@ultriciesdignissim.org',
  'cursus.purus@risus.ca',
];
const ADDRESS_BANK = [
  '526-3499 Ullamcorper, Rd.',
  '4387 Sed Avenue',
  'P.O. Box 467, 471 Eu Avenue',
];
const ADDRESS2_BANK = ['suite 3', 'apt 2', 'lot 6', '2nd floor', 'unit 16'];
const CITY_BANK = ['Austin', 'Springfield', 'Hawthorne', 'Paris', 'Heidelberg', 'Southend', 'Erie'];
const ZIP_BANK = ['11101', '60202', '38270', '64889'];
const SENTENCE_BANK = [
  'Cherubfish, salamanderfish, dartfish dealfish gombessa slimy mackerel bangus, ocean perch.',
  'Driftfish greeneye mackerel shark dartfish Razorback sucker roughy snook lampfish.',
  'African glass catfish silverside.',
  'Burrowing goby blue eye Chinook salmon gianttail Colorado squawfish; blenny; whale catfish hawkfish.',
  'Riffle dace tailor tadpole cod knifefish Red salmon, medaka, yellow weaver ling cod.',
  'Trumpetfish channel catfish bandfish trevally roach yellowfin skilfish, ribbonbearer.',
];
const WORD_BANK = [
  'diam',
  'erat',
  'nunc',
  'lectus',
  'condimentum.',
  'Nullam',
  'at',
  'eget',
  'velit',
  'Nam',
  'aliquet',
  'augue.',
  'dolor.',
  'enim',
  'nisi.',
  'sem',
  'nulla.',
  'auctor',
  'urna.',
  'eget',
  'eros',
  'magna',
  'tincidunt',
  'Aliquam',
  'accumsan',
  'facilisis',
  'mauris',
  'nisl.',
  'ornare',
  'enim.',
  'aliquet',
  'nibh',
  'Curabitur',
  'sollicitudin',
  'vel',
  'dui',
  'justo',
  'non',
  'Donec',
  'lorem',
  'ultrices.',
  'luctus',
  'ipsum',
  'Aenean',
  'pede,',
  'Donec',
  'viverra.',
  'mauris.',
  'quis',
  'vitae',
  'arcu.',
  'lobortis',
  'magna,',
  'vitae',
  'Suspendisse',
  'Mauris',
  'vehicula.',
  'sollicitudin',
  'et,',
  'dolor.',
  'In',
  'ligula',
  'urna.',
  'sed',
  'convallis',
  'faucibus.',
  'urna.',
  'interdum',
  'Class',
  'Vivamus',
  'non',
  'nisl',
  'Aliquam',
  'Fusce',
  'nec',
  'penatibus',
  'nisi.',
  'Curabitur',
  'et',
  'Nullam',
  'Quisque',
  'In',
  'inceptos',
  'sociosqu',
  'Quisque',
  'augue',
  'lacinia',
  'Suspendisse',
  'sem',
  'torquent',
  'felis',
  'mauris',
  'euismod',
  'ridiculus',
  'purus',
  'ornare',
  'vitae',
  'eu',
  'ut',
  'risus.',
];
const DEALERSHIP_NAME_BANK = [
  'Ford valley Ford',
  '6 Towns Chrystler',
  '3 Towns Toyota',
  'Peter Pan BMW',
  'Harrison County Ford',
  'Sals Used Auto',
  'Jack Frost Honda',
  'Kia of Tokyo',
];

const getSaleDate = _.memoize((days = 2) => moment().subtract(days, 'day').format('MM/DD/YYYY'));

export function testDataIfRequested(...fields) {
  if (typeof window !== 'undefined' && _.get(parse(window.location.search), 'testData')) {
    return _.chain(fields)
      .filter(_.isObject)
      .reduce((acc, field) => {
        _.set(
          acc,
          _.get(field, 'name'),
          !_.isFunction(field.custom)
            ? (() => {
                const type = _.get(field, 'type');
                switch (type) {
                  case 'processorName':
                    return `${_.sample(FIRST_NAME_BANK)} ${_.sample(LAST_NAME_BANK)}`;
                  case 'dealerName':
                    return _.sample(DEALERSHIP_NAME_BANK);
                  case 'dealershipAddress':
                    return _.sample(ADDRESS_BANK);
                  case 'dealershipAddress2':
                    return _.sample(ADDRESS2_BANK);
                  case 'dealerEmail':
                    return _.sample(EMAIL_BANK);
                  case 'bankName':
                    return _.sample(BANK_NAME_BANK);
                  case 'dealerCity':
                    return _.sample(CITY_BANK);
                  case 'dealerZip':
                    return _.sample(ZIP_BANK);
                  case 'dealerId':
                    return _.sample(DEALER_ID_BANK);
                  case 'name':
                    return `${_.sample(FIRST_NAME_BANK)} ${_.sample(LAST_NAME_BANK)}`;
                  case 'companyName':
                    return _.sample(DEALERSHIP_NAME_BANK);
                  case 'firstName':
                    return _.sample(FIRST_NAME_BANK);
                  case 'lastName':
                    return _.sample(LAST_NAME_BANK);
                  case 'email':
                    return _.sample(EMAIL_BANK);
                  case 'address':
                    return _.sample(ADDRESS_BANK);
                  case 'city':
                    return _.sample(CITY_BANK);
                  case 'state':
                    return _.sample(STATES);
                  case 'zipCode':
                    return _.sample(ZIP_BANK);
                  case 'sentence':
                    return _.sample(SENTENCE_BANK);
                  case 'routingNumber':
                    return _.sample(ROUTING_NUMBER_BANK);
                  case 'accountNumber':
                    return _.sample(ACCOUNT_NUMBER_BANK);
                  case 'dealerState':
                    return _.sample(STATES_ABBREVIATIONS);
                  case 'contractType':
                    return _.sample(contractTypesAll);
                  case 'number':
                    return _.sample(_.range(1, 20)) + _.sample(_.range(0, 100)) / 100;
                  default:
                    return _.sample(WORD_BANK);
                }
              })()
            : field.custom(field),
        );
        return acc;
      }, {})
      .value();
  } else {
    return undefined;
  }
}

export const newAccountFormTestDataIfRequested = _.partial(
  testDataIfRequested,
  {
    name: 'dealerId',
    type: 'dealerId',
  },
  {
    name: 'companyName',
    type: 'companyName',
  },
  {
    name: 'address',
    type: 'address',
  },
  {
    name: 'city',
    type: 'city',
  },
  {
    name: 'state',
    type: 'state',
  },
  {
    name: 'zipCode',
    type: 'zipCode',
  },
  {
    name: 'routingNumber',
    type: 'routingNumber',
  },
  {
    name: 'bankName',
    type: 'bankName',
  },
  {
    name: 'accountNumber',
    type: 'accountNumber',
  },
  {
    name: 'accountType',
    type: 'accountType',
  },
  {
    name: 'submittedByName',
    type: 'name',
  },
  {
    name: 'submittedByEmail',
    type: 'email',
  },
  {
    name: 'amount',
    type: 'number',
  },
  {
    name: 'checkNumber',
    type: 'dealerId',
  },
  {
    name: 'accountType',
    type: 'accountType',
  },
  {
    name: 'depositType',
    type: 'depositType',
  },
  {
    name: 'memo',
    type: 'sentence',
  },
);

export const dealerOnboardTestDataIfRequested = _.partial(
  testDataIfRequested,
  {
    name: 'processorName',
    type: 'processorName',
  },
  {
    name: 'dealershipName',
    type: 'dealershipName',
  },
  {
    name: 'dealershipAddress',
    type: 'dealershipAddress',
  },
  {
    name: 'dealershipAddress2',
    type: 'dealershipAddress2',
  },
  {
    name: 'dealerCity',
    type: 'dealerCity',
  },
  {
    name: 'dealerState',
    type: 'dealerState',
  },
  {
    name: 'dealerZip',
    type: 'dealerZip',
  },
  {
    name: 'dealerEmail',
    type: 'dealerEmail',
  },
);

export const issuedContractTestDataIfRequested = _.partial(
  testDataIfRequested,
  {
    name: 'dealerState',
    type: 'dealerState',
  },
  {
    name: 'contractType',
    type: 'contractType',
  },
  {
    name: 'customerFirstName',
    type: 'firstName',
  },
  {
    name: 'customerLastName',
    type: 'lastName',
  },
  {
    name: 'customerEmail',
    custom: _.constant('trevorewen+customer@gmail.com'),
  },
  {
    name: 'dealerName',
    type: 'dealerName',
  },
  {
    name: 'dealerEmail',
    custom: _.constant('trevorewen+dealer@gmail.com'),
  },
);

export const quoteMetaTestDataIfRequested = _.partial(
  testDataIfRequested,
  {
    name: 'commercialType',
    custom: _.constant(COMMERCIAL_TYPES.NON_COMMERCIAL),
  },
  {
    name: 'quoteType',
    custom: () =>
      quoteTypeNormalize(
        _.get(parse(window.location.search), 'testContractType', quoteTypeUnion(contractTypesAll)),
      ),
  },
);

export const vscGapQuoteTestDataIfRequested = _.partial(
  testDataIfRequested,
  {
    name: 'vin',
    custom: () =>
      _.sample([
        '1GNSKJKC6GR123038', // 2016 Chevrolet Suburban
        '3C3CFFER6CT225038', // 2012 Fiat 500
        'JTHFN48Y020002281', // 2002 Lexus SC 430
        'WDCGG8HB0AF462890', // 2010 Mercedes Benz GLK Class
        '1J4FJ78L5KL535075', // 1989 Jeep Cherokee
        '5XYZWDLA2HG407020', // 2017 Hyundai Santa Fe Sport
        'JH4KA8260PC000495', // 1993 Acura Legend
        '1FTRW12507FB38262', // 2007 Ford F 150
        '1GCHK29U86E255778', // 2006 Chevrolet Silverado 2500HD
        '2HGFA5E55BH700024', // 2011 Honda Civic
      ]),
  },
  {
    name: 'vehicleMileage',
    custom: () => _.random(10000, 50000),
  },
  {
    name: 'vehicleCondition',
    custom: () => _.sample(['NEW', 'USED']),
  },
  {
    name: 'msrp',
    custom: () => _.random(1000, 3000),
  },
  {
    name: 'nadaAverageRetailValue',
    custom: () => _.random(1000, 3000),
  },
  {
    name: 'financedAmountLessCostOfGap',
    custom: () => _.random(100, 4800),
  },
  {
    name: 'vehiclePurchasePrice',
    custom: () => _.random(35000, 50000),
  },
  {
    name: 'vehicleSaleDate',
    custom: () => getSaleDate(2),
  },
  {
    name: 'loanAmount',
    custom: () => _.random(35000, 50000),
  },
);

export const vscRateTestDataIfRequested = _.partial(
  testDataIfRequested,
  {
    name: 'vscRate.issueType',
    custom: () => quoteTypeUnion(_.sample(contractTypesAll)),
  },
  {
    name: 'vscRate.saleDate',
    custom: () => getSaleDate(2),
  },
  {
    name: 'vscRate.language',
    custom: () => _.sample(['EN', 'SP']),
  },
  {
    name: 'vscRate.vehiclePrice',
    custom: () => _.random(20000, 50000),
  },
  {
    name: 'vscRate.retailPriceOfVsc',
    custom: () => _.random(1200, 1900),
  },
  {
    name: 'vscRate.stockNumber',
    custom: () => _.shuffle(`${_.random(10000, 50000)}USABAGEL`.split('')).join(''),
  },
);

export const gapRateTestDataIfRequested = ({
  lowerBandOfGap = 0,
  higherBandOfGap = Infinity,
  maximumLiabilityLimit = Infinity,
}) =>
  testDataIfRequested(
    {
      name: 'gapRate.retailPriceOfGap',
      custom: () => _.random(1000, Math.min(5000, maximumLiabilityLimit || 5000)),
    },
    {
      name: 'gapRate.loanAmountOfGap',
      custom: () => _.random(10000, 25000),
    },
    {
      name: 'gapRate.gapLoanTermInMonths',
      custom: () =>
        _.sample(
          [1, 12, 24, 36, 48, 60, 72, 84].filter(m => m >= lowerBandOfGap && m <= higherBandOfGap),
        ),
    },
    {
      name: 'gapRate.bhph',
      custom: () => _.sample(['Yes', 'No']),
    },
    {
      name: 'gapRate.kelleyBlueBook',
      custom: () => _.random(10000, 25000),
    },
  );

export const twpRateTestDataIfRequested = _.partial(testDataIfRequested, {
  name: 'twpRate.retailPriceOfTwp',
  custom: () => _.random(1000, 5000),
});

export const customerInformationRateTestDataIfRequested = _.partial(
  testDataIfRequested,
  {
    name: 'customer.firstName',
    custom: () => _.sample(FIRST_NAME_BANK),
  },
  {
    name: 'customer.lastName',
    custom: () => _.sample(LAST_NAME_BANK),
  },
  {
    name: 'customer.address.street',
    custom: () => _.sample(ADDRESS_BANK),
  },
  {
    name: 'customer.address.city',
    custom: () => _.sample(CITY_BANK),
  },
  {
    name: 'customer.address.state',
    custom: () => _.sample(STATES),
  },
  {
    name: 'customer.address.zipCode',
    custom: () => _.sample(ZIP_BANK),
  },
  {
    name: 'customer.phoneNumber',
    custom: () => _.random(1000000000, 9999999999),
  },
  {
    name: 'customer.email',
    custom: _.constant('george.jones@superiorautos.com'),
  },
  {
    name: 'customer.confirmEmail',
    custom: _.constant('george.jones@superiorautos.com'),
  },
);

export const lenderInformationRateTestDataIfRequested = _.partial(
  testDataIfRequested,
  {
    name: 'lender.companyName',
    custom: () => _.sample(BANK_NAME_BANK),
  },
  {
    name: 'lender.address.street',
    custom: () => _.sample(ADDRESS_BANK),
  },
  {
    name: 'lender.address.city',
    custom: () => _.sample(CITY_BANK),
  },
  {
    name: 'lender.address.state',
    custom: () => _.sample(STATES),
  },
  {
    name: 'lender.address.zipCode',
    custom: () => _.sample(ZIP_BANK),
  },
  {
    name: 'lender.phoneNumber',
    custom: () => _.random(1000000000, 9999999999),
  },
);

export const templateMetaDataIfRequested = _.partial(
  testDataIfRequested,
  {
    name: 'templateMetaData.templateType',
    custom: () => _.sample(contractTypesAll),
  },
  {
    name: 'templateMetaData.language',
    custom: () => _.sample(['english', 'spanish']),
  },
  {
    name: 'templateMetaData.state',
    custom: () => _.sample(STATES),
  },
  {
    name: 'templateMetaData.isGapPLus',
    custom: () => _.sample([true, false]),
  },
);
