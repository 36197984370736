import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Modal, Button, Row, Col } from 'react-bootstrap';

function NotificationModal({
  show,
  disabled,
  disabledConfirm,
  disabledCancel,
  title,
  message,
  children,
  onConfirm,
  onCancel,
  confirmText = 'Confirm',
  cancelText = 'Cancel',
  'data-test-name': dataTestName,
}) {
  const [settled, setSettled] = useState(true);

  const handleSettled = () => setSettled(true);
  const handleUnsettled = () => setSettled(false);

  return (
    <Modal
      show={show}
      onEnter={handleUnsettled}
      onEntered={handleSettled}
      onExit={handleUnsettled}
      onExited={handleSettled}
      backdropClassName={[
        `testName-${dataTestName}-backdrop`,
        `testName-${dataTestName}-status-${settled ? 'settled' : 'unsettled'}`,
        `testName-${dataTestName}-${show ? 'show' : 'hidden'}`,
      ].join(' ')}
    >
      <Modal.Header>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Row>
          <Col xs={12}>
            <p>{message}</p>
          </Col>
          {children && <Col xs={12}>{children}</Col>}
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Row>
          <Col xs={6}>
            <Button
              bsStyle="primary"
              onClick={onConfirm}
              block
              disabled={disabled || disabledConfirm}
              data-test-id={`${dataTestName}-confirm`}
            >
              {confirmText}
            </Button>
          </Col>
          <Col xs={6}>
            <Button
              bsStyle="default"
              onClick={onCancel}
              block
              disabled={disabled || disabledCancel}
              data-test-id={`${dataTestName}-cancel`}
            >
              {cancelText}
            </Button>
          </Col>
        </Row>
      </Modal.Footer>
    </Modal>
  );
}

NotificationModal.propTypes = {
  title: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  confirmText: PropTypes.string,
  cancelText: PropTypes.string,
  show: PropTypes.bool,
  disabled: PropTypes.bool,
  disabledConfirm: PropTypes.bool,
  disabledCancel: PropTypes.bool,
  children: PropTypes.any,
  'data-test-name': PropTypes.string,
};

NotificationModal.defaultProps = {
  confirmText: 'Confirm',
  cancelText: 'Cancel',
  show: false,
  disabled: false,
  disabledConfirm: false,
  disabledCancel: false,
  children: undefined,
  'data-test-name': undefined,
};

export default NotificationModal;
