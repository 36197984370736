import React from 'react';
import Helmet from 'react-helmet';

export default function Pay() {
  return (
    <div className="container">
      <Helmet title="Payment" />
      <iframe
        title="pay-prco"
        style={{
          width: '100%',
          height: '2000px',
        }}
        src={`//payprco.com`}
        width="300"
        height="2000"
        frameBorder="0"
        scrolling="yes"
      />
    </div>
  );
}
