import React from 'react';
import { Field, reduxForm, change, propTypes } from 'redux-form';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { connect } from 'react-redux';
import { compose, withState } from 'recompose';
import { Alert } from 'react-bootstrap';
import ValidationInput from '../../../components/common/ValidationInput';
import ValidationSelect from '../../../components/common/ValidationSelect';
import { statesForSelection, abbreviationFromState } from '../../../constants/general';
import { LENDER_INFORMATION_RATE_FORM } from '../../../redux/modules/form';
import { lenderInformationRateValidator } from '../../../components/validation/rateValidation';
import Buttons from '../../../components/wizard/Buttons';
import { lenderInformationRateTestDataIfRequested } from '../../../utils/testData';
import { QuoteTypePropType } from '../../../constants/newQuote';
import { receiveValuesFromQueryData } from '../../../utils/decorators';
import AvailableLenders from '../components/AvailableLenders';
import { isZipCode, integerPhoneNumber } from '../../../utils/validation';
import { getZipCodeAddress } from '../../../redux/modules/zipCode';
const STATES_VALUES = statesForSelection();

export function LenderInformationForm(props) {
  const handleLenderSelected = ({ name, address, phoneNumber } = {}) => {
    const { dispatch, onUpdateNonEditableLender } = props;
    dispatch(change(LENDER_INFORMATION_RATE_FORM, 'lender.companyName', name || ''));
    dispatch(
      change(LENDER_INFORMATION_RATE_FORM, 'lender.address.street', _.get(address, 'street', '')),
    );
    dispatch(
      change(LENDER_INFORMATION_RATE_FORM, 'lender.address.city', _.get(address, 'city', '')),
    );
    dispatch(
      change(
        LENDER_INFORMATION_RATE_FORM,
        'lender.address.state',
        abbreviationFromState(_.get(address, 'state', '')),
      ),
    );
    dispatch(
      change(LENDER_INFORMATION_RATE_FORM, 'lender.address.zipCode', _.get(address, 'zipCode', '')),
    );
    dispatch(change(LENDER_INFORMATION_RATE_FORM, 'lender.phoneNumber', phoneNumber || ''));
    onUpdateNonEditableLender(!!name);
  };
  const handleZipCodeChange = async e => {
    const { dispatch } = props;
    if (isZipCode(e.target.value) === undefined) {
      try {
        const responseData = await getZipCodeAddress(e.target.value);

        if (responseData.data.length) {
          dispatch(
            change(
              LENDER_INFORMATION_RATE_FORM,
              'lender.address.city',
              _.get(responseData.data[0], 'city', ''),
            ),
          );
          dispatch(
            change(
              LENDER_INFORMATION_RATE_FORM,
              'lender.address.state',
              _.get(responseData.data[0], 'state', ''),
            ),
          );
        }
      } catch (error) {
        return error;
      }
    }
  };

  const handleLenderEdit = () => {
    const { onUpdateNonEditableLender } = props;
    onUpdateNonEditableLender(false);
  };

  const { nonEditableLender, handleSubmit, loading, hasInvalidPagesBefore } = props;

  if (hasInvalidPagesBefore)
    return (
      <Alert className="alert-success">
        You need to fix problems on previous pages to issue the contract
      </Alert>
    );

  return (
    <form
      className="form-horizontal"
      onSubmit={handleSubmit}
      noValidate={true}
      data-test-id="LenderInformationForm"
    >
      <AvailableLenders onLenderSelected={handleLenderSelected} onLenderEdit={handleLenderEdit} />

      <Field
        name="lender.companyName"
        type="text"
        component={ValidationInput}
        label="Finance Company Name"
        disabled={nonEditableLender}
        placeholder="eg. First National Bank"
      />
      <Field
        name="lender.address.zipCode"
        type="text"
        component={ValidationInput}
        label="Zip Code"
        placeholder="eg. 11101"
        onChange={handleZipCodeChange}
        disabled={nonEditableLender}
        validate={[isZipCode]}
      />
      <Field
        name="lender.address.street"
        type="text"
        component={ValidationInput}
        label="Address"
        placeholder="eg. 919 Main St."
        disabled={nonEditableLender}
      />
      <Field
        name="lender.address.city"
        type="text"
        component={ValidationInput}
        label="City"
        placeholder="eg. Lafayette"
        disabled={nonEditableLender}
      />
      <Field
        name="lender.address.state"
        type="text"
        component={ValidationSelect}
        label="State"
        options={STATES_VALUES}
        disabled={nonEditableLender}
      />
      <Field
        name="lender.phoneNumber"
        type="text"
        component={ValidationInput}
        label="Finance Company Phone Number"
        placeholder="eg. 8882627890"
        disabled={nonEditableLender}
        validate={[integerPhoneNumber]}
      />
      <Buttons
        {..._.omit(props, 'onSubmit')}
        overrides={{
          submitStyle: 'primary',
          submitText: 'Issue Contract',
        }}
        disabled={loading}
        data-test-name="LenderInformationForm-Buttons"
      />
    </form>
  );
}

LenderInformationForm.propTypes = {
  ...propTypes,
  loading: PropTypes.bool.isRequired,
  quoteType: QuoteTypePropType.isRequired,
  nonEditableLender: PropTypes.bool.isRequired,
  onUpdateNonEditableLender: PropTypes.func.isRequired,
  hasInvalidPagesBefore: PropTypes.bool.isRequired,
};

export const mapFromConnect = ({ newQuote, page }) => ({
  loading: page.loading,
  quoteType: _.get(newQuote, 'form.quoteType'),
  initialValues: lenderInformationRateTestDataIfRequested(),
});

export default compose(
  connect(mapFromConnect),
  receiveValuesFromQueryData({
    financierName: 'lender.companyName',
    financierAddress: 'lender.address.street',
    financierCity: 'lender.address.city',
    financierState: 'lender.address.state',
    financierZip: 'lender.address.zipCode',
  }),
  reduxForm({
    form: LENDER_INFORMATION_RATE_FORM,
    validate: lenderInformationRateValidator,
  }),
  withState('nonEditableLender', 'onUpdateNonEditableLender', false),
)(LenderInformationForm);
