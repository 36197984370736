import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';
import Alert from 'react-bootstrap/lib/Alert';

function allNotificationsAndErrors(state, namespace) {
  return _.chain(state.notifs[namespace])
    .concat(
      _.reduce(
        state,
        (acc, subState, key) => {
          if (key !== 'notifs') {
            if (_.get(subState, 'error')) {
              acc.push({
                message: subState.error,
                kind: 'danger',
              });
            }
            if (_.get(subState, 'message')) {
              acc.push({
                message: subState.message,
                kind: 'info',
              });
            }
          }
          return acc;
        },
        [],
      ),
    )
    .compact()
    .value();
}

export function Notifs(props) {
  const { notifs, className } = props;
  const notification = _.first(notifs);
  if (!_.isEmpty(notification)) {
    return (
      <div className={`notif-container ${className}`}>
        <Alert bsStyle={notification.kind}>{notification.message}</Alert>
      </div>
    );
  } else {
    return null;
  }
}

Notifs.propTypes = {
  notifs: PropTypes.array.isRequired,
  className: PropTypes.string.isRequired,
};

export default connect((state, props) => ({
  notifs: allNotificationsAndErrors(state, props.namespace),
}))(Notifs);
