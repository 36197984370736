import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { connect } from 'react-redux';
import { Field, reduxForm, propTypes, formValueSelector, change } from 'redux-form';
import { compose, withProps } from 'recompose';
import { get, omit, pick } from 'lodash';
import { required, money, notBeforeDays, notInFuture, isValidDate } from '../../utils/validation';
import { VEHICLE_BASIC_INFORMATION_FORM } from '../../redux/modules/form';
import {
  vehicleBasicInformationValidation,
  minFinancedAmountLessCostOfGap,
  maxLoanAmount,
} from '../../components/validation/newQuoteValidation';
import Buttons from '../../components/wizard/Buttons';
import ValidationSelect from '../../components/common/ValidationSelect';
import ValidationInput from '../../components/common/ValidationInput';
import { vscGapQuoteTestDataIfRequested } from '../../utils/testData';
import { VEHICLE_CONDITION_TYPES, QuoteTypePropType } from '../../constants/newQuote';
import ValidationDatePicker from '../../components/common/ValidationDatePicker';
import { quoteTypeIncludesGAP } from '../../constants/quoteType';
import { getVerificationOverride } from '../../redux/modules/verificationOverride';

export const isLoanAmountRequired = ({ quoteType }) => quoteTypeIncludesGAP(quoteType);

export const isVehicleConditionRequired = ({ quoteType, dealerState }) =>
  quoteTypeIncludesGAP(quoteType) && dealerState === 'IN';

export const isMsrpRequired = ({ quoteType, dealerState, vehicleCondition }) =>
  quoteTypeIncludesGAP(quoteType) &&
  dealerState === 'IN' &&
  vehicleCondition === VEHICLE_CONDITION_TYPES.NEW;

export const isNadaAverageRetailValueRequired = ({ quoteType, dealerState, vehicleCondition }) =>
  quoteTypeIncludesGAP(quoteType) &&
  dealerState === 'IN' &&
  vehicleCondition === VEHICLE_CONDITION_TYPES.USED;

export const isFinancedAmountLessCostOfGapRequired = ({ quoteType, dealerState }) =>
  quoteTypeIncludesGAP(quoteType) && dealerState === 'IN';

export const isReliableCreditAssociatedRequired = ({ quoteType, dealerState }) =>
  quoteTypeIncludesGAP(quoteType) && dealerState === 'AZ';

const vehicleBasicInformationFormValueSelector = formValueSelector(VEHICLE_BASIC_INFORMATION_FORM);

const VEHICLE_CONDITION_OPTIONS = [
  {
    label: 'New',
    value: VEHICLE_CONDITION_TYPES.NEW,
  },
  {
    label: 'Used',
    value: VEHICLE_CONDITION_TYPES.USED,
  },
];

const RELIABLE_CREDIT_ASSOCIATION_OPTIONS = [
  {
    label: 'Reliable Credit Association',
    value: 'AZRCA',
  },
  {
    label: 'Other',
    value: 'OTHER',
  },
];

export function VehicleBasicInformationForm(props) {
  const [loanAmountCleared, setLoanAmountCleared] = useState(false);
  const [loanAmountOverride, setLoanAmountOverride] = useState(false);
  const { quoteType } = props;

  useEffect(() => {
    props.getVerificationOverride();
  }, []);

  useEffect(() => {
    const { dispatch } = props;
    if (!quoteTypeIncludesGAP(quoteType) && !loanAmountCleared) {
      console.warn('This is a deprecated workflow, we should consider another route for ');
      dispatch(change(VEHICLE_BASIC_INFORMATION_FORM, 'loanAmount', 0));
      // @TODO - We need to replace in the long term. This will not work well.
      setLoanAmountCleared(true);
    }
  }, [loanAmountCleared, quoteType]);

  const handleVinChange = e => {
    const { verificationOverride } = props;
    if (get(verificationOverride, 'length')) {
      const overrides = verificationOverride.filter(({ vin }) => e.target.value); // eslint-disable-line no-unused-vars
      setLoanAmountOverride(overrides.length > 0);
    }
  };

  const { handleSubmit } = props;

  return (
    <form
      className="form-horizontal"
      onSubmit={handleSubmit}
      noValidate={true}
      data-test-id="VehicleBasicInformationForm"
    >
      <Field
        name="vin"
        type="text"
        component={ValidationInput}
        label="VIN"
        placeholder="Full VIN - eg. 1GNSKJKC6GR123038"
        data-test-id="VehicleBasicInformationForm-vin"
        onChange={handleVinChange}
      />
      <Field
        name="vehicleMileage"
        type="number"
        component={ValidationInput}
        label="Current Mileage"
        placeholder="eg. 4681"
        data-test-id="VehicleBasicInformationForm-vehicleMileage"
      />
      {isVehicleConditionRequired(props) && (
        <Field
          name="vehicleCondition"
          label="Vehicle Condition"
          component={ValidationSelect}
          options={VEHICLE_CONDITION_OPTIONS}
          data-test-id="VehicleBasicInformationForm-vehicleCondition"
        />
      )}
      {isMsrpRequired(props) && (
        <Field
          name="msrp"
          type="number"
          component={ValidationInput}
          label="MSRP"
          placeholder="eg. 22000"
          validate={[required, money]}
          data-test-id="VehicleBasicInformationForm-msrp"
        />
      )}
      {isNadaAverageRetailValueRequired(props) && (
        <Field
          name="nadaAverageRetailValue"
          type="number"
          component={ValidationInput}
          label="NADA Average Retail Value"
          placeholder="eg. 16500"
          validate={[required, money]}
          data-test-id="VehicleBasicInformationForm-nadaAverageRetailValue"
        />
      )}
      {isFinancedAmountLessCostOfGapRequired(props) && (
        <Field
          name="financedAmountLessCostOfGap"
          type="number"
          component={ValidationInput}
          label="Financed Amount less cost of GAP, credit insurance, and vehicle service contract"
          placeholder="eg. 7600"
          validate={[required, money, minFinancedAmountLessCostOfGap(props)]}
          data-test-id="VehicleBasicInformationForm-financedAmountLessCostOfGap"
        />
      )}
      <Field
        name="vehiclePurchasePrice"
        type="number"
        component={ValidationInput}
        label="Vehicle Price"
        placeholder="eg. 20000"
        data-test-id="VehicleBasicInformationForm-vehiclePurchasePrice"
      />
      <Field
        name="vehicleSaleDate"
        component={ValidationDatePicker}
        label="Vehicle Sale Date"
        validate={[required, isValidDate('MM/DD/YYYY'), notBeforeDays(20), notInFuture]}
        placeholder={`eg. ${moment().format('MM/DD/YYYY')}`}
        data-test-id="VehicleBasicInformationForm-vehicleSaleDate"
      />
      {isLoanAmountRequired(props) && (
        <Field
          name="loanAmount"
          type="number"
          component={ValidationInput}
          label="Loan Amount"
          placeholder="eg. 4681"
          step="0.01"
          validate={
            loanAmountOverride ? [required, money] : [required, money, maxLoanAmount(props)]
          }
          data-test-id="VehicleBasicInformationForm-loanAmount"
        />
      )}
      {isReliableCreditAssociatedRequired(props) && (
        <Field
          name="reliableCreditAssociation"
          label="Is the lender Reliable Credit Association or not?"
          placeholder="Choose one"
          component={ValidationSelect}
          validate={[required]}
          options={RELIABLE_CREDIT_ASSOCIATION_OPTIONS}
          data-test-id="VehicleBasicInformationForm-reliableCreditAssociation"
        />
      )}
      <Buttons {...omit(props, 'onSubmit')} data-test-name="VehicleBasicInformationForm-Buttons" />
    </form>
  );
}

VehicleBasicInformationForm.propTypes = {
  ...propTypes,
  quoteType: QuoteTypePropType.isRequired,
  dealerState: PropTypes.string.isRequired,
};

export const populateInitialValues = ({ queryData }) => ({
  initialValues: {
    ...vscGapQuoteTestDataIfRequested(),
    ...pick(queryData, [
      'vin',
      'vehicleMileage',
      'vehicleCondition',
      'msrp',
      'nadaAverageRetailValue',
      'financedAmountLessCostOfGap',
      'vehiclePurchasePrice',
      'vehicleSaleDate',
      'loanAmount',
      'reliableCreditAssociation',
    ]),
  },
});

export const mapFromConnect = ({ newQuote, dealer, verificationOverride, ...rest }) => ({
  contractYear: get(dealer, 'details.rating.contractYear'),
  dealerState: get(dealer, 'details.address.state'),
  quoteType: get(newQuote, 'form.quoteType'),
  vehicleCondition: vehicleBasicInformationFormValueSelector(rest, 'vehicleCondition'),
  msrp: vehicleBasicInformationFormValueSelector(rest, 'msrp'),
  nadaAverageRetailValue: vehicleBasicInformationFormValueSelector(rest, 'nadaAverageRetailValue'),
  verificationOverride: verificationOverride.data,
});

export default compose(
  connect(mapFromConnect, { getVerificationOverride }),
  withProps(populateInitialValues),
  reduxForm({
    form: VEHICLE_BASIC_INFORMATION_FORM,
    validate: vehicleBasicInformationValidation,
  }),
)(VehicleBasicInformationForm);
