import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Helmet from 'react-helmet';
import { connect } from 'react-redux';
import { Grid, Row, Col, Table, Button } from 'react-bootstrap';
import { compose, withState } from 'recompose';
import { getMessaging } from '../redux/modules/messaging';
import UpdateMessagingModal from './messaging-admin/UpdateMessagingModal';

export function MessagingAdmin(props) {
  useEffect(() => {
    const { getMessaging } = props;
    getMessaging();
  }, []);
  const { selectedMessaging, setSelectedMessaging, messaging } = props;

  return (
    <div>
      <Helmet title="Messaging Administration" />

      <Grid>
        <Row>
          <Col xs={12}>
            <Table responsive hover>
              <tr>
                <th>Name</th>
                <th>&nbsp;</th>
              </tr>
              <tbody>
                {_.map(messaging, m => (
                  <tr>
                    <td>{m.name}</td>
                    <td>
                      <Button bsStyle="primary" onClick={() => setSelectedMessaging(m)}>
                        Edit
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Col>
        </Row>
      </Grid>
      {selectedMessaging && (
        <UpdateMessagingModal
          messaging={selectedMessaging}
          onComplete={() => setSelectedMessaging(null)}
        />
      )}
    </div>
  );
}

MessagingAdmin.propTypes = {
  messaging: PropTypes.array,
  selectedMessaging: PropTypes.object,
  setSelectedMessaging: PropTypes.func.isRequired,
};

MessagingAdmin.defaultProps = {
  messaging: [],
  selectedMessaging: null,
};

export default compose(
  withState('selectedMessaging', 'setSelectedMessaging'),
  connect(
    ({ messaging }) => ({
      messaging: messaging.data,
    }),
    {
      getMessaging,
    },
  ),
)(MessagingAdmin);
