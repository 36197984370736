import React from 'react';
import { Button } from 'react-bootstrap';

export default function Submit({ loadingText = 'Submitting...', children, ...props }) {
  const { isValid, isSubmitting } = props;
  return (
    <Button
      className="btn btn-primary"
      type="submit"
      disabled={!isValid || isSubmitting}
      {...props}
    >
      {isSubmitting && <span>Need loading spinner here.</span>}
      <span>{isSubmitting ? loadingText : children}</span>
    </Button>
  );
}
