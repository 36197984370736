import React, { useEffect, useMemo, useState } from 'react';
import { map, pickBy, chain, omit } from 'lodash';
import { Button, Checkbox, Glyphicon, Label } from 'react-bootstrap';
import {
  CONTRACT_TYPE_GAP,
  CONTRACT_TYPE_VSC,
  CONTRACT_TYPE_TWP,
} from '../../../constants/contractType';

const calculateContractQuotePrice = (dealerSettings, price, type) => {
  if (dealerSettings.dollarMarkupVSC && type === CONTRACT_TYPE_VSC) {
    return +price + +dealerSettings.dollarMarkupVSC;
  } else if (dealerSettings.percentageMarkupVSC && type === 'VSC') {
    return +price + +price * (+dealerSettings.percentageMarkupVSC / 100);
  }
  if (dealerSettings.dollarMarkupTWP && type === 'TWP') {
    return +price + +dealerSettings.dollarMarkupTWP;
  } else if (dealerSettings.percentageMarkupTWP && type === CONTRACT_TYPE_TWP) {
    return +price + +price * (+dealerSettings.percentageMarkupTWP / 100);
  }
  if (dealerSettings.dollarMarkupGAP && type === 'GAP') {
    return +price + +dealerSettings.dollarMarkupGAP;
  } else if (dealerSettings.percentageMarkupGAP && type === CONTRACT_TYPE_GAP) {
    return +price + +price * (+dealerSettings.percentageMarkupGAP / 100);
  }
  return +price;
};

const coverageButtonStyle = {
  whiteSpace: 'normal',
  textAlign: 'left',
};

const CheckMark = ({ checked }) => (
  <div
    style={{
      display: 'inline-block',
      width: 16,
      minWidth: 16,
      height: 16,
      borderRadius: 8,
      borderWidth: 1,
      borderStyle: 'solid',
      borderColor: '#1F232F',
      backgroundColor: checked ? '#5bc0de' : 'white',
      marginRight: '.3em',
    }}
  />
);

const Price = ({ children }) => (
  <Label style={{ marginLeft: 8 }} bsStyle="primary">
    ${children.toFixed(2)}
  </Label>
);

const CoverageOptions = ({
  coverage: { quoteId, coverages: otherCoverages },
  contractType,
  extraCoverages,
  changeExtraCoverages,
}) => (
  <div className="CoverageOptions">
    {map(otherCoverages, ({ label, issueKey, selectable, ...cov }) => {
      const disabled = !selectable;

      // TODO `checked` prop initialization doesn't work (https://app.clickup.com/t/nz7p40)
      const props = pickBy({ checked: disabled });
      return (
        <Checkbox
          key={issueKey}
          disabled={disabled}
          checked={extraCoverages?.[issueKey]}
          onClick={
            selectable
              ? () =>
                  changeExtraCoverages?.(
                    extraCoverages?.[issueKey]
                      ? omit(extraCoverages, issueKey)
                      : {
                          ...extraCoverages,
                          [issueKey]: { label, issueKey, ...cov },
                        },
                  )
              : undefined
          }
          {...props}
          data-test-id={`Coverages-Item-${contractType}-${quoteId}-options-${issueKey}`}
        >
          {label}
        </Checkbox>
      );
    })}
  </div>
);

const CoverageBrief = ({
  dealerSettings,
  contractType,
  coverage: { contractQuotePrice, termMonthsQuoted, termMilesQuoted },
}) => (
  <div style={{ display: 'flex', alignItems: 'baseline' }}>
    <div style={{ whiteSpace: 'nowrap', flex: 1 }}>
      {chain([
        contractType === CONTRACT_TYPE_GAP && 'Up to',
        `${termMonthsQuoted} Months`,
        contractType === CONTRACT_TYPE_VSC && `/ ${termMilesQuoted} Miles`,
      ])
        .filter()
        .join(' ')
        .value()}
    </div>

    <Price>{calculateContractQuotePrice(dealerSettings, contractQuotePrice, contractType)}</Price>
  </div>
);

const QuotesTypePlans = props => {
  const {
    dealerSettings,
    contractType,
    plans,
    selectedPlanIndex,
    selectedCoverageId,
    onOpen,
    extraCoverages,
    changeExtraCoverages,
  } = props;

  const selectedCoverage =
    typeof selectedPlanIndex === 'number' &&
    !!selectedCoverageId &&
    plans[selectedPlanIndex].data.find(({ quoteId }) => selectedCoverageId === quoteId);

  return plans.map((plan, index) => (
    <div
      tabIndex={0}
      role="button"
      onClick={event => {
        if (event.target?.closest('.CoverageOptions')) return undefined;
        onOpen(index);
      }}
      className="btn btn-default"
      style={coverageButtonStyle}
    >
      <div
        style={{ display: 'flex', alignItems: 'center' }}
        data-test-type="Coverages-Plan-selectHandle"
      >
        <CheckMark checked={selectedPlanIndex === index} />
        {plan.title}
      </div>
      {selectedPlanIndex === index && selectedCoverage && (
        <div style={{ paddingTop: 12 }}>
          <CoverageBrief
            dealerSettings={dealerSettings}
            contractType={contractType}
            coverage={selectedCoverage}
          />
          <hr />
          <CoverageOptions
            contractType={contractType}
            coverage={selectedCoverage}
            extraCoverages={extraCoverages}
            changeExtraCoverages={changeExtraCoverages}
          />
        </div>
      )}
    </div>
  ));
};

const redMark = <span style={{ color: 'darkred' }}>*</span>;

const headers = {
  [CONTRACT_TYPE_GAP]: (
    <div>
      <h5>
        GAP <nobr>Protection{redMark}</nobr>
        <div style={{ color: 'darkgrey', marginTop: 6 }}>
          Price is&nbsp;valid for&nbsp;any term in the selected&nbsp;range
        </div>
      </h5>
    </div>
  ),
  [CONTRACT_TYPE_VSC]: (
    <div>
      <h5>
        Vehicle Service <nobr>Contract{redMark}</nobr>
      </h5>
    </div>
  ),
  [CONTRACT_TYPE_TWP]: (
    <div>
      <h5>
        Tire &amp; Wheel <nobr>Coverage{redMark}</nobr>
      </h5>
    </div>
  ),
};

export const QuotesTypeGroup = props => {
  const {
    dealerSettings,
    contractType,
    plans,
    selectedCoverageId,
    onCoverageClick,
    extraCoverages,
    changeExtraCoverages,
  } = props;

  const [openPlanIndex, setOpenPlanIndex] = useState();

  useEffect(() => {
    if (plans.length === 1) setOpenPlanIndex(0);
  }, [plans]);

  const selectedPlanIndex = useMemo(
    () =>
      selectedCoverageId
        ? plans.findIndex(plan => plan.data.some(({ quoteId }) => quoteId === selectedCoverageId))
        : undefined,
    [plans, selectedCoverageId],
  );

  const openPlan = typeof openPlanIndex === 'number' ? plans[openPlanIndex] : null;

  let title;
  let options;

  if (openPlan) {
    title = plans.length > 1 && (
      <div className="h4" style={{ display: 'flex', alignItems: 'center' }}>
        <span style={{ flex: 1 }}>{openPlan.title}</span>
        &nbsp;
        <Button
          onClick={plans.length > 1 ? () => setOpenPlanIndex(null) : undefined}
          bsStyle="default"
          bsSize="small"
        >
          <Glyphicon glyph="triangle-bottom" />
        </Button>
      </div>
    );
    options = openPlan.data.map(coverage => (
      <div
        tabIndex={0}
        role="button"
        onClick={() => {
          onCoverageClick(coverage.quoteId);
          if (plans.length > 1) setOpenPlanIndex(null);
        }}
        className="btn btn-default"
        style={coverageButtonStyle}
        data-test-type="Coverages-Item"
        data-test-id={`Coverages-Item-${contractType}-${coverage.quoteId}`}
      >
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <CheckMark checked={selectedCoverageId === coverage.quoteId} />
          <div style={{ flex: 1 }}>
            <CoverageBrief
              dealerSettings={dealerSettings}
              contractType={contractType}
              coverage={coverage}
            />
          </div>
        </div>
      </div>
    ));
  } else {
    title = null;
    options = (
      <QuotesTypePlans
        dealerSettings={dealerSettings}
        contractType={contractType}
        plans={plans}
        selectedPlanIndex={selectedPlanIndex}
        selectedCoverageId={selectedCoverageId}
        onOpen={setOpenPlanIndex}
        extraCoverages={extraCoverages}
        changeExtraCoverages={changeExtraCoverages}
      />
    );
  }

  return (
    <div>
      {headers[contractType]}
      {title}
      <div
        style={{
          display: 'flex',
          gap: 16,
          flexFlow: 'column',
          alignItems: 'stretch',
        }}
      >
        {options}
      </div>
    </div>
  );
};
