import { useEffect } from 'react';

export function UserSnap() {
  useEffect(() => {
    const s = document.createElement('script');
    s.type = 'text/javascript';
    s.async = true;
    s.src = '//api.usersnap.com/load/86666b57-bcef-42e1-b869-ba260a5f5eba.js';
    const x = document.getElementsByTagName('script')[0];
    x.parentNode.insertBefore(s, x);
  }, []);
  return null;
}

export default UserSnap;
