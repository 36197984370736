import React, { Fragment } from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import PropTypes from 'prop-types';
import _ from 'lodash';

const Buttons = ({
  goBack,
  hideBack,
  onSubmit,
  error,
  invalid,
  overrides,
  disabled,
  'data-test-name': dataTestName,
}) => (
  <Row>
    {error && (
      <p className="text-danger">
        <strong>{error}</strong>
      </p>
    )}
    {!hideBack && (
      <Col xs={4}>
        <Button block type="button" bsStyle="default" onClick={goBack} disabled={disabled}>
          Back
        </Button>
      </Col>
    )}
    <Col xs={hideBack ? 12 : 8}>
      <Button
        block
        type="submit"
        bsStyle={_.get(overrides, 'submitStyle', 'primary')}
        disabled={disabled || invalid}
        onClick={onSubmit}
        data-test-id={dataTestName ? `${dataTestName}-submit` : undefined}
      >
        {_.get(overrides, 'submitText', <Fragment>Next</Fragment>)}
      </Button>
    </Col>
  </Row>
);

Buttons.propTypes = {
  goBack: PropTypes.func.isRequired,
  hideBack: PropTypes.bool,
  overrides: PropTypes.object,
  onSubmit: PropTypes.func,
  disabled: PropTypes.bool,
};

Buttons.defaultProps = {
  hideBack: false,
  onSubmit: _.noop,
  overrides: {},
  disabled: false,
};

export default Buttons;
