import React, { useContext, useEffect, useMemo } from 'react';
import Helmet from 'react-helmet';
import _ from 'lodash';
import { compose } from 'redux';
import { Field, reduxForm } from 'redux-form';
import { Button, Grid, Row, Col, Modal } from 'react-bootstrap';
import { connect } from 'react-redux';
import ValidationInput from '../components/common/ValidationInput';
import { PROFILE_FORM, PROFILE_CHANGE_PASSWORD_FORM } from '../redux/modules/form';
import { updateAuthUser, changePassword } from '../redux/modules/auth';
import { minLength, exactLength, required, match } from '../utils/validation';
import { AuthContext } from '../components/Auth';

const ProfileFormComponent = props => {
  const { handleSubmit, dirty, user, touch } = props;

  const dealerId = _.get(user, 'dealerRel.dealerId');

  useEffect(() => {
    touch('dealerRel.dealerId');
  }, [touch]);

  return (
    <form className="form-horizontal" onSubmit={handleSubmit}>
      <Field name="email" type="text" component={ValidationInput} label="Email" disabled />
      <Field
        name="dealerRel.dealerId"
        type="text"
        component={ValidationInput}
        label="Dealer ID"
        validate={[required, exactLength(4)]}
        disabled={!!dealerId}
      />
      {dirty && (
        <Button bsStyle="primary" type="submit">
          Update
        </Button>
      )}
    </form>
  );
};

const ProfileForm = compose(
  connect(({ auth: { user } }) => ({
    user,
    initialValues: user,
  })),
  reduxForm({
    form: PROFILE_FORM,
    enableReinitialize: true,
    onSubmit: async (values, dispatch) => {
      await updateAuthUser(values)(dispatch);
      window.location.reload();
    },
  }),
)(ProfileFormComponent);

const ChangePasswordFormComponent = props => {
  const { handleSubmit, dirty } = props;

  return (
    <form className="form-horizontal" onSubmit={handleSubmit}>
      <Field
        name="password"
        type="password"
        component={ValidationInput}
        placeholder="New password here"
        label="Password"
        validate={[required, minLength(8)]}
      />
      <Field
        name="passwordConfirmation"
        type="password"
        component={ValidationInput}
        placeholder="New password here"
        label="Confirm Password"
        validate={[required, match('password')]}
      />
      {dirty && (
        <Button bsStyle="primary" type="submit">
          Change password
        </Button>
      )}
    </form>
  );
};

const ChangePasswordForm = reduxForm({
  form: PROFILE_CHANGE_PASSWORD_FORM,
  onSubmit: async (values, dispatch, { reset }) => {
    await changePassword(values)(dispatch);
    reset();
  },
})(ChangePasswordFormComponent);

export const ProfilePage = () => (
  <Grid>
    <Helmet title="My Profile" />
    <Row>
      <Col xs={12}>
        <ProfileForm />
      </Col>
    </Row>
    <Row style={{ marginTop: '1em' }}>
      <Col xs={12}>
        <ChangePasswordForm />
      </Col>
    </Row>
  </Grid>
);

const getMissedInfo = user => {
  const missedInfo = [];
  const dealerId = _.get(user, 'dealerRel.dealerId');
  if (!dealerId) missedInfo.push('dealerRel.dealerId');

  return missedInfo;
};

export const ProfileCompletePopupComponent = props => {
  const { logout } = useContext(AuthContext);
  const { user } = props;

  const missedInfo = useMemo(() => (user ? getMissedInfo(user) : undefined), [user]);

  if (!missedInfo || missedInfo.length === 0) return null;

  return (
    <Modal show={true}>
      <Modal.Header>
        <Modal.Title style={{ display: 'flex', alignItems: 'center' }}>
          <div style={{ flex: 1 }}>Profile</div>
          <Button bsStyle="link" onClick={logout}>
            Logout
          </Button>
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <h2>Complete your profile</h2>
        <p>
          We just need you to specify required information in your profile, and you're ready to go.
        </p>
        <ProfileForm />
      </Modal.Body>
    </Modal>
  );
};

export const ProfileCompletePopup = connect(({ auth: { user } }) => ({ user }))(
  ProfileCompletePopupComponent,
);
