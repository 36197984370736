import React, { useCallback, useEffect, useState } from 'react';
import Helmet from 'react-helmet';
import { Grid, Row, Col, Button, Alert } from 'react-bootstrap';
import { loadingCycle } from '../redux/modules/page';
import api from '../utils/service';
import NotificationModal from '../components/common/NotificationModal';

const clearCache = () => loadingCycle(api.delete('/cache'));

export const CacheAdmin = () => {
  const [action, setAction] = useState();

  const handleClearAction = useCallback(() => setAction(undefined), []);

  const modalAction = action === 'clear cache' && <ClearCacheDialog onClose={handleClearAction} />;

  const handleClearCacheClick = useCallback(() => setAction('clear cache'), []);

  return (
    <div>
      <Helmet title="Cache Administration" />
      <Grid fluid>
        <Row>
          <Col xs={10} xsOffset={1} style={{ textAlign: 'center' }}>
            <Button onClick={handleClearCacheClick} bsSize="large">
              Clear Dealer Information Cache
            </Button>
          </Col>
        </Row>
      </Grid>
      {modalAction}
    </div>
  );
};

const ClearCacheDialog = ({ onClose }) => {
  const [inProgress, setInProgress] = useState();
  const [result, setResult] = useState();

  // Auto close on success
  useEffect(() => {
    if (inProgress || !result || result.error) return undefined;
    const h = setTimeout(() => onClose(), 1000);
    return () => clearTimeout(h);
  }, [result, onClose, inProgress]);

  const handleClearCacheClick = useCallback(async () => {
    setInProgress(true);
    setResult(undefined);

    try {
      await clearCache();
      setResult({});
    } catch (error) {
      setResult({ error });
    }

    setInProgress(false);
  }, [clearCache]);

  const errorDisplay = result && result.error && (
    <Alert className="alert-danger">{result.error.message}</Alert>
  );

  const successDisplay = result && !result.error && (
    <Alert className="alert-success">Cache clean</Alert>
  );

  let message = 'Would you like to clear the dealer information cache for all dealers?';
  if (inProgress) message = 'Clearing cache...';
  if (result && !result.error) message = 'Success';

  return (
    <NotificationModal
      show={true}
      title="Clear Cache"
      message={message}
      disabled={inProgress}
      onConfirm={handleClearCacheClick}
      confirmText="Yes"
      onCancel={onClose}
    >
      {errorDisplay}
      {successDisplay}
    </NotificationModal>
  );
};
