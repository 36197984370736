import React from 'react';
import { connect } from 'react-redux';
import { Alert, Button, Grid, Row, Modal, ProgressBar } from 'react-bootstrap';
import FieldTypeSelect from './components/FieldTypeSelect';
import FieldTable from '../shared/FieldTable';
import {
  toggleEditFieldsModal,
  fetchFields,
  toggleFieldRequired,
  saveModifiedFields,
  latestFields,
} from '../../../../redux/modules/eSignAdmin';

const mapStateToProps = state => ({
  showEditFieldsModal: state.eSignAdmin.showEditFieldsModal,
  fieldsLoading: state.eSignAdmin.fieldsLoading,
  fieldsError: state.eSignAdmin.fieldsError,
  fields: latestFields(state),
  modifiedFields: state.eSignAdmin.modifiedFields,
});

const EditFieldsModal = ({
  showEditFieldsModal,
  toggleEditFieldsModal,
  fetchFields,
  fieldsLoading,
  fieldsError,
  fields,
  modifiedFields,
  toggleFieldRequired,
  saveModifiedFields,
}) => (
  <Modal bsSize="large" show={showEditFieldsModal} onHide={() => toggleEditFieldsModal(false)}>
    <Modal.Header closeButton>
      <Modal.Title>Edit Template Fields</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <Grid fluid>
        <Row style={{ marginBottom: '20px' }}>
          <FieldTypeSelect handleType={fetchFields} />
        </Row>
        <Row>{fieldsError && <Alert className="alert-danger">{fieldsError}</Alert>}</Row>
        <Row>{fieldsLoading && <ProgressBar striped active now={100} />}</Row>
        {fields.length > 0 && (
          <Row className="container-fluid" style={{ overflowY: 'auto', height: '45vmin' }}>
            <FieldTable fields={fields} toggleRequired={toggleFieldRequired} />
          </Row>
        )}
      </Grid>
    </Modal.Body>
    <Modal.Footer style={{ textAlign: 'left' }}>
      <Button onClick={() => toggleEditFieldsModal(false)}>Close</Button>
      <Button
        bsStyle="primary"
        disabled={modifiedFields.length === 0}
        onClick={saveModifiedFields}
        style={{ float: 'right' }}
      >
        Save
      </Button>
    </Modal.Footer>
  </Modal>
);

export default connect(mapStateToProps, {
  toggleEditFieldsModal,
  fetchFields,
  toggleFieldRequired,
  saveModifiedFields,
})(EditFieldsModal);
