import React from 'react';
import { map, startCase, pick, zipObject, times, merge } from 'lodash';
import { Table, Button } from 'react-bootstrap';

export default ({ headers = [], data = [], onClick, onDelete, setDefaults = true, ...props }) => {
  const getRowInfo = row => {
    if (setDefaults) {
      const defaults = zipObject(
        headers,
        times(headers.length, () => null),
      );
      return pick(merge(defaults, row), headers);
    }
    return pick(row, headers);
  };
  return (
    <Table responsive {...props}>
      <thead>
        <tr>
          {headers.map(f => (
            <td key={f}>{startCase(f)}</td>
          ))}
          {onDelete && <td key="actions">Actions</td>}
        </tr>
      </thead>
      <tbody>
        {map(data, (row, idx) => (
          <tr key={idx} onClick={() => onClick && onClick(row)}>
            {map(getRowInfo(row), (v, i) => (
              <td key={v || i}>{typeof v === 'boolean' ? (v ? 'REQUIRED' : 'NOT-REQUIRED') : v}</td>
            ))}
            {onDelete && (
              <td>
                <Button bsStyle="danger" onClick={() => onDelete(row)}>
                  Delete
                </Button>
              </td>
            )}
          </tr>
        ))}
      </tbody>
    </Table>
  );
};
