import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose, withState, withProps } from 'recompose';
import _ from 'lodash';
import { Grid, Button, Row, Col, Alert } from 'react-bootstrap';
import {
  selectedGapQuote,
  selectedTwpQuote,
  selectedVscQuote,
} from '../../../redux/modules/newQuote';
import { createSavedQuote } from '../../../redux/modules/savedQuote';
import ESignModal from '../ESignModal';
import PlanTable from './PlanTable';
import NotificationModal from '../../../components/common/NotificationModal';
import {
  CONTRACT_TYPE_GAP,
  CONTRACT_TYPE_TWP,
  CONTRACT_TYPE_VSC,
} from '../../../constants/contractType';

const FOOTER_BUTTON_STYLE = { marginRight: '5px' };

export function FinalQuote(props) {
  const [printing, setPrinting] = useState(false);

  const handlePrint = () => {
    setPrinting(!printing);
    setTimeout(window.print, 250);
    setTimeout(() => setPrinting(false), 1500);
  };

  const handleSaveQuote = () => {
    const { quoteRecord, createSavedQuote, setConfirmingSaveQuote } = props;
    createSavedQuote(quoteRecord).then(() => setConfirmingSaveQuote(true));
  };

  const handleReadyToProceed = () => {
    const { setReadyToProceed } = props;
    setReadyToProceed(true);
  };

  const handleProceed = () => {
    const { router, location } = props;
    router.push(`/rate${location.search}`);
  };

  const {
    quoteRecord,
    vscQuote,
    gapQuote,
    twpQuote,
    readyToProceed,
    confirmingSaveQuote,
    extraCoverages,
    setConfirmingSaveQuote,
    navigateToDashboard,
    dealer,
    hasInvalidPagesBefore,
    dealerSettings,
  } = props;

  const coverages = [
    ..._.get(vscQuote, 'coverages', []).filter(c => !c.selectable),
    ...extraCoverages,
  ];
  const { eSignRequired } = dealer || {};

  return (
    <Grid data-test-id="FinalQuote">
      <h5> This vehicle qualifies as of {new Date().toLocaleString()} </h5>
      {vscQuote && (
        <PlanTable
          contractType={CONTRACT_TYPE_VSC}
          quote={vscQuote}
          request={quoteRecord}
          otherCoverages={coverages}
          dealerSettings={dealerSettings}
        />
      )}
      {gapQuote && (
        <PlanTable
          contractType={CONTRACT_TYPE_GAP}
          quote={gapQuote}
          request={quoteRecord}
          dealerSettings={dealerSettings}
        />
      )}
      {twpQuote && (
        <PlanTable
          contractType={CONTRACT_TYPE_TWP}
          quote={twpQuote}
          request={quoteRecord}
          dealerSettings={dealerSettings}
        />
      )}
      {hasInvalidPagesBefore && (
        <Alert className="alert-success">
          You need to fix problems on previous pages to continue
        </Alert>
      )}
      {!printing && (
        <Fragment>
          <Row style={{ marginTop: 16 }}>
            <Col md={6} xs={12}>
              <Button
                bsStyle="default"
                className="ellipsis"
                onClick={handlePrint}
                style={FOOTER_BUTTON_STYLE}
              >
                Print This Page
              </Button>
              <Button
                bsStyle="info"
                className="ellipsis"
                onClick={handleSaveQuote}
                style={FOOTER_BUTTON_STYLE}
                disabled={hasInvalidPagesBefore}
                data-test-id="FinalQuote-save"
              >
                Save Quote
              </Button>
              <Button
                bsStyle="primary"
                onClick={handleReadyToProceed}
                style={FOOTER_BUTTON_STYLE}
                disabled={hasInvalidPagesBefore}
                data-test-id="FinalQuote-proceed"
              >
                Proceed To Contract
              </Button>
            </Col>
            <Col md={6} xs={12}>
              <Button
                bsStyle="link"
                className="ellipsis"
                onClick={() => alert('survey')}
                block
                style={{ fontSize: '18px' }}
              >
                Customer Satisfaction Survey
              </Button>
            </Col>
          </Row>
          <ESignModal
            eSignRequired={eSignRequired}
            show={readyToProceed}
            onProceed={handleProceed}
          />
        </Fragment>
      )}
      {
        <NotificationModal
          show={confirmingSaveQuote}
          title="Quote Saved"
          message="This quote has been saved. It's available in the dashboard via the 'All Quotes & Contracts' section. Where would you like to go?"
          confirmText="Return to Dashboard"
          cancelText="Continue"
          onConfirm={navigateToDashboard}
          onCancel={() => setConfirmingSaveQuote(false)}
          data-test-name="FinalQuote-saveModal"
        />
      }
    </Grid>
  );
}

FinalQuote.propTypes = {
  router: PropTypes.object.isRequired,
  createSavedQuote: PropTypes.func.isRequired,
  setReadyToProceed: PropTypes.func.isRequired,
  setConfirmingSaveQuote: PropTypes.func.isRequired,
  navigateToDashboard: PropTypes.func.isRequired,
  quoteRecord: PropTypes.object.isRequired,
  dealer: PropTypes.object.isRequired,
  vscQuote: PropTypes.object,
  gapQuote: PropTypes.object,
  twpQuote: PropTypes.object,
  extraCoverages: PropTypes.array,
  readyToProceed: PropTypes.bool,
  confirmingSaveQuote: PropTypes.bool,
  hasInvalidPagesBefore: PropTypes.bool,
  dealerSettings: PropTypes.object,
};

FinalQuote.defaultProps = {
  vscQuote: null,
  gapQuote: null,
  twpQuote: null,
  extraCoverages: null,
  readyToProceed: false,
  confirmingSaveQuote: false,
  hasInvalidPagesBefore: false,
  dealerSettings: null,
};

export default compose(
  withState('readyToProceed', 'setReadyToProceed', false),
  withState('confirmingSaveQuote', 'setConfirmingSaveQuote', false),
  withProps(({ queryData }) => ({
    quoteRecord: queryData,
  })),
  connect(
    ({ newQuote, dealer, dealerSettings }) => {
      const vscQuote = selectedVscQuote({ newQuote });
      const gapQuote = selectedGapQuote({ newQuote });
      const twpQuote = selectedTwpQuote({ newQuote });
      return {
        dealer: dealer.details,
        commercialType: _.get(newQuote.ratingQuoteRequest, 'commercialType'),
        extraCoverages: _.values(newQuote.extraCoverages),
        vscQuote,
        gapQuote,
        twpQuote,
        dealerSettings: dealerSettings.data,
      };
    },
    {
      createSavedQuote,
    },
  ),
)(FinalQuote);
