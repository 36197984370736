import _ from 'lodash';
import { withProps } from 'recompose';

function mapQueryValuesFromQueryData(mapping, queryData, initialValues) {
  return _.reduce(
    mapping,
    (acc, destinationKey, originKey) => {
      const setValue = subKey => _.set(acc, subKey, _.get(queryData, originKey));
      const chooseSetValue = subKey => {
        if (_.isArray(subKey)) {
          _.each(subKey, chooseSetValue);
        } else if (_.get(subKey, 'key')) {
          const key = _.get(subKey, 'key');
          if (_.get(initialValues, key)) {
            if (!_.get(subKey, 'useInitial')) {
              chooseSetValue(key);
            }
          } else {
            chooseSetValue(key);
          }
        } else {
          setValue(subKey);
        }
      };

      chooseSetValue(destinationKey);

      return acc;
    },
    {},
  );
}

export function receiveValuesFromQueryData(objKey) {
  return withProps(({ queryData, initialValues }) => {
    const queryValues = _.isObject(objKey)
      ? mapQueryValuesFromQueryData(objKey, queryData, initialValues)
      : {
          [objKey]: _.get(queryData, objKey, {}),
        };
    return {
      initialValues: _.merge({}, initialValues, queryValues),
    };
  });
}
