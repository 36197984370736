import _ from 'lodash';

export function readableList(strAr) {
  if (strAr) {
    return _.chain(strAr)
      .tap(ar => {
        ar[ar.length - 1] = `and ${ar[ar.length - 1]}`;
        return ar;
      })
      .join(', ')
      .value();
  }
  return strAr;
}

export function escapeForRegExp(str) {
  return _.replace(str, /[.*+?^${}()|[\]\\]/g, '\\$&');
}
