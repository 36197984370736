import React from 'react';
import { Grid, Tabs, Tab } from 'react-bootstrap';
import ESignTemplate from './components/ESignTemplates';
import MandatoryEsign from './components/MandatoryEsign';

export default () => {
  return (
    <Grid>
      <Tabs id="e-sign-admin">
        <Tab eventKey="templates" title="Templates">
          <div style={{ marginTop: 16 }}>
            <ESignTemplate />
          </div>
        </Tab>
        <Tab eventKey="mandatory-esign" title="Mandatory E-Sign">
          <div style={{ marginTop: 16 }}>
            <MandatoryEsign />
          </div>
        </Tab>
      </Tabs>
    </Grid>
  );
};
