/* eslint-disable no-restricted-syntax */
import _ from 'lodash';
import { loadingCycle } from './page';
import api from '../../utils/service';
const CREATE_UPLOAD_CSV_FILE = 'redux/uploadCSVFile/CREATE_UPLOAD_CSV_FILE';
const CREATE_UPLOAD_CSV_FAIL = 'redux/uploadCSVFile/CREATE_UPLOAD_CSV_FAIL';

const initialState = {};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case CREATE_UPLOAD_CSV_FILE:
      return {
        ...state,
        data: action.data.data,
        isSuccessful: action.data.isSuccessful.isSuccessful,
      };
    case CREATE_UPLOAD_CSV_FAIL:
      return {
        ...state,
        error: action.error.error,
        isSuccessful: action.error.isSuccessful.isSuccessful,
      };

    default:
      return state;
  }
}
export function uploadCSVFile(data) {
  const formData = new FormData();
  formData.append('file', data.file);

  return dispatch => {
    return loadingCycle(api.post('/addresses/upload', formData))
      .then(data => dispatch(createUploadCSVFile(data, { isSuccessful: true })))
      .catch(error => {
        console.log('error in upload file api', error.response.data);
        dispatch(createUploadCSVFail(error, { isSuccessful: true }));
      });
  };
}

export function createUploadCSVFile(data, isSuccessful) {
  return {
    type: CREATE_UPLOAD_CSV_FILE,
    data: { data, isSuccessful },
  };
}

export function createUploadCSVFail(error, isSuccessful) {
  return {
    type: CREATE_UPLOAD_CSV_FAIL,
    error: {
      isSuccessful,
      error: _.get(error, 'response.data.message', _.get(error, 'message')),
    },
  };
}
