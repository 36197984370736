import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Helmet from 'react-helmet';
import { Checkbox } from 'react-bootstrap';
import qs from 'qs';
import _ from 'lodash';
import moment from 'moment';
import { destroy, getFormSyncErrors, getFormValues } from 'redux-form';
import { createIssuedContract, deleteIssuedContract } from '../../redux/modules/issuedContract';
import RateBreadcrumb from './RateBreadcrumb';
import {
  VscRateForm,
  GapRateForm,
  TwpRateForm,
  CustomerInformationForm,
  LenderInformationForm,
} from './forms';
import { selectedGapQuote, selectedVscQuote, selectedTwpQuote } from '../../redux/modules/newQuote';
import api from '../../utils/service';
import NotificationModal from '../../components/common/NotificationModal';
import {
  quoteTypeDifference,
  quoteTypeIncludesGAP,
  quoteTypeIncludesTWP,
  quoteTypeIncludesVSC,
  quoteTypeIntersection,
  quoteTypeTitle,
} from '../../constants/quoteType';
import {
  VSC_RATE_FORM,
  GAP_RATE_FORM,
  TWP_RATE_FORM,
  CUSTOMER_INFORMATION_RATE_FORM,
  LENDER_INFORMATION_RATE_FORM,
} from '../../redux/modules/form';
import { notifSend } from '../../redux/modules/notifs';
import { contractTypeTitle } from '../../constants/contractType';
import { TaskEventsList } from '../Tasks/EventsList';

/**
 * Some pages can preserve their state after unmount.
 * The `destroyState` callbacks should be called
 * when the state is no longer needed.
 */
const RATE_PAGES = [
  {
    page: VscRateForm,
    name: 'VSC Details',
    required: ({ quoteType } = {}) => quoteType && quoteTypeIncludesVSC(quoteType),
    formName: VSC_RATE_FORM,
    props: { destroyOnUnmount: false },
    destroyState: ({ dispatch }) => dispatch(destroy(VSC_RATE_FORM)),
  },
  {
    page: GapRateForm,
    name: 'GAP Details',
    required: ({ quoteType } = {}) => quoteType && quoteTypeIncludesGAP(quoteType),
    formName: GAP_RATE_FORM,
    props: { destroyOnUnmount: false },
    destroyState: ({ dispatch }) => dispatch(destroy(GAP_RATE_FORM)),
  },
  {
    page: TwpRateForm,
    name: 'T&W Details',
    required: ({ quoteType } = {}) => quoteType && quoteTypeIncludesTWP(quoteType),
    formName: TWP_RATE_FORM,
    props: { destroyOnUnmount: false },
    destroyState: ({ dispatch }) => dispatch(destroy(TWP_RATE_FORM)),
  },
  {
    page: CustomerInformationForm,
    name: 'Customer Information',
    formName: CUSTOMER_INFORMATION_RATE_FORM,
    props: { destroyOnUnmount: false },
    destroyState: ({ dispatch }) => dispatch(destroy(CUSTOMER_INFORMATION_RATE_FORM)),
  },
  {
    page: LenderInformationForm,
    name: 'Lender Information',
    formName: LENDER_INFORMATION_RATE_FORM,
    props: { destroyOnUnmount: false },
    destroyState: ({ dispatch }) => dispatch(destroy(LENDER_INFORMATION_RATE_FORM)),
  },
];

const requiredRatePages = (quoteSettings = {}) =>
  _.chain(RATE_PAGES).filter(({ required }) => _.isNil(required) || required(quoteSettings));

const parseSpecialProperties = data =>
  _.chain(data)
    .cloneDeep()
    .thru(d => {
      if (_.get(d, 'vscRate.saleDate') && moment.isMoment(d.vscRate.saleDate)) {
        return {
          vscRate: {
            ...d.vscRate,
            saleDate: d.vscRate.saleDate.format('MM/DD/YYYY'),
          },
        };
      }
      return d;
    })
    .value();

const CreateDuplicateContractConfirmation = ({
  show,
  disabled,
  onConfirm,
  onClose,
  pendingQuoteType,
  existingContract,
}) => {
  if (!existingContract) {
    return null;
  }

  const [keepContractTypes, setKeepContractTypes] = useState([]);

  const omittedContractTypes = quoteTypeDifference(existingContract.contractType)(pendingQuoteType);
  const replacedContractTypes = quoteTypeIntersection(pendingQuoteType)(
    existingContract.contractType,
  );

  const omittedContractText = quoteTypeTitle(omittedContractTypes);

  const handleToggleContractCb = contractType => () => {
    if (keepContractTypes.includes(contractType))
      setKeepContractTypes(keepContractTypes.filter(t => t !== contractType));
    else setKeepContractTypes([...keepContractTypes, contractType]);
  };

  // To allow something to keep, we need to have something to cancel first.
  // If there is some replaced contracts, they will be cancelled
  // Or, if there is more than one omitted contract, user can select some to cancel.
  const enableChooseToKeep = replacedContractTypes.length > 0 || omittedContractTypes.length > 1;

  // If there is no replaced contracts, and user selected all to keep, we have nothing to cancel.
  const hasNothingToCancel =
    replacedContractTypes.length === 0 &&
    omittedContractTypes.every(t => keepContractTypes.includes(t));

  const keepCheckBoxes = enableChooseToKeep
    ? omittedContractTypes.map(contractType => (
        <Checkbox
          key={contractType}
          checked={keepContractTypes.includes(contractType)}
          onChange={handleToggleContractCb(contractType)}
        >
          Keep {contractTypeTitle(contractType)} Contract
        </Checkbox>
      ))
    : [];

  return (
    <NotificationModal
      show={show}
      disabled={disabled}
      disabledConfirm={hasNothingToCancel}
      title="Contract Already Exists"
      message={`An existing ${quoteTypeTitle(
        existingContract.contractType,
      )} contract was submitted for ${existingContract.vin}, would you like to cancel it?`}
      confirmText={`Cancel Existing & Submit New`}
      cancelText={`Continue (Submit New)`}
      onConfirm={() => onConfirm(keepContractTypes)}
      onCancel={onClose}
      data-test-name="Rate-CreateDuplicateContractConfirmation"
    >
      {keepCheckBoxes.length > 1 && (
        <div>
          <hr />
          <p>
            The previous contract included {omittedContractText} contracts, which are not included
            anymore.
          </p>
          <p>Select contracts you want to keep:</p>
          {keepCheckBoxes}
        </div>
      )}

      {keepCheckBoxes.length === 1 && (
        <div>
          <hr />
          <p>
            The previous contract included {omittedContractText} contract, which is not included
            anymore.
          </p>
          <p>Do you want to keep it?</p>
          {keepCheckBoxes}
        </div>
      )}
    </NotificationModal>
  );
};

const hasNonCompletedPagesBefore = completedPages => page => {
  for (let i = 0; i < page; i += 1) if (!completedPages.includes(i)) return true;

  return false;
};

export function Rate(props) {
  const [page, setPage] = useState(0);
  const [showConfirmCancelExistingContract, setShowConfirmCancelExistingContract] = useState(false);
  const [existingContract, setExistingContract] = useState(false);
  const [pendingContract, setPendingContract] = useState(null);
  const [confirmMoveToPage, setConfirmMoveToPage] = useState(null);
  const [completedPages, setCompletedPages] = useState([]);
  const [skipCheckInProgress, setSkipCheckInProgress] = useState(false);
  const [issueError, setIssueError] = useState(false);

  // Query data is used to pre-fill initial values.
  const [queryData, setQueryData] = useState();
  useEffect(() => {
    if (!queryData) setQueryData(qs.parse(props.location.search, { ignoreQueryPrefix: true }));
  }, [props.location, queryData]);

  useEffect(() => {
    // Exposing the handler to use for puppeteer testing
    // @ts-ignore-next-line
    window.__Rate_setSkipCheckInProgress = setSkipCheckInProgressToTrue;

    return () => {
      const { dispatch } = props;
      getPages().forEach(({ destroyState }) => {
        if (destroyState) destroyState({ dispatch });
      });

      // @ts-ignore-next-line
      delete window.__Rate_setSkipCheckInProgress;
    };
  }, []);

  const [startIssue, setStartIssue] = useState(false);

  useEffect(() => {
    if (startIssue) {
      const pendingContract = getCombinedPendingContract();
      setStartIssue(false);
      checkForExistingContract(pendingContract.vin);
    }
  }, [startIssue]);

  const setSkipCheckInProgressToTrue = () => setSkipCheckInProgress(true);

  const getPages = () => requiredRatePages(props.quoteSettings).value();

  const isPageValid = page => {
    const { formState } = props;

    const { formName } = getPages()[page] || {};
    if (!formName) return true;

    return _.isEmpty(getFormSyncErrors(formName, _.identity)(formState));
  };

  const refreshCompletedPages = (changedPage, completed) => {
    // Some pages can require reset its completion state
    // when a page before it changes.
    // Here we filtering out such pages.
    const pages = getPages();
    const newCompletedPages = completedPages.filter(completedPage => {
      // Currently changed page is after one
      if (completedPage < changedPage) return true;

      // We'll add it back if it's completed later
      if (completedPage === changedPage) return false;

      return !pages[completedPage].resetCompleteWhenPreviousPageChanges;
    });

    // Adding page itself if it's completed
    if (completed) newCompletedPages.push(changedPage);

    setCompletedPages(_.uniq(newCompletedPages));
  };

  const hasInvalidPagesBefore = page => {
    for (let i = 0; i < page; i += 1) if (!isPageValid(i)) return true;

    return false;
  };

  const getPageData = page => {
    const { formState } = props;

    const { formName } = getPages()[page] || {};
    if (!formName) return {};

    return getFormValues(formName, _.identity)(formState);
  };

  const switchToPageHandler = page => setPage(page);

  const storePageDataAndSwitchTo = ({ currentPage, switchToPage }) => {
    const valid = isPageValid(currentPage);

    const data = getPageData(currentPage);
    if (valid) storeData(currentPage, data);

    refreshCompletedPages(currentPage, valid);

    switchToPageHandler(switchToPage);
  };

  const handleMoveToPage = moveToPage => {
    const currentPage = page;

    if (moveToPage === null) return undefined;

    const isValid = isPageValid(currentPage);
    if (isValid) storePageDataAndSwitchTo({ currentPage, switchToPage: moveToPage });
    else setConfirmMoveToPage(moveToPage);
  };

  const handleCancelMoveToPage = () => setConfirmMoveToPage(null);

  const handleConfirmMoveToPage = () => {
    const currentPage = page;

    if (confirmMoveToPage === null) return undefined;

    setConfirmMoveToPage(null);

    storePageDataAndSwitchTo({ currentPage, switchToPage: confirmMoveToPage });
  };

  const renderMoveToPageConfirmation = () => (
    <NotificationModal
      show={true}
      onConfirm={handleConfirmMoveToPage}
      onCancel={handleCancelMoveToPage}
      title="Confirm leaving the form"
      confirmText="Continue"
      cancelText="Cancel"
    >
      <p>There are remaining items to&nbsp;complete on&nbsp;nbsp;form.</p>
      <p>
        If you continue, you will have to&nbsp;return before&nbsp;submission to&nbsp;complete
        these&nbsp;items
      </p>
    </NotificationModal>
  );

  const storeData = (page, data) => {
    const { location, router } = props;

    const newPendingContract = {
      ...pendingContract,
      ...parseSpecialProperties(data),
    };

    setPendingContract(newPendingContract);

    refreshCompletedPages(page, true);

    router.push(`${location.pathname}?${qs.stringify(newPendingContract)}`);

    return newPendingContract;
  };

  const getCombinedPendingContract = () => {
    const { quoteSettings, dealer, vscQuote, gapQuote, twpQuote, extraCoverages, eSign } = props;
    return {
      ...pendingContract,
      ...quoteSettings,
      eSign,
      dealer,
      quotes: {
        vscQuote,
        gapQuote,
        twpQuote,
        extraCoverages,
      },
    };
  };

  const isLastPage = page => page === getPages().length - 1;

  const checkForExistingContract = async vin => {
    try {
      /**
       * @typedef ExistingIssuedContract {{
       *  id: string;
       *  created: string;
       *  vin: string;
       *  status: string;
       *  contractType: string;
       *  vscQuoteId: string;
       *  gapQuoteId: string;
       *  twpQuoteId: string;
       * }}
       */
      const { data } = await api.get(`/actions/issued-contracts/vin/${vin}`);
      if (data && data.issuedContract) {
        setShowConfirmCancelExistingContract(true);
        setExistingContract(data.issuedContract);
        return;
      }
    } catch (err) {
      const { response } = err;
      // note: its okay if its not found.. continue submission
      if (response.status !== 404) {
        throw new Error(
          `Error checking for already issued contract: ${response.message || err.toString()}`,
        );
      }
    }
    submitContract();
    setExistingContract(null);
  };

  const handleConfirmCancelExistingContract = async (cancel, keepContractTypes = null) => {
    const { notifSend } = props;
    const pendingContract = getCombinedPendingContract();

    if (!pendingContract) {
      setShowConfirmCancelExistingContract(false);
      notifSend({
        kind: 'danger',
        message: `Unable to locate pending contract, please go back a step and try again.`,
        dismissAfter: 6000,
      });
      return pendingContract;
    }

    if (cancel) {
      await deleteContract(keepContractTypes);
      notifSend({
        message: `Requested cancellation for existing contract on ${pendingContract.vin}`,
        dismissAfter: 5000,
      });
    }
    await submitContract();
    setShowConfirmCancelExistingContract(false);
    setExistingContract(null);
  };

  const deleteContract = async keepContractTypes => {
    const { deleteIssuedContract } = props;
    await deleteIssuedContract(existingContract.id, {
      reason: 'duplicate',
      keepContractTypes,
    });
  };

  const onSubmitResult = result => {
    // Result can be either Task info (when its execution delayed), or the Task execution result.
    const { router } = props;
    const taskId = _.get(result, 'taskId');
    if (taskId) {
      // Got Task info, navigating user to the Task
      router.push(`/tasks/IssueContract/${taskId}`);
    } else {
      // Got execution result
      const taskEvents = _.get(result, 'taskEvents');
      if (taskEvents) {
        const resultEvent = taskEvents.find(e => e.eventType === 'TaskEventResult');
        if (resultEvent) {
          const { issuedContractId } = resultEvent;
          router.push(`/contractIssueComplete/${issuedContractId}`);
        } else {
          setIssueError(taskEvents);
        }
      } else {
        setIssueError(true);
      }
    }
  };

  const submitContract = async contract => {
    const { createIssuedContract } = props;
    /**
     * Small hack around a bug we've experiencing.
     * When users send their form, lender information is sometimes not available due to
     * a race condition. Before rewriting this component to get rid of that race condition
     * we are implementing a retry approach in order to validate lender information be in place.
     */
    let pendingContract = null;
    let attemps = 0;
    const f = async () => {
      pendingContract = contract || getCombinedPendingContract();
      if (pendingContract && pendingContract.lender) {
        const result = await createIssuedContract({ ...pendingContract, skipCheckInProgress });
        onSubmitResult(result);
        return;
      }
      attemps++;
      console.log(`Error while trying to retrieve lender information. Attemp number ${attemps}`);
      if (attemps === 50) {
        throw new Error(`Too many attemps to submit contract with full information for lender.`);
      }
      setTimeout(f, 100);
    };

    await f();
  };

  const handleSubmit = async data => {
    storeData(page, data);
    setIssueError(false);

    // anything but the last page
    if (!isLastPage(page)) {
      switchToPageHandler(page + 1);
    } else {
      setStartIssue(true);
    }
  };
  const { loading } = props;

  const pages = getPages();
  const { page: PageComponent, props: pageProps } = pages[page];
  const pageDisplay = PageComponent && (
    <PageComponent
      key={page}
      goBack={_.partial(handleMoveToPage, page - 1)}
      queryData={queryData}
      onSubmit={handleSubmit}
      {...props}
      {...pageProps}
      hasInvalidPagesBefore={hasInvalidPagesBefore(page)}
      onChange={() => {
        refreshCompletedPages(page);
      }}
      hideBack={page === 0}
    />
  );

  const moveToPageConfirmation = confirmMoveToPage !== null && renderMoveToPageConfirmation();

  /**
   * This should be new for each render,
   * so when completedPages change, the function changes too.
   */
  const isPageDisabled = hasNonCompletedPagesBefore(completedPages);

  return (
    <React.Fragment>
      <div className="container">
        <Helmet title="Rate Form" />
        <RateBreadcrumb
          pages={pages}
          moveToPage={handleMoveToPage}
          pageNo={page}
          isPageValid={isPageValid}
          isPageDisabled={isPageDisabled}
        />
        {pageDisplay}
        {moveToPageConfirmation}
      </div>

      <CreateDuplicateContractConfirmation
        show={showConfirmCancelExistingContract}
        disabled={loading}
        onConfirm={keepContractTypes =>
          handleConfirmCancelExistingContract(true, keepContractTypes)
        }
        onClose={() => handleConfirmCancelExistingContract(false)}
        pendingQuoteType={props.quoteSettings.quoteType}
        existingContract={existingContract}
      />
      {issueError && (
        <div data-test-id="Rate-issueError">
          <TaskEventsList taskEvents={Array.isArray(issueError) ? issueError : []} />
        </div>
      )}
    </React.Fragment>
  );
}

Rate.propTypes = {
  location: PropTypes.object.isRequired,
  router: PropTypes.object.isRequired,
  createIssuedContract: PropTypes.func.isRequired,
  deleteIssuedContract: PropTypes.func.isRequired,
  quoteSettings: PropTypes.object.isRequired,
  vscQuote: PropTypes.object,
  gapQuote: PropTypes.object,
  twpQuote: PropTypes.object,
  extraCoverages: PropTypes.array,
  dealer: PropTypes.object,
  eSign: PropTypes.bool,
  loading: PropTypes.bool,
  formState: PropTypes.object.isRequired,
};

Rate.defaultProps = {
  vscQuote: null,
  gapQuote: null,
  twpQuote: null,
  extraCoverages: null,
  dealer: null,
  eSign: false,
  loading: false,
};

export default connect(
  ({ dealer, newQuote, eSign, page, form: formState }) => {
    const vscQuote = selectedVscQuote({ newQuote });
    const gapQuote = selectedGapQuote({ newQuote });
    const twpQuote = selectedTwpQuote({ newQuote });

    const quoteSettings = _.get(newQuote, 'form');

    return {
      loading: page.loading,
      quoteSettings,
      dealer: dealer.details,
      vscQuote,
      gapQuote,
      twpQuote,
      extraCoverages: _.values(newQuote.extraCoverages),
      eSign: !!_.get(eSign, 'customer.email'),
      formState,
    };
  },
  { createIssuedContract, deleteIssuedContract, notifSend },
)(Rate);
