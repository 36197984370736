import _ from 'lodash';
import { loadingCycle } from './page';
import api from '../../utils/service';

const GET_DEALER_SETTINGS_SUCCESS = 'redux/dealerSettings/GET_DEALER_SETTINGS_SUCCESS';
const GET_DEALER_SETTINGS_FAIL = 'redux/dealerSettings/GET_DEALER_SETTINGS_FAIL';
const UPDATE_DEALER_SETTINGS_SUCCESS = 'redux/dealerSettings/UPDATE_DEALER_SETTINGS_SUCCESS';
const UPDATE_DEALER_SETTINGS_FAIL = 'redux/dealerSettings/UPDATE_DEALER_SETTINGS_FAIL';

const initialState = {};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case GET_DEALER_SETTINGS_SUCCESS:
      return {
        ...state,
        data: action.data,
      };
    case GET_DEALER_SETTINGS_FAIL:
    case UPDATE_DEALER_SETTINGS_FAIL:
      return {
        ...state,
        error: action.error,
      };
    default:
      return state;
  }
}

export function getDealerSettings(dealerId = 'me') {
  return dispatch =>
    loadingCycle(api.get(`/dealer-settings?dealerId=${dealerId}`))
      .then(({ data }) => dispatch(getDealerSettingsSuccess(data)))
      .catch(error => dispatch(getDealerSettingsFail(error)));
}

export function getDealerSettingsSuccess(data) {
  return {
    type: GET_DEALER_SETTINGS_SUCCESS,
    data,
  };
}

export function getDealerSettingsFail(error) {
  return {
    type: GET_DEALER_SETTINGS_FAIL,
    error: _.get(error, 'response.data.message', _.get(error, 'message')),
  };
}

export function updateDealerSettings(id, data) {
  return dispatch =>
    loadingCycle(api.put(`/dealer-settings/${id}`, data))
      .then(({ data }) => dispatch(updateDealerSettingsSuccess(data)))
      .catch(error => dispatch(updateDealerSettingsFail(error)));
}

export function updateDealerSettingsSuccess(data) {
  return {
    type: UPDATE_DEALER_SETTINGS_SUCCESS,
    data,
  };
}

export function updateDealerSettingsFail(error) {
  return {
    type: UPDATE_DEALER_SETTINGS_FAIL,
    error: _.get(error, 'response.data.message', _.get(error, 'message')),
  };
}
