import { useReducer, useMemo, useEffect } from 'react';
import { TASK_EVENT_TYPE_RESULT, TASK_EVENT_TYPE_STEP_RESULT } from '../../constants/task';
import { watchEvents } from '../../utils/service';

const limit = 300;

const taskEventReducer = (prevState, [action, payload]) => {
  if (action === 'offline') return undefined;
  if (action === 'init') return payload || [];
  if (action === 'add') return [...(prevState || []), ...(payload || [])].slice(-limit);
};

export const useTaskEvents = taskId => {
  const [taskEvents, onTaskEvent] = useReducer(taskEventReducer, undefined);

  const taskEventsByType = useMemo(
    () =>
      taskEvents
        ? taskEvents.reduce(
            (res, taskEvent) => ({
              ...res,
              [taskEvent.eventType]: [...(res[taskEvent.eventType] || []), taskEvent],
            }),
            {},
          )
        : {},
    [taskEvents],
  );

  const taskEventResultList = taskEventsByType[TASK_EVENT_TYPE_RESULT];
  const taskEventResult = (taskEventResultList || [])[0];

  useEffect(() => {
    if (!taskId) return undefined;
    const cancel = watchEvents(
      `tasks/${taskId}/events`,
      (taskEvent, { initial }) => {
        onTaskEvent([initial ? 'init' : 'add', [taskEvent]]);
      },
      ['TaskEvent'],
    );

    return () => {
      onTaskEvent(['offline']);
      cancel();
    };
  }, [taskId]);

  const errorIshEvents = useMemo(
    () => [
      ...(taskEventsByType.TaskEventLog || []).filter(
        ({ meaning }) => meaning === 'error' || meaning === 'alert',
      ),
      ...(taskEventsByType[TASK_EVENT_TYPE_STEP_RESULT] || []).filter(({ error }) => !!error),
    ],
    [taskEventsByType],
  );

  return {
    taskEvents,
    taskEventsByType,
    taskEventResultList,
    taskEventResult,
    errorIshEvents,
  };
};
