import _ from 'lodash';
import { quoteTypeExclude, quoteTypeItems, quoteTypeUnion } from '../../constants/quoteType';
import {
  CONTRACT_TYPE_GAP,
  CONTRACT_TYPE_TWP,
  CONTRACT_TYPE_VSC,
  contractTypesAll,
  contractTypeTitle,
} from '../../constants/contractType';
import { makeValidationCheckboxSet } from '../../components/common/ValidationCheckboxSet';

export const QuoteTypeInput = makeValidationCheckboxSet({
  parseValue: quoteTypeItems,
  getItemTitle: contractTypeTitle,
  changeValue: ({ item, checked, value }) =>
    checked ? quoteTypeUnion(value, item) : quoteTypeExclude(item)(value),
  allowItemsDefault: contractTypesAll,
});

export const filterQuoteTypesOnDealerInfo = dealerInfo => {
  const vscValid = _.get(dealerInfo, 'rating.vscValid');
  const gapValid = _.get(dealerInfo, 'rating.gapValid');
  const twpValid = _.get(dealerInfo, 'rating.twpValid');
  return [
    vscValid && CONTRACT_TYPE_VSC,
    gapValid && CONTRACT_TYPE_GAP,
    twpValid && CONTRACT_TYPE_TWP,
  ]
    .filter(val => !!val)
    .sort();
};
