import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose, withProps } from 'recompose';
import _ from 'lodash';
import { Field, reduxForm, formValueSelector, propTypes, change } from 'redux-form';
import { NEW_QUOTE_META_FORM } from '../../redux/modules/form';
import { newQuoteMetaValidation } from '../../components/validation/newQuoteValidation';
import CommercialMessage from './CommercialMessage';
import ValidationSelect from '../../components/common/ValidationSelect';
import Buttons from '../../components/wizard/Buttons';
import {
  COMMERCIAL_TYPES,
  QuoteTypePropType,
  CommercialTypePropType,
  VEHICLE_CONDITION_TYPES,
} from '../../constants/newQuote';
import { quoteMetaTestDataIfRequested } from '../../utils/testData';
import { filterQuoteTypesOnDealerInfo, QuoteTypeInput } from './QuoteTypeInput';
import {
  quoteTypeIncludesEvery,
  quoteTypeIncludesVSCxTWP,
  QUOTE_TYPE_ONLY_VSC,
} from '../../constants/quoteType';

const newQuoteMetaFormValueSelector = formValueSelector(NEW_QUOTE_META_FORM);

const VEHICLE_CONDITION_OPTIONS = [
  {
    value: VEHICLE_CONDITION_TYPES.USED,
    label: 'Used',
  },
  {
    value: VEHICLE_CONDITION_TYPES.NEW,
    label: 'New',
  },
];

const COMMERCIAL_OPTIONS = [
  {
    value: COMMERCIAL_TYPES.NON_COMMERCIAL,
    label: 'Non-Commercial',
  },
  {
    value: COMMERCIAL_TYPES.COMMERCIAL,
    label: 'Commercial',
  },
];

export function QuoteMetaForm(props) {
  const handleCommercialTypeChange = (e, commercialType) => {
    const { dispatch, dealerHasUsedNewFeature } = props;
    if (commercialType === COMMERCIAL_TYPES.COMMERCIAL) {
      if (dealerHasUsedNewFeature) {
        dispatch(change(NEW_QUOTE_META_FORM, 'vehicleCondition', VEHICLE_CONDITION_TYPES.USED));
      }
      dispatch(change(NEW_QUOTE_META_FORM, 'quoteType', QUOTE_TYPE_ONLY_VSC));
    }
  };

  const handleQuoteTypeChange = (e, quoteType) => {
    const { dispatch, dealerHasUsedNewFeature, onDestructiveQuoteChange } = props;
    if (quoteTypeIncludesVSCxTWP(quoteType) && dealerHasUsedNewFeature) {
      dispatch(change(NEW_QUOTE_META_FORM, 'vehicleCondition', VEHICLE_CONDITION_TYPES.USED));
    }
    // NOTE - This is triggered when some item has been unchecked
    if (!quoteTypeIncludesEvery(props.quoteType)(quoteType)) {
      onDestructiveQuoteChange();
    }
  };

  const {
    quoteType,
    commercialType,
    dealerHasUsedNewFeature,
    dealerInfo,
    disableProceed,
    handleSubmit,
  } = props;

  return (
    <form
      className="form-horizontal"
      onSubmit={handleSubmit}
      noValidate={true}
      data-test-id="QuoteMetaForm"
    >
      <Field
        name="commercialType"
        type="text"
        label="Quote"
        placeholder="Choose one"
        component={ValidationSelect}
        options={COMMERCIAL_OPTIONS}
        onChange={handleCommercialTypeChange}
        data-test-id="QuoteMetaForm-commercialType"
      />
      {dealerHasUsedNewFeature && (
        <Field
          name="vehicleCondition"
          type="text"
          label="Vehicle Condition"
          disabled={quoteTypeIncludesVSCxTWP(quoteType)}
          component={ValidationSelect}
          options={VEHICLE_CONDITION_OPTIONS}
          data-test-id="QuoteMetaForm-vehicleCondition"
        />
      )}
      <Field
        name="quoteType"
        label="Type"
        disabled={commercialType === COMMERCIAL_TYPES.COMMERCIAL}
        component={QuoteTypeInput}
        props={{
          allowItems: filterQuoteTypesOnDealerInfo(dealerInfo),
        }}
        onChange={handleQuoteTypeChange}
        data-test-name="QuoteMetaForm-quoteType"
      />
      <CommercialMessage show={commercialType && commercialType === COMMERCIAL_TYPES.COMMERCIAL} />
      <Buttons
        {..._.omit(props, 'onSubmit')}
        invalid={disableProceed}
        data-test-name="QuoteMetaForm-Buttons"
      />
    </form>
  );
}

QuoteMetaForm.propTypes = {
  ...propTypes,
  dispatch: PropTypes.func.isRequired,
  quoteType: QuoteTypePropType,
  commercialType: CommercialTypePropType,
  dealerHasUsedNewFeature: PropTypes.bool,
  disableProceed: PropTypes.bool,
  onDestructiveQuoteChange: PropTypes.func.isRequired,
};

QuoteMetaForm.defaultProps = {
  quoteType: null,
  commercialType: null,
  dealerHasUsedNewFeature: false,
  disableProceed: false,
};

export const setInitialValues = ({ queryData }) => ({
  initialValues: _.merge({}, quoteMetaTestDataIfRequested(), {
    commercialType: _.get(queryData, 'commercialType'),
    vehicleCondition: _.get(queryData, 'vehicleCondition', VEHICLE_CONDITION_TYPES.USED),
    quoteType: _.get(queryData, 'quoteType'),
  }),
});

export const mapFromConnect = state => ({
  quoteType: newQuoteMetaFormValueSelector(state, 'quoteType'),
  commercialType: newQuoteMetaFormValueSelector(state, 'commercialType'),
  dealerHasUsedNewFeature: _.get(state, 'dealerSettings.data.features.usedNew', false),
  dealerInfo: _.get(state, 'dealer.details'),
  disableProceed: _.get(state, 'template.disableProceed', false) || _.get(state, 'page.loading'),
});

export default compose(
  connect(mapFromConnect),
  withProps(setInitialValues),
  reduxForm({
    form: NEW_QUOTE_META_FORM,
    validate: newQuoteMetaValidation,
  }),
)(QuoteMetaForm);
