import _ from 'lodash';
import {
  CONTRACT_TYPE_GAP,
  CONTRACT_TYPE_TWP,
  CONTRACT_TYPE_VSC,
} from '../../../constants/contractType';

export function filenameForDownload(issuedContract, contractType) {
  let number;
  if (contractType === CONTRACT_TYPE_GAP) {
    number = _.get(issuedContract, 'fourDResponse.gapContractNumber');
  }
  if (contractType === CONTRACT_TYPE_VSC) {
    number = _.get(issuedContract, 'fourDResponse.vscContractNumber');
  }
  if (contractType === CONTRACT_TYPE_TWP) {
    number = _.get(issuedContract, 'fourDResponse.twpContractNumber');
  }

  if (!number) {
    return `${contractType} CONTRACT.pdf`;
  }

  return `${contractType} CONTRACT ${number}.pdf`;
}
