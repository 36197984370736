import * as Yup from 'yup';
import * as validation from '../../../../utils/validation';
import * as newQuoteValidation from '../../../validation/newQuoteValidation';

export const errorMessageToYupException = (errorMessage, { path, createError }) => {
  if (!errorMessage) {
    return true;
  } else {
    return createError({ path, message: errorMessage });
  }
};

export const textTrimRequired = Yup.string()
  .trim('Must not include leading/trailing spaces')
  .strict(true)
  .required('Required');

export const money = function (value) {
  return errorMessageToYupException(validation.money(value), this);
};

export const minFinancedAmountLessCostOfGap = function (
  _value,
  { parent: { vehicleCondition, msrp, nadaAverageRetailValue } },
) {
  return errorMessageToYupException(
    newQuoteValidation.minFinancedAmountLessCostOfGap({
      vehicleCondition,
      msrp,
      nadaAverageRetailValue,
    }),
    this,
  );
};

export const notBeforeDays = (days = 30) =>
  function (value) {
    return errorMessageToYupException(validation.notBeforeDays(days)(value), this);
  };

export const notInFuture = function (value) {
  return errorMessageToYupException(validation.notInFuture(value), this);
};

export const isZipCode = function (value) {
  return errorMessageToYupException(validation.isZipCode(value), this);
};

export const integerPhoneNumber = function (value) {
  return errorMessageToYupException(validation.integerPhoneNumber(value), this);
};

export const isOnlyAlphabetWithSymbols = function (value) {
  return errorMessageToYupException(validation.isOnlyAlphabetWithSymbols(value), this);
};

export const isEmail = function (value) {
  return errorMessageToYupException(validation.email(value), this);
};

export const maxLoanAmount = ({ dealerState, contractYear }) =>
  function (value) {
    return errorMessageToYupException(
      newQuoteValidation.maxLoanAmount({ dealerState, contractYear })(value),
      this,
    );
  };

function maxRetailPriceOfVscHandler({ vehiclePrice }) {
  return value => {
    if (value >= 0.8 * parseFloat(vehiclePrice)) {
      return `Retail price of Vehicle Service Contract must be less 
        than 80% of the purchase price of the vehicle ${numeral(
          parseFloat(vehiclePrice) * 0.8,
        ).format('$0,0.00')}`;
    }
  };
}

function maxRetailGapHandler({ dealerState }) {
  return (value, { loanAmountOfGap }) => {
    if (dealerState === 'OR' && 20 * parseFloat(value) > parseFloat(loanAmountOfGap)) {
      return 'State law in Oregon requires a retail price less than or equal to 5% of the loan amount.';
    } else if (parseFloat(value) >= parseFloat(loanAmountOfGap)) {
      return 'The retail price must be less than the loan amount.';
    }
  };
}

export const maxRetailPriceOfVsc = function (value, { vehiclePrice }) {
  return errorMessageToYupException(maxRetailPriceOfVscHandler({ vehiclePrice })(value), this);
};

export const maxRetailGap = ({ dealerState }) =>
  function (value, { parent }) {
    return errorMessageToYupException(maxRetailGapHandler({ dealerState })(value, parent), this);
  };

export const match = (path, errorMessage) =>
  function (value, { parent }) {
    return errorMessageToYupException(
      _.get(parent, path) !== value ? errorMessage : undefined,
      this,
    );
  };

export const notMatch = (path, errorMessage) =>
  function (value, { parent }) {
    return errorMessageToYupException(
      _.get(parent, path) === value ? errorMessage : undefined,
      this,
    );
  };

export const requiredIfTrue = (cb, args = {}) =>
  function (value, { parent }) {
    return errorMessageToYupException(
      cb({ ...args, ...parent }) && validation.required(value),
      this,
    );
  };
