export function openDownload(data, name = 'contract.pdf', { testId = undefined } = {}) {
  const blob = new Blob([data]);
  const url = window.URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', name);
  document.body.appendChild(link);

  // @ts-ignore-next-line
  const { __TEST_OPEN_DOWNLOAD__ } = window;

  if (__TEST_OPEN_DOWNLOAD__) {
    // @ts-ignore-next-line
    link.testBlob = blob;
    // Not a mistake: testId will be converted to data-test-id property by browser
    link.dataset.testId = testId;
  }

  if (!__TEST_OPEN_DOWNLOAD__) link.click();
}
