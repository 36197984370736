import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { reduxForm, Field, propTypes, change } from 'redux-form';
import { NEW_USER_FORM } from '../redux/modules/form';
import { verifyEmail } from '../redux/modules/email';
import newUserValidation from './validation/newUserValidation';
import ValidationInput from './common/ValidationInput';
import ValidationCheckbox from './common/ValidationCheckbox';
import { exactLength, minLength, required, email as emailValidation } from '../utils/validation';
import ValidationSelect from './common/ValidationSelect';

async function newUserFormAsyncValidation({ email }) {
  // Note - make sure the email matches the basic regex of an email address before reaching out to server
  if (emailValidation(email) === undefined) {
    try {
      await verifyEmail(email);
    } catch (error) {
      return {
        email: _.get(error, 'message'),
      };
    }
  }
}

const DEPLOYMENT_OPTIONS = [
  {
    value: 'a',
    label: 'A',
  },
  {
    value: 'b',
    label: 'B',
  },
];

export function NewUserForm(props) {
  const { user } = props;
  const userId = _.get(user, 'userId');
  useEffect(() => {
    const { dispatch } = props;
    if (userId) {
      const { email, asnUser, dealerRel, deployment = DEPLOYMENT_OPTIONS[0].value } = user;
      dispatch(change(NEW_USER_FORM, 'email', email));
      dispatch(change(NEW_USER_FORM, 'dealerId', _.get(dealerRel, 'dealerId')));
      dispatch(change(NEW_USER_FORM, 'prcoAdmin', _.get(dealerRel, 'prcoAdmin', false)));
      dispatch(change(NEW_USER_FORM, 'dealerAdmin', _.get(dealerRel, 'dealerAdmin', false)));
      dispatch(change(NEW_USER_FORM, 'asnUser', asnUser || false));
      dispatch(change(NEW_USER_FORM, 'deployment', deployment));
    } else {
      dispatch(change(NEW_USER_FORM, 'deployment', DEPLOYMENT_OPTIONS[0].value));
    }
  }, [userId]);
  const { handleSubmit, error, currentUser } = props;
  const isPrcoAdmin = _.get(currentUser, 'dealerRel.prcoAdmin');
  const isUpdate = !!_.get(user, 'userId');

  return (
    <form className="form-horizontal" onSubmit={handleSubmit} noValidate={false}>
      <legend>{isUpdate ? 'Update User' : 'Create User'}</legend>
      <Field
        name="email"
        type="text"
        component={ValidationInput}
        label="Email"
        placeholder="eg. george.clinton@autoworld.com"
        validate={[required, emailValidation]}
      />
      <Field
        name="password"
        type="text"
        component={ValidationInput}
        label="Password"
        placeholder="eg. ********"
        validate={!isUpdate ? [required, minLength(8)] : undefined}
      />
      {isPrcoAdmin && (
        <Field
          name="dealerId"
          type="text"
          component={ValidationInput}
          label="Dealer ID (4 Digit)"
          placeholder="eg. 5713"
          validate={[required, exactLength(4)]}
        />
      )}
      {isPrcoAdmin && <Field name="prcoAdmin" component={ValidationCheckbox} label="PRco Admin" />}
      <Field name="dealerAdmin" component={ValidationCheckbox} label="Dealer Admin" />
      {isPrcoAdmin && <Field name="asnUser" component={ValidationCheckbox} label="ASN User" />}
      {isPrcoAdmin && (
        <Field
          name="deployment"
          component={ValidationSelect}
          label="Deployment"
          options={DEPLOYMENT_OPTIONS}
          placeholder=""
          validate={[required]}
          inputGroupClassName="col-sm-2"
        />
      )}
      {error && (
        <p className="text-danger">
          <strong>{error}</strong>
        </p>
      )}
      <div className="row">
        <div className="col-md-3 col-xs-12">
          <button className="btn btn-primary btn-block" type="submit">
            {isUpdate ? 'Update User' : 'Create User'}
          </button>
        </div>
      </div>
    </form>
  );
}

NewUserForm.propTypes = {
  ...propTypes,
  user: PropTypes.object,
  currentUser: PropTypes.object.isRequired,
};

NewUserForm.defaultProps = {
  user: null,
};

export default reduxForm({
  form: NEW_USER_FORM,
  validate: newUserValidation,
  asyncValidate: newUserFormAsyncValidation,
  asyncBlurFields: ['email'],
})(NewUserForm);
