import React from 'react';
import PropTypes from 'prop-types';
import { Field, propTypes, reduxForm } from 'redux-form';
import _ from 'lodash';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { required, money, maxNumber } from '../../../utils/validation';
import ValidationInput from '../../../components/common/ValidationInput';
import { TWP_RATE_FORM } from '../../../redux/modules/form';
import { twpRateValidator } from '../../../components/validation/rateValidation';
import Buttons from '../../../components/wizard/Buttons';
import { twpRateTestDataIfRequested } from '../../../utils/testData';
import { selectedTwpQuote } from '../../../redux/modules/newQuote';
import { receiveValuesFromQueryData } from '../../../utils/decorators';

export function TwpRateForm(props) {
  const { handleSubmit, retailPriceOfTwp, maximumLiabilityLimit, hideBack } = props;
  return (
    <form
      className="form-horizontal"
      onSubmit={handleSubmit}
      noValidate={true}
      data-test-id="TwpRateForm"
    >
      <Field
        name="twpRate.retailPriceOfTwp"
        type="text"
        component={ValidationInput}
        label="Retail Price of T&W"
        placeholder="eg. 1200.00"
        validate={[required, money, maxNumber(maximumLiabilityLimit)]}
        disabled={!!retailPriceOfTwp}
        data-test-id="TwpRateForm-retailPriceOfTwp"
      />
      <Buttons
        hideBack={hideBack}
        {..._.omit(props, 'onSubmit')}
        data-test-name="TwpRateForm-Buttons"
      />
    </form>
  );
}

TwpRateForm.propTypes = {
  ...propTypes,
  twpQuote: PropTypes.object.isRequired,
  retailPriceOfTwp: PropTypes.number,
  maximumLiabilityLimit: PropTypes.number,
  hideBack: PropTypes.bool,
};

TwpRateForm.defaultProps = {
  retailPriceOfTwp: null,
  maximumLiabilityLimit: Infinity,
  hideBack: false,
};

export const mapFromConnect = ({ newQuote }) => {
  const twpQuote = selectedTwpQuote({ newQuote });

  return {
    maximumLiabilityLimit: _.get(twpQuote, 'maximumLiabilityLimit'),
    initialValues: _.merge({}, twpRateTestDataIfRequested(), {
      twpRate: {},
    }),
  };
};

export default compose(
  connect(mapFromConnect),
  receiveValuesFromQueryData({
    retailPriceOfTwp: 'twpRate.retailPriceOfTwp',
  }),
  reduxForm({
    form: TWP_RATE_FORM,
    validate: twpRateValidator,
  }),
)(TwpRateForm);
