import React, { useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { LinkContainer } from 'react-router-bootstrap';
import { Glyphicon } from 'react-bootstrap';
import { TASK_FINISH_STATUS_COMPLETED, TASK_FINISH_STATUS_FAILED } from '../constants/task';
import { COLOR_DANGER, COLOR_SUCCESS } from '../constants/styles';
import { watchTasks } from '../redux/modules/task';

export const TasksBadgeDisplay = props => {
  const { tasksMetas = {}, watchTasks } = props;

  useEffect(() => {
    if (watchTasks) return watchTasks();
  }, [watchTasks]);

  const completedUnreadCount = useMemo(
    () =>
      Object.values(tasksMetas).filter(
        ({ finishStatus, hasBeenRead }) =>
          finishStatus === TASK_FINISH_STATUS_COMPLETED && !hasBeenRead,
      ).length,
    [tasksMetas],
  );

  const failedUnreadCount = useMemo(
    () =>
      Object.values(tasksMetas).filter(
        ({ finishStatus, hasBeenRead }) =>
          finishStatus === TASK_FINISH_STATUS_FAILED && !hasBeenRead,
      ).length,
    [tasksMetas],
  );

  if (Object.keys(tasksMetas).length === 0) {
    return null;
  }

  const unreadCount = completedUnreadCount + failedUnreadCount;
  const hasUnreadFailed = failedUnreadCount > 0;

  const completedCountDisplay = (
    <span style={{ minWidth: 8 }}>
      {unreadCount > 0 ? (
        <span>{unreadCount > 9 ? '+' : unreadCount}</span>
      ) : (
        <span style={{ opacity: 0 }}>0</span>
      )}
    </span>
  );

  let color;
  if (unreadCount) color = COLOR_SUCCESS;
  if (hasUnreadFailed) color = COLOR_DANGER;

  const opacity = color ? 1 : 0.6;

  return (
    <LinkContainer to="/tasks" style={{ fontWeight: 'bold', textDecoration: 'none' }}>
      <a>
        <span style={{ color, opacity, display: 'inline-flex', alignItems: 'end' }}>
          <Glyphicon glyph="bell" style={{ fontSize: 20, paddingRight: 2 }} />
          {completedCountDisplay}
        </span>
      </a>
    </LinkContainer>
  );
};

export const TasksBadge = connect(
  ({ task: { tasksMetas } }) => ({
    tasksMetas,
  }),
  dispatch => ({
    watchTasks: () => watchTasks(dispatch),
  }),
)(TasksBadgeDisplay);
