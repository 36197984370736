import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Helmet from 'react-helmet';
import qs from 'qs';
import _ from 'lodash';
import { destroy, getFormSyncErrors, getFormValues } from 'redux-form';
import Eula from './Eula';
import VehicleBasicInformation from './VehicleBasicInformation';
import NewQuoteBreadcrumb from './NewQuoteBreadcrumb';
import QuoteMetaForm from './QuoteMetaForm';
import QuoteSummary from './summary/QuoteSummary';
import FinalQuote from './summary/FinalQuote';
import { clearAllMessages, clearAllErrors } from '../../redux/modules';
import { storeNewQuoteFormValues } from '../../redux/modules/newQuote';
import { getDealer } from '../../redux/modules/dealer';
import { getDealerSettings } from '../../redux/modules/dealerSettings';
import {
  NEW_QUOTE_META_FORM,
  QUOTE_SUMMARY_FORM,
  VEHICLE_BASIC_INFORMATION_FORM,
} from '../../redux/modules/form';
import NotificationModal from '../../components/common/NotificationModal';

const V2_QUOTE_WHITE_LIST_DEALERS = [2240];

/**
 * Some pages can preserve their state after unmount.
 * The `destroyState` callbacks should be called
 * when the state is no longer needed.
 */
const NEW_QUOTE_PAGES = [
  {
    page: Eula,
    name: 'User Agreement',
  },
  {
    page: QuoteMetaForm,
    name: 'Choose Quote',
    formName: NEW_QUOTE_META_FORM,
    props: { destroyOnUnmount: false },
    destroyState: ({ dispatch }) => dispatch(destroy(NEW_QUOTE_META_FORM)),
  },
  {
    page: VehicleBasicInformation,
    name: 'Vehicle Information',
    formName: VEHICLE_BASIC_INFORMATION_FORM,
    props: { destroyOnUnmount: false },
    destroyState: ({ dispatch }) => dispatch(destroy(VEHICLE_BASIC_INFORMATION_FORM)),
  },
  {
    page: QuoteSummary,
    name: 'Summary',
    formName: QUOTE_SUMMARY_FORM,
    props: { destroyOnUnmount: false },
    destroyState: ({ dispatch }) => dispatch(destroy(QUOTE_SUMMARY_FORM)),
    resetCompleteWhenPreviousPageChanges: true,
  },
  {
    page: FinalQuote,
    name: 'Final Quote',
    disable: true,
    resetCompleteWhenPreviousPageChanges: true,
  },
];

const hasNonCompletedPagesBefore = completedPages => page => {
  for (let i = 0; i < page; i += 1) if (!completedPages.includes(i)) return true;

  return false;
};

export function NewQuote(props) {
  const [page, setPage] = useState(0);
  const [confirmMoveToPage, setConfirmMoveToPage] = useState(null);
  const [completedPages, setCompletedPages] = useState([]);

  useEffect(() => {
    const { getDealer, getDealerSettings } = props;
    getDealer();
    getDealerSettings();
    return () => {
      const { dispatch } = props;
      getPages().forEach(({ destroyState }) => {
        if (destroyState) destroyState({ dispatch });
      });
    };
  }, []);

  const getPages = () => NEW_QUOTE_PAGES;

  const isPageValid = page => {
    const { formState } = props;

    const { formName } = getPages()[page] || {};
    if (!formName) return true;

    return _.isEmpty(getFormSyncErrors(formName, _.identity)(formState));
  };

  const refreshCompletedPages = (changedPage, completed) => {
    // Some pages can require reset its completion state
    // when a page before it changes.
    // Here we filtering out such pages.
    const pages = getPages();
    const newCompletedPages = completedPages.filter(completedPage => {
      // Currently changed page is after one
      if (completedPage < changedPage) return true;

      // We'll add it back if it's completed later
      if (completedPage === changedPage) return false;

      return !pages[completedPage].resetCompleteWhenPreviousPageChanges;
    });

    // Adding page itself if it's completed
    if (completed) newCompletedPages.push(changedPage);

    setCompletedPages(_.uniq(newCompletedPages));
  };

  const hasInvalidPagesBefore = page => {
    for (let i = 0; i < page; i += 1) if (!isPageValid(i)) return true;

    return false;
  };

  const getPageData = page => {
    const { formState } = props;

    const { formName } = getPages()[page] || {};
    if (!formName) return {};

    return getFormValues(formName, _.identity)(formState);
  };

  const switchToPageHandler = page => setPage(page);

  const storePageDataAndSwitchTo = ({ currentPage, switchToPage }) => {
    const valid = isPageValid(currentPage);

    const data = getPageData(currentPage);
    if (valid) storeData(currentPage, data);

    refreshCompletedPages(currentPage, valid);

    switchToPageHandler(switchToPage);
  };

  const handleMoveToPage = moveToPage => {
    const currentPage = page;

    if (moveToPage === null) return undefined;

    const isValid = isPageValid(currentPage);
    if (isValid) storePageDataAndSwitchTo({ currentPage, switchToPage: moveToPage });
    else setConfirmMoveToPage(moveToPage);
  };

  const handleCancelMoveToPage = () => setConfirmMoveToPage(null);

  const handleConfirmMoveToPage = () => {
    const currentPage = page;

    if (confirmMoveToPage === null) return undefined;

    setConfirmMoveToPage(null);

    storePageDataAndSwitchTo({ currentPage, switchToPage: confirmMoveToPage });
  };

  const renderMoveToPageConfirmation = () => (
    <NotificationModal
      show={true}
      onConfirm={handleConfirmMoveToPage}
      onCancel={handleCancelMoveToPage}
      title="Confirm leaving the form"
      confirmText="Continue"
      cancelText="Cancel"
    >
      <p>There are remaining items to&nbsp;complete on&nbsp;nbsp;form.</p>
      <p>
        If you continue, you will have to&nbsp;return before&nbsp;submission to&nbsp;complete
        these&nbsp;items
      </p>
    </NotificationModal>
  );

  const getQueryData = () => qs.parse(props.location.search, { ignoreQueryPrefix: true });

  const storeData = (page, data) => {
    const { location, router } = props;

    setData(data);

    refreshCompletedPages(page, true);

    const combined = { ...getQueryData(), ...data };
    router.push(`${location.pathname}?${qs.stringify(combined)}`);

    return combined;
  };

  const setData = data => {
    const { storeNewQuoteFormValues } = props;

    storeNewQuoteFormValues(data);
  };

  const handleSubmit = data => {
    const { router, clearAllErrors, clearAllMessages, dealerInfo } = props;

    const queryData = storeData(page, data);

    if (page === 0 && V2_QUOTE_WHITE_LIST_DEALERS.includes(+dealerInfo.id)) {
      router.push(`/v2/quote?${qs.stringify(queryData)}`);
    } else if (page < getPages().length - 1) {
      switchToPageHandler(page + 1);
    } else {
      router.push(`/rate?${qs.stringify(queryData)}`);
    }

    clearAllErrors();
    clearAllMessages();
  };

  const handleNavigateToDashboard = () => {
    const { router } = props;
    router.push('/');
  };

  const handleDestructiveChangeToQuote = () => {
    const { location, router } = props;
    // Ensures the removal of all query params that vary with quotes
    const queryData = qs.parse(location.search, { ignoreQueryPrefix: true });
    router.push(
      `${location.pathname}?${qs.stringify(
        _.pick(queryData, [
          'quoteType',
          'vehicleCondition',
          'commercialType',
          'vin',
          'vehicleMileage',
          'vehiclePurchasePrice',
        ]),
      )}`,
    );
  };
  const queryData = qs.parse(props.location.search, { ignoreQueryPrefix: true });

  const pages = getPages();
  const { page: PageComponent, props: pageProps } = pages[page] || {};
  const pageDisplay = !!PageComponent && (
    <PageComponent
      key={page}
      goBack={_.partial(handleMoveToPage, page - 1)}
      navigateToDashboard={handleNavigateToDashboard}
      queryData={queryData}
      onSubmit={handleSubmit}
      onDestructiveQuoteChange={handleDestructiveChangeToQuote}
      {...props}
      {...pageProps}
      hasInvalidPagesBefore={hasInvalidPagesBefore(page)}
      onChange={() => {
        refreshCompletedPages(page);
      }}
    />
  );

  const moveToPageConfirmation = confirmMoveToPage !== null && renderMoveToPageConfirmation();

  /**
   * This should be new for each render,
   * so when completedPages change, the function changes too.
   */
  const isPageDisabled = hasNonCompletedPagesBefore(completedPages);

  return (
    <div className="container">
      <Helmet title="New Quote" />
      <NewQuoteBreadcrumb
        pages={pages}
        moveToPage={handleMoveToPage}
        pageNo={page}
        isPageValid={isPageValid}
        isPageDisabled={isPageDisabled}
      />
      {pageDisplay}
      {moveToPageConfirmation}
    </div>
  );
}

NewQuote.propTypes = {
  location: PropTypes.object.isRequired,
  router: PropTypes.object.isRequired,
  getDealer: PropTypes.func.isRequired,
  getDealerSettings: PropTypes.func.isRequired,
  storeNewQuoteFormValues: PropTypes.func.isRequired,
  formState: PropTypes.object.isRequired,
  dealerInfo: PropTypes.object.isRequired,
};

export default connect(
  ({ form, dealer }) => ({
    formState: form,
    dealerInfo: _.get(dealer, 'details'),
  }),
  {
    getDealer,
    getDealerSettings,
    storeNewQuoteFormValues,
    clearAllErrors,
    clearAllMessages,
  },
)(NewQuote);
