import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { connect } from 'react-redux';
import { reduxForm, Field } from 'redux-form';
import { Modal, Button, Row, Col } from 'react-bootstrap';
import { getMessaging, updateMessaging } from '../../redux/modules/messaging';
import { UPDATE_MESSAGING_MODAL_FORM } from '../../redux/modules/form';
import ValidationTextarea from '../../components/common/ValidationTextarea';

const Form = reduxForm({
  form: UPDATE_MESSAGING_MODAL_FORM,
})(({ onCancel, handleSubmit }) => (
  <form
    className="form-horizontal update-messaging-modal-form"
    onSubmit={handleSubmit}
    noValidate={true}
  >
    <Modal.Body>
      <Field name="text" component={ValidationTextarea} label="Text" />
    </Modal.Body>
    <Modal.Footer>
      <Row>
        <Col xs={8}>
          <Button bsStyle="primary" type="submit" block>
            Save
          </Button>
        </Col>
        <Col xs={4}>
          <Button onClick={onCancel} bsStyle="default" block>
            Cancel
          </Button>
        </Col>
      </Row>
    </Modal.Footer>
  </form>
));

export function UpdateMessagingModal(props) {
  const handleSubmit = async data => {
    const { messaging, updateMessaging, getMessaging, onComplete } = props;
    await updateMessaging(messaging._id, { text: data.text });
    await getMessaging();
    onComplete();
  };
  const { messaging, onComplete } = props;

  return (
    <Modal show={!!messaging}>
      <Modal.Header>
        <Modal.Title>Update {_.get(messaging, 'name')}</Modal.Title>
      </Modal.Header>
      <Form
        initialValues={{ text: _.get(messaging, 'text') }}
        onSubmit={handleSubmit}
        onCancel={onComplete}
      />
    </Modal>
  );
}

UpdateMessagingModal.propTypes = {
  messaging: PropTypes.object.isRequired,
  onComplete: PropTypes.func.isRequired,
  updateMessaging: PropTypes.func.isRequired,
  getMessaging: PropTypes.func.isRequired,
};

export default connect(null, {
  getMessaging,
  updateMessaging,
})(UpdateMessagingModal);
