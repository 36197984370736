import React, { useCallback, useMemo } from 'react';
import _ from 'lodash';
import moment from 'moment';
import { Button, Col, Glyphicon, Grid, Row } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { LinkContainer } from 'react-router-bootstrap';
import classNames from 'classnames';
import { deleteTask, markTaskAsRead, markTaskAsUnread } from '../redux/modules/task';
import './Tasks.css';

export const TasksDisplay = props => {
  const { tasksMetas = {}, markTaskAsRead, markTaskAsUnread } = props;

  const metasList = useMemo(
    () => _.sortBy(Object.values(tasksMetas), 'createTimestamp'),
    [tasksMetas],
  );

  const handleHasBeenReadToggle = useCallback(
    taskId => read => () => {
      if (read) {
        markTaskAsRead(taskId);
      } else {
        markTaskAsUnread(taskId);
      }
    },
    [],
  );

  const handleDelete = useCallback(
    taskId => () => {
      deleteTask(taskId);
    },
    [],
  );

  const displayItems = metasList
    .map(taskMeta => (
      <TaskDisplay
        key={taskMeta.taskId}
        taskMeta={taskMeta}
        onHasBeenReadToggle={handleHasBeenReadToggle(taskMeta.taskId)}
        onDelete={handleDelete(taskMeta.taskId)}
      />
    ))
    .reverse();

  const placeholder = displayItems.length === 0 && (
    <div style={{ color: 'grey', fontStyle: 'italic', textAlign: 'center' }}>
      You have no notifications.
    </div>
  );

  return (
    <div className="container">
      <Helmet title="Tasks" />
      <Grid>
        <Row>
          <Col xsOffset={2} xs={8}>
            {placeholder}
            {displayItems}
          </Col>
        </Row>
      </Grid>
    </div>
  );
};

const TaskDisplay = props => {
  const { taskMeta, onHasBeenReadToggle, onDelete } = props;

  const {
    taskId,
    taskType,
    taskTitle,
    finishStatus,
    hasBeenRead,
    createTimestamp,
    finishTimestamp,
  } = taskMeta;

  const created = createTimestamp && moment(createTimestamp).fromNow();
  const finished = finishTimestamp && moment(finishTimestamp).fromNow();

  const title = [created && `Created: ${created}`, finished && `Finished: ${finished}`]
    .filter(s => !!s)
    .join('\n');

  const createDisplay = created && (
    <span className="Tasks-task-created" title={title}>
      {created}
    </span>
  );

  return (
    <div
      key={taskId}
      className={classNames(
        'Tasks-task',
        !finishStatus && `Tasks-task-inProgress`,
        finishStatus && `Tasks-task-finished`,
        finishStatus && `Tasks-task-finished-${finishStatus}`,
        !!finishStatus && !!hasBeenRead && `Tasks-task-hasBeenRead`,
        !!finishStatus && !hasBeenRead && `Tasks-task-hasNotBeenRead`,
      )}
    >
      <div
        className="Tasks-hasBeenReadToggle"
        onClick={finishStatus ? onHasBeenReadToggle(!hasBeenRead) : undefined}
      >
        <div className="Tasks-hasBeenReadToggle-circle" />
      </div>
      <LinkContainer to={`/tasks/${taskType}/${taskId}`}>
        <div className="Tasks-task-main">
          <div className="Tasks-task-title">{taskTitle}</div>
          {createDisplay}
        </div>
      </LinkContainer>
      <div className="Tasks-task-delete">
        <Button onClick={onDelete} bsStyle={finishStatus && hasBeenRead ? 'link' : 'danger'}>
          <Glyphicon glyph="trash" />
        </Button>
      </div>
    </div>
  );
};

export const Tasks = connect(
  ({ task: { tasksMetas } }) => ({
    tasksMetas,
  }),
  {
    markTaskAsRead,
    markTaskAsUnread,
  },
)(TasksDisplay);
