import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { connect } from 'react-redux';
import { Grid, Row, Col, Button, ProgressBar } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { LinkContainer } from 'react-router-bootstrap';
import { loadQueryParams } from '../redux/modules/queryParams';
import HomeContent from '../components/HomeContent';
import { AuthContext } from '../components/Auth';

export function Home(props) {
  const { user, isLoading } = props;
  const { login } = useContext(AuthContext);

  if (isLoading) {
    return <ProgressBar now={100} active={true} />;
  }

  if (!user) {
    return (
      <div>
        <Helmet title="Home" />

        <Grid>
          <Row>
            <Col md={6} xs={12} mdOffset={3}>
              <Button bsStyle="primary" bsSize="large" block onClick={login}>
                Login
              </Button>
            </Col>
            {
              // @deprecated TODO delete when Auth0 migration is over.
              // TODO if it's not 2021, it's time to delete it.
            }
            <Col md={6} xs={12} mdOffset={3} style={{ marginTop: '2em', textAlign: 'right' }}>
              <LinkContainer to="/MigrateFrom219ApiToAuth0">
                <Button bsStyle="link">Migrate account</Button>
              </LinkContainer>
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }

  return <HomeContent />;
}

Home.propTypes = {
  user: PropTypes.object,
};

Home.defaultProps = {
  user: null,
  queryParamsLoaded: false,
  loadQueryParams: _.noop,
};

export default connect(
  ({ auth }) => ({
    user: auth.user,
    isLoading: auth.isLoading,
  }),
  {
    loadQueryParams,
  },
)(Home);
