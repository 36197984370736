import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose, withState } from 'recompose';
import _ from 'lodash';
import { Button, Row, Col, Glyphicon } from 'react-bootstrap';
import {
  selectedGapQuote,
  selectedTwpQuote,
  selectedVscQuote,
  setSignatureFlow,
} from '../../../redux/modules/newQuote';
import { createSavedQuote } from '../../../redux/modules/savedQuote';
import PlanTable from './PlanTable';
import NotificationModal from '../../../components/common/NotificationModal';
import {
  CONTRACT_TYPE_GAP,
  CONTRACT_TYPE_TWP,
  CONTRACT_TYPE_VSC,
} from '../../../constants/contractType';

const FOOTER_BUTTON_STYLE = { marginRight: '5px' };

export function FinalQuote(props) {
  const {
    vin,
    quoteRecord,
    vscQuote,
    gapQuote,
    twpQuote,
    confirmingSaveQuote,
    extraCoverages,
    setConfirmingSaveQuote,
    dealer,
    hasInvalidPagesBefore,
    dealerSettings,
  } = props;

  const printInfoRef = useRef();

  const handlePrint = () => {
    const content = printInfoRef.current?.innerHTML;
    if (!content) return undefined;

    const win = window.open('', 'Quote', 'height=800,width=600');

    win.document.write(`<html><head><title>Quote for ${vin}</title>`);
    win.document.write('</head><body >');
    win.document.write(content);
    win.document.write('</body></html>');
    win.document.close();

    win.focus();
    win.print();
  };

  const handleSaveQuote = () => {
    const { quoteRecord, createSavedQuote, setConfirmingSaveQuote } = props;
    createSavedQuote(quoteRecord).then(() => setConfirmingSaveQuote(true));
  };

  const handleProceedPrinted = () => {
    const { router, setSignatureFlow } = props;
    setSignatureFlow('printed');
    router.push(`/v2/rate`);
  };

  const handleProceedESign = () => {
    const { router, setSignatureFlow } = props;
    setSignatureFlow('eSign');
    router.push(`/v2/rate`);
  };

  const navigateToDashboard = () => {
    const { router } = props;
    router.push('/');
  };

  const coverages = [
    ..._.get(vscQuote, 'coverages', []).filter(c => !c.selectable),
    ...extraCoverages,
  ];
  const { eSignRequired } = dealer || {};

  return (
    <div>
      <div style={{ display: 'none' }}>
        <div ref={printInfoRef}>
          <h5>This vehicle qualifies as of {new Date().toLocaleString()} </h5>
          {vscQuote && (
            <PlanTable
              contractType={CONTRACT_TYPE_VSC}
              quote={vscQuote}
              request={quoteRecord}
              otherCoverages={coverages}
              dealerSettings={dealerSettings}
            />
          )}
          {gapQuote && (
            <PlanTable
              contractType={CONTRACT_TYPE_GAP}
              quote={gapQuote}
              request={quoteRecord}
              dealerSettings={dealerSettings}
            />
          )}
          {twpQuote && (
            <PlanTable
              contractType={CONTRACT_TYPE_TWP}
              quote={twpQuote}
              request={quoteRecord}
              dealerSettings={dealerSettings}
            />
          )}
        </div>
      </div>
      <div data-test-id="FinalQuote">
        <Row style={{ marginTop: 16 }}>
          <Col md={6} xs={12}>
            <Button
              bsStyle="default"
              className="ellipsis"
              onClick={handlePrint}
              style={FOOTER_BUTTON_STYLE}
            >
              Print Quote Info
            </Button>
            <Button
              bsStyle="info"
              className="ellipsis"
              onClick={handleSaveQuote}
              style={FOOTER_BUTTON_STYLE}
              disabled={hasInvalidPagesBefore}
              data-test-id="FinalQuote-save"
            >
              Save Quote
            </Button>
          </Col>
          <Col md={6} xs={12}>
            <Button
              bsStyle="link"
              className="ellipsis"
              onClick={() => alert('survey')}
              block
              style={{ fontSize: '18px' }}
            >
              Customer Satisfaction Survey
            </Button>
          </Col>
        </Row>
        <Row>
          <Col sm={12} style={{ display: 'flex', justifyContent: 'center', gap: 20 }}>
            <Button
              bsStyle="primary"
              bsSize="large"
              onClick={handleProceedESign}
              style={FOOTER_BUTTON_STYLE}
              disabled={hasInvalidPagesBefore}
              data-test-id="FinalQuote-proceed-eSign"
            >
              <Glyphicon glyph="phone" /> eSign Contract
            </Button>
            &nbsp;
            {!eSignRequired && (
              <Button
                bsStyle="primary"
                bsSize="large"
                onClick={handleProceedPrinted}
                style={FOOTER_BUTTON_STYLE}
                disabled={hasInvalidPagesBefore || eSignRequired}
                data-test-id="FinalQuote-proceed-non-eSign"
              >
                <Glyphicon glyph="print" /> Printed Contract
              </Button>
            )}
          </Col>
        </Row>
        {
          <NotificationModal
            show={confirmingSaveQuote}
            title="Quote Saved"
            message="This quote has been saved. It's available in the dashboard via the 'All Quotes & Contracts' section. Where would you like to go?"
            confirmText="Return to Dashboard"
            cancelText="Continue"
            onConfirm={navigateToDashboard}
            onCancel={() => setConfirmingSaveQuote(false)}
            data-test-name="FinalQuote-saveModal"
          />
        }
      </div>
    </div>
  );
}

FinalQuote.propTypes = {
  vin: PropTypes.string.isRequired,
  router: PropTypes.object.isRequired,
  createSavedQuote: PropTypes.func.isRequired,
  setConfirmingSaveQuote: PropTypes.func.isRequired,
  quoteRecord: PropTypes.object.isRequired,
  dealer: PropTypes.object.isRequired,
  vscQuote: PropTypes.object,
  gapQuote: PropTypes.object,
  twpQuote: PropTypes.object,
  extraCoverages: PropTypes.array,
  confirmingSaveQuote: PropTypes.bool,
  dealerSettings: PropTypes.object,
};

FinalQuote.defaultProps = {
  vscQuote: null,
  gapQuote: null,
  twpQuote: null,
  extraCoverages: null,
  confirmingSaveQuote: false,
  dealerSettings: null,
};

export default compose(
  withState('confirmingSaveQuote', 'setConfirmingSaveQuote', false),
  connect(
    (state, props) => {
      const newQuote = _.get(state, 'newQuote');
      const { quoteSubmissionValues, router, location } = props;
      newQuote.form = quoteSubmissionValues;
      const vscQuote = selectedVscQuote({ newQuote });
      const gapQuote = selectedGapQuote({ newQuote });
      const twpQuote = selectedTwpQuote({ newQuote });
      return {
        vin: _.get(newQuote, 'form.vin'),
        dealer: _.get(state, 'dealer.details'),
        commercialType: _.get(newQuote.ratingQuoteRequest, 'commercialType'),
        extraCoverages: _.values(newQuote.extraCoverages),
        vscQuote,
        gapQuote,
        twpQuote,
        dealerSettings: _.get(state, 'dealerSettings.data'),
        quoteRecord: quoteSubmissionValues,
        router,
        location,
      };
    },
    {
      createSavedQuote,
      setSignatureFlow,
    },
  ),
)(FinalQuote);
