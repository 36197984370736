import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Row, Col, Checkbox, Alert } from 'react-bootstrap';
import _ from 'lodash';
import { STATUSES } from '../../constants/issuedContract';
import {
  contractTypesAll,
  contractTypeTitle,
  CONTRACT_TYPE_VSC,
} from '../../constants/contractType';
import NotificationModal from '../../components/common/NotificationModal';

const CopyDialog = props => {
  const { onCopyQuote, defaultContractTypes, commercialUse, show, onShowChange } = props;

  const [selectedContractTypes, setSelectedContractTypes] = useState(defaultContractTypes);
  useEffect(() => {
    if (!show) setSelectedContractTypes(defaultContractTypes);
  }, [show, defaultContractTypes]);

  const handleToggleContractType = contractType => () =>
    setSelectedContractTypes(
      selectedContractTypes.includes(contractType)
        ? selectedContractTypes.filter(t => t !== contractType)
        : [...selectedContractTypes, contractType],
    );

  const contractTypeCheckboxes = contractTypesAll.map(contractType => (
    <Checkbox
      key={contractType}
      onChange={handleToggleContractType(contractType)}
      checked={selectedContractTypes.includes(contractType)}
    >
      {contractTypeTitle(contractType)}
    </Checkbox>
  ));

  const selectedOnlyVSC =
    selectedContractTypes.length === 1 && selectedContractTypes[0] === CONTRACT_TYPE_VSC;

  const commercialTypeChangeAlert = commercialUse &&
    selectedContractTypes.length > 0 &&
    !selectedOnlyVSC && (
      <Alert className="alert-success">New contract copy will be started as non-commercial</Alert>
    );

  return (
    <NotificationModal
      onConfirm={() => onCopyQuote(selectedContractTypes)}
      onCancel={() => onShowChange(false)}
      disabledConfirm={selectedContractTypes.length === 0}
      show={show}
      title="Copy Quote"
    >
      Select contract types to copy:
      {contractTypeCheckboxes}
      {commercialTypeChangeAlert}
    </NotificationModal>
  );
};

function IssuedContractControls({
  status,
  vscContract,
  gapContract,
  twpContract,
  commercialUse,
  downloadContractType,
  copyQuote,
  signContracts,
  openUpdateSignersModal,
  deleteIssuedContract,
  showDelete = false,
}) {
  const contractTypeInfos = {
    vscContract,
    gapContract,
    twpContract,
  };

  const contractTypes = contractTypesAll.filter(
    contractType => !!contractTypeInfos[`${_.toLower(contractType)}Contract`],
  );

  const [showCopyDialog, setShowCopyDialog] = useState(false);
  const copyDialog = (
    <CopyDialog
      show={showCopyDialog}
      onShowChange={setShowCopyDialog}
      defaultContractTypes={contractTypes}
      commercialUse={commercialUse}
      onCopyQuote={selectedContractTypes => {
        setShowCopyDialog(false);
        copyQuote(selectedContractTypes);
      }}
    />
  );

  const isESignComplete =
    status === STATUSES.DEALER_E_SIGN_COMPLETE_STATUS ||
    status === STATUSES.DEALER_E_SIGN_ARCHIVED_STATUS;
  return (
    <div className="text-center">
      {contractTypes.map(contractType => (
        <p>
          <a onClick={() => downloadContractType(contractType)}>
            {isESignComplete ? 'Signed' : 'Unsigned'} {contractTypeTitle(contractType)} Contract
            (Download)
          </a>
        </p>
      ))}
      {STATUSES.E_SIGN_IN_PROCESS_STATUS(status) && (
        <Row className="margin-bottom-10px">
          <Col xs={12}>
            <Button bsStyle="primary" block onClick={signContracts}>
              Sign
            </Button>
          </Col>
        </Row>
      )}
      {STATUSES.E_SIGN_IN_PROCESS_STATUS(status) && (
        <Row className="margin-bottom-10px">
          <Col xs={12}>
            <Button bsStyle="primary" block onClick={openUpdateSignersModal}>
              Update
            </Button>
          </Col>
        </Row>
      )}
      <Row>
        <Col xs={showDelete ? 6 : 12}>
          {copyDialog}
          <Button bsStyle="primary" block onClick={() => setShowCopyDialog(true)}>
            Copy
          </Button>
        </Col>
        {showDelete && (
          <Col xs={6}>
            <Button bsStyle="danger" block onClick={deleteIssuedContract}>
              Delete
            </Button>
          </Col>
        )}
      </Row>
    </div>
  );
}

IssuedContractControls.propTypes = {
  status: PropTypes.string.isRequired,
  vscContract: PropTypes.object,
  gapContract: PropTypes.object,
  twpContract: PropTypes.object,
  downloadContractType: PropTypes.func.isRequired,
  copyQuote: PropTypes.func.isRequired,
  signContracts: PropTypes.func.isRequired,
  openUpdateSignersModal: PropTypes.func.isRequired,
  deleteIssuedContract: PropTypes.func.isRequired,
  showDelete: PropTypes.bool,
};

IssuedContractControls.defaultProps = {
  vscContract: null,
  gapContract: null,
  twpContract: null,
  showDelete: false,
};

export default IssuedContractControls;
