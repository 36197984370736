const DEALER_E_SIGN_COMPLETE_STATUS = 'E-SIGN COMPLETED';
const DEALER_E_SIGN_ARCHIVED_STATUS = 'E-SIGN ARCHIVED';

export const STATUSES = {
  DEALER_E_SIGN_COMPLETE_STATUS,
  DEALER_E_SIGN_ARCHIVED_STATUS,
  DEALER_NON_E_SIGN_STATUS: 'DEALER NON E-SIGN',
  E_SIGN_IN_PROCESS_STATUS: value =>
    value !== DEALER_E_SIGN_COMPLETE_STATUS &&
    value !== DEALER_E_SIGN_ARCHIVED_STATUS &&
    /^E-SIGN/.test(value),
  UNKNOWN_STATUS: 'UNKNOWN',
};

export const ROLES = {
  CUSTOMER: 'Customer',
  CO_BUYER: 'Co-Buyer',
  DEALER: 'Dealer',
};
