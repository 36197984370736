import { useEffect, useState } from 'react';

/**
 * Calls `onComplete` once, when task is completed before the eyes of the user.
 * @param {import('../../../../server/tasks/Driver').TaskMeta} taskMeta
 * @param {() => void} onComplete
 */
export const useOnTaskComplete = (taskMeta, onComplete) => {
  const { taskId, finishStatus } = taskMeta || {};

  const [waitingCompletion, setWaitingCompletion] = useState(finishStatus ? undefined : taskId);
  const [completionDetected, setCompletionDetected] = useState();

  useEffect(() => {
    if (waitingCompletion && taskId !== waitingCompletion) {
      setWaitingCompletion(undefined);
      return undefined;
    }

    if (completionDetected && taskId !== completionDetected) {
      setCompletionDetected(undefined);
      return undefined;
    }

    if (!taskId) return undefined;

    if (finishStatus && waitingCompletion) {
      setCompletionDetected(finishStatus === 'completed' ? taskId : undefined);
      setWaitingCompletion(undefined);
    }

    if (!finishStatus && !waitingCompletion) {
      setCompletionDetected(undefined);
      setWaitingCompletion(taskId);
    }
  }, [waitingCompletion, completionDetected, finishStatus, taskId]);

  useEffect(() => {
    if (!taskId || !completionDetected || !onComplete) return undefined;
    if (taskId !== completionDetected) return undefined;

    onComplete();
    setCompletionDetected(undefined);
  }, [taskId, completionDetected, onComplete]);
};
