import React, { Fragment, useReducer } from 'react';
import moment from 'moment';
import { Alert, Button } from 'react-bootstrap';

const isTaskEventGoFront = taskEvent => {
  const { eventType } = taskEvent;

  if (eventType === 'TaskEventLog') {
    const { finePrint } = taskEvent;
    return !finePrint;
  }

  if (eventType === 'TaskEventStepResult') {
    const { error } = taskEvent;
    return !!error;
  }

  if (eventType === 'TaskEventResult') {
    return false;
  }

  return true;
};

export const TaskEventDisplay = props => {
  const { taskEvent } = props;

  const { eventType, eventTimestamp } = taskEvent;

  const timeDisplay = (
    <div style={{ fontSize: 'small' }}>{moment(eventTimestamp).format('LLLL')}</div>
  );

  if (eventType === 'TaskEventLog') {
    const { meaning, message, error } = taskEvent;

    let bsStyle = 'info';
    if (meaning === 'error') bsStyle = 'danger';
    else if (meaning === 'success') bsStyle = 'success';

    return (
      <Alert bsStyle={bsStyle}>
        {message}
        <div>{error && error.message}</div>
        {timeDisplay}
      </Alert>
    );
  }

  if (eventType === 'TaskEventStepResult') {
    const { title, completed, error, errorId } = taskEvent;
    return (
      <Alert bsStyle={error ? 'danger' : completed ? 'success' : 'info'}>
        Step "{title}" execution {error ? 'error' : completed ? 'success' : 'result'}
        {!!completed && <div>Step completed</div>}
        {error && error.message && <blockquote>{error.message}</blockquote>}
        {!!errorId && <div>Error ID: {errorId}</div>}
        {timeDisplay}
      </Alert>
    );
  }

  if (eventType === 'TaskResult') {
    return (
      <Alert bsStyle={'success'}>
        Task results obtained
        {timeDisplay}
      </Alert>
    );
  }

  return (
    <Alert bsStyle={'info'}>
      Event: {eventType}
      {timeDisplay}
    </Alert>
  );
};

export const TaskEventDisplayList = props => {
  const { taskEvents = [], detailed = false, frontLimit = 5 } = props;

  if (detailed)
    return taskEvents.map(taskEvent => (
      <TaskEventDisplay key={taskEvent.eventId} taskEvent={taskEvent} detailed={detailed} />
    ));

  // If detailed view is off, we'll need to display only some events on front.

  // Picking events worth displaying
  const taskEventsToFront = taskEvents.filter(isTaskEventGoFront);

  // Limiting the front list
  const taskEventsToFrontLimited = taskEventsToFront.slice(-frontLimit);
  const taskEventsDisplays = taskEventsToFrontLimited.map(taskEvent => (
    <TaskEventDisplay key={taskEvent.eventId} taskEvent={taskEvent} detailed={detailed} />
  ));

  const hiddenNotice = taskEventsDisplays.length < taskEventsToFront.length && (
    <i>Some events are hidden</i>
  );

  return (
    <Fragment>
      {hiddenNotice}
      {taskEventsDisplays}
    </Fragment>
  );
};

export const TaskEventsList = props => {
  const [detailed, toggleDetailed] = useReducer(prev => !prev, false);

  return (
    <div>
      <TaskEventDisplayList detailed={detailed} toggleDetailed={toggleDetailed} {...props} />
      <div style={{ textAlign: 'right' }}>
        <Button bsStyle="link" onClick={toggleDetailed}>
          {detailed ? 'Hide details' : 'Show details'}
        </Button>
      </div>
    </div>
  );
};
