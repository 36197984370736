import React from 'react';
import Confirm from 'react-confirm-bootstrap';

export default function ConfirmationModal(props) {
  const message = 'Your CSV file has been uploaded successfully';
  return (
    <Confirm
      onConfirm={props.onConfirm}
      body={props.message ? props.message : message}
      confirmText="Ok"
      cancelText="Dismiss"
      title="File uploaded"
      confirmBSStyle="primary"
      visible={props.visible}
    >
      <div> </div>
    </Confirm>
  );
}
