/* eslint-disable react/sort-comp */
/* eslint-disable camelcase */
/* eslint-disable no-return-assign */
/* eslint-disable no-unused-vars */
/* eslint-disable no-unused-expressions */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { reduxForm, Field, propTypes, change, FieldArray } from 'redux-form';
import _ from 'lodash';
import Helmet from 'react-helmet';
import { Button, Grid, Row, Col } from 'react-bootstrap';
import { VERIFICATION_OVERRIDE_FORM } from '../redux/modules/form';
import ValidationInput from '../components/common/ValidationInput';
import ValidateSelect from '../components/common/ValidationSelect';
import {
  deleteVerificationOverride,
  getVerificationOverride,
  postVerificationOverride,
} from '../redux/modules/verificationOverride';

const Loan_Amount = 'loanAmount';
let saveDisable = true;
let renderFalse = true;
const FIELD_OVERRIDE_OPTIONS = [
  {
    value: Loan_Amount,
    label: 'Loan Amount',
  },
];
export class VerificationOverride extends Component {
  static propTypes = {
    ...propTypes,
    getVerificationOverride: PropTypes.func.isRequired,
    deleteVerificationOverride: PropTypes.func.isRequired,
  };

  componentDidMount() {
    const { getVerificationOverride } = this.props;
    getVerificationOverride();
  }

  componentWillReceiveProps(nextProps) {
    const { dispatch } = nextProps;
    if (renderFalse) {
      dispatch(
        change(
          VERIFICATION_OVERRIDE_FORM,
          'verificationOverride',
          _.get(nextProps, 'verificationOverride'),
        ),
      );
    }
  }

  handleDelete(e, index) {
    saveDisable = false;
    const { deleteVerificationOverride, verificationOverride } = this.props;
    if (verificationOverride && verificationOverride.length) {
      if (verificationOverride[index]) {
        deleteVerificationOverride(verificationOverride[index]._id);
      }
    }
  }

  render() {
    const { handleSubmit, loading } = this.props;
    const renderMembers = ({ fields, meta: { error, submitFailed } }) => (
      <ul>
        {fields && fields.length
          ? fields.map((verificationOverride, index) => (
              <Col xs={12} id={index}>
                <Col xs={5}>
                  <Field
                    name={`${verificationOverride}.vin`}
                    type="string"
                    component={ValidationInput}
                    label="VIN"
                    onChange={() => (saveDisable = false)}
                  />
                </Col>
                <Col xs={5}>
                  <Field
                    name={`${verificationOverride}.fieldOverrides`}
                    type="number"
                    component={ValidateSelect}
                    options={FIELD_OVERRIDE_OPTIONS}
                    multiple={true}
                    style={{ height: 34 }}
                    label="Override:"
                    multi
                  />
                </Col>
                <Col xs={2}>
                  <Button
                    bsStyle="primary"
                    style={{ backgroundColor: '#932525' }}
                    onClick={e => {
                      this.handleDelete(e, index);
                      fields.remove(index);
                    }}
                  >
                    Delete
                  </Button>
                </Col>
              </Col>
            ))
          : ''}
        <Col xs={1} style={{ display: 'flex', justifyContent: 'end' }}>
          <Button
            bsStyle="primary"
            onClick={() => {
              fields.push({ vin: '', fieldOverrides: [Loan_Amount] });
              fields.length === undefined &&
                fields.push({ vin: '', fieldOverrides: [Loan_Amount] });
              saveDisable = false;
              renderFalse = false;
            }}
          >
            Add
          </Button>
        </Col>
      </ul>
    );
    return (
      <Grid>
        <Row>
          <Col xs={12}>
            <Helmet title="Verification Override" />
            {!loading && (
              <form
                className="form-horizontal"
                onSubmit={handleSubmit}
                noValidate={true}
                style={{ marginTop: 20 }}
              >
                <FieldArray name="verificationOverride" component={renderMembers} />
                <Col xs={3} style={{ paddingLeft: '0px' }}>
                  <Button bsStyle="primary" disabled={saveDisable} type="submit">
                    Save
                  </Button>
                </Col>
              </form>
            )}
          </Col>
        </Row>
      </Grid>
    );
  }
}

export default compose(
  connect(
    ({ verificationOverride, page }) => ({
      verificationOverride: verificationOverride.data,
      loading: page.loading,
    }),
    {
      postVerificationOverride,
      getVerificationOverride,
      deleteVerificationOverride,
    },
    (stateProps, dispatchProps, ownProps) => ({
      ...stateProps,
      ...dispatchProps,
      ...ownProps,
      onSubmit: async data => {
        await dispatchProps.postVerificationOverride({
          ...data,
        });
      },
    }),
  ),
  reduxForm({
    form: VERIFICATION_OVERRIDE_FORM,
    keepDirtyOnReinitialize: true,
    enableReinitialize: true,
    initialValues: {
      verificationOverride: [{ vin: '', fieldOverrides: [Loan_Amount] }],
    },
  }),
)(VerificationOverride);
