import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { Field, reduxForm, propTypes } from 'redux-form';
import { Well } from 'react-bootstrap';
import { ADMIN_OVERRIDE_FORM } from '../../redux/modules/form';
import { overrideDealerState } from '../../redux/modules/dealer';
import ValidationSelect from '../../components/common/ValidationSelect';
import { statesForSelection } from '../../constants/general';

const DEALER_STATE_OPTIONS = statesForSelection();

export function AdminOverrides(props) {
  const { user, overrideDealerState } = props;

  // TEMP for testing, should check if user is admin
  if (_.get(user, 'dealerRel.prcoAdmin')) {
    return (
      <Well>
        <form className="form-horizontal" noValidate={true}>
          <h4 style={{ marginBottom: '20px' }}>Overrides (Admin Only)</h4>
          <Field
            name="overrides.dealer.address.state"
            component={ValidationSelect}
            label="Dealer State"
            options={DEALER_STATE_OPTIONS}
            onChange={(event, dealerState) => overrideDealerState(dealerState)}
            data-test-id="AdminOverrides-dealerState"
          />
        </form>
      </Well>
    );
  } else {
    return null;
  }
}

AdminOverrides.propTypes = {
  ...propTypes,
  user: PropTypes.object.isRequired,
  overrideDealerState: PropTypes.func.isRequired,
};

export default compose(
  connect(
    ({ auth, dealer }) => ({
      user: auth.user,
      initialValues: {
        overrides: {
          dealer: {
            address: {
              state: _.get(dealer, 'details.address.state'),
            },
          },
        },
      },
    }),
    {
      overrideDealerState,
    },
  ),
  reduxForm({
    form: ADMIN_OVERRIDE_FORM,
  }),
)(AdminOverrides);
