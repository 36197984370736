import _ from 'lodash';
import { loadingCycle } from './page';
import api from '../../utils/service';
import { notifSend } from './notifs';

export const sendStatements = () => dispatch =>
  loadingCycle(api.get(`/actions/statements/send`))
    .then(({ data: { email } }) => {
      notifSend({
        message: `Statements sent to email ${email}`,
        dismissAfter: 5000,
      })(dispatch);
    })
    .catch(result => {
      const message = _.get(result, 'response.data.message', 'Unknown error');
      notifSend({
        kind: 'danger',
        message: `Error while sending statements: ${message}`,
        dismissAfter: 5000,
      })(dispatch);
    });
