import React from 'react';
import { connect } from 'react-redux';
import { FormControl, Button } from 'react-bootstrap';
import {
  storeTemplateSearchText,
  toggleUploadTemplateModal,
  toggleEditFieldsModal,
  getTemplates,
} from '../../../redux/modules/eSignAdmin';

const toState = ({ eSignAdmin }) => ({
  value: eSignAdmin.templateSearchText,
});

const TemplateSearchForm = ({
  value,
  getTemplates,
  storeTemplateSearchText,
  toggleUploadTemplateModal,
  toggleEditFieldsModal,
}) => (
  <form
    style={{ display: 'flex', marginBottom: 8, justifyContent: 'space-evenly', flexWrap: 'wrap' }}
    onSubmit={e => {
      e.preventDefault();
      getTemplates({ text: value });
    }}
    noValidate={true}
  >
    <div style={{ flexGrow: 2 }}>
      <FormControl
        type="text"
        value={value}
        placeholder="Search for previous templates (state, language, contract year, full name)"
        onChange={({ target }) => storeTemplateSearchText(target.value)}
      />
    </div>
    {'  '}
    <div style={{ display: 'flex', flexWrap: 'wrap', flexGrow: 1, justifyContent: 'space-evenly' }}>
      <Button style={{ flexGrow: 1, margin: '0px 8px' }} bsStyle="primary" type="submit">
        {value ? 'Submit Search' : 'Latest Templates'}
      </Button>
      <Button
        style={{ flexGrow: 1, marginRight: '8px' }}
        bsStyle="primary"
        onClick={() => toggleUploadTemplateModal(true)}
      >
        Upload New
      </Button>
      <Button style={{ flexGrow: 1 }} bsStyle="primary" onClick={() => toggleEditFieldsModal(true)}>
        Edit Fields
      </Button>
    </div>
  </form>
);

export default connect(toState, {
  getTemplates,
  storeTemplateSearchText,
  toggleUploadTemplateModal,
  toggleEditFieldsModal,
})(TemplateSearchForm);
