import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Breadcrumb, Glyphicon } from 'react-bootstrap';
import { Alert, ProgressBar } from 'react-bootstrap';
import { get } from 'lodash';

const mapStateToProps = ({ newQuote }) => ({
  error: get(newQuote, 'error.message') || get(newQuote, 'ratingQuoteRequest.failedReason'),
  loadingSummary: newQuote.loadingSummary,
});

export function RateBreadcrumb(props) {
  const { moveToPage, pageNo, pages, error, loadingSummary, isPageValid, isPageDisabled } = props;
  return (
    <Fragment>
      {loadingSummary && <ProgressBar striped active now={100} />}
      <Breadcrumb>
        {pages.map(({ name }, idx) => {
          const invalidMark = pageNo !== idx && !isPageValid(idx) && (
            <span>
              &nbsp;
              <Glyphicon glyph="exclamation-sign" className="text-danger" />
            </span>
          );

          if (pageNo === idx)
            return (
              <li>
                <strong>{name}</strong>
                {invalidMark}
              </li>
            );

          if (isPageDisabled(idx))
            return (
              <li>
                <span>{name}</span>
                {invalidMark}
              </li>
            );

          return (
            <Breadcrumb.Item key={name} onClick={() => moveToPage(idx)}>
              {name}
              {invalidMark}
            </Breadcrumb.Item>
          );
        })}
      </Breadcrumb>
      {!!error && (
        <Alert className="alert-danger">
          <strong>Uh oh!</strong> {error}
        </Alert>
      )}
    </Fragment>
  );
}

export default connect(mapStateToProps, null)(RateBreadcrumb);
