import React from 'react';
import { ControlLabel, FormControl, FormGroup } from 'react-bootstrap';
import { useField } from 'formik';

export default function Input({ label = '', description = '', ...props }) {
  const [field, meta] = useField(props);
  return (
    <FormGroup>
      {label && <ControlLabel>{label}</ControlLabel>}
      {description && <label>{description}</label>}
      <FormControl {...field} {...props} isInvalid={!!meta.error && meta.touched} />
      {meta.error && meta.touched && <span className="text-danger">{meta.error}</span>}
    </FormGroup>
  );
}
