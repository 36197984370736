import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

const ValidationSelect = ({
  input,
  label,
  placeholder,
  options,
  multiple,
  disabled,
  style,
  meta: { touched, error },
  'data-test-id': dataTestId,
  labelClassName = 'col-sm-3',
  inputGroupClassName = 'col-sm-9',
}) => (
  <div className={`form-group ${error && touched ? 'has-error' : ''}`}>
    <label htmlFor={input.name} className={labelClassName}>
      {label || ''}
    </label>
    <div className={inputGroupClassName}>
      <select
        {...input}
        style={style}
        disabled={disabled}
        className="form-control"
        data-test-id={dataTestId}
        multiple={multiple}
      >
        {!_.isNil(placeholder) && (
          <option value="" disabled={true}>
            {placeholder}
          </option>
        )}
        {_.map(options, o => {
          if (_.isObject(o)) {
            const value = o._id || o.value;
            return (
              <option value={value} key={value}>
                {o._id || o.label}
              </option>
            );
          } else {
            return (
              <option value={o} key={o}>
                {o}
              </option>
            );
          }
        })}
      </select>
      {error && touched && <span className="glyphicon glyphicon-remove form-control-feedback" />}
      {error && touched && (
        <div className="text-danger">
          <strong>{error}</strong>
        </div>
      )}
    </div>
  </div>
);

ValidationSelect.propTypes = {
  input: PropTypes.object,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  options: PropTypes.array.isRequired,
  meta: PropTypes.object.isRequired,
  'data-test-id': PropTypes.string,
  multiple: PropTypes.bool,
  style: PropTypes.object,
};

ValidationSelect.defaultProps = {
  input: {},
  label: '',
  'data-test-id': undefined,
  multiple: false,
  style: {},
};

export default ValidationSelect;
