import React from 'react';
import { Field, reduxForm, propTypes, formValueSelector } from 'redux-form';
import PropTypes from 'prop-types';
import moment from 'moment';
import _ from 'lodash';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { required, money, maxNumber } from '../../../utils/validation';
import ValidationInput from '../../../components/common/ValidationInput';
import ValidationSelect from '../../../components/common/ValidationSelect';
import ValidationDatePicker from '../../../components/common/ValidationDatePicker';
import { VSC_RATE_FORM } from '../../../redux/modules/form';
import {
  vscRateValidator,
  maxRetailPriceOfVsc,
} from '../../../components/validation/rateValidation';
import Buttons from '../../../components/wizard/Buttons';
import { vscRateTestDataIfRequested } from '../../../utils/testData';
import { QuoteTypePropType } from '../../../constants/newQuote';
import { receiveValuesFromQueryData } from '../../../utils/decorators';
import { QuoteTypeInput } from '../../new-quote/QuoteTypeInput';
import {
  quoteTypeIncludesGAP,
  quoteTypeIncludesVSCxTWP,
  quoteTypeItems,
} from '../../../constants/quoteType';
import { contractTypeDetailTitle } from '../../../constants/contractType';
import { selectedVscQuote } from '../../../redux/modules/newQuote';

const vscRateFormValueSelector = formValueSelector(VSC_RATE_FORM);

const CONTRACT_LANGUAGE_OPTIONS = [
  {
    value: 'EN',
    label: 'English',
  },
  {
    value: 'SP',
    label: 'Spanish',
  },
];

export const isLanguageDisabled = ({ quoteType, dealerState }) =>
  !quoteTypeIncludesGAP(quoteType) && _.includes(['IN', 'ID'], dealerState);

export const isRetailPriceOfVscRequired = ({ quoteType }) => quoteTypeIncludesVSCxTWP(quoteType);

export const getInitialSaleDate = saleDate => {
  if (moment.isMoment(saleDate)) {
    return saleDate.format('MM/DD/YYYY');
  } else if (saleDate) {
    return saleDate;
  } else {
    return moment().format('MM/DD/YYYY');
  }
};

export function VscRateForm(props) {
  const { handleSubmit, quoteType, maximumLiabilityLimit, hideBack } = props;

  return (
    <form
      className="form-horizontal"
      onSubmit={handleSubmit}
      noValidate={true}
      data-test-id="VscRateForm"
    >
      <Field
        name="vscRate.issueType"
        component={QuoteTypeInput}
        props={{
          requireSelection: true,
          allowItems: ({ value, initial }) => quoteTypeItems([quoteType, value, initial]),
          getItemTitle: contractTypeDetailTitle,
        }}
        label="Types of Contracts to Issue"
        disabled
      />
      <Field
        name="vscRate.saleDate"
        component={ValidationDatePicker}
        label="Sale Date of the Vehicle"
        placeholder={`eg. ${moment().format('MM/DD/YYYY')}`}
        disabled={true}
      />
      <Field
        name="vscRate.language"
        component={ValidationSelect}
        label="Contract Language"
        options={CONTRACT_LANGUAGE_OPTIONS}
        disabled={isLanguageDisabled(props)}
        data-test-id="VscRateForm-language"
      />
      <Field
        name="vscRate.vehiclePrice"
        type="number"
        component={ValidationInput}
        label="Purchase Price of the Vehicle"
        placeholder="eg. 25000"
      />
      {isRetailPriceOfVscRequired(props) && (
        <Field
          name="vscRate.retailPriceOfVsc"
          type="number"
          component={ValidationInput}
          label="Retail Price of VSC"
          placeholder="eg. 1688"
          validate={[required, money, maxRetailPriceOfVsc(props), maxNumber(maximumLiabilityLimit)]}
          data-test-id="VscRateForm-retailPriceOfVsc"
        />
      )}
      <Field
        name="vscRate.vin"
        type="text"
        component={ValidationInput}
        label="Vehicle VIN"
        disabled={true}
      />
      <Field
        name="vscRate.vehicleMileage"
        type="text"
        component={ValidationInput}
        label="Mileage"
        disabled={true}
      />
      <Field
        name="vscRate.stockNumber"
        type="text"
        component={ValidationInput}
        label="Stock Number of the Vehicle"
        placeholder="eg. 25OR624 (OPTIONAL)"
        data-test-id="VscRateForm-stockNumber"
      />
      <Buttons
        {..._.omit(props, 'onSubmit')}
        hideBack={hideBack}
        data-test-name="VscRateForm-Buttons"
      />
    </form>
  );
}

VscRateForm.propTypes = {
  ...propTypes,
  quoteType: QuoteTypePropType.isRequired,
  saleDate: PropTypes.string,
  vehiclePrice: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  maximumLiabilityLimit: PropTypes.number,
  hideBack: PropTypes.bool,
};

VscRateForm.defaultProps = {
  saleDate: null,
  vehiclePrice: null,
  maximumLiabilityLimit: Infinity,
  hideBack: false,
};

export const mapFromConnect = ({ newQuote, ...rest }) => {
  const saleDate = _.get(newQuote, 'form.vehicleSaleDate');
  const quoteType = _.get(newQuote, 'form.quoteType');
  const vscQuote = selectedVscQuote({ newQuote });
  return {
    quoteType,
    saleDate: getInitialSaleDate(saleDate),
    vehiclePrice: vscRateFormValueSelector(rest, 'vscRate.vehiclePrice'),
    maximumLiabilityLimit: _.get(vscQuote, 'maximumLiabilityLimit'),
    initialValues: _.merge({}, vscRateTestDataIfRequested(), {
      vscRate: {
        issueType: quoteType,
        saleDate: getInitialSaleDate(saleDate),
        language: 'EN',
        retailPriceOfVsc: vscRateFormValueSelector(rest, 'vscRate.retailPriceOfVsc'),
        vin: _.get(newQuote, 'form.vin'),
        vehiclePrice: _.get(newQuote, 'form.vehiclePurchasePrice'),
        vehicleMileage: _.get(newQuote, 'form.vehicleMileage'),
      },
    }),
  };
};

export default compose(
  connect(mapFromConnect),
  receiveValuesFromQueryData({
    retailPriceOfVsc: 'vscRate.retailPriceOfVsc',
    stockNumber: 'vscRate.stockNumber',
  }),
  reduxForm({
    form: VSC_RATE_FORM,
    validate: vscRateValidator,
  }),
)(VscRateForm);
