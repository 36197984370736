import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Field, propTypes, reduxForm, formValueSelector, blur, touch } from 'redux-form';
import _ from 'lodash';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { required, money, minNumber, maxNumber } from '../../../utils/validation';
import ValidationInput from '../../../components/common/ValidationInput';
import ValidationSelect from '../../../components/common/ValidationSelect';
import { GAP_RATE_FORM } from '../../../redux/modules/form';
import {
  gapRateValidator,
  maxRetailGap,
  maxLoanAmount,
} from '../../../components/validation/rateValidation';
import Buttons from '../../../components/wizard/Buttons';
import { gapRateTestDataIfRequested } from '../../../utils/testData';
import { selectedGapQuote } from '../../../redux/modules/newQuote';
import { receiveValuesFromQueryData } from '../../../utils/decorators';

const gapRateFormValueSelector = formValueSelector(GAP_RATE_FORM);

const BHPH_OPTIONS = ['No', 'Yes'];
const MAXIMUM_RETAIL_PRICE_GAP = 2000;

export const isKelleyBlueBookRequired = ({ bhph }) => bhph === 'Yes';

export function GapRateForm(props) {
  useEffect(() => {
    const { dispatch } = props;
    dispatch(blur(GAP_RATE_FORM, 'gapRate.gapLoanTermInMonths'));
    dispatch(touch(GAP_RATE_FORM, 'gapRate.gapLoanTermInMonths'));
    dispatch(touch(GAP_RATE_FORM, 'gapRate.loanAmountOfGap'));
  }, []);
  const {
    handleSubmit,
    retailPriceOfGap,
    maximumLiabilityLimit,
    lowerBandOfGap,
    higherBandOfGap,
    hideBack,
    dispatch,
  } = props;

  // NOTE - We use this because validation is not updating for retail price and loan values.
  const onChangeWithDependencies = () => {
    dispatch(touch(GAP_RATE_FORM, 'gapRate.retailPriceOfGap'));
    dispatch(touch(GAP_RATE_FORM, 'gapRate.loanAmountOfGap'));
  };

  return (
    <form
      className="form-horizontal"
      onSubmit={handleSubmit}
      noValidate={true}
      data-test-id="GapRateForm"
    >
      <Field
        name="gapRate.retailPriceOfGap"
        type="text"
        component={ValidationInput}
        label="Retail Price of GAP"
        placeholder="eg. 1200.00"
        validate={[
          required,
          money,
          maxRetailGap(props),
          maxNumber(Math.min(maximumLiabilityLimit, MAXIMUM_RETAIL_PRICE_GAP)),
        ]}
        onChange={onChangeWithDependencies}
        disabled={!!retailPriceOfGap}
        data-test-id="GapRateForm-retailPriceOfGap"
      />
      <Field
        name="gapRate.loanAmountOfGap"
        type="text"
        component={ValidationInput}
        label="Loan Amount of GAP"
        placeholder="eg. 4500.00"
        onChange={onChangeWithDependencies}
        validate={[required, money, minNumber(100.01), maxLoanAmount(props)]}
        data-test-id="GapRateForm-loanAmountOfGap"
      />
      <Field
        name="gapRate.gapLoanTermInMonths"
        type="number"
        component={ValidationInput}
        label="GAP Loan Term in Months"
        placeholder="eg. 72"
        validate={[required, minNumber(lowerBandOfGap), maxNumber(higherBandOfGap)]}
        data-test-id="GapRateForm-gapLoanTermInMonths"
      />
      <Field
        name="gapRate.bhph"
        component={ValidationSelect}
        label="BHPH Deal"
        placeholder="Choose one"
        options={BHPH_OPTIONS}
        data-test-id="GapRateForm-bhph"
      />
      {isKelleyBlueBookRequired(props) && (
        <Field
          name="gapRate.kelleyBlueBook"
          type="text"
          component={ValidationInput}
          label="Kelly Blue Book Value"
          placehholder="1350.00"
          validate={[required, money]}
          data-test-id="GapRateForm-kelleyBlueBook"
        />
      )}
      <Buttons
        hideBack={hideBack}
        {..._.omit(props, 'onSubmit')}
        data-test-name="GapRateForm-Buttons"
      />
    </form>
  );
}

GapRateForm.propTypes = {
  ...propTypes,
  gapQuote: PropTypes.object.isRequired,
  contractYear: PropTypes.string.isRequired,
  dealerState: PropTypes.string.isRequired,
  retailPriceOfGap: PropTypes.number,
  maximumLoanAllowed: PropTypes.number,
  maximumLiabilityLimit: PropTypes.number,
  lowerBandOfGap: PropTypes.number,
  higherBandOfGap: PropTypes.number,
  bhph: PropTypes.string,
  hideBack: PropTypes.bool,
};

GapRateForm.defaultProps = {
  retailPriceOfGap: null,
  maximumLoanAllowed: null,
  maximumLiabilityLimit: Infinity,
  lowerBandOfGap: 0,
  higherBandOfGap: null,
  bhph: null,
  hideBack: false,
};

export const mapFromConnect = ({ dealer, newQuote, ...rest }) => {
  const gapQuote = selectedGapQuote({ newQuote });
  const retailPriceOfGap = _.get(gapQuote, 'retailCostRequired');
  const props = {
    retailPriceOfGap,
    maximumLoanAllowed: _.get(gapQuote, 'maximumLoanAllowed'),
    // maximumLiabilityLimit = 0 means limit is undefined
    maximumLiabilityLimit: parseFloat(_.get(gapQuote, 'maximumLiabilityLimit', 0)) || undefined,
    dealerState: _.get(dealer, 'details.address.state'),
    contractYear: _.get(dealer, 'details.rating.contractYear'),
    lowerBandOfGap: _.get(gapQuote, ['band', 0]),
    higherBandOfGap: _.get(gapQuote, ['band', 1]),
    bhph: gapRateFormValueSelector(rest, 'gapRate.bhph'),
    kelleyBlueBook: gapRateFormValueSelector(rest, 'gapRate.kelleyBlueBook') || 0,
  };

  return {
    ...props,
    initialValues: _.merge({}, gapRateTestDataIfRequested(props), {
      gapRate: {
        loanAmountOfGap: _.get(newQuote, 'form.loanAmount'),
        retailPriceOfGap: retailPriceOfGap > 0 ? retailPriceOfGap : undefined,
      },
    }),
  };
};

export default compose(
  connect(mapFromConnect),
  receiveValuesFromQueryData({
    retailPriceOfGap: 'gapRate.retailPriceOfGap',
    loanTerm: 'gapRate.gapLoanTermInMonths',
    bhph: 'gapRate.bhph',
    kelleyBlueBook: 'gapRate.kelleyBlueBook',
  }),
  reduxForm({
    form: GAP_RATE_FORM,
    validate: gapRateValidator,
  }),
)(GapRateForm);
