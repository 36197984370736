import React from 'react';
import { Checkbox, Table } from 'react-bootstrap';

const FieldTable = ({ fields, toggleRequired, checkboxHeaderTitle = 'Required' }) => (
  <Table responsive condensed bordered striped hover>
    <thead style={{ fontWeight: 'bold' }}>
      <tr>
        <td>Field Name</td>
        <td>{checkboxHeaderTitle}</td>
      </tr>
    </thead>
    <tbody>
      {fields.map(field => (
        <tr key={field._id} style={{ padding: '0', verticalAlign: 'middle' }}>
          <td>{`${field.fieldNameAlt} (${field.fieldName})`}</td>
          <td>
            <Checkbox
              checked={field.required}
              style={{ margin: '0', marginBottom: '-2px', textAlign: 'center' }}
              onChange={() => toggleRequired(field)}
            />
          </td>
        </tr>
      ))}
    </tbody>
  </Table>
);

export default FieldTable;
