import _ from 'lodash';
import qs from 'qs';
import api from '../../utils/service';
import { loadingCycle } from './page';

const GET_ALL_TEMPLATES_SUCCESS = 'redux/template/GET_ALL_TEMPLATES_SUCCESS';
const GET_ALL_TEMPLATES_FAIL = 'redux/template/GET_ALL_TEMPLATES_FAIL';
const SEND_TEMPLATE_ERROR = 'redux/template/SEND_TEMPLATE_ERROR';
const CLEAR_TEMPLATE_ERROR = 'redux/template/CLEAR_TEMPLATE_ERROR';

const initialState = {};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case GET_ALL_TEMPLATES_SUCCESS:
      return {
        ...state,
        list: action.data,
        error: null,
      };
    case GET_ALL_TEMPLATES_FAIL:
      return {
        ...state,
        error: action.error,
      };
    case SEND_TEMPLATE_ERROR:
      return {
        ...state,
        error: action.error,
        disableProceed: true,
      };
    case CLEAR_TEMPLATE_ERROR:
      return {
        ...state,
        error: null,
        disableProceed: false,
      };
    default:
      return state;
  }
}

export function getAllTemplates(query) {
  return dispatch =>
    loadingCycle(api.get(`/templates?${qs.stringify(query)}`))
      .then(({ data }) => dispatch(getAllTemplatesSuccess(data)))
      .catch(error => dispatch(getAllTemplatesFail(error)));
}

export function getAllTemplatesSuccess(data) {
  return {
    type: GET_ALL_TEMPLATES_SUCCESS,
    data,
  };
}

export function getAllTemplatesFail(error) {
  return {
    type: GET_ALL_TEMPLATES_FAIL,
    error: _.get(error, 'response.data.message', _.get(error, 'message')),
  };
}

export function sendTemplateError(error) {
  return dispatch =>
    dispatch({
      type: SEND_TEMPLATE_ERROR,
      error,
    });
}

export function clearTemplateError() {
  return dispatch =>
    dispatch({
      type: CLEAR_TEMPLATE_ERROR,
    });
}
