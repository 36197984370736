import React from 'react';
import { Col, Grid, Row } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { markTaskAsRead } from '../../redux/modules/task';
import { TaskProgressPanel } from './ProgressPanel';
import { useTaskEvents } from './useTaskEvents';

export const TaskUnknownTypeDisplay = props => {
  const {
    params: { taskId },
    tasksMetas: { [taskId]: taskMeta } = {},
    markTaskAsRead,
  } = props;

  const { taskEvents } = useTaskEvents(taskId);

  return (
    <div className="container">
      <Helmet title="Tasks" />
      <Grid>
        <Row>
          <Col xs={12}>
            <TaskProgressPanel
              taskMeta={taskMeta}
              taskEvents={taskEvents}
              markTaskAsRead={markTaskAsRead}
            />
          </Col>
        </Row>
      </Grid>
    </div>
  );
};

export const TaskUnknownType = connect(
  ({ task: { tasksMetas } }) => ({
    tasksMetas,
  }),
  {
    markTaskAsRead,
  },
)(TaskUnknownTypeDisplay);
