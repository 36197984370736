import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Helmet from 'react-helmet';
import { connect } from 'react-redux';
import { Grid, Row, Col, Table, Button } from 'react-bootstrap';
import { compose } from 'recompose';
import { reset } from 'redux-form';
import { EMAIL_VERIFICATION_FORM } from '../redux/modules/form';
import {
  createEmailVerification,
  getAllVerifiedEmails,
  deleteVerifiedEmail,
} from '../redux/modules/emailVerification';
import EmailVerficationForm from '../components/EmailVerficationForm';
import {
  required as requiredValidationError,
  email as emailValidationError,
} from '../utils/validation';

export class EmailVerificationAdmin extends PureComponent {
  static propTypes = {
    getAllVerifiedEmails: PropTypes.func.isRequired,
    createEmailVerification: PropTypes.func.isRequired,
    deleteVerifiedEmail: PropTypes.func.isRequired,
  };

  static defaultProps = {
    emails: [],
    emailVerficationForm: {},
  };

  componentDidMount() {
    const { getAllVerifiedEmails } = this.props;
    getAllVerifiedEmails();
  }

  submitHandler = e => {
    e.preventDefault();
    const { createEmailVerification, emailVerficationForm, dispatch } = this.props;
    const { email } = emailVerficationForm.values || {};
    const validationError = requiredValidationError(email) || emailValidationError(email);
    if (!validationError) {
      createEmailVerification({ email }).then(res => {
        if (!res.error) {
          dispatch(reset(EMAIL_VERIFICATION_FORM));
        }
      });
    }
  };

  render() {
    const { emails, deleteVerifiedEmail } = this.props;

    return (
      <div>
        <Helmet title="Messaging Administration" />
        <Grid>
          <Row>
            <Col xs={12}>
              <EmailVerficationForm submitHandler={this.submitHandler} />
            </Col>
            {emails.length ? (
              <Col xs={12}>
                <legend>Emails</legend>
                <Table responsive hover>
                  <tbody>
                    {_.map(emails, m => (
                      <tr key={m._id}>
                        <td>{m.email}</td>
                        <td>
                          <Button bsStyle="danger" onClick={() => deleteVerifiedEmail(m._id)}>
                            Delete
                          </Button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Col>
            ) : null}
          </Row>
        </Grid>
      </div>
    );
  }
}

export default compose(
  connect(
    state => ({
      emailVerficationForm: state.form.emailVerficationForm,
      emails: state.emailVerification.data,
    }),
    {
      getAllVerifiedEmails,
      createEmailVerification,
      deleteVerifiedEmail,
    },
  ),
)(EmailVerificationAdmin);
