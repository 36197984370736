import _ from 'lodash';
import { loadingCycle } from './page';
import api from '../../utils/service';

const GET_DEALER_SUCCESS = 'redux/dealer/GET_DEALER_SUCCESS';
const GET_DEALER_FAIL = 'redux/dealer/GET_DEALER_FAIL';
const OVERRIDE_DEALER_STATE = 'redux/dealer/OVERRIDE_DEALER_STATE';
const CLEAR_DEALER_VALIDATION = 'redux/dealer/CLEAR_DEALER_VALIDATION';

const initialState = {};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case GET_DEALER_SUCCESS:
      return {
        ...state,
        details: action.data,
      };
    case GET_DEALER_FAIL:
      return {
        ...state,
        error: action.error,
      };
    case OVERRIDE_DEALER_STATE:
      // NOTE - Using merge here for the deep merge characteristics
      return _.merge({}, state, {
        details: {
          address: {
            state: action.data,
          },
        },
      });
    case CLEAR_DEALER_VALIDATION:
      return {
        ...state,
        details: null,
      };
    default:
      return state;
  }
}

export function getDealer(id = 'me') {
  return dispatch =>
    loadingCycle(api.get(`/dealers/${id}`))
      .then(({ data }) => dispatch(getDealerSuccess(data)))
      .catch(error => dispatch(getDealerFail(error)));
}

export function getDealerSuccess(data) {
  return {
    type: GET_DEALER_SUCCESS,
    data,
  };
}

export function getDealerFail(error) {
  return {
    type: GET_DEALER_FAIL,
    error: _.get(error, 'response.data.message', _.get(error, 'message')),
  };
}

export function overrideDealerState(dealerState) {
  return dispatch =>
    dispatch({
      type: OVERRIDE_DEALER_STATE,
      data: dealerState,
    });
}

export function clearDealerValidation() {
  return dispatch =>
    dispatch({
      type: CLEAR_DEALER_VALIDATION,
    });
}
