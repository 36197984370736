import React from 'react';
import { contractTypesAll, contractTypeTitle } from '../../../../../constants/contractType';

const FieldTypeSelect = ({ handleType, errorMessage, loading }) => (
  <form noValidate={true}>
    <div className={`form-group ${errorMessage ? 'has-error' : ''}`}>
      <label className="col-sm-3">Template Type</label>
      <div className="col-sm-9">
        <select
          disabled={loading}
          className="form-control"
          onChange={ev => handleType(ev.target.value)}
        >
          <option value="" disabled selected>
            Please Choose an Option
          </option>
          {contractTypesAll.map(item => (
            <option key={item} value={item.toLowerCase()}>
              {contractTypeTitle(item)}
            </option>
          ))}
        </select>
      </div>
    </div>
  </form>
);

export default FieldTypeSelect;
