import React from 'react';
import { connect } from 'react-redux';
import { Panel, Button, Row, Col } from 'react-bootstrap';
import { Field, reduxForm } from 'redux-form';
import { statesForSelection } from '../../../../../constants/general';
import { TEMPLATE_META_DATA_FORM } from '../../../../../redux/modules/form';
import templateMetaDataValidation from '../../../../../components/validation/templateMetaDataValidation';
import ValidationSelect from '../../../../../components/common/ValidationSelect';
import ValidationCheckbox from '../../../../../components/common/ValidationCheckbox';
import ValidationInput from '../../../../../components/common/ValidationInput';
import { templateMetaDataIfRequested } from '../../../../../utils/testData';
import { contractTypesAll, contractTypeTitle } from '../../../../../constants/contractType';

const toState = ({ eSignAdmin }) => ({
  templateFileData: eSignAdmin.templateFileData,
});

const TEMPLATE_TYPE_OPTIONS = contractTypesAll.map(type => ({
  value: type,
  label: contractTypeTitle(type),
}));

const TEMPLATE_LANGUAGE_OPTIONS = [
  {
    value: 'english',
    label: 'English',
  },
  {
    value: 'spanish',
    label: 'Spanish',
  },
];

const TemplateMetaDataForm = ({ goBack, handleSubmit, disabled, templateFileData = {} }) => (
  <div>
    {templateFileData.name && (
      <Panel>
        <Panel.Heading>Please describe {templateFileData.name}'s properties</Panel.Heading>
      </Panel>
    )}
    <form className="form-horizontal" onSubmit={handleSubmit} noValidate={true}>
      <Field
        name="templateType"
        type="text"
        label="Type"
        placeholder="Choose one"
        component={ValidationSelect}
        options={TEMPLATE_TYPE_OPTIONS}
      />
      <Field
        name="language"
        type="text"
        label="Language"
        placeholder="Choose one"
        component={ValidationSelect}
        options={TEMPLATE_LANGUAGE_OPTIONS}
      />
      <Field
        name="state"
        type="text"
        label="State"
        placeholder="Choose one"
        component={ValidationSelect}
        options={statesForSelection()}
      />
      <Field
        name="contractYear"
        type="text"
        label="Contract Year"
        placeholder="contract version.."
        component={ValidationInput}
      />
      <Field
        name="gapPlus"
        type="checkbox"
        label="Is Gap Plus Template?"
        component={ValidationCheckbox}
      />
      <Row>
        <Col xs={6}>
          <Button style={{ width: '100%' }} type="button" bsStyle="primary" onClick={goBack}>
            Back
          </Button>
        </Col>
        <Col xs={6}>
          <Button style={{ width: '100%' }} disabled={disabled} type="submit" bsStyle="primary">
            Next
          </Button>
        </Col>
      </Row>
    </form>
  </div>
);

export default connect(toState)(
  reduxForm({
    form: TEMPLATE_META_DATA_FORM,
    validate: templateMetaDataValidation,
    destroyOnUnmount: false,
    initialValues: templateMetaDataIfRequested(),
  })(TemplateMetaDataForm),
);
