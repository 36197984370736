import _ from 'lodash';
import { loadingCycle } from './page';
import api from '../../utils/service';

const GET_MESSAGING_SUCCESS = 'redux/messaging/GET_MESSAGING_SUCCESS';
const GET_MESSAGING_FAIL = 'redux/messaging/GET_MESSAGING_FAIL';
const CREATE_MESSAGING_SUCCESS = 'redux/messaging/CREATE_MESSAGING_SUCCESS';
const CREATE_MESSAGING_FAIL = 'redux/messaging/CREATE_MESSAGING_FAIL';
const UPDATE_MESSAGING_SUCCESS = 'redux/messaging/UPDATE_MESSAGING_SUCCESS';
const UPDATE_MESSAGING_FAIL = 'redux/messaging/UPDATE_MESSAGING_FAIL';

const initialState = {};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case GET_MESSAGING_SUCCESS:
      return {
        ...state,
        data: action.data,
      };
    case GET_MESSAGING_FAIL:
    case CREATE_MESSAGING_FAIL:
    case UPDATE_MESSAGING_FAIL:
      return {
        ...state,
        error: action.error,
      };
    default:
      return state;
  }
}

export function getMessaging({ name } = {}) {
  return dispatch =>
    loadingCycle(api.get(!name ? '/messaging' : `/messaging?name=${name}`))
      .then(({ data }) => dispatch(getMessagingSuccess(data)))
      .catch(error => dispatch(getMessagingFail(error)));
}

export function getMessagingSuccess(data) {
  return {
    type: GET_MESSAGING_SUCCESS,
    data,
  };
}

export function getMessagingFail(error) {
  return {
    type: GET_MESSAGING_FAIL,
    error: _.get(error, 'response.data.message', _.get(error, 'message')),
  };
}

export function createMessaging(data) {
  return dispatch =>
    loadingCycle(api.post('/messaging', data))
      .then(({ data }) => dispatch(createMessagingSuccess(data)))
      .catch(error => dispatch(createMessagingFail(error)));
}

export function createMessagingSuccess(data) {
  return {
    type: CREATE_MESSAGING_SUCCESS,
    data,
  };
}

export function createMessagingFail(error) {
  return {
    type: CREATE_MESSAGING_FAIL,
    error: _.get(error, 'response.data.message', _.get(error, 'message')),
  };
}

export function updateMessaging(id, data) {
  return dispatch =>
    loadingCycle(api.put(`/messaging/${id}`, data))
      .then(({ data }) => dispatch(updateMessagingSuccess(data)))
      .catch(error => dispatch(updateMessagingFail(error)));
}

export function updateMessagingSuccess(data) {
  return {
    type: UPDATE_MESSAGING_SUCCESS,
    data,
  };
}

export function updateMessagingFail(error) {
  return {
    type: UPDATE_MESSAGING_FAIL,
    error: _.get(error, 'response.data.message', _.get(error, 'message')),
  };
}
