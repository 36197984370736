import React, { useEffect, useState, useMemo } from 'react';
import * as Yup from 'yup';
import { Formik, Field } from 'formik';
import { connect } from 'react-redux';
import { Col, Row, Checkbox, FormGroup } from 'react-bootstrap';
import _ from 'lodash';
import moment from 'moment';
import SubmitButton from '../../../components/common/form/Submit';
import Select from '../../../components/common/form/Select';
import Input from '../../../components/common/form/Input';
import { createIssuedContract, deleteIssuedContract } from '../../../redux/modules/issuedContract';
import {
  selectedGapQuote,
  selectedVscQuote,
  selectedTwpQuote,
} from '../../../redux/modules/newQuote';
import {
  quoteTypeDifference,
  quoteTypeIncludesGAP,
  quoteTypeIncludesTWP,
  quoteTypeIncludesVSC,
  quoteTypeIntersection,
  quoteTypeTitle,
} from '../../../constants/quoteType';
import { notifSend } from '../../../redux/modules/notifs';
import { contractTypeTitle } from '../../../constants/contractType';
import {
  money,
  maxLoanAmount,
  requiredIfTrue,
  maxRetailPriceOfVsc,
  maxRetailGap,
  isZipCode,
  isOnlyAlphabetWithSymbols,
  integerPhoneNumber,
  isEmail,
  match,
  notMatch,
} from '../../../components/common/form/schema/common';
import ContractTypeCheckboxes from './form/ContractTypeCheckboxes';
import { useStoreFormInQuery } from '../../../components/common/query/hooks';
import { abbreviationFromState } from '../../../constants/general';
import { quoteTypeIncludesVSCxTWP } from '../../../constants/quoteType';
import {
  vscRateTestDataIfRequested,
  gapRateTestDataIfRequested,
  twpRateTestDataIfRequested,
  customerInformationRateTestDataIfRequested,
  lenderInformationRateTestDataIfRequested,
} from '../../../utils/testData';
import { statesForSelection } from '../../../constants/general';
import AvailableLenders from './form/AvailableLenders';
import { getZipCodeAddress } from '../../../redux/modules/zipCode';
import { TaskEventsList } from '../../Tasks/EventsList';
import NotificationModal from '../../../components/common/NotificationModal';
import api from '../../../utils/service';

const CONTRACT_LANGUAGE_OPTIONS = [
  {
    value: 'EN',
    label: 'English',
  },
  {
    value: 'SP',
    label: 'Spanish',
  },
];

export const isLanguageDisabled = ({ quoteType, dealerState }) =>
  !quoteTypeIncludesGAP(quoteType) && _.includes(['IN', 'ID'], dealerState);

export const isRetailPriceOfVscRequired = ({ quoteType }) => quoteTypeIncludesVSCxTWP(quoteType);

export const getInitialSaleDate = saleDate => {
  if (moment.isMoment(saleDate)) {
    return saleDate.format('MM/DD/YYYY');
  } else if (saleDate) {
    return saleDate;
  } else {
    return moment().format('MM/DD/YYYY');
  }
};

const BHPH_OPTIONS = ['No', 'Yes'];
const MAXIMUM_RETAIL_PRICE_GAP = 2000;

export const isKelleyBlueBookRequired = ({ bhph }) => bhph === 'Yes';

const STATES_VALUES = statesForSelection();

export const customerPhoneNumberRequired = ({ eSign, email }) => !eSign && !email;

export const customerEmailRequired = ({ eSign, phoneNumber }) => eSign || !phoneNumber;

export function Rate({
  router,
  location,
  loading,
  quoteType,
  saleDate,
  newQuote,
  dealerState,
  retailPriceOfGap,
  contractYear,
  vscQuote,
  gapQuote,
  twpQuote,
  dealer,
  extraCoverages,
  createIssuedContract,
  deleteIssuedContract,
  notifSend,
}) {
  const [valuesFromQuery, setValuesFromQuery] = useStoreFormInQuery(router, location);
  const [quoteSubmissionValues, setQuoteSubmissionValues] = useState();
  const [nonEditableLender, onUpdateNonEditableLender] = useState(false);
  const [showConfirmCancelExistingContract, setShowConfirmCancelExistingContract] = useState(false);
  const [existingContract, setExistingContract] = useState(false);
  const [issueError, setIssueError] = useState(false);

  const { signatureFlow } = newQuote;

  const eSign = signatureFlow === 'eSign';

  const schema = useMemo(
    () =>
      Yup.object().shape({
        ...(quoteType &&
        quoteTypeIncludesVSC(quoteType) &&
        isRetailPriceOfVscRequired({ quoteType })
          ? {
              vscRate: Yup.object({
                retailPriceOfVsc: Yup.number()
                  .test(money)
                  .test(maxRetailPriceOfVsc)
                  .max(_.get(vscQuote, 'maximumLiabilityLimit'))
                  .required('Required'),
              }),
            }
          : {}),
        ...(quoteType && quoteTypeIncludesGAP(quoteType)
          ? {
              gapRate: Yup.object({
                retailPriceOfGap: Yup.number()
                  .test(money)
                  .test(maxRetailGap({ dealerState }))
                  .max(
                    Math.min(_.get(gapQuote, 'maximumLiabilityLimit'), MAXIMUM_RETAIL_PRICE_GAP),
                  ),
                loanAmountOfGap: Yup.number()
                  .test(money)
                  .min(100.01)
                  .test(maxLoanAmount({ dealerState, contractYear })),
                gapLoanTermInMonths: Yup.number()
                  .min(_.get(gapQuote, ['band', 0]))
                  .max(_.get(gapQuote, ['band', 1])),
                kelleyBlueBook: Yup.number()
                  .test(money)
                  .test(requiredIfTrue(isKelleyBlueBookRequired)),
              }),
            }
          : {}),
        ...(quoteType && quoteTypeIncludesTWP(quoteType)
          ? {
              twpRate: Yup.object({
                retailPriceOfTwp: Yup.number()
                  .test(money)
                  .max(_.get(twpQuote, 'maximumLiabilityLimit'))
                  .required('Required'),
              }),
            }
          : {}),
        customer: Yup.object({
          phoneNumber: Yup.string()
            .test(integerPhoneNumber)
            .test(requiredIfTrue(customerPhoneNumberRequired, { eSign })),
          email: Yup.string().test(isEmail).test(requiredIfTrue(customerEmailRequired, { eSign })),
          confirmEmail: Yup.string()
            .test(match('email', "Emails don't match"))
            .test(requiredIfTrue(customerEmailRequired, { eSign })),
          coBuyerEmail: Yup.string()
            .test(isEmail)
            .test(notMatch('email', 'The co-buyer must have a separate email from the buyer'))
            .test(requiredIfTrue(({ hasCoBuyer }) => hasCoBuyer)),
          coBuyerConfirmEmail: Yup.string()
            .test(match('coBuyerEmail', "Emails don't match"))
            .test(requiredIfTrue(({ hasCoBuyer }) => hasCoBuyer)),
          address: Yup.object({
            zipCode: Yup.string().test(isZipCode),
            city: Yup.string().test(isOnlyAlphabetWithSymbols),
          }),
        }),
        lender: Yup.object({
          address: Yup.object({
            zipCode: Yup.string().test(isZipCode),
          }),
          phoneNumber: Yup.string().test(integerPhoneNumber),
        }),
      }),
    [quoteType, eSign, vscQuote, gapQuote, twpQuote, dealerState, contractYear],
  );

  useEffect(() => {
    setQuoteSubmissionValues(valuesFromQuery);
  }, [valuesFromQuery]);

  const getCombinedPendingContract = (values = {}) => {
    return {
      ..._.get(newQuote, 'form'),
      ...quoteSubmissionValues,
      ...values,
      eSign,
      dealer,
      quotes: {
        vscQuote,
        gapQuote,
        twpQuote,
        extraCoverages,
      },
    };
  };

  const checkForExistingContract = async pendingContract => {
    const { vin } = pendingContract;
    try {
      /**
       * @typedef ExistingIssuedContract {{
       *  id: string;
       *  created: string;
       *  vin: string;
       *  status: string;
       *  contractType: string;
       *  vscQuoteId: string;
       *  gapQuoteId: string;
       *  twpQuoteId: string;
       * }}
       */
      const { data } = await api.get(`/actions/issued-contracts/vin/${vin}`);
      if (data && data.issuedContract) {
        setShowConfirmCancelExistingContract(true);
        setExistingContract(data.issuedContract);
        return;
      }
    } catch (err) {
      const { response } = err;
      // note: its okay if its not found.. continue submission
      if (response.status !== 404) {
        throw new Error(
          `Error checking for already issued contract: ${response.message || err.toString()}`,
        );
      }
    }
    submitContract(pendingContract);
    setExistingContract(null);
  };

  const handleConfirmCancelExistingContract = async (
    pendingContract,
    cancel,
    keepContractTypes = null,
  ) => {
    if (!pendingContract) {
      setShowConfirmCancelExistingContract(false);
      notifSend({
        kind: 'danger',
        message: `Unable to locate pending contract, please go back a step and try again.`,
        dismissAfter: 6000,
      });
      return pendingContract;
    }

    if (cancel) {
      await deleteContract(keepContractTypes);
      notifSend({
        message: `Requested cancellation for existing contract on ${pendingContract.vin}`,
        dismissAfter: 5000,
      });
    }
    await submitContract(pendingContract);
    setShowConfirmCancelExistingContract(false);
    setExistingContract(null);
  };

  const deleteContract = async keepContractTypes => {
    await deleteIssuedContract(existingContract.id, {
      reason: 'duplicate',
      keepContractTypes,
    });
  };

  const onSubmitResult = result => {
    // Result can be either Task info (when its execution delayed), or the Task execution result.
    const taskId = _.get(result, 'taskId');
    if (taskId) {
      // Got Task info, navigating user to the Task
      router.push(`/tasks/IssueContract/${taskId}`);
    } else {
      // Got execution result
      const taskEvents = _.get(result, 'taskEvents');
      if (taskEvents) {
        const resultEvent = taskEvents.find(e => e.eventType === 'TaskEventResult');
        if (resultEvent) {
          const { issuedContractId } = resultEvent;
          router.push(`/contractIssueComplete/${issuedContractId}`);
        } else {
          setIssueError(taskEvents);
        }
      } else {
        setIssueError(true);
      }
    }
  };

  const submitContract = async pendingContract => {
    /**
     * Small hack around a bug we've experiencing.
     * When users send their form, lender information is sometimes not available due to
     * a race condition. Before rewriting this component to get rid of that race condition
     * we are implementing a retry approach in order to validate lender information be in place.
     */
    let attemps = 0;
    const f = async () => {
      if (pendingContract && pendingContract.lender) {
        const result = await createIssuedContract({ ...pendingContract });
        onSubmitResult(result);
        return;
      }
      attemps++;
      console.log(`Error while trying to retrieve lender information. Attemp number ${attemps}`);
      if (attemps === 50) {
        throw new Error(`Too many attemps to submit contract with full information for lender.`);
      }
      setTimeout(f, 100);
    };

    await f();
  };

  const CreateDuplicateContractConfirmation = ({
    show,
    disabled,
    onConfirm,
    onClose,
    pendingQuoteType,
    existingContract,
  }) => {
    if (!existingContract) {
      return null;
    }

    const [keepContractTypes, setKeepContractTypes] = useState([]);

    const omittedContractTypes = quoteTypeDifference(existingContract.contractType)(
      pendingQuoteType,
    );
    const replacedContractTypes = quoteTypeIntersection(pendingQuoteType)(
      existingContract.contractType,
    );

    const omittedContractText = quoteTypeTitle(omittedContractTypes);

    const handleToggleContractCb = contractType => () => {
      if (keepContractTypes.includes(contractType))
        setKeepContractTypes(keepContractTypes.filter(t => t !== contractType));
      else setKeepContractTypes([...keepContractTypes, contractType]);
    };

    // To allow something to keep, we need to have something to cancel first.
    // If there is some replaced contracts, they will be cancelled
    // Or, if there is more than one omitted contract, user can select some to cancel.
    const enableChooseToKeep = replacedContractTypes.length > 0 || omittedContractTypes.length > 1;

    // If there is no replaced contracts, and user selected all to keep, we have nothing to cancel.
    const hasNothingToCancel =
      replacedContractTypes.length === 0 &&
      omittedContractTypes.every(t => keepContractTypes.includes(t));

    const keepCheckBoxes = enableChooseToKeep
      ? omittedContractTypes.map(contractType => (
          <Checkbox
            key={contractType}
            checked={keepContractTypes.includes(contractType)}
            onChange={handleToggleContractCb(contractType)}
          >
            Keep {contractTypeTitle(contractType)} Contract
          </Checkbox>
        ))
      : [];

    return (
      <NotificationModal
        show={show}
        disabled={disabled}
        disabledConfirm={hasNothingToCancel}
        title="Contract Already Exists"
        message={`An existing ${quoteTypeTitle(
          existingContract.contractType,
        )} contract was submitted for ${existingContract.vin}, would you like to cancel it?`}
        confirmText={`Cancel Existing & Submit New`}
        cancelText={`Continue (Submit New)`}
        onConfirm={() => onConfirm(keepContractTypes)}
        onCancel={onClose}
        data-test-name="Rate-CreateDuplicateContractConfirmation"
      >
        {keepCheckBoxes.length > 1 && (
          <div>
            <hr />
            <p>
              The previous contract included {omittedContractText} contracts, which are not included
              anymore.
            </p>
            <p>Select contracts you want to keep:</p>
            {keepCheckBoxes}
          </div>
        )}

        {keepCheckBoxes.length === 1 && (
          <div>
            <hr />
            <p>
              The previous contract included {omittedContractText} contract, which is not included
              anymore.
            </p>
            <p>Do you want to keep it?</p>
            {keepCheckBoxes}
          </div>
        )}
      </NotificationModal>
    );
  };

  const handleSubmit = values => {
    setQuoteSubmissionValues(values);
    const contract = getCombinedPendingContract(values);
    checkForExistingContract(contract);
    setIssueError(false);
  };

  return (
    <>
      {!loading && (
        <div className="container">
          <Formik
            validateOnBlur
            validateOnMount
            initialValues={{
              vscRate: {
                issueType: quoteType && quoteType.split(','),
                saleDate,
                language: 'EN',
                vin: _.get(newQuote, 'form.vin'),
                vehiclePrice: _.get(newQuote, 'form.vehiclePurchasePrice'),
                vehicleMileage: _.get(newQuote, 'form.vehicleMileage'),
                ...vscRateTestDataIfRequested(),
              },
              gapRate: {
                loanAmountOfGap: _.get(newQuote, 'form.loanAmount'),
                retailPriceOfGap: retailPriceOfGap > 0 ? retailPriceOfGap : undefined,
                ...gapRateTestDataIfRequested({
                  maximumLiabilityLimit: _.get(gapQuote, 'maximumLiabilityLimit'),
                  lowerBandOfGap: _.get(gapQuote, ['band', 0]),
                  higherBandOfGap: _.get(gapQuote, ['band', 1]),
                }),
              },
              twpRate: {
                ...twpRateTestDataIfRequested(),
              },
              customer: {
                ...customerInformationRateTestDataIfRequested(),
              },
              lender: {
                ...lenderInformationRateTestDataIfRequested(),
              },
              ...valuesFromQuery,
              ...quoteSubmissionValues,
            }}
            validationSchema={schema}
            onSubmit={handleSubmit}
          >
            {({ handleReset, handleSubmit, values, isValid, isSubmitting, setFieldValue }) => {
              const {
                gapRate: { bhph },
                customer,
                lender,
              } = values;

              const customerZipCode = _.get(customer, 'address.zipCode');
              const lenderZipCode = _.get(lender, 'address.zipCode');
              const hasCoBuyer = _.get(customer, 'hasCoBuyer');

              useEffect(() => {
                setValuesFromQuery({
                  ...values,
                });
              }, [values]);

              useEffect(() => {
                if (customerZipCode) {
                  getZipCodeAddress(customerZipCode).then(ResponseData => {
                    if (ResponseData.data.length) {
                      setFieldValue(
                        'customer.address.city',
                        _.get(ResponseData.data[0], 'city', ''),
                      );
                      setFieldValue(
                        'customer.address.state',
                        _.get(ResponseData.data[0], 'state', ''),
                      );
                    }
                  });
                }
              }, [customerZipCode]);

              useEffect(() => {
                if (lenderZipCode) {
                  getZipCodeAddress(lenderZipCode).then(ResponseData => {
                    if (ResponseData.data.length) {
                      setFieldValue('lender.address.city', _.get(ResponseData.data[0], 'city', ''));
                      setFieldValue(
                        'lender.address.state',
                        _.get(ResponseData.data[0], 'state', ''),
                      );
                    }
                  });
                }
              }, [lenderZipCode]);

              const handleLenderSelected = ({ name, address, phoneNumber } = {}) => {
                setFieldValue('lender.companyName', name || '');
                setFieldValue('lender.address.street', _.get(address, 'street', ''));
                setFieldValue('lender.address.city', _.get(address, 'city', ''));
                setFieldValue(
                  'lender.address.state',
                  abbreviationFromState(_.get(address, 'state', '')),
                );
                setFieldValue('lender.address.zipCode', _.get(address, 'zipCode', ''));
                setFieldValue('lender.phoneNumber', phoneNumber || '');
                onUpdateNonEditableLender(!!name);
              };

              const handleLenderEdit = () => {
                onUpdateNonEditableLender(false);
              };

              return (
                <form
                  onReset={handleReset}
                  noValidate={true}
                  onSubmit={handleSubmit}
                  data-test-id="VscRateForm"
                >
                  {quoteType && quoteTypeIncludesVSC(quoteType) && (
                    <FormGroup>
                      <h3>VSC Details</h3>
                      <Row>
                        <Col xs={12} md={6}>
                          <ContractTypeCheckboxes
                            name="vscRate.issueType"
                            disabled
                            quoteType={quoteType}
                          />
                        </Col>
                        <Col xs={12} md={6}>
                          <Input
                            name="vscRate.saleDate"
                            type="date"
                            label="Sale Date of the Vehicle"
                            placeholder={`eg. ${moment().format('MM/DD/YYYY')}`}
                            disabled={true}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={12} md={6}>
                          <Select
                            name="vscRate.language"
                            label="Contract Language"
                            options={CONTRACT_LANGUAGE_OPTIONS}
                            disabled={isLanguageDisabled({ quoteType, dealerState })}
                            data-test-id="VscRateForm-language"
                          />
                        </Col>
                        <Col xs={12} md={6}>
                          <Input
                            name="vscRate.vehiclePrice"
                            type="number"
                            label="Purchase Price of the Vehicle"
                            placeholder="eg. 25000"
                          />
                        </Col>
                      </Row>
                      <Row>
                        {isRetailPriceOfVscRequired({ quoteType }) && (
                          <Col xs={12} md={6}>
                            <Input
                              name="vscRate.retailPriceOfVsc"
                              type="number"
                              label="Retail Price of VSC"
                              placeholder="eg. 1688"
                              data-test-id="VscRateForm-retailPriceOfVsc"
                            />
                          </Col>
                        )}
                        <Col xs={12} md={6}>
                          <Input name="vscRate.vin" type="text" label="Vehicle VIN" disabled />
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={12} md={6}>
                          <Input
                            name="vscRate.vehicleMileage"
                            type="text"
                            label="Mileage"
                            disabled
                          />
                        </Col>
                        <Col xs={12} md={6}>
                          <Input
                            name="vscRate.stockNumber"
                            type="text"
                            label="Stock Number of the Vehicle"
                            placeholder="eg. 25OR624 (OPTIONAL)"
                            data-test-id="VscRateForm-stockNumber"
                          />
                        </Col>
                      </Row>
                      <hr />
                    </FormGroup>
                  )}
                  {quoteType && quoteTypeIncludesGAP(quoteType) && (
                    <FormGroup>
                      <h3>GAP Details</h3>
                      <Row>
                        <Col xs={12} md={6}>
                          <Input
                            name="gapRate.retailPriceOfGap"
                            type="text"
                            label="Retail Price of GAP"
                            placeholder="eg. 1200.00"
                            disabled={!!retailPriceOfGap}
                            data-test-id="GapRateForm-retailPriceOfGap"
                          />
                        </Col>
                        <Col xs={12} md={6}>
                          <Input
                            name="gapRate.loanAmountOfGap"
                            type="text"
                            label="Loan Amount of GAP"
                            placeholder="eg. 4500.00"
                            data-test-id="GapRateForm-loanAmountOfGap"
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={12} md={6}>
                          <Input
                            name="gapRate.gapLoanTermInMonths"
                            type="number"
                            label="GAP Loan Term in Months"
                            placeholder="eg. 72"
                            data-test-id="GapRateForm-gapLoanTermInMonths"
                          />
                        </Col>
                        <Col xs={12} md={6}>
                          <Select
                            name="gapRate.bhph"
                            label="BHPH Deal"
                            placeholder="Choose one"
                            options={BHPH_OPTIONS}
                            data-test-id="GapRateForm-bhph"
                          />
                        </Col>
                      </Row>
                      <Row>
                        {isKelleyBlueBookRequired({ bhph }) && (
                          <Col xs={12} md={6}>
                            <Input
                              name="gapRate.kelleyBlueBook"
                              type="text"
                              label="Kelly Blue Book Value"
                              placehholder="1350.00"
                              data-test-id="GapRateForm-kelleyBlueBook"
                            />
                          </Col>
                        )}
                      </Row>
                      <hr />
                    </FormGroup>
                  )}
                  {quoteType && quoteTypeIncludesTWP(quoteType) && (
                    <FormGroup>
                      <h3>T&W Details</h3>
                      <Row>
                        <Col xs={12} md={6}>
                          <Input
                            name="twpRate.retailPriceOfTwp"
                            type="text"
                            label="Retail Price of T&W"
                            placeholder="eg. 1200.00"
                            data-test-id="TwpRateForm-retailPriceOfTwp"
                          />
                        </Col>
                      </Row>
                      <hr />
                    </FormGroup>
                  )}
                  <FormGroup>
                    <h3>Customer Information</h3>
                    <Row>
                      <Col xs={12} md={6}>
                        <Input
                          name="customer.firstName"
                          type="text"
                          label="First Name"
                          placeholder="eg. George"
                          data-test-id="CustomerInformationForm-firstName"
                        />
                      </Col>
                      <Col xs={12} md={6}>
                        <Input
                          name="customer.middleInitial"
                          type="text"
                          label="Middle Initial"
                          placeholder="eg. R"
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12} md={6}>
                        <Input
                          name="customer.lastName"
                          type="text"
                          label="Last Name"
                          placeholder="eg. Jones"
                          data-test-id="CustomerInformationForm-lastName"
                        />
                      </Col>
                      <Col xs={12} md={6}>
                        <Input
                          name="customer.address.zipCode"
                          type="text"
                          label="Zip Code"
                          placeholder="eg. 11101, 11101-1234"
                          data-test-id="CustomerInformationForm-zipCode"
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12} md={6}>
                        <Input
                          name="customer.address.street"
                          type="text"
                          label="Address"
                          placeholder="eg. 919 Main St."
                          data-test-id="CustomerInformationForm-street"
                        />
                      </Col>
                      <Col xs={12} md={6}>
                        <Input
                          name="customer.address.city"
                          type="text"
                          label="City"
                          placeholder="eg. Lafayette"
                          data-test-id="CustomerInformationForm-city"
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12} md={6}>
                        <Input
                          name="customer.address.state"
                          type="text"
                          label="State"
                          options={STATES_VALUES}
                          data-test-id="CustomerInformationForm-state"
                        />
                      </Col>
                      <Col xs={12} md={6}>
                        <Input
                          name="customer.phoneNumber"
                          type="text"
                          label="Phone Number"
                          placeholder="eg. 8882627890"
                          data-test-id="CustomerInformationForm-phoneNumber"
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12} md={6}>
                        <Input
                          name="customer.email"
                          type="text"
                          label={
                            <>
                              Email{' '}
                              {eSign && (
                                <small style={{ opacity: 0.6 }}>
                                  E-Sign instructions will be sent over
                                </small>
                              )}
                            </>
                          }
                          placeholder="eg. george.jones@superiorautos.com"
                          data-test-id="CustomerInformationForm-email"
                        />
                      </Col>
                      <Col xs={12} md={6}>
                        <Input
                          name="customer.confirmEmail"
                          type="text"
                          label="Confirm"
                          placeholder="eg. george.jones@superiorautos.com"
                          data-test-id="CustomerInformationForm-confirmEmail"
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12} md={6}>
                        <label>
                          <Field type="checkbox" name="customer.hasCoBuyer" className="mr-2" />I
                          have a co-buyer
                        </label>
                      </Col>
                    </Row>
                    {hasCoBuyer && (
                      <>
                        <Row>
                          <Col xs={12} md={6}>
                            <Input
                              name="customer.coBuyerFirstName"
                              type="text"
                              label="First Name"
                              placeholder="eg. Waylon"
                            />
                          </Col>
                          <Col xs={12} md={6}>
                            <Input
                              name="customer.coBuyerLastName"
                              type="text"
                              label="Last Name"
                              placeholder="eg. Jennings"
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col xs={12} md={6}>
                            <Input
                              name="customer.coBuyerEmail"
                              type="text"
                              label="Email"
                              placeholder="eg. waylon.jennings@worthycarriages.net"
                            />
                          </Col>
                          <Col xs={12} md={6}>
                            <Input
                              name="customer.coBuyerConfirmEmail"
                              type="text"
                              label="Confirm"
                              placeholder="eg. waylon.jennings@worthycarriages.net"
                            />
                          </Col>
                        </Row>
                      </>
                    )}
                    <hr />
                  </FormGroup>
                  <FormGroup>
                    <h3>Lender Information</h3>
                    <AvailableLenders
                      onLenderSelected={handleLenderSelected}
                      onLenderEdit={handleLenderEdit}
                    />
                    <Row>
                      <Col xs={12} md={6}>
                        <Input
                          name="lender.companyName"
                          type="text"
                          label="Finance Company Name"
                          disabled={nonEditableLender}
                          placeholder="eg. First National Bank"
                        />
                      </Col>
                      <Col xs={12} md={6}>
                        <Input
                          name="lender.address.zipCode"
                          type="text"
                          label="Zip Code"
                          placeholder="eg. 11101"
                          disabled={nonEditableLender}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12} md={6}>
                        <Input
                          name="lender.address.street"
                          type="text"
                          label="Address"
                          placeholder="eg. 919 Main St."
                          disabled={nonEditableLender}
                        />
                      </Col>
                      <Col xs={12} md={6}>
                        <Input
                          name="lender.address.city"
                          type="text"
                          label="City"
                          placeholder="eg. Lafayette"
                          disabled={nonEditableLender}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12} md={6}>
                        <Input
                          name="lender.address.state"
                          type="text"
                          label="State"
                          options={STATES_VALUES}
                          disabled={nonEditableLender}
                        />
                      </Col>
                      <Col xs={12} md={6}>
                        <Input
                          name="lender.phoneNumber"
                          type="text"
                          label="Finance Company Phone Number"
                          placeholder="eg. 8882627890"
                          disabled={nonEditableLender}
                        />
                      </Col>
                    </Row>
                    <hr />
                  </FormGroup>
                  <Row className="margin-top-20px">
                    {' '}
                    <Col xs={12}>
                      <SubmitButton
                        loadingText="Retrieving Quote..."
                        isValid={isValid}
                        isSubmitting={isSubmitting}
                        data-test-name="VehicleBasicInformationForm-Buttons"
                      >
                        Generate Contracts
                      </SubmitButton>
                    </Col>
                  </Row>
                </form>
              );
            }}
          </Formik>
        </div>
      )}

      <CreateDuplicateContractConfirmation
        show={showConfirmCancelExistingContract}
        disabled={loading}
        onConfirm={keepContractTypes =>
          handleConfirmCancelExistingContract(getCombinedPendingContract(), true, keepContractTypes)
        }
        onClose={() => handleConfirmCancelExistingContract(getCombinedPendingContract(), false)}
        pendingQuoteType={quoteType}
        existingContract={existingContract}
      />
      {issueError && (
        <div data-test-id="Rate-issueError">
          <TaskEventsList taskEvents={Array.isArray(issueError) ? issueError : []} />
        </div>
      )}
    </>
  );
}

export default connect(
  ({ dealer, page, newQuote }) => {
    const saleDate = _.get(newQuote, 'form.vehicleSaleDate');
    const quoteType = _.get(newQuote, 'form.quoteType');
    const vscQuote = selectedVscQuote({ newQuote });
    const gapQuote = selectedGapQuote({ newQuote });
    const twpQuote = selectedTwpQuote({ newQuote });

    const retailPriceOfGap = _.get(gapQuote, 'retailCostRequired');
    return {
      loading: _.get(page, 'loading'),
      quoteType,
      saleDate: getInitialSaleDate(saleDate),
      newQuote,
      dealer: _.get(dealer, 'details'),
      dealerState: _.get(dealer, 'details.address.state'),
      retailPriceOfGap,
      contractYear: _.get(dealer, 'details.rating.contractYear'),
      vscQuote,
      gapQuote,
      twpQuote,
      extraCoverages: _.values(newQuote.extraCoverages),
    };
  },
  { createIssuedContract, deleteIssuedContract, notifSend },
)(Rate);
