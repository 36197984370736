import rest from '../../utils/service';

export const GET_API_KEYS = 'apiKeys/GET_API_KEYS';
export const getApiKeys = id => async dispatch => {
  const { data } = await rest.get(`api-keys/${id || ''}`);
  dispatch({ type: GET_API_KEYS, payload: data.keys || [] });
};

// export const CREATE_OR_UPDATE_API_KEY = 'apiKeys/UPDATE_API_KEY';
export const createOrUpdateApiKey =
  ({ application, permissions }) =>
  async dispatch => {
    await rest.post('api-keys', { application, permissions: [permissions] });
    dispatch(getApiKeys());
  };

// export const DELETE_API_KEY = 'apiKeys/DELETE_API_KEY';
export const deleteApiKey = id => async dispatch => {
  await rest.delete(`api-keys/${id}`);
  dispatch(getApiKeys());
};

const initialState = { keys: [] };
export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case GET_API_KEYS:
      return {
        ...state,
        keys: action.payload,
      };
    default:
      return state;
  }
}
