/* eslint-disable no-restricted-syntax */

import api from '../../utils/service';

export function getZipCodeAddress(zipCode) {
  return api
    .get(`/addresses?zipCode=${zipCode}`)
    .then(data => data)
    .catch(error => {
      throw new Error(error.message);
    });
}
