import React, { PureComponent } from 'react';
import { reduxForm, Field, propTypes } from 'redux-form';
import { EMAIL_VERIFICATION_FORM } from '../redux/modules/form';
import whiteListedEmailValidation from './validation/whiteListedEmailValidation';
import ValidationInput from './common/ValidationInput';

export class EmailVerficationForm extends PureComponent {
  static propTypes = {
    ...propTypes,
  };

  render() {
    const { submitHandler, error } = this.props;
    return (
      <form
        className="form-horizontal margin-bottom-20px"
        onSubmit={submitHandler}
        noValidate={true}
        data-test-id="LoginForm"
      >
        <legend>Add Email</legend>
        <Field
          name="email"
          type="text"
          data-test-id="LoginForm-email"
          component={ValidationInput}
          label="Email"
        />
        {error && (
          <p className="text-danger" data-test-id="LoginForm-error">
            <strong>{error}</strong>
          </p>
        )}
        <div className="row">
          <div className="col-md-2 col-xs-2">
            <button
              className="btn btn-primary btn-block"
              type="submit"
              data-test-id="LoginForm-submit"
            >
              Add
            </button>
          </div>
        </div>
      </form>
    );
  }
}

export default reduxForm({
  form: EMAIL_VERIFICATION_FORM,
  validate: whiteListedEmailValidation,
})(EmailVerficationForm);
