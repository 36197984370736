import React, { useCallback, useMemo } from 'react';
import _ from 'lodash';

const changeValueArray = ({ item, checked, value }) => {
  const includes = value.includes(item);
  if (!checked && includes) return value;
  if (checked && !includes) return value;

  if (checked) return [...value, item];
  else return value.filter(i => i !== item);
};

const sortByTitle = itemTitlePairs =>
  _.clone(itemTitlePairs).sort((a, b) => {
    if (a[1] > b[1]) return 1;
    if (a[1] < b[1]) return -1;
    return 0;
  });

export const makeValidationCheckboxSet = opts => {
  const {
    parseValue = _.identity,
    getItemTitle: getItemTitleDefault = _.identity,
    changeValue = changeValueArray,
    allowItemsDefault = [],
    sortDefault = sortByTitle,
  } = opts;

  const ValidationCheckboxSet = props => {
    const {
      className,
      input,
      meta,
      label,
      getItemTitle = getItemTitleDefault,
      allowItems = allowItemsDefault,
      requireSelection,
      sort = sortDefault,
      disabled,
      'data-test-name': dataTestName,
    } = props;

    const { value, initial, onChange, onBlur, onFocus } = input;

    const { error, touched, active } = meta;

    const selectedItems = parseValue(value);
    const allowedItems = Array.isArray(allowItems) ? allowItems : allowItems({ value, initial });

    const handleChange = useMemo(
      () =>
        onChange
          ? ({ currentTarget: { value: item, checked } }) =>
              onChange(changeValue({ item, checked, value }))
          : undefined,
      [onChange, value],
    );

    const requiredItems =
      requireSelection && selectedItems.length === 1 ? selectedItems : undefined;

    const itemTitlePairs = useMemo(
      () => sort(allowedItems.map(item => [item, getItemTitle(item) || item])),
      [allowedItems],
    );

    const checkboxes = itemTitlePairs.map(([item, title]) => (
      <label style={{ display: 'inline-flex', alignItems: 'center', marginRight: '1.75rem' }}>
        <input
          key={item}
          type="checkbox"
          value={item}
          checked={selectedItems.includes(item)}
          onChange={handleChange}
          disabled={disabled || (requiredItems && requiredItems.includes(item))}
          style={{ width: '1.5rem', height: '1.5rem', margin: 0 }}
          data-test-id={dataTestName ? `${dataTestName}-${item}` : undefined}
        />
        <span>&nbsp;{title}</span>
      </label>
    ));

    const handleFocus = useCallback(() => {
      if (!active) onFocus(value);
    }, [active, onFocus, value]);

    const handleBlur = useCallback(
      event => {
        if (event.currentTarget.contains(event.relatedTarget)) return undefined;
        onBlur(value);
      },
      [onBlur, value],
    );

    return (
      <div className={`form-group ${error && touched ? 'has-error' : ''} ${className}`}>
        <label className="col-sm-3">{label}</label>
        <div className="col-sm-9">
          <div
            onFocusOut={handleBlur}
            onFocus={handleFocus}
            style={{ display: 'flex', alignItems: 'center' }}
          >
            {checkboxes}
          </div>
          {error && touched && (
            <span className="glyphicon glyphicon-remove form-control-feedback" />
          )}
          {error && touched && (
            <div className="text-danger">
              <strong>{error}</strong>
            </div>
          )}
        </div>
      </div>
    );
  };

  return ValidationCheckboxSet;
};
