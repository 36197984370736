import React, { useState, useEffect } from 'react';
import {
  Grid,
  Row,
  Col,
  FormGroup,
  ControlLabel,
  FormControl,
  HelpBlock,
  Checkbox,
  Button,
  Alert,
} from 'react-bootstrap';
import { statesForSelection } from '../../../constants/general';
import api from '../../../utils/service';
import GenericTable from '../../../components/common/GenericTable';

function FieldGroup({ id, label, ...props }) {
  return (
    <FormGroup controlId={id}>
      <ControlLabel>{label}</ControlLabel>
      <FormControl {...props} />
    </FormGroup>
  );
}

/**
 *
 * @param onResponse { function(err, data) }
 * @return {function({ dealerId: number, state: string, required: boolean })}
 */
const putMandatoryEsignRequirement =
  onResponse =>
  async ({ dealerId, state, required }) => {
    const query = dealerId ? `${dealerId}?` : `?state=${state}&`;
    try {
      const { data } = await api.put(`/e-sign/mandatory/${query}required=${required}`);
      onResponse(null, data);
    } catch (err) {
      onResponse(err, null);
    }
  };

export default () => {
  const [dealerId, setRequiredDealerId] = useState(undefined);
  const [dealerState, setRequiredState] = useState(undefined);
  const [eSignRequired, setESignRequired] = useState(true);
  const [mandatoryItems, setMandatoryItems] = useState([]);
  const [status, setStatus] = useState({});

  const getMandatory = async () => {
    const { data } = await api.get('/e-sign/mandatory');
    setMandatoryItems(data.required);
  };

  const handleSubmit = putMandatoryEsignRequirement((err, data) => {
    if (err) {
      setStatus({ status: 'danger', message: err.toString() });
    } else {
      setStatus({
        status: 'success',
        message: `Set ${data.dealerId || data.state} to ${
          data.eSignRequired ? 'REQUIRED' : 'NOT-REQUIRED'
        }`,
      });
      setTimeout(() => {
        setStatus({});
      }, 5000);
      getMandatory();
    }
  });

  useEffect(() => {
    getMandatory();
  }, []);

  const missingRequired = !dealerId && !dealerState;

  const setValues = row => {
    if (row) {
      setRequiredDealerId(row.dealerId);
      setRequiredState(row.state);
      setESignRequired(row.eSignRequired);
    } else {
      setRequiredDealerId(undefined);
      setRequiredState(undefined);
      setESignRequired(true);
    }
  };
  const { status: statusType, message } = status || {};
  return (
    <Grid>
      {statusType && (
        <Alert className="alert-success" type={statusType} title="Success">
          {message}
        </Alert>
      )}
      <Row>Require a dealer or state to use e-sign</Row>
      <Row>
        <Col md={6}>
          <GenericTable
            headers={['dealerId', 'state', 'eSignRequired', 'updated']}
            data={mandatoryItems}
            onClick={setValues}
          />
        </Col>
        <Col md={6}>
          <form
            onSubmit={e => {
              e.preventDefault();
              handleSubmit({ dealerId, state: dealerState, required: eSignRequired });
            }}
          >
            <FieldGroup
              id="dealerId"
              type="text"
              label="Text"
              placeholder="Dealer ID"
              value={dealerId || ''}
              onChange={({ target }) => setRequiredDealerId(target.value)}
            />
            <FormGroup controlId="state">
              <ControlLabel>State</ControlLabel>
              <FormControl
                componentClass="select"
                placeholder="select"
                value={dealerState || ''}
                onChange={({ target }) => setRequiredState(target.value)}
              >
                <option value={null}>Select State</option>
                {statesForSelection().map(({ label, value }) => (
                  <option key={label} value={value}>
                    {label}
                  </option>
                ))}
              </FormControl>
              <Checkbox
                checked={eSignRequired}
                onChange={({ target }) => setESignRequired(target.checked)}
              >
                Required
              </Checkbox>
            </FormGroup>
            {missingRequired && (
              <HelpBlock>You must select either a state or input a dealer Id</HelpBlock>
            )}
            <Button disabled={missingRequired} type="submit">
              Submit
            </Button>
            <Button
              bsStyle="danger"
              disabled={missingRequired}
              type="button"
              onClick={() => setValues()}
            >
              Clear
            </Button>
          </form>
        </Col>
      </Row>
    </Grid>
  );
};
