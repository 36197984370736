import { useCallback, useEffect, useState } from 'react';

export const useUncontrolled = (controlled, changeControlled, defaultUncontrolled) => {
  const [uncontrolled, setUncontrolled] = useState();

  const handleChange = useCallback(
    newValue => {
      setUncontrolled(newValue);
      if (changeControlled) changeControlled(newValue);
    },
    [changeControlled, defaultUncontrolled],
  );

  useEffect(() => {
    if (controlled !== undefined && controlled !== uncontrolled) setUncontrolled(controlled);
  }, []);

  useEffect(() => {
    if (controlled !== undefined && controlled !== uncontrolled) setUncontrolled(controlled);
  }, []);

  let current = defaultUncontrolled;
  if (uncontrolled !== undefined) current = uncontrolled;
  if (controlled !== undefined) current = controlled;

  return [current, handleChange];
};
