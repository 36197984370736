import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose, withState } from 'recompose';
import _ from 'lodash';
import { Row, Col, Button } from 'react-bootstrap';
import { getAllLenders, deleteLender } from '../../../redux/modules/lender';
import { preventDefault } from '../../../utils/click';

export function AvailableLenders(props) {
  useEffect(() => {
    const { getAllLenders } = props;
    getAllLenders();
  }, []);

  const getSelectedLenderObject = id => {
    const { lenders, selectedLender } = props;
    return _.find(lenders, { _id: id || selectedLender });
  };

  const handleChange = e => {
    const { onLenderSelected, onUpdateSelectedLender } = props;
    const value = _.get(e, 'target.value');
    // NOTE - These have to be done in order
    onUpdateSelectedLender(value);
    onLenderSelected(getSelectedLenderObject(value));
  };

  const handleEnterNewLender = () => {
    const { onLenderSelected, onUpdateSelectedLender } = props;
    onLenderSelected(undefined);
    onUpdateSelectedLender('');
  };

  const handleEditLender = () => {
    const { onLenderEdit } = props;
    onLenderEdit(getSelectedLenderObject());
  };

  const handleDeleteLender = () => {
    const { selectedLender, deleteLender } = props;
    if (selectedLender) {
      deleteLender(selectedLender);
    }
  };

  const { selectedLender, lenders } = props;
  return (
    <Row className="margin-bottom-40px">
      <Col xs={12}>
        <label>Available Lenders</label>
        <select
          value={selectedLender}
          className="form-control"
          onChange={handleChange}
          data-test-id="LenderInformationForm-AvailableLenders-select"
        >
          <option value="" disabled={true}>
            Choose one
          </option>
          {_.map(lenders, lender => (
            <option value={lender._id} key={lender._id}>
              {lender.name} - {_.get(lender.address, 'city')}, {_.get(lender.address, 'state')}
            </option>
          ))}
        </select>
      </Col>
      {selectedLender && (
        <Col xs={12} onClick={preventDefault}>
          <Button bsStyle="link" onClick={handleEnterNewLender}>
            Enter New Lender
          </Button>
          <span>|</span>
          <Button bsStyle="link" onClick={handleEditLender}>
            Edit This Lender
          </Button>
          <span>|</span>
          <Button bsStyle="link" onClick={handleDeleteLender}>
            Delete This Lender
          </Button>
        </Col>
      )}
    </Row>
  );
}

AvailableLenders.propTypes = {
  lenders: PropTypes.array,
  selectedLender: PropTypes.string,
  onLenderSelected: PropTypes.func,
  onLenderEdit: PropTypes.func,
  getAllLenders: PropTypes.func.isRequired,
  deleteLender: PropTypes.func.isRequired,
  onUpdateSelectedLender: PropTypes.func.isRequired,
};

AvailableLenders.defaultProps = {
  lenders: [],
  selectedLender: null,
  onLenderSelected: _.noop,
  onLenderEdit: _.noop,
};

export default compose(
  connect(
    ({ lender }) => ({
      lenders: lender.list,
    }),
    {
      getAllLenders,
      deleteLender,
    },
  ),
  withState('selectedLender', 'onUpdateSelectedLender', ''),
)(AvailableLenders);
