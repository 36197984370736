import React, { Fragment } from 'react';

function BrowserWarning() {
  return (
    <Fragment>
      <p>Recommended Browser: Chrome.</p>
      <p>
        For help with PRcoRATE / ePRco click "Live Chat" or "Email" below or call 1-800-388-PRCO
        Monday through Friday 8:30 am - 5:00 pm. You can also click here:{' '}
        <a href="https://prcousa.com/knowledge-base/" target="_blank" rel="noopener noreferrer">
          Knowledge Base Page
        </a>{' '}
        for the step by step tutorial for PRcoRate / ePRco.
      </p>
    </Fragment>
  );
}

export default BrowserWarning;
