import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Helmet from 'react-helmet';
import { connect } from 'react-redux';
import Grid from 'react-bootstrap/lib/Grid';
import Row from 'react-bootstrap/lib/Row';
import Col from 'react-bootstrap/lib/Col';
import Button from 'react-bootstrap/lib/Button';
import { LinkContainer } from 'react-router-bootstrap';
import { loadQueryParams } from '../redux/modules/queryParams';
import { sendStatements } from '../redux/modules/statements';

const getStatementsUrlFromEnvironment = () => {
  if (/staging|alpha|localhost/gi.test(window.location.href)) {
    return `${process.env.REACT_APP_PRCO_STATEMENTS_STAGING_URL}?autoLogin=true`;
  }
  return `${process.env.REACT_APP_PRCO_STATEMENTS_PRODUCTION_URL}?autoLogin=true`;
};

const getPayUrlFromEnvironment = () => {
  if (/staging|alpha|localhost/gi.test(window.location.href)) {
    return `${process.env.REACT_APP_PRCO_ACH_PAYMENTS_STAGING_URL}?autoLogin=true`;
  }
  return `${process.env.REACT_APP_PRCO_ACH_PAYMENTS_PRODUCTION_URL}?autoLogin=true`;
};

const HomeContent = props => {
  const { user, sendStatements } = props;

  return (
    <div data-test-id="Home">
      <Helmet title="Home" />
      <Grid>
        <Row>
          <Col md={8} xs={12} mdOffset={2}>
            <LinkContainer to="/newQuote">
              <Button
                bsStyle="primary"
                bsSize="large"
                block
                className="home-container-link-container"
              >
                New Quote
              </Button>
            </LinkContainer>
          </Col>
        </Row>
        <Row>
          <Col md={8} xs={12} mdOffset={2}>
            <LinkContainer to="http://prcousa.com/1016-2/" target="_blank">
              <Button
                bsStyle="primary"
                bsSize="large"
                block
                className="home-container-link-container"
              >
                Dealer Forms
              </Button>
            </LinkContainer>
          </Col>
        </Row>
        <Row>
          <Col md={8} xs={12} mdOffset={2}>
            <LinkContainer to="/allQuotes">
              <Button
                bsStyle="primary"
                bsSize="large"
                block
                className="home-container-link-container"
              >
                All Quotes & Contracts
              </Button>
            </LinkContainer>
          </Col>
        </Row>
        <Row>
          <Col md={8} xs={12} mdOffset={2}>
            <LinkContainer to="/dealerSettings">
              <Button
                bsStyle="primary"
                bsSize="large"
                block
                className="home-container-link-container"
              >
                Dealer Rate Settings
              </Button>
            </LinkContainer>
          </Col>
        </Row>
        <Row>
          <Col md={4} xs={6} mdOffset={2}>
            <Button
              href={getStatementsUrlFromEnvironment()}
              componentClass="a"
              target="_blank"
              bsSize="large"
              block
              className="home-container-link-container"
            >
              PRco Statements
            </Button>
          </Col>
          <Col md={4} xs={6}>
            <Button
              onClick={sendStatements}
              bsSize="large"
              block
              className="home-container-link-container"
            >
              Email Statements
            </Button>
          </Col>
        </Row>
        <Row>
          <Col md={8} xs={12} mdOffset={2}>
            <Button
              href={getPayUrlFromEnvironment()}
              componentClass="a"
              target="_blank"
              bsSize="large"
              block
              className="home-container-link-container"
            >
              Pay PRco
            </Button>
          </Col>
        </Row>
        <Row>
          <Col md={8} xs={12} mdOffset={2}>
            <LinkContainer to="/profile">
              <Button
                bsStyle="default"
                bsSize="large"
                block
                className="home-container-link-container"
              >
                Manage My Profile
              </Button>
            </LinkContainer>
          </Col>
        </Row>
        {(_.get(user, 'dealerRel.prcoAdmin') || _.get(user, 'dealerRel.dealerAdmin')) && (
          <React.Fragment>
            <Row>
              <Col md={8} xs={12} mdOffset={2}>
                <LinkContainer to="/admin/users">
                  <Button
                    bsStyle="default"
                    bsSize="large"
                    block
                    className="home-container-link-container"
                  >
                    Manage Users
                  </Button>
                </LinkContainer>
              </Col>
            </Row>
            {_.get(user, 'dealerRel.prcoAdmin') && (
              <Row>
                <Col md={8} xs={12} mdOffset={2}>
                  <LinkContainer to="/admin">
                    <Button
                      bsStyle="default"
                      bsSize="large"
                      block
                      className="home-container-link-container"
                    >
                      Admin
                    </Button>
                  </LinkContainer>
                </Col>
              </Row>
            )}
            {_.get(user, 'dealerRel.prcoAdmin') && (
              <Row>
                <Col md={8} xs={12} mdOffset={2}>
                  <LinkContainer to="/admin/api-keys">
                    <Button
                      bsStyle="default"
                      bsSize="large"
                      block
                      className="home-container-link-container"
                    >
                      Api Keys
                    </Button>
                  </LinkContainer>
                </Col>
              </Row>
            )}
          </React.Fragment>
        )}
      </Grid>
    </div>
  );
};

HomeContent.propTypes = {
  user: PropTypes.object.isRequired,
};

HomeContent.defaultProps = {};

export default connect(
  ({ auth }) => ({
    user: auth.user,
  }),
  {
    loadQueryParams,
    sendStatements,
  },
)(HomeContent);
