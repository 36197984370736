import _ from 'lodash';
import {
  contractTypeDetailTitle,
  contractTypesAll,
  contractTypeTitle,
  CONTRACT_TYPE_GAP,
  CONTRACT_TYPE_TWP,
  CONTRACT_TYPE_VSC,
} from './contractType';

/** @deprecated */
export const QUOTE_TYPE_BOTH = 'BOTH';

export const replaceBothInItems = items => {
  const itemsUniq = _.uniq(items);

  if (!itemsUniq.includes(QUOTE_TYPE_BOTH)) return itemsUniq;

  const index = itemsUniq.indexOf(QUOTE_TYPE_BOTH);

  return _.uniq([
    ...itemsUniq.slice(0, index),
    CONTRACT_TYPE_GAP,
    CONTRACT_TYPE_VSC,
    ...itemsUniq.slice(index + 1),
  ]);
};

export const quoteTypeItems = quoteTypeSrc => {
  const quoteType =
    typeof quoteTypeSrc === 'string' ? quoteTypeSrc.toUpperCase().trim() : quoteTypeSrc;

  // Following is for backward compatibility
  if (quoteType === 'ALL') return [...contractTypesAll].sort();
  if (quoteType === QUOTE_TYPE_BOTH || quoteType === 'PB' /* PrcoContractTypes.BOTH */)
    return [CONTRACT_TYPE_GAP, CONTRACT_TYPE_VSC].sort();
  if (quoteType === 'PG' /* PrcoContractTypes.GAP */) return [CONTRACT_TYPE_GAP];
  if (quoteType === 'PV' /* PrcoContractTypes.VSC */) return [CONTRACT_TYPE_VSC];

  if (!quoteType) return [];

  return replaceBothInItems(
    Array.isArray(quoteType)
      ? _.flatten(
          _.flattenDeep(quoteType)
            .filter(t => !!t)
            .map(t => t.split(',')),
        )
      : quoteType.split(','),
  ).sort();
};

export const quoteTypeNormalize = quoteType => quoteTypeItems(quoteType).join(',');

export const quoteTypeIncludesSome =
  (...quoteTypeNeedle) =>
  quoteTypeHaystack => {
    const haystackNormItems = quoteTypeItems(quoteTypeHaystack);
    const needleNormItems = quoteTypeItems(quoteTypeNeedle.join(','));
    return needleNormItems.some(item => haystackNormItems.includes(item));
  };

export const quoteTypeIncludesEvery =
  (...quoteTypeNeedle) =>
  quoteTypeHaystack => {
    const haystackNormItems = quoteTypeItems(quoteTypeHaystack);
    const needleNormItems = quoteTypeItems(quoteTypeNeedle.join(','));
    return needleNormItems.every(item => haystackNormItems.includes(item));
  };

export const quoteTypesEqual = a => b => quoteTypeNormalize(a) === quoteTypeNormalize(b);

export const quoteTypeIncludesExactly =
  (...quoteTypeOrItemNeedles) =>
  quoteType =>
    quoteTypeNormalize(quoteTypeOrItemNeedles) === quoteTypeNormalize(quoteType);

export const quoteTypeIncludesOnly =
  (...quoteTypeNeedle) =>
  quoteTypeHaystack => {
    const haystackNormItems = quoteTypeItems(quoteTypeHaystack);
    const needleNormItems = quoteTypeItems(quoteTypeNeedle.join(','));
    if (needleNormItems.length === 0) return false;
    return haystackNormItems.every(item => needleNormItems.includes(item));
  };

export const quoteTypeUnion = (...quoteTypesAndItems) =>
  quoteTypeNormalize(
    _.flattenDeep(quoteTypesAndItems)
      .filter(type => !!type)
      .map(quoteTypeItems),
  );

export const quoteTypeExclude =
  (...quoteTypesAndItems) =>
  quoteType => {
    const removeItems = quoteTypeItems(quoteTypesAndItems.join(','));

    return quoteTypeItems(quoteType)
      .filter(item => !removeItems.includes(item))
      .join(',');
  };

export const quoteTypeFilter = filterFn => quoteType =>
  quoteTypeItems(quoteType).filter(filterFn).join(',');
export const quoteTypeDifference = inspect => exclude =>
  _.difference(quoteTypeItems(inspect), quoteTypeItems(exclude));
export const quoteTypeIntersection = inspect => exclude =>
  _.intersection(quoteTypeItems(inspect), quoteTypeItems(exclude));

export const quoteTypeIncludesVSC = quoteTypeIncludesSome(CONTRACT_TYPE_VSC);
export const QUOTE_TYPE_ONLY_VSC = quoteTypeUnion(CONTRACT_TYPE_VSC);
export const quoteTypeIncludesOnlyVSC = quoteTypeIncludesExactly(CONTRACT_TYPE_VSC);

export const quoteTypeIncludesTWP = quoteTypeIncludesSome(CONTRACT_TYPE_TWP);
export const QUOTE_TYPE_ONLY_TWP = quoteTypeUnion(CONTRACT_TYPE_TWP);
export const quoteTypeIncludesOnlyTWP = quoteTypeIncludesExactly(CONTRACT_TYPE_TWP);

/**
 * @deprecated
 * @TODO inspect usage - looks like it should not be used anymore
 */
export const quoteTypeIncludesVSCxTWP = quoteTypeIncludesSome(CONTRACT_TYPE_VSC, CONTRACT_TYPE_TWP);

export const quoteTypeIncludesGAP = quoteTypeIncludesSome(CONTRACT_TYPE_GAP);
export const QUOTE_TYPE_ONLY_GAP = quoteTypeUnion(CONTRACT_TYPE_GAP);
export const quoteTypeIncludesOnlyGAP = quoteTypeIncludesExactly(CONTRACT_TYPE_GAP);

export const quoteTypeTitle = (quoteType, separator = ' & ') =>
  quoteTypeItems(quoteType).map(contractTypeTitle).join(separator);

export const quoteTypeDetailTitle = (quoteType, separator = ' & ') =>
  quoteTypeItems(quoteType).map(contractTypeDetailTitle).join(separator);
