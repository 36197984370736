/* eslint-disable react/sort-comp */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { reduxForm, Field, propTypes, change } from 'redux-form';
import _ from 'lodash';
import Helmet from 'react-helmet';
import { Button, Grid, Row, Col } from 'react-bootstrap';
import { DEALER_SETTINGS_FORM } from '../redux/modules/form';
import { getDealerSettings, updateDealerSettings } from '../redux/modules/dealerSettings';
import ValidationInput from '../components/common/ValidationInput';
import ValidationSelect from '../components/common/ValidationSelect';
import { CONTRACT_TYPE_GAP, CONTRACT_TYPE_TWP, CONTRACT_TYPE_VSC } from '../constants/contractType';

const DOLLAR_MARKUP = 'dollarValueMarkup';
const PERCENTAGE_MARKUP = 'percentageMarkup';

const MARKUP_OPTIONS = [
  {
    value: DOLLAR_MARKUP,
    label: 'Dollar Value Markup',
  },
  {
    value: PERCENTAGE_MARKUP,
    label: 'Percentage Markup',
  },
];

export class DealerSettingsForm extends Component {
  static propTypes = {
    ...propTypes,
    dealerId: PropTypes.string.isRequired,
    dealerSettingsId: PropTypes.string,
    getDealerSettings: PropTypes.func.isRequired,
  };

  static defaultProps = {
    dealerSettingsId: null,
  };

  constructor() {
    super();
    this.state = {
      handleVSCMarkup: DOLLAR_MARKUP,
      handleTWPMarkup: DOLLAR_MARKUP,
      handleGAPMarkup: DOLLAR_MARKUP,
    };
  }

  componentDidMount() {
    const { dealerId, getDealerSettings } = this.props;
    getDealerSettings(dealerId);
  }

  componentWillReceiveProps(nextProps) {
    const { dispatch } = nextProps;
    if (nextProps.dealerSettingsId && !this.dealerSettingsLoaded) {
      dispatch(
        change(
          DEALER_SETTINGS_FORM,
          'markupSelectVSC',
          _.get(nextProps, 'dealerSettings.percentageMarkupVSC')
            ? PERCENTAGE_MARKUP
            : DOLLAR_MARKUP,
        ),
      );
      dispatch(
        change(
          DEALER_SETTINGS_FORM,
          'markupSelectTWP',
          _.get(nextProps, 'dealerSettings.percentageMarkupTWP')
            ? PERCENTAGE_MARKUP
            : DOLLAR_MARKUP,
        ),
      );
      dispatch(
        change(
          DEALER_SETTINGS_FORM,
          'markupSelectGAP',
          _.get(nextProps, 'dealerSettings.percentageMarkupGAP')
            ? PERCENTAGE_MARKUP
            : DOLLAR_MARKUP,
        ),
      );
      dispatch(
        change(
          DEALER_SETTINGS_FORM,
          'dollarMarkupVSC',
          _.get(nextProps, 'dealerSettings.dollarMarkupVSC'),
        ),
      );
      dispatch(
        change(
          DEALER_SETTINGS_FORM,
          'percentageMarkupVSC',
          _.get(nextProps, 'dealerSettings.percentageMarkupVSC'),
        ),
      );
      dispatch(
        change(
          DEALER_SETTINGS_FORM,
          'dollarMarkupTWP',
          _.get(nextProps, 'dealerSettings.dollarMarkupTWP'),
        ),
      );
      dispatch(
        change(
          DEALER_SETTINGS_FORM,
          'percentageMarkupTWP',
          _.get(nextProps, 'dealerSettings.percentageMarkupTWP'),
        ),
      );
      dispatch(
        change(
          DEALER_SETTINGS_FORM,
          'dollarMarkupGAP',
          _.get(nextProps, 'dealerSettings.dollarMarkupGAP'),
        ),
      );
      dispatch(
        change(
          DEALER_SETTINGS_FORM,
          'percentageMarkupGAP',
          _.get(nextProps, 'dealerSettings.percentageMarkupGAP'),
        ),
      );
      this.setState({
        handleVSCMarkup: _.get(nextProps, 'dealerSettings.percentageMarkupVSC')
          ? PERCENTAGE_MARKUP
          : DOLLAR_MARKUP,
        handleTWPMarkup: _.get(nextProps, 'dealerSettings.percentageMarkupTWP')
          ? PERCENTAGE_MARKUP
          : DOLLAR_MARKUP,
        handleGAPMarkup: _.get(nextProps, 'dealerSettings.percentageMarkupGAP')
          ? PERCENTAGE_MARKUP
          : DOLLAR_MARKUP,
      });
      this.dealerSettingsLoaded = true;
    }
  }

  handleMarkupType(e, type) {
    const { dispatch } = this.props;
    if (type === CONTRACT_TYPE_VSC) {
      this.setState({ handleVSCMarkup: e.target.value });
      dispatch(
        change(
          DEALER_SETTINGS_FORM,
          e.target.value === PERCENTAGE_MARKUP ? 'dollarMarkupVSC' : 'percentageMarkupVSC',
          null,
        ),
      );
    } else if (type === CONTRACT_TYPE_TWP) {
      this.setState({ handleTWPMarkup: e.target.value });
      dispatch(
        change(
          DEALER_SETTINGS_FORM,
          e.target.value === PERCENTAGE_MARKUP ? 'dollarMarkupTWP' : 'percentageMarkupTWP',
          null,
        ),
      );
    } else if (type === CONTRACT_TYPE_GAP) {
      this.setState({ handleGAPMarkup: e.target.value });
      dispatch(
        change(
          DEALER_SETTINGS_FORM,
          e.target.value === PERCENTAGE_MARKUP ? 'dollarMarkupGAP' : 'percentageMarkupGAP',
          null,
        ),
      );
    }
  }

  render() {
    const { handleSubmit, loading } = this.props;
    const { handleVSCMarkup, handleTWPMarkup, handleGAPMarkup } = this.state;
    return (
      <Grid>
        <Row>
          <Col xs={12}>
            <Helmet title="Dealer Settings" />
            {!loading && (
              <form
                className="form-horizontal"
                onSubmit={handleSubmit}
                noValidate={true}
                style={{ marginTop: 20 }}
              >
                <Col xs={12}>
                  <Col xs={6}>
                    {handleVSCMarkup === DOLLAR_MARKUP ? (
                      <Field
                        name="dollarMarkupVSC"
                        type="number"
                        component={ValidationInput}
                        label="VSC"
                      />
                    ) : (
                      <Field
                        name="percentageMarkupVSC"
                        type="number"
                        component={ValidationInput}
                        label="VSC"
                      />
                    )}
                  </Col>
                  <Col xs={6}>
                    <Field
                      name="markupSelectVSC"
                      type="number"
                      component={ValidationSelect}
                      options={MARKUP_OPTIONS}
                      onChange={e => this.handleMarkupType(e, 'VSC')}
                      defaultValue={handleVSCMarkup}
                    />
                  </Col>
                </Col>
                <Col xs={12}>
                  <Col xs={6}>
                    {handleTWPMarkup === DOLLAR_MARKUP ? (
                      <Field
                        name="dollarMarkupTWP"
                        type="number"
                        component={ValidationInput}
                        label="TWP"
                      />
                    ) : (
                      <Field
                        name="percentageMarkupTWP"
                        type="number"
                        component={ValidationInput}
                        label="TWP"
                      />
                    )}
                  </Col>
                  <Col xs={6}>
                    <Field
                      name="markupSelectTWP"
                      type="number"
                      component={ValidationSelect}
                      options={MARKUP_OPTIONS}
                      onChange={e => this.handleMarkupType(e, 'TWP')}
                      defaultValue={handleTWPMarkup}
                    />
                  </Col>
                </Col>
                <Col xs={12}>
                  <Col xs={6}>
                    {handleGAPMarkup === DOLLAR_MARKUP ? (
                      <Field
                        name="dollarMarkupGAP"
                        type="number"
                        component={ValidationInput}
                        label="GAP"
                      />
                    ) : (
                      <Field
                        name="percentageMarkupGAP"
                        type="number"
                        component={ValidationInput}
                        label="GAP"
                      />
                    )}
                  </Col>
                  <Col xs={6}>
                    <Field
                      name="markupSelectGAP"
                      type="number"
                      component={ValidationSelect}
                      options={MARKUP_OPTIONS}
                      onChange={e => this.handleMarkupType(e, 'GAP')}
                      defaultValue={handleGAPMarkup}
                    />
                  </Col>
                  <Button bsStyle="primary" type="submit">
                    Save
                  </Button>
                </Col>
              </form>
            )}
          </Col>
        </Row>
      </Grid>
    );
  }
}

export default compose(
  connect(
    ({ dealerSettings, page }) => ({
      dealerSettingsId: _.get(dealerSettings, 'data._id'),
      features: _.get(dealerSettings, 'data.features', {}),
      dealerSettings: dealerSettings.data,
      loading: page.loading,
    }),
    {
      getDealerSettings,
      updateDealerSettings,
    },
    (stateProps, dispatchProps, ownProps) => ({
      ...stateProps,
      ...dispatchProps,
      ...ownProps,
      onSubmit: async data => {
        await dispatchProps.updateDealerSettings(stateProps.dealerSettingsId, {
          ...data,
        });
      },
    }),
  ),
  reduxForm({
    form: DEALER_SETTINGS_FORM,
  }),
)(DealerSettingsForm);
